import React from 'react';
import ReactDOM from 'react-dom/client';
// import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/style.min.css';
import $ from "jquery";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);

$(document).ready(function () {
  $(document).on('click', '.main_menu', function () {
    if ($(this).parent().hasClass('active')) {
      $(this).parent().removeClass('active')
      $(this).parent().find('.sidebar-submenu').removeClass('d-block');
    } else {
      $('.sidebar-dropdown').removeClass('active')
      $('.sidebar-submenu').removeClass('d-block')
      $(this).parent().addClass('active');
      $(this).parent().find('.sidebar-submenu').addClass('d-block');
    }
  })
});
