import React, { useState, useEffect } from "react";
import Header from '../Navbar/Header'
import NavLinkHeader from '../Navbar/NavLinkHeader'
import Services from "../../Services/auth.service";
import swal from "sweetalert";
import 'react-accessible-accordion/dist/fancy-example.css';
import $ from 'jquery';
import { Link } from "react-router-dom";
import deleteImg from "../../assets/images/delete.png";
import { useNavigate, useLocation, Navigate } from "react-router-dom"
import loader from '../../assets/images/loder.gif'
import moment from "moment";
import Dropzone from "react-dropzone";
import Play from "../../assets/images/play.png"


const CryptoJS = require("crypto-js");

const Index = () => {
    const history = useNavigate();
    const getid = useLocation().search;
    const gatMenuID = new URLSearchParams(getid).get("id");

    var decryptId = CryptoJS.enc.Base64.parse(gatMenuID);
    var menuID = CryptoJS.enc.Utf8.stringify(decryptId); // 'Hello world'
    const [isLoading, setIsLoading] = useState(false);
    const [showLoader, setLoader] = useState(true);
    const [userRole, setUserRole] = useState();
    const [videoFile, setVideoFile] = useState();
    const [removeVideoId, setRemoveVideoID] = useState();

    //doctor faq
    var doctorVideo = [];
    const [doctorVideoData, setDoctorVideoData] = useState([]);

    //pharmacy
    var pharmacyVideo = [];
    const [pharmacyVideoData, setPharmacyVideoData] = useState([]);

    //laboratory
    var laboratoryVideo = [];
    const [laboratoryVideoData, setLaboratoryVideoData] = useState([]);

    var patientVideo = [];
    const [patientVideoData, setPatientVideoData] = useState([]);

    const [selectedVideoId, setSelectedVideoId] = useState();
    const [addVal, setAddVal] = useState({
        title: '',
        video: []
    });

    const [errAddVal, setErrAddVal] = useState({
        title: '',
        video: ''
    });

    const [editVal, setEditVal] = useState({
        titleEdit: '',
        videoEdit: '',
        videoId: ''
    });

    const [errEditVal, setErrEditVal] = useState({
        titleEdit: '',
        videoEdit: ''
    });

    useEffect(() => {
        const loggedInUser = localStorage.getItem("token");
        !loggedInUser && history("/");
        getData();
    }, [])

    const getData = async () => {
        await Services.getVideoData(menuID)
            .then((response) => {

                setLoader(false)

                if (response.data.status === true) {
                    var data = response.data.data;
                    data.map(async (data1) => {
                        if (await data1._id === "doctor") {
                            setUserRole('doctor')
                            doctorVideo = [...doctorVideoData, data1.records]
                        }
                        setDoctorVideoData(doctorVideo[0])
                        if (await data1._id === "pharmacy") {
                            setUserRole('pharmacy')
                            pharmacyVideo = [...pharmacyVideoData, data1.records]
                        }
                        setPharmacyVideoData(pharmacyVideo[0])
                        if (await data1._id === "patient") {
                            setUserRole('patient')
                            patientVideo = [...patientVideoData, data1.records]
                        }
                        setPatientVideoData(patientVideo[0])
                        if (await data1._id === "laboratory") {
                            setUserRole('laboratory')
                            laboratoryVideo = [...laboratoryVideoData, data1.records]
                        }
                        setLaboratoryVideoData(laboratoryVideo[0])
                    })

                }
            })
            .catch(function (err) {
                setLoader(false)
                // swal("Failed", err.response.data.message, "error");
            });

    }

    const onclickAdd = (userrole) => {
        setUserRole(userrole)
        setAddVal({ ...addVal, ["title"]: "", ["video"]: "" });
        $('#addVideo')[0].reset();
        $('#answer').val('');
        $('.error-msg').hide();
    }

    const handleOnchangeAdd = (e, type) => {
        var videofile;
        var value;
        var name;
        if (type === "title") {
            value = e.target.value;
            name = e.target.name;
            if (name == "title") {
                errAddVal.title = value.length > 0 ? "" : "Please enter the video title";
            }
            setAddVal({ ...addVal, ["title"]: value });

        } if (type === "video") {
            videofile = e[0]

            var filesplit = videofile.name.split(".").pop();
            var videoExtension = [
                "mp4",
                "MOV",
                "mov",
                "wmv",
                "avi",
                "avchd",
                "flv",
                "f4v",
                "swf",
                "mkv",
                "webm",
                "html5",
                "mpeg-2",
            ];
            videoExtension.includes(filesplit)
                ? (errAddVal.video = "")
                : (errAddVal.video = "Upload video only");
            setVideoFile(e[0])
            // if (videofile === " ") {
            //     errAddVal.video = videofile === "" ? "Please Upload video" : "";
            // }
            setAddVal({ ...addVal, ["video"]: videofile });

        }
        setErrAddVal({ ...errAddVal });

    };

    const onSubmitAdd = async (e) => {
        e.preventDefault();
        $('.error-msg').show();
        if (addVal.title == "") {
            errAddVal.title = "Please enter the video title";
        } else {
            errAddVal.title = "";
        }

        if (addVal.video == "") {
            errAddVal.video = "Please Upload video";
        } else {
            errAddVal.video = '';
        }

        setErrAddVal({ ...errAddVal });
        if (errAddVal.title === '' && errAddVal.video === '') {
            let formData = new FormData();
            // return false

            formData.append("title", addVal.title);
            formData.append("video", addVal.video);
            formData.append("user_type", userRole);
            setIsLoading(true)


            Services.addVideo(menuID, formData).then((response) => {
                setIsLoading(false)

                if (response.data.status === true) {
                    swal("Success", response.data.message, "success");
                    document.querySelector("#addVideo .btn-close").click();
                    // getData()
                     setTimeout(() => {
                        window.location.reload()
                    }, 1500) 

                } else {
                    swal("Failed", response.data.message, "error");
                    document.querySelector("#addVideo .btn-close").click();

                }
            }).catch((err) => {
                setIsLoading(false)

                swal("Failed", err.response.data.message, "error");
            });

        }
    };

    const onClickDelete = () => {
        Services.deleteVideo(menuID, selectedVideoId)
            .then((response) => {
                if (response.data.status === true) {
                    document.querySelector("#cancelappointment .btn-close").click();
                    setTimeout(() => {
                        window.location.reload()
                    }, 1500)
                    swal("Success", response.data.message, "success");
                    // getData();
                }
                else {
                    swal("Failed", response.data.message, "error");
                }
            })
            .catch(function (err) {
                swal("Failed", err.response.data.message, "error");
            });
    };

    const onCloseButton = () => {
        setVideoFile("")
        setErrAddVal("")
    }

    // const setEditData = (_id, title, video) => {
    //     setEditVal({ ...editVal, videoId: _id, titleEdit: title, videoEdit: video })
    // }
    // const RemoveVideoData = (selectedId) => {
    //     Services.deleteVideo(menuID, selectedId)

    //         .then((response) => {
    //             if (response.data.status === true) {
    //                 setVideoFile("")
    //                 document.querySelector("#cancelappointment .btn-close").click();
    //                 getData();
    //                 setTimeout(() => {
    //                   window.location.reload()
    //                 }, 1500)
    //                 swal("Success", response.data.message, "success");
    //             } else {
    //                 swal("Failed", response.data.message, "error");

    //             }
    //         })
    //         .catch(function (err) {
    //             swal("Failed", err.response.data.message, "error");
    //         });


    //     // setEditVal({ ...editVal, videoEdit: "" })
    // }
    // const handleOnchangeEdit = (e, type) => {

    //     var videofile;
    //     var value;
    //     var name;
    //     if (type === "title") {
    //         value = e.target.value;
    //         name = e.target.name
    //         if (name == "title") {
    //             errEditVal.titleEdit = value.length > 0 ? "" : "Please enter the video title";
    //         }
    //         setEditVal({ ...editVal, ["titleEdit"]: value });

    //     } if (type === "video") {
    //         videofile = e[0]
    //         setVideoFile(e[0])
    //         if (videofile === " ") {
    //             errEditVal.video = videofile === "" ? "Please Upload video" : "";
    //         }
    //         setAddVal({ ...editVal, ["videoEdit"]: videofile });

    //     }
    //     setErrEditVal({ ...errEditVal });
    // };

    // const onSubmitEdit = async (e) => {
    //     e.preventDefault();
    //     $('.error-msg').show();
    //     if (editVal.titleEdit == "") {
    //         errEditVal.question1 = "Please enter the video title";
    //     } else {
    //         errEditVal.question1 = "";
    //     }

    //     if (editVal.answer1 == "") {
    //         errEditVal.answer1 = "Please Upload video";
    //     } else {
    //         errEditVal.answer1 = '';
    //     }

    //     setErrEditVal({ ...errEditVal });

    //     if (errEditVal.question1 === '' && errEditVal.answer1 === '') {
    //         var bodyData = {
    //             question: editVal.question1,
    //             answer: editVal.answer1,
    //             user: userRole
    //         };
    //         return false
    //         Services.editVideo(menuID, editVal.videoId, bodyData).then((response) => {
    //             if (response.data.status === true) {
    //                 document.querySelector("#editfaqmodel .btn-close").click();
    //                 swal("Success", response.data.message, "success");
    //                 setTimeout(() => {
    //                     window.location.reload()
    //                 }, 1500)

    //             } else {
    //                 swal("Failed", response.data.message, "error");
    //             }
    //         }).catch((err) => {
    //             swal("Failed", err.response.data.message, "error");
    //         });

    //     }
    // };

    return (
        <>

            <main className="page-content">
                <Header />
                <div className="container-fluid">
                    <div className="layout-specing">
                        <div className="d-flex justify-content-between align-items-center mb-3">
                            <h4 className="mb-0">Onboarding Video List</h4>
                            <NavLinkHeader title1="Video List" />
                        </div>
                        {/* ------------------ */}
                        <div className="cstm-doctor-profile  rounded-md  border">
                            <div className="cstm-faq-only">
                                <div className="cstm-tabs-pf">
                                    <ul className="nav" id="pills-tab" role="tablist">
                                        <li className="nav-item">
                                            <a className="nav-link active" id="overview-tab" data-bs-toggle="pill" href="#Doctor" role="tab" aria-controls="Doctor" aria-selected="false">
                                                Doctor
                                            </a>
                                        </li>

                                        <li className="nav-item">
                                            <a className="nav-link" id="experience-tab" data-bs-toggle="pill" href="#Pharmacy" role="tab" aria-controls="Pharmacy" aria-selected="false">
                                                Pharmacy
                                            </a>
                                        </li>

                                        <li className="nav-item">
                                            <a className="nav-link" id="experience-tab" data-bs-toggle="pill" href="#Laboratory" role="tab" aria-controls="Laboratory" aria-selected="false">
                                                Laboratory
                                            </a>
                                        </li>

                                        <li className="nav-item">
                                            <a className="nav-link" id="experience-tab" data-bs-toggle="pill" href="#Users" role="tab" aria-controls="Users" aria-selected="false">
                                                Users
                                            </a>
                                        </li>
                                    </ul>

                                    <div className="tab-content mt-2" id="pills-tabContent">

                                        <div className="tab-pane fade show active" id="Doctor" role="tabpanel" aria-labelledby="overview-tab">

                                            <div className="d-flex justify-content-between align-items-center p-3">
                                                <h6 className="mb-0">Doctor Onboarding Video</h6>
                                                <div className="faq-add-bonus">
                                                    <a
                                                        href="javascript:void(0)"
                                                        data-bs-toggle="modal"
                                                        data-bs-target="#addVideo"
                                                        onClick={() => onclickAdd("doctor")}
                                                    >
                                                        <button className="cstm-btn">Add Video</button>
                                                    </a>
                                                </div>
                                            </div>

                                            <div class="d-flex justify-content-between align-items-center p-3">
                                                <div class="cstm-dov">

                                                    {doctorVideoData !== undefined &&  doctorVideoData.length > 0 &&

                                                        doctorVideoData.map((videoData, index) =>
                                                        (

                                                            <div class="cstm-dov-main">
                                                                <div class="cstm-dov-main-video">
                                                                    <video src={"https://anywherehealth.s3.amazonaws.com/" + videoData.video} controls />
                                                                </div>
                                                                <div class="cstm-dov-main-box">
                                                                    <div class="cstm-dov-main-title"><a href="#">{videoData.title}</a></div>
                                                                    <div class="cstm-dov-main-cont">
                                                                        <div class="cstm-dov-main-cont-left">
                                                                            <span class="cstm-oploaded-at">Uploaded at:</span>
                                                                            <span class="cstm-date-up">{moment(videoData.createdAt).format('DD MMM, YYYY')}</span>
                                                                        </div>
                                                                        <div class="cstm-dov-main-cont-right">

                                                                            {/* <Link
                                                                                className="cstm-chekmank mrn-rt2"
                                                                                data-bs-toggle="modal"
                                                                                data-bs-target="#editVideo"
                                                                                to={" "}
                                                                                onClick={() =>
                                                                                    (setEditData(videoData._id, videoData.title, videoData.video), setUserRole(videoData.user_type))
                                                                                }
                                                                            >
                                                                                <i className="fi fi-rr-pencil"></i>
                                                                            </Link> */}

                                                                            <Link
                                                                                to={" "}
                                                                                onClick={() => setSelectedVideoId(videoData._id)}
                                                                                className="cstm-cross"
                                                                                data-bs-toggle="modal"
                                                                                data-bs-target="#cancelappointment"
                                                                            >
                                                                                <i className="fi fi-rr-trash"></i>
                                                                            </Link>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))
                                                    }
                                                    {((doctorVideoData == undefined )|| (doctorVideoData !== undefined &&  doctorVideoData.length <= 0))&& <><div class="cstm-no-videos-found">No videos found</div></>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className="tab-pane fade" id="Pharmacy" role="tabpanel" aria-labelledby="experience-tab">
                                            <div className="d-flex justify-content-between align-items-center p-3">
                                                <h6 className="mb-0">Pharmacy Onboarding Video</h6>
                                                <div className="faq-add-bonus">
                                                    <a
                                                        href="javascript:void(0)"
                                                        data-bs-toggle="modal"
                                                        data-bs-target="#addVideo"
                                                        onClick={() => onclickAdd("pharmacy")}
                                                    >
                                                        <button className="cstm-btn">Add Video</button>
                                                    </a>
                                                </div>
                                            </div>
                                            <div class="d-flex justify-content-between align-items-center p-3">
                                                <div class="cstm-dov">
                                                    {pharmacyVideoData !== undefined && pharmacyVideoData?.map((videoData, index) => (
                                                        <div class="cstm-dov-main">
                                                            <div class="cstm-dov-main-video">
                                                                <video src={"https://anywherehealth.s3.amazonaws.com/" + videoData.video} controls />
                                                            </div>
                                                            <div class="cstm-dov-main-box">
                                                                <div class="cstm-dov-main-title"><a href="#">{videoData.title}</a></div>
                                                                <div class="cstm-dov-main-cont">
                                                                    <div class="cstm-dov-main-cont-left">
                                                                        <span class="cstm-oploaded-at">Uploaded at:</span>
                                                                        <span class="cstm-date-up">{moment(videoData.createdAt).format('DD MMM, YYYY')}</span>
                                                                    </div>
                                                                    <div class="cstm-dov-main-cont-right">

                                                                        {/* <Link
                                                                                className="cstm-chekmank mrn-rt2"
                                                                                data-bs-toggle="modal"
                                                                                data-bs-target="#editVideo"
                                                                                to={" "}
                                                                                onClick={() =>
                                                                                    (setEditData(videoData._id, videoData.title, videoData.video), setUserRole(videoData.user_type))
                                                                                }
                                                                            >
                                                                                <i className="fi fi-rr-pencil"></i>
                                                                         </Link> */}

                                                                        <Link
                                                                            to={" "}
                                                                            onClick={() => setSelectedVideoId(videoData._id)}
                                                                            className="cstm-cross"
                                                                            data-bs-toggle="modal"
                                                                            data-bs-target="#cancelappointment"
                                                                        >
                                                                            <i className="fi fi-rr-trash"></i>
                                                                        </Link>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                     {((pharmacyVideoData == undefined) || (pharmacyVideoData !== undefined &&  pharmacyVideoData.length <= 0))&& <><div class="cstm-no-videos-found">No videos found</div></>
                                                    }
                                                </div>
                                            </div>

                                            {/* <div className="accordion  p-3 cstm-faq-accordion" id="buying"> */}

                                            {/* {
                                                    pharmacyVideoData !== undefined && pharmacyVideoData.map((video, index) =>
                                                    (<div className="accordion-item border rounded" key={index}>
                                                        <h2 className="accordion-header" id={`heading${index}`}>
                                                            <div className="cstm-doctor-faq-icon">
                                                                <Link
                                                                    className="cstm-chekmank mrn-rt2"
                                                                    data-bs-toggle="modal"
                                                                    data-bs-target="#editfaqmodel"
                                                                    to={" "}
                                                                    onClick={() =>
                                                                        (setEditData(video._id, faq.question, faq.answer), setUserRole("pharmacy"))
                                                                    }
                                                                >
                                                                    <i className="fi fi-rr-pencil"></i>
                                                                </Link>

                                                                <Link
                                                                    to={" "}
                                                                    onClick={() => setSelectedVideoId(video._id)}
                                                                    className="cstm-cross"
                                                                    data-bs-toggle="modal"
                                                                    data-bs-target="#cancelappointment"
                                                                >
                                                                    <i className="fi fi-rr-trash"></i>
                                                                </Link>
                                                            </div>

                                                            <button className="accordion-button border-0 " type="button" data-bs-toggle="collapse" data-bs-target={`#collapse${index}`}
                                                                aria-expanded="true" aria-controls={`collapse${index}`}>
                                                                {faq.question}
                                                            </button>
                                                        </h2>
                                                        <div id={`collapse${index}`} className="accordion-collapse border-0 collapse show" aria-labelledby={`heading${index}`}
                                                            data-bs-parent="#buying">
                                                            <div className="accordion-body text-muted">
                                                                {faq.answer}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    ))

                                                } */}

                                            {/* </div> */}
                                        </div>
                                        <div className="tab-pane fade" id="Laboratory" role="tabpanel" aria-labelledby="experience-tab">
                                            <div className="d-flex justify-content-between align-items-center p-3">
                                                <h6 className="mb-0">Laboratory Onboarding Video</h6>
                                                <div className="faq-add-bonus">
                                                    <a
                                                        href="javascript:void(0)"
                                                        data-bs-toggle="modal"
                                                        data-bs-target="#addVideo"
                                                        onClick={() => onclickAdd("laboratory")}
                                                    >
                                                        <button className="cstm-btn">Add Video</button>
                                                    </a>
                                                </div>
                                            </div>
                                            <div class="d-flex justify-content-between align-items-center p-3">
                                                <div class="cstm-dov">

                                                    {laboratoryVideoData !== undefined && laboratoryVideoData?.map((videoData, index) =>
                                                    (

                                                        <div class="cstm-dov-main">
                                                            <div class="cstm-dov-main-video">
                                                                <video src={"https://anywherehealth.s3.amazonaws.com/" + videoData.video} controls />
                                                            </div>
                                                            <div class="cstm-dov-main-box">
                                                                <div class="cstm-dov-main-title"><a href="#">{videoData.title}</a></div>
                                                                <div class="cstm-dov-main-cont">
                                                                    <div class="cstm-dov-main-cont-left">
                                                                        <span class="cstm-oploaded-at">Uploaded at:</span>
                                                                        <span class="cstm-date-up">{moment(videoData.createdAt).format('DD MMM, YYYY')}</span>
                                                                    </div>
                                                                    <div class="cstm-dov-main-cont-right">

                                                                        {/* <Link
                                                                                className="cstm-chekmank mrn-rt2"
                                                                                data-bs-toggle="modal"
                                                                                data-bs-target="#editVideo"
                                                                                to={" "}
                                                                                onClick={() =>
                                                                                    (setEditData(videoData._id, videoData.title, videoData.video), setUserRole(videoData.user_type))
                                                                                }
                                                                            >
                                                                                <i className="fi fi-rr-pencil"></i>
                                                                            </Link> */}

                                                                        <Link
                                                                            to={" "}
                                                                            onClick={() => setSelectedVideoId(videoData._id)}
                                                                            className="cstm-cross"
                                                                            data-bs-toggle="modal"
                                                                            data-bs-target="#cancelappointment"
                                                                        >
                                                                            <i className="fi fi-rr-trash"></i>
                                                                        </Link>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))
                                                    }
                                                    {((laboratoryVideoData == undefined )|| (laboratoryVideoData !== undefined &&  laboratoryVideoData.length <= 0))&& <><div class="cstm-no-videos-found">No videos found</div></>
                                                    }
                                                </div>
                                            </div>

                                            {/* <div className="accordion  p-3 cstm-faq-accordion" id="buying"> */}

                                            {/* {
                                                    laboratoryVideoData !== undefined && laboratoryVideoData.map((video, index) =>
                                                    (<div className="accordion-item border rounded" key={index}>
                                                        <h2 className="accordion-header" id={`heading${index}`}>
                                                            <div className="cstm-doctor-faq-icon">
                                                                <Link
                                                                    className="cstm-chekmank mrn-rt2"
                                                                    data-bs-toggle="modal"
                                                                    data-bs-target="#editfaqmodel"
                                                                    to={" "}
                                                                    onClick={() =>
                                                                        (setEditData(video._id, faq.question, faq.answer), setUserRole("laboratory"))
                                                                    }
                                                                >
                                                                    <i className="fi fi-rr-pencil"></i>
                                                                </Link>

                                                                <Link
                                                                    to={" "}
                                                                    onClick={() => setSelectedVideoId(video._id)}
                                                                    className="cstm-cross"
                                                                    data-bs-toggle="modal"
                                                                    data-bs-target="#cancelappointment"
                                                                >
                                                                    <i className="fi fi-rr-trash"></i>
                                                                </Link>
                                                            </div>

                                                            <button className="accordion-button border-0 " type="button" data-bs-toggle="collapse" data-bs-target={`#collapse${index}`}
                                                                aria-expanded="true" aria-controls={`collapse${index}`}>
                                                                {faq.question}
                                                            </button>
                                                        </h2>
                                                        <div id={`collapse${index}`} className="accordion-collapse border-0 collapse show" aria-labelledby={`heading${index}`}
                                                            data-bs-parent="#buying">
                                                            <div className="accordion-body text-muted">
                                                                {faq.answer}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    ))

                                                } */}

                                            {/* </div> */}
                                        </div>
                                        <div className="tab-pane fade" id="Users" role="tabpanel" aria-labelledby="experience-tab">
                                            <div className="d-flex justify-content-between align-items-center p-3">
                                                <h6 className="mb-0">User Onboarding Video</h6>
                                                <div className="faq-add-bonus">
                                                    <a
                                                        href="javascript:void(0)"
                                                        data-bs-toggle="modal"
                                                        data-bs-target="#addVideo"
                                                        onClick={() => onclickAdd("patient")}
                                                    >
                                                        <button className="cstm-btn">Add Video</button>
                                                    </a>
                                                </div>
                                            </div>
                                            <div class="d-flex justify-content-between align-items-center p-3">
                                                <div class="cstm-dov">

                                                    {patientVideoData !== undefined &&  patientVideoData.length > 0 &&

                                                        patientVideoData.map((videoData, index) =>
                                                        (

                                                            <div class="cstm-dov-main">
                                                                <div class="cstm-dov-main-video">
                                                                    <video src={"https://anywherehealth.s3.amazonaws.com/" + videoData.video} controls />
                                                                </div>
                                                                <div class="cstm-dov-main-box">
                                                                    <div class="cstm-dov-main-title"><a href="#">{videoData.title}</a></div>
                                                                    <div class="cstm-dov-main-cont">
                                                                        <div class="cstm-dov-main-cont-left">
                                                                            <span class="cstm-oploaded-at">Uploaded at:</span>
                                                                            <span class="cstm-date-up">{moment(videoData.createdAt).format('DD MMM, YYYY')}</span>
                                                                        </div>
                                                                        <div class="cstm-dov-main-cont-right">

                                                                            {/* <Link
                                                                                className="cstm-chekmank mrn-rt2"
                                                                                data-bs-toggle="modal"
                                                                                data-bs-target="#editVideo"
                                                                                to={" "}
                                                                                onClick={() =>
                                                                                    (setEditData(videoData._id, videoData.title, videoData.video), setUserRole(videoData.user_type))
                                                                                }
                                                                            >
                                                                                <i className="fi fi-rr-pencil"></i>
                                                                            </Link> */}

                                                                            <Link
                                                                                to={" "}
                                                                                onClick={() => setSelectedVideoId(videoData._id)}
                                                                                className="cstm-cross"
                                                                                data-bs-toggle="modal"
                                                                                data-bs-target="#cancelappointment"
                                                                            >
                                                                                <i className="fi fi-rr-trash"></i>
                                                                            </Link>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))
                                                    }
                                                     {((patientVideoData == undefined )|| (patientVideoData !== undefined &&  patientVideoData.length <= 0))&& <><div class="cstm-no-videos-found">No videos found</div></>
                                                    }
                                                </div>
                                            </div>

                                            {/* <div className="accordion  p-3 cstm-faq-accordion" id="buying"> */}

                                            {/* {
                                                    patientVideoData !== undefined && patientVideoData.map((video, index) =>
                                                    (<div className="accordion-item border rounded" key={index}>
                                                        <h2 className="accordion-header" id={`heading${index}`}>
                                                            <div className="cstm-doctor-faq-icon">
                                                                <Link
                                                                    className="cstm-chekmank mrn-rt2"
                                                                    data-bs-toggle="modal"
                                                                    data-bs-target="#editfaqmodel"
                                                                    to={" "}
                                                                    onClick={() => (setEditData(video._id, faq.question, faq.answer), setUserRole("patient")
                                                                    )}
                                                                >
                                                                    <i className="fi fi-rr-pencil"></i>
                                                                </Link>

                                                                <Link
                                                                    to={" "}
                                                                    onClick={() => setSelectedVideoId(video._id)}
                                                                    className="cstm-cross"
                                                                    data-bs-toggle="modal"
                                                                    data-bs-target="#cancelappointment"
                                                                >
                                                                    <i className="fi fi-rr-trash"></i>
                                                                </Link>
                                                            </div>

                                                            <button className="accordion-button border-0 " type="button" data-bs-toggle="collapse" data-bs-target={`#collapse${index}`}
                                                                aria-expanded="true" aria-controls={`collapse${index}`}>
                                                                {faq.question}
                                                            </button>
                                                        </h2>
                                                        <div id={`collapse${index}`} className="accordion-collapse border-0 collapse show" aria-labelledby={`heading${index}`}
                                                            data-bs-parent="#buying">
                                                            <div className="accordion-body text-muted">
                                                                {faq.answer}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    ))

                                                } */}

                                            {/* </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* ----------------------------- */}
                    </div>
                </div>
                {showLoader && <div className="NoRecord-cstm"> <img className="pageloader" src={loader} /></div>
                }
            </main>

            {/* add Video modal */}

            <div class="modal fade" id="addVideo" tabindex="-1" aria-labelledby="exampleModalLabel1" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header border-0 p-4">
                            <h4 class="modal-title" id="exampleModalLabel1">Add New Video</h4>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={onCloseButton}></button>
                        </div>

                        <form id="addvideoform" onSubmit={onSubmitAdd}>


                            <div class="modal-body p-4 pt-0">
                                <div class="mb-3">
                                    <label class="cstm-label">Title of Video*</label>
                                    <input type="text" class="cstm-input" placeholder="Title of video " onChange={(e) => handleOnchangeAdd(e, "title")} name="title" required="" />
                                    {errAddVal.title !== "" && (
                                        <span className="error-msg" style={{ color: "red" }}>
                                            {errAddVal.title}
                                        </span>
                                    )}
                                </div>
                                <Dropzone
                                    onDrop={(e) => handleOnchangeAdd(e, "video")}
                                    name="video_dragdrop"
                                    multiple={false}
                                    accept="video/*"
                                    maxSizeBytes="10485760"
                                >
                                    {({ getRootProps, getInputProps }) => (

                                        <div {...getRootProps({ className: "mb-4 dropzone" })}>

                                            <label class="cstm-upload-video">

                                                {/* <input {...getInputProps()} name="video" /> */}

                                                <div class="cstm-upload-video-icon">
                                                    {/* <img src={Play} /> */}
                                                    {
                                                        videoFile ? <video width="200px" src={URL.createObjectURL(videoFile)} /> :
                                                            <img src={Play} />

                                                    }
                                                </div>
                                                <div class="cstm-upload-video-name">
                                                    <span class="cstm-add-video-name">Add video.</span>
                                                    <span class="cstm-upload-video-upto">Upload video upto 10MB</span>
                                                </div>
                                            </label>
                                            {errAddVal.video !== "" && (
                                                <span className="error-msg" style={{ color: "red" }}>
                                                    {errAddVal.video}
                                                </span>
                                            )}
                                        </div>
                                    )}
                                </Dropzone>


                                <div class="d-flex justify-content-end">
                                    <div className="cstm-ldr-mn">
                                        <button class="cstm-btn6">Upload</button>
                                    </div>
                                    {isLoading &&
                                        <div id="btn-loader" className="btn-loader-main">
                                            <img className="btn-loaderImg" src={loader} /></div>
                                    }
                                </div>


                            </div>
                        </form>
                    </div>
                </div>
            </div>


            {/* edit Video modal */}

            {/* <div class="modal fade" id="editVideo" tabindex="-1" aria-labelledby="exampleModalLabel1" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header border-0 p-4">
                            <h4 class="modal-title" id="exampleModalLabel1">Edit Video</h4>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <form id="editvideoform" onSubmit={onSubmitEdit}>

                            <div class="modal-body p-4 pt-0">
                                <div class="mb-3">
                                    <label class="cstm-label">Title of Video*</label>
                                    <input type="text" class="cstm-input" placeholder="Title of video " value={editVal.titleEdit} onChange={(e) => handleOnchangeEdit(e, "title")} name="titleEdit" required="" />
                                    {errEditVal.titleEdit !== "" && (
                                        <span className="error-msg" style={{ color: "red" }}>
                                            {errEditVal.titleEdit}
                                        </span>
                                    )}
                                </div>
                                <Dropzone
                                    onDrop={(e) => handleOnchangeEdit(e, "video")}
                                    name="video_dragdrop"
                                    multiple={false}
                                    accept="video/*"
                                    maxSizeBytes="10485760"
                                >
                                    {({ getRootProps, getInputProps }) => (

                                        <div {...getRootProps({ className: "mb-4 dropzone" })}>
                                            <input {...getInputProps()} name="video" />

                                            <div class="cstm-upload-detial">
                                                <div class="cstm-upload-detial-video">
                                                    <video src=""></video>

                                                    {
                                                        editVal.videoEdit && <video width="200px" src={"https://anywherehealth.s3.amazonaws.com/" + editVal.videoEdit} />

                                                    }
                                                </div>
                                                <div class="cstm-upload-detial-dt">
                                                    <span class="cstm-upload-detial-dt-name">{editVal.titleEdit}</span>
                                                    <span class="cstm-upload-video-upto">8.23MB</span>
                                                    <span class="cstm-upload-video-remove">
                                                        <Link to={""}  
                                                        onClick={() =>
                                                            (setRemoveVideoData(editVal.videoId, editVal.titleEdit, editVal.videoEdit), setRemoveVideoID(editVal._id))
                                                        }>Remove Video</Link>
                                                        </span>
                                                </div>
                                            </div>


                                            {errEditVal.videoEdit !== "" && (
                                                <span className="error-msg" style={{ color: "red" }}>
                                                    {errEditVal.videoEdit}
                                                </span>
                                            )}
                                        </div>
                                    )}
                                </Dropzone>
                                <button onClick={() =>(RemoveVideoData(editVal.videoId))}>Remove Video</button>


                                <div class="d-flex justify-content-end">
                                    <button class=" cstm-btn5">Upload</button>
                                </div>

                            </div>
                        </form>
                    </div>
                </div>
            </div> */}






            {/* ------------delete modal--------------------- */}

            <div className="modal fade cstm-add-bonus" id="cancelappointment" tabindex="-1" aria-labelledby="exampleModalLabel1" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header border-0 p-4">
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body p-4 pt-0">
                            <div className="mb-4">

                                <div className="text-center mb-4">
                                    <img src={deleteImg} />
                                </div>
                                <h4 className="text-center mb-4">Are you sure want to delete this Video?</h4>
                                <div className="text-center">
                                    <button className="cstm-btn7" onClick={onClickDelete}>Delete Video</button>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Index;


