import React, { useState, useEffect } from 'react'
import Header from '../Navbar/Header'
import Services from "../../Services/auth.service";
import swal from "sweetalert";
import NavLinkHeader from '../Navbar/NavLinkHeader'
import moment from "moment";
import priceIcon from "../../assets/images/price-icon.png"
import expenseImage from "../../assets/images/expense.svg"
import incomeImage from "../../assets/images/income.svg"
import dummyImage from "../../assets/images/dummy.png"
import plusImg from "../../assets/images/plus.png";
import { Link, useNavigate, useLocation } from "react-router-dom";
const CryptoJS = require("crypto-js");


const RevenueDashboard = () => {

    const [PurchesSubscription, setPurchesSubscription] = useState([]);
    const [RecentlyWithdrawal, setRecentlyWithdrawal] = useState([]);
    const [dates, setDates] = useState("")

    useEffect(() => {
        getPurchesSubscription();
        getRecentlyWithdrawal();
    }, []);

    const history = useNavigate();
    const getid = useLocation().search;
    const getMenuID = new URLSearchParams(getid).get("id");
    var decryptId = CryptoJS.enc.Base64.parse(getMenuID);
    var menuID = CryptoJS.enc.Utf8.stringify(decryptId);

    var encyptId;
    var recordIdEncrypt;

    // Recently Purchased Subscriptions

    const getPurchesSubscription = () => {
        Services.getPurchesSubscription(menuID).then((response) => {
            if (response.data.status === true) {
                setPurchesSubscription(response.data.data)
            } else {
                swal("Failed", response.data.message, "error");
            }
        })
            .catch((err) => {
                swal("Failed", err.response.data.message, "error");
            });
    }

    const getRecentlyWithdrawal = () => {
        Services.getRecentlyWithdrawal(menuID).then((response) => {
            if (response.data.status === true) {
                var alldata = response.data.data;

                if (response.data.data.length > 0) {
                    for (var i = 0; i < alldata.length; i++) {
                        var date = moment(alldata.createdAt).fromNow()
                        setDates(date)
                    }
                    setRecentlyWithdrawal(alldata);
                }

                // setRecentlyWithdrawal(alldata)
                // for (var i = 0; i < alldata.length; i++) {
                // var date = moment(alldata.createdAt)
                // .utcOffset('+05:30')
                // .format('YYYY-MM-DD hh:mm:ss a');
                
                // }
            } else {
                swal("Failed", response.data.message, "error");
            }
        })
            .catch((err) => {
                swal("Failed", err.response.data.message, "error");
            });
    }

    return (
        <>-``.  -+
            <div className="page-wrapper doctris-theme toggled">

                <main className="page-content bg-light">
                    <Header />
                    <div className="container-fluid">
                        <div className="layout-specing">
                            <div className="d-flex justify-content-between align-items-center mb-3">
                                <h4 className="mb-0">Revenue Dashboard</h4>
                                <NavLinkHeader main_title="Dashboard" main_title_link="/dashboard" title1="Revenue Dashboard" />
                            </div>
                            <div className="row">
                                <div className="cstm-rd-list">
                                    <div className="card features feature-primary border rounded-md p-3">
                                        <div className="cstm-icon4 mb-3">
                                            <img src={priceIcon} />
                                        </div>
                                        <div className="flex-1">
                                            <h3 className="mb-0">₦3500</h3>
                                            <p className="text-muted mb-0 cstm-tx14">Total Earning </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="cstm-rd-list">
                                    <div className="card features feature-primary border rounded-md p-3">
                                        <div className="cstm-icon4 mb-3">
                                            <img src={priceIcon} />
                                        </div>
                                        <div className="flex-1">
                                            <h3 className="mb-0">₦1500</h3>
                                            <p className="text-muted mb-0 cstm-tx14">Total Expense</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="cstm-rd-list">
                                    <div className="card features feature-primary border rounded-md p-3">
                                        <div className="cstm-icon4 mb-3">
                                            <img src={priceIcon} />
                                        </div>
                                        <div className="flex-1">
                                            <h3 className="mb-0">₦2000</h3>
                                            <p className="text-muted mb-0 cstm-tx14">Remain Balance</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="cstm-rd-list">
                                    <div className="card features feature-primary border rounded-md p-3">
                                        <div className="cstm-icon4 mb-3">
                                            <img src={priceIcon} />
                                        </div>
                                        <div className="flex-1">
                                            <h3 className="mb-0">₦500</h3>
                                            <p className="text-muted mb-0 cstm-tx14">Doctor Expense</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="cstm-rd-list">
                                    <div className="card features feature-primary border rounded-md p-3">
                                        <div className="cstm-icon4 mb-3">
                                            <img src={priceIcon} />
                                        </div>
                                        <div className="flex-1">
                                            <h3 className="mb-0">₦500</h3>
                                            <p className="text-muted mb-0 cstm-tx14">Laboratroy Expense</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xl-6 col-lg-6  mt-4  mt-4 ">
                                    <div className="card border rounded-md p-3">
                                        <div className="d-flex justify-content-between align-items-center mb-3">
                                            <h5 className="align-items-center mb-0">Expense report</h5>
                                            <div className="mb-0 position-relative">
                                                <select className="form-select form-control" id="yearchart">
                                                    <option selected="">2021</option>
                                                    <option value={2019}>2020</option>
                                                    <option value={2018}>2019</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="cstm-revenue-analytics">
                                            <img src={expenseImage} />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-6 col-lg-6 mt-4">
                                    <div className="card border rounded-md p-3">
                                        <div className="d-flex justify-content-between align-items-center mb-3">
                                            <h5 className="align-items-center mb-0">Income report</h5>
                                            <div className="mb-0 position-relative">
                                                <select className="form-select form-control" id="yearchart">
                                                    <option selected="">2021</option>
                                                    <option value={2019}>2020</option>
                                                    <option value={2018}>2019</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="cstm-revenue-analytics">
                                            <img src={incomeImage} />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-xl-6 col-lg-6 mt-4">
                                    <div className="card  border rounded-md rounded">
                                        <div className="d-flex justify-content-between align-items-center p-3 border-bottom">
                                            <h5 className="mb-0">Recently Purchased Subscriptions</h5>
                                            <button className="cstm-btn4">View All</button>
                                        </div>
                                        <ul className="list-unstyled mb-0 p-3">

                                            {
                                                PurchesSubscription.length > 0 && PurchesSubscription.slice(0, 5).map((subscription, index) => (
                                                    encyptId = CryptoJS.enc.Utf8.parse(subscription._id),  // WordArray object
                                                    recordIdEncrypt = CryptoJS.enc.Base64.stringify(encyptId),
                                                    <li className="mb-3">
                                                        <div className="d-flex align-items-center justify-content-between">
                                                            <div className="d-inline-flex">
                                                            <img className="cstm-rco-photo" src={subscription.patient_otherinfo[0].profileImg !== "" ? "https://anywherehealth.s3.amazonaws.com/" + subscription.patient_otherinfo[0].profileImg : plusImg} />
                                                                <div className="ms-3">
                                                                   
                                                                    <h5 className="text-dark mb-0 d-block">
                                                                        {subscription.patient_otherinfo[0].first_name} {subscription.patient_otherinfo[0].surname}
                                                                    </h5>
                                                                    <small className="text-muted">Heart Sergon</small>
                                                                </div>
                                                            </div>
                                                            <div className="cstm-rps-label">
                                                                <span className="cstm-silver">{subscription.plan_info[0].name}</span>
                                                            </div>
                                                        </div>
                                                    </li>
                                                ))
                                            }
                                        </ul>
                                    </div>
                                </div>


                                <div className="col-xl-6 col-lg-6 mt-4">
                                    <div className="card  border rounded-md rounded">
                                        <div className="d-flex justify-content-between align-items-center p-3 border-bottom">
                                            <h5 className="mb-0"> Recently Payroll</h5>
                                            <button className="cstm-btn4">View All</button>
                                        </div>
                                        <ul className="list-unstyled mb-0 p-3 cstm-requested-charity-crganization">
                                            {
                                                RecentlyWithdrawal.length > 0 && RecentlyWithdrawal.slice(0, 5).map((Withdrawal, index) => (
                                                    encyptId = CryptoJS.enc.Utf8.parse(Withdrawal._id),  // WordArray object
                                                    recordIdEncrypt = CryptoJS.enc.Base64.stringify(encyptId),

                                                    <li className="d-flex align-items-center justify-content-between mb-3">
                                                        <div className="d-flex align-items-center">
                                                            <img
                                                                src={Withdrawal.doctorData.signup_otherdetail_id[0].profileImg !== "" ? "https://anywherehealth.s3.amazonaws.com/" + Withdrawal.doctorData.signup_otherdetail_id[0].profileImg : dummyImage}
                                                                className="cstm-rco-photo"
                                                                alt=""
                                                            />
                                                            <div className="flex-1 ms-3">
                                                                <span className="d-block h5 mb-0">{Withdrawal.doctorData.signup_otherdetail_id[0].first_name} {Withdrawal.doctorData.signup_otherdetail_id[0].surname}</span>
                                                                <small className="text-muted">Orthopedic</small>
                                                            </div>
                                                        </div>
                                                        <div className="text-right">
                                                            <h4 className="mb-0">₦{Withdrawal.amount}</h4>
                                                            <small className="text-muted">{dates}</small>
                                                        </div>
                                                    </li>

                                                ))

                                            }
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>


        </>
    )
}

export default RevenueDashboard
