import React, { useState, useEffect } from "react";
import Header from "../Navbar/Header";
import NavLinkHeader from "../Navbar/NavLinkHeader";
import Services from "../../Services/auth.service";
import swal from "sweetalert";
import loader from "../../assets/images/loder.gif";
import { Link, useNavigate, useLocation } from "react-router-dom";
import pn from "../../assets/images/pn.png";
import ms from "../../assets/images/ms.png";
import photo from "../../assets/images/photo.png";
import documentImg from "../../assets/images/document.png";
import plusImg from "../../assets/images/plus.png";
import docxImage from "../../assets/images/docs.png";
import pdfImage from '../../assets/images/download.png'
import yes from "../../assets/images/yes.png";
import moment from "moment";

const CryptoJS = require("crypto-js");

const ViewPendingDoctor = () => {
    const history = useNavigate();
    const getid = useLocation().search;
    const getMenuID = new URLSearchParams(getid).get("id");
    var decryptId = CryptoJS.enc.Base64.parse(getMenuID);
    var menuID = CryptoJS.enc.Utf8.stringify(decryptId);

    //decrypt recordid
    const gatRecordID = new URLSearchParams(getid).get("record_id");
    var decryptRecordId = CryptoJS.enc.Base64.parse(gatRecordID);
    var recordID = CryptoJS.enc.Utf8.stringify(decryptRecordId);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingDeclain, setIsLoadingDeclain] = useState(false);

    const [doctorProfile, setDoctorProfile] = useState({});
    const [doctorAllData, setDoctorAllData] = useState({});
    const [specializationCategory, setSpecializationCategory] = useState([]);
    const [documentsData, setDocumentsData] = useState([]);
    var DocumentExtension;

    const [declainData, setDeclainData] = useState({
        "reason": "",
        "declain_done": false
    })
    const [err, setErr] = useState({
        "reason": "",
        "declain_done": ""
    })

    useEffect(() => {
        const loggedInUser = localStorage.getItem("token");
        !loggedInUser && history("/");
        getPendingDoctorProfileData();
    }, []);
    var get_doctor_id = [];
    const getPendingDoctorProfileData = () => {
        Services.getDoctorById(menuID, recordID).then((response) => {
            setDoctorProfile(response.data.data.signup_otherdetail_id)
            setDoctorAllData(response.data.data)

            var SpecializedCategory = response.data.data.specialization_ids
            setSpecializationCategory(SpecializedCategory)
            if (response.data.data.documents_id.length > 0) {
                setDocumentsData(response.data.data.documents_id)

                var arr = response.data.data.documents_id;
                for (var i = 0; i < arr.length; i++) {
                    get_doctor_id = response.data.data.documents_id[i].files;
                }
            }

        }).catch((err) => {
        })
    }


    const handleApproveRejectDocument = (documentID, documentCatID, doctorID, status) => {

        var bodyData = {
            "doctor_document_id": documentID,
            "document_category_id": documentCatID,
            "doctor_id": doctorID,
            "verify_status": status
            // "approved"
        }

        setIsLoading(true)
        Services.approveDocument(menuID, bodyData)
            .then((response) => {
                setIsLoading(false)
                if (response.data.status === true) {
                    if(response.data.message === "Document's verify status updated.")
                    swal("Success", response.data.message, "success");
                    else{
                        history(`/doctors?id=${getMenuID}`);
                    }
                    getPendingDoctorProfileData();

                } else {
                    setIsLoading(false)
                    swal("Failed", response.data.message, "error")
                }
            })
            .catch(function (err) {
                setIsLoading(false)
                swal("Failed", err.response.data.message, "error");

            })
    }
    const handleApprovedAccount = (doctorId) => {
        var bodyData = {
            "doctor_id": doctorId
        }

        Services.approveDoctorAccount(menuID, bodyData)
            .then((response) => {
                if (response.data.status === true) {
                    // document.querySelector("#ApproveAccount .btn-close").click();
                    swal("Success", response.data.message, "success");
                    history(`/doctors?id=${getMenuID}`);
                } else {
                    swal("Failed", response.data.message, "error");
                }
            })
            .catch(function (err) {
                // swal("Failed", err.response.message, "error");
            });
    };

    const handleOnChange = (e) => {
        const { name, value } = e.target;
        if (name === "declain_done") {
            var status = e.target.checked;
            e.target.checked === true ? err.declain_done = "" : err.declain_done = "you need to check the checkbox"
            setDeclainData({ ...declainData, declain_done: status })
        }

        if (name === "reason") {
            value.length > 0 ? err.reason = "" : err.reason = "Write the declain reason"
            setDeclainData({ ...declainData, "reason": value })
        }
        setErr({ ...err })
    }



    const handleSubmit = (e) => {
        e.eventDefault();
        if (declainData.reason === "") {
            err.reason = "Write the declain reason"
        } else {
            err.reason = ""
        }
        if (declainData.declain_done === false) {
            err.declain_done = "you need to check the checkbox"
        } else {
            err.declain_done = ""
        }
        setErr({ ...err })
    }

    const handleDeclain = () => {
        if (declainData.declain_done === true) {

            var bodyData = {
                "doctor_id": recordID,
                "account_decline_reasons": declainData.reason
            }

            setIsLoadingDeclain(true);
            Services.declainDoctorAccount(menuID, bodyData)
                .then((response) => {
                    setIsLoadingDeclain(false);
                    if (response.data.status === true) {
                        document.querySelector("#DeclineAccount .btn-close").click();
                        swal("Success", response.data.message, "success");
                    }
                })
                .catch(function (err) {
                    setIsLoadingDeclain(false);
                })
        }
    }


    return (
        <main class="page-content">
            <Header />
            <div class="container-fluid">
                <div class="layout-specing">
                    <div class="d-flex justify-content-between align-items-center mb-3">
                        <h4 class="mb-0">Doctor Profile</h4>
                        <NavLinkHeader
                            title1="Pending Requests"
                            title1_link={`/pending-doctors?id=${getMenuID}`}
                            title2="View Doctor Profile"
                        />
                    </div>

                    <div class="cstm-doctor-profile  rounded-md  border">
                        <div class="cstm-doctor-profile-left">
                            <div class="">
                                <div class="mb-3  mt-5 text-center">
                                    <img class="csatm-od-photo" src={doctorProfile.profileImg ? "https://anywherehealth.s3.amazonaws.com/" + doctorProfile.profileImg : plusImg} />
                                </div>
                                <h6 class="mb-1 text-center">{doctorProfile.surname && doctorProfile.surname} {doctorProfile.first_name && doctorProfile.first_name}</h6>
                                <p class="mb-4 text-center">{doctorProfile.address && doctorProfile.address}</p>

                                <div class="d-flex text-center p-3  cstm-vp-te">
                                    <div class="col-md-6">
                                        <h4 class="mb-0">{doctorAllData.total_patients}</h4>
                                        <p class="mb-0">Visited Patients</p>
                                    </div>

                                    <div class="col-md-6">
                                        <h4 class="mb-0">₦{doctorAllData.total_earning}</h4>
                                        <p class="mb-0">Total Earning</p>
                                    </div>

                                </div>

                            </div>
                            {/* <div class="p-3">
                                <h6 class="mb-2">About Doctor</h6>
                                <div class="title2">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</div>
                            </div> */}
                            <div class="p-3 pt-1">
                                <h6 class="mb-3">Specialized Categories</h6>
                                <div>
                                    {
                                        specializationCategory.length > 0 &&
                                        specializationCategory.map((item, index) => (
                                            <span className="cstm-sc-label" key={index}>{item.specialization}</span>
                                        ))
                                    }
                                </div>
                            </div>

                            <div class="p-3 pt-1 pb-1">
                                <h6>Contact Info</h6>
                            </div>

                            <div class="p-3 pt-1 cstm-ci-box">
                                <img src={pn} class="cstm-cl-img" />
                                <div class="cstm-cl-mn">
                                    <span class="cstm-cl-mn-ttl">Phone:</span>
                                    <span class="cstm-cl-mn-sub">{doctorProfile.phone_number}</span>
                                </div>
                            </div>

                            <div class="p-3 pt-1   cstm-ci-box">
                                <img src={ms} class="cstm-cl-img" />
                                <div class="cstm-cl-mn">
                                    <span class="cstm-cl-mn-ttl">Email address:</span>
                                    <span class="cstm-cl-mn-sub">{doctorProfile.email}</span>
                                </div>
                            </div>

                        </div>
                        <div class="cstm-doctor-profile-right">
                            <div class="cstm-tabs-pf">
                                <ul class="nav" id="pills-tab" role="tablist">
                                    <li class="nav-item">
                                        <a class="nav-link  active" id="overview-tab" data-bs-toggle="pill" href="#pills-overview" role="tab" aria-controls="pills-overview" aria-selected="false">
                                            Overview
                                        </a>
                                    </li>

                                </ul>
                                <div class="tab-content mt-2" id="pills-tabContent">
                                    <div class="tab-pane fade show active" id="pills-overview" role="tabpanel" aria-labelledby="overview-tab">
                                        <div class="py-2 px-3">
                                            <h5 class="mb-3">Submitted Documents</h5>
                                            <div class="cstm-sd-main">
                                                {
                                                    documentsData ? documentsData.map((doc, index, verify_status) => (

                                                        // DocumentExtension = doc.files[0].split(".").pop(),
                                                        <>
                                                            {
                                                                doc.files.map((item, index) => (
                                                                    DocumentExtension = item.split(".").pop(),

                                                                    <div class="cstm-sd-main-block">
                                                                        <div className="cstm-icon-download2">
                                                                            <a href={"https://anywherehealth.s3.amazonaws.com/" + item} download >
                                                                                <i className="fi fi-rr-download" />
                                                                            </a>
                                                                        </div>

                                                                        {/* {item !== "" && DocumentExtension === "pdf" && <iframe src={"https://anywherehealth.s3.amazonaws.com/" + item} height="100%" width="100%" scrolling="auto"></iframe>} */}

                                                                        <div className="documentdis">
                                                                            {item !== "" && DocumentExtension === "pdf" && <embed src={pdfImage} height="100%" width="100%" />}
                                                                            <img className="cstm-documentImg" src={item !== "" && (DocumentExtension === "jpg" || DocumentExtension === "png" || DocumentExtension === "jpeg") ? "https://anywherehealth.s3.amazonaws.com/" + item : DocumentExtension === "docx"  && docxImage || DocumentExtension === "doc" && docxImage} />
                                                                        </div>
                                                                        <h6 class="mt-3 mb-0 titledoc">{doc.document_category_id.category}</h6>

                                                                        {doc.verify_status === "pending" ? <div className="approve-reject-btn" key={index}>
                                                                            <button onClick={() => handleApproveRejectDocument(doc._id, doc.document_category_id._id, doc.doctor_id, "approved")} className="cstm-chekmank document-btn"><i className="fi fi-rr-check" aria-hidden="true" ></i></button>
                                                                            <button onClick={() => handleApproveRejectDocument(doc._id, doc.document_category_id._id, doc.doctor_id, "rejected")} className="cstm-cross mrn-rt document-btn"><i className="fi fi-rr-cross"></i></button>
                                                                        </div> : doc.verify_status === "approved" ? <h5>Approved</h5> : <h5>Rejected</h5>}
                                                                    </div>
                                                                ))
                                                            }

                                                        </>
                                                    )) : ""
                                                }
                                            </div>

                                            <div class="pb-3">
                                                <h6 class="mb-2">Experience</h6>
                                                <div class="title2">{doctorAllData.experience ? `Total ${doctorAllData.experience}` : `Total 0 year`}</div>
                                            </div>

                                            <div class="pb-3">
                                                <h6 class="mb-2">Joining Date</h6>
                                                <div class="title2">{moment(doctorAllData.createdAt).format('DD MMM, YYYY')}</div>
                                            </div>

                                            <div class="pb-3 mt-2">
                                                {/* <div className="cstm-ldr-mn mr-2">
                                                    <button class="cstm-btn9" onClick={() => handleApprovedAccount(doctorAllData._id)} >Approved Account</button>
                                                    {isLoading &&
                                                        <div id="btn-loader" className="btn-loader-main">
                                                            <img className="btn-loaderImg" src={loader} />
                                                        </div>
                                                    }
                                                </div> */}

                                                <button class="cstm-btn7" data-bs-toggle="modal" data-bs-target="#DeclineAccount">Decline Account</button>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>

                </div>
            </div>

            <div class="modal fade" id="DeclineAccount" tabindex="-1" aria-labelledby="exampleModalLabel1" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header border-0 p-4">
                            <h4 class="modal-title" id="exampleModalLabel1">Decline Account</h4>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>

                        <div class="modal-body p-4 pt-0">
                            <form onSubmit={handleSubmit}>
                                <div class="mb-3">
                                    <label class="cstm-label">Reasons of decline</label>
                                    <textarea name="reason" onChange={handleOnChange} id="comments" rows="4" class="cstm-textarea" placeholder="write your reasons"></textarea>
                                </div>

                                {/* {err.reason !==""} */}
                                <div class="mb-4">
                                    <label><input type="checkbox" onChange={handleOnChange} name="declain_done" value="" /> Are you sure decline this Account?</label>
                                </div>

                                <div class="d-flex justify-content-end">
                                    <div className="cstm-ldr-mn">
                                        <button type="button" onClick={handleDeclain} className="cstm-btn7">Send</button>
                                        {isLoadingDeclain &&
                                            <div id="btn-loader" className="btn-loader-main">
                                                <img className="btn-loaderImg" src={loader} /></div>
                                        }
                                    </div>
                                </div>

                            </form>
                        </div>

                    </div>
                </div>
            </div>

            {/* aprove sucess modal */}
            <div className="modal fade cstm-add-bonus" id="ApproveAccount" tabindex="-1" aria-labelledby="exampleModalLabel1"
                aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header border-0 p-4">
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>

                        <div className="modal-body p-4 pt-0">
                            <div className="mb-4">
                                <div className="text-center mb-4">
                                    <img src={yes} />
                                </div>

                                <h4 className="text-center mb-4">Are you sure want to approve this Doctor?</h4>
                                <div className="text-center">
                                    <div className="cstm-ldr-mn">
                                        <button className="cstm-btn7" onClick={() => handleApprovedAccount(doctorAllData._id)}>Approve Doctor</button>
                                        {isLoading &&
                                            <div id="btn-loader" className="btn-loader-main">
                                                <img className="btn-loaderImg" src={loader} />
                                            </div>
                                        }
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </main>

    )
}
export default ViewPendingDoctor