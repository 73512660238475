import React, { useState, useEffect, useRef } from 'react'
import Header from '../Navbar/Header'
import NavLinkHeader from '../Navbar/NavLinkHeader'
import Services from "../../Services/auth.service";
import loader from "../../assets/images/loder.gif";
import Dropzone from "react-dropzone";
import { Link, useNavigate, useLocation } from "react-router-dom";
import swal from "sweetalert";
import photo from "../../assets/images/photo.png"

import plusImg from "../../assets/images/plus.png";

import group from "../../assets/images/group.png"
const CryptoJS = require("crypto-js");


const AddPartner = () => {
    const history = useNavigate();
    const getid = useLocation().search;
    const getMenuID = new URLSearchParams(getid).get("id");
    var decryptId = CryptoJS.enc.Base64.parse(getMenuID);
    var menuID = CryptoJS.enc.Utf8.stringify(decryptId);

    const [isLoading, setIsLoading] = useState(false);

    const [imagePrevs, setImagePrevs] = useState();
    const [recentJoin, setRecentJoin] = useState([]);
    const [partnerImage, setPartnerImage] = useState("");
    const [isOpen, setIsOpen] = useState(false);

    //   image
    const [imagesPrev, setImagesPrev] = useState([]);
    const [finalImages, setFinalImages] = useState([]);
    var imgArray = [];
    var imgExtArray = [];

    // image gallary
    const [imagesGallary, setImagesGallary] = useState([]);

    //prev img re-order
    const dragImagePrev = useRef();
    const dragOverImagePrev = useRef();

    //images reorder
    // final file re-order
    const dragFinalImage = useRef();
    const dragOverFinalImage = useRef();

    var encyptId
    var recordIdEncrypt;

    useEffect(() => {
        const loggedInUser = localStorage.getItem("token");
        !loggedInUser && history("/");
        recentjoindata();
    }, [])

    const recentjoindata = () => {

        Services.getPartner(menuID)
            .then((response) => {
                setRecentJoin(response.data.data)
            }).catch((err) => {
            })
    }

    const [error, seterror] = useState({
        media: ""
    });
    const [formFields, setFormFields] = useState({
        name: "",
        image: "",
        city: "",
        about: "",
    });

    const [err, setErr] = useState({
        name: "",
        image: "",
        city: "",
        about: "",
    });
    // image preview


    const handleImageChange = (e) => {
        // e.stopPropagation()

        // e.preventDefault();
        // var files = e.target.files;
        var files = e;
        var filesArr = Array.prototype.slice.call(files);
        filesArr.forEach(function (f, index) {
            imgArray = [...imgArray, f];
        });
        setFinalImages([...finalImages, ...imgArray]);
        const arr = [];
        imgArray.forEach(function (f, index) {
            var u = URL.createObjectURL(f);
            arr.push(u);
            setImagesGallary([...imagesGallary, ...arr]);
            var filesplit = f.name.split(".").pop();
            var imageExtension = [
                "png",
                "jpg",
                "jpeg",
                "apng",
                ".avif",
                "jfif",
                "pjpeg",
                "pjp",
                "svg",
                "webp",
            ];

            imageExtension.includes(filesplit) && imgExtArray.push(u)
                ? (error.media = "")
                : (error.media = "Upload image only");
            seterror({ ...error });

            setImagesPrev([...imagesPrev, ...imgExtArray]);
        });
    };

    function deleteImages(e, name) {
        if (name === "image") {
            const imgp = imagesPrev.filter((item, index) => index !== e);
            const fi = finalImages.filter((item, index) => index !== e);
            setFinalImages([...fi]);
            setImagesPrev([...imgp]);
        }
    }
    // image reorder
    const dragStart = (e, position) => {
        dragFinalImage.current = position;
        dragImagePrev.current = position;
    };

    const dragEnter = (e, position) => {
        dragOverFinalImage.current = position;
        dragOverImagePrev.current = position;
    };

    const drop = (e) => {
        const copyListImagePrev = [...imagesPrev];
        const dragImagePrevContent = copyListImagePrev[dragImagePrev.current];
        copyListImagePrev.splice(dragImagePrev.current, 1);
        copyListImagePrev.splice(dragOverImagePrev.current, 0, dragImagePrevContent);
        dragImagePrev.current = null;
        dragOverImagePrev.current = null;
        setImagesPrev(copyListImagePrev)

        const copyFinalImages = [...finalImages];
        const dragImageContent = copyFinalImages[dragFinalImage.current];
        copyFinalImages.splice(dragFinalImage.current, 1);
        copyFinalImages.splice(dragOverFinalImage.current, 0, dragImageContent);
        dragFinalImage.current = null;
        dragOverFinalImage.current = null;
        setFinalImages(copyFinalImages)
    };

    const imageUpload = (e) => {
        e.preventDefault();
        // err.image = e.target.value.length > 0 ? "" : "Please select image";
        setImagePrevs(URL.createObjectURL(e.target.files[0]));
        let partnerImage = e.target.files[0];
        setPartnerImage(e.target.files[0]);
        // setErr({ ...err });
    };

    const handleOnchange = (e) => {
        const { value, name } = e.target;

        switch (name) {
            case "name":
                err.name = value.length > 0 ? "" : "Enter your name";

                break;

            case "city":
                err.city = value.length > 0 ? "" : "Enter your city";
                break;

            case "about":
                err.about = value.length > 0 ? "" : "Enter your about partner detail";
                break;

            default:
                break;
        }
        setFormFields({ ...formFields, [name]: value });
        setErr({ ...err });

    };

    const handleOnSubmit = (e) => {
        e.preventDefault();

        if (formFields.name === "") {
            err.name = "Enter partner name";
        }
        else {
            err.name = "";
        }
        if (formFields.city === "") {
            err.city = "Enter city";
        }
        else {
            err.city = "";
        }
        // if (partnerImage.length === "") {
        //     err.image = "Select partner image";
        // } else {
        //     err.image = "";
        // }

        if (formFields.about === "") {
            err.about = "Write about partner detail";
        }
        else {
            err.about = "";
        }
        setErr({ ...err });

        if (
            err.name === "" &&
            err.image === "" &&
            err.city === "" &&
            err.about === ""
        ) {
            let formData = new FormData();
            // return false
            formData.append("name", formFields.name);
            formData.append("city", formFields.city);
            formData.append("profile", partnerImage);
            formData.append("aboutus", formFields.about);

            for (var i = 0; i < finalImages.length; i++) {
                formData.append("media", finalImages[i]);
            }
            var bodydata = {
                "name": formFields.name,
                "city": formFields.city,
                "profile": partnerImage,
                "aboutus": formFields.about,
                "media": finalImages
            }
            // return false

            setIsLoading(true)
            Services.addPartner(menuID, formData)
                .then((response) => {
                    // return false
                    setIsLoading(false)
                    if (response.data.status === true) {
                        history(`/partner-list?id=${getMenuID}`);
                        swal("Success", response.data.message, "success");
                    } else {
                        setIsLoading(false)

                        swal("Failed", response.data.message, "error");
                    }
                })
                .catch((err) => {
                    setIsLoading(false)
                    swal("Failed", err.response.data.message, "error");
                });
        }
    };

    return (
        <div className="page-wrapper doctris-theme toggled">
            <main className="page-content">
                <Header />
                <div className="container-fluid">
                    <div className="layout-specing">
                        <div className="d-flex justify-content-between align-items-center mb-3">
                            <h4 className="mb-0">Partners</h4>
                            <NavLinkHeader title1="partners"
                                title1_link={`/partner-list?id=${getMenuID}`}
                                title2="Editprofile" />
                        </div>
                        <div className="row">
                            <div className="col-xl-8 col-lg-8 mt-1">
                                <form
                                    name="addPartner"
                                    id="addPartner"
                                    onSubmit={handleOnSubmit}
                                >
                                    <div className="card  border rounded-md rounded p-4 cstm-pt-ht">
                                        <div className="mb-4 cstm-profile-img d-flex  align-items-center">
                                            <label className="cstm-upload-choose">
                                                <div className="mr-5">
                                                    {imagePrevs ? (
                                                        <img
                                                            className="profile-upl-img"
                                                            src={imagePrevs}
                                                        />
                                                    ) : (
                                                        <img src={plusImg} className="profile-upl-img" />
                                                    )}
                                                    {/* <img src={photo}className="profile-upl-img" /> */}
                                                </div>
                                                <div>
                                                    <input
                                                        type="file"
                                                        name="profile"
                                                        onChange={imageUpload}
                                                    />
                                                    <h4>Upload Profile Picture</h4>
                                                    <span className="cstm-for-best">
                                                        For best results, use an image at least 600px by 600px in
                                                        either .jpg or .png format
                                                    </span>
                                                </div>
                                            </label>

                                        </div>
                                        {err.image !== "" && (
                                            <span style={{ color: "red" }}>{err.image}</span>
                                        )}
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className="mb-4">
                                                    <label className="cstm-label">Partner Name</label>
                                                    <input
                                                        type="text"
                                                        className="cstm-input"
                                                        placeholder="Enter partner name"
                                                        name="name"
                                                        onChange={handleOnchange}
                                                        required=""
                                                    />
                                                    {err.name !== "" && (
                                            <span style={{ color: "red" }}>{err.name}</span>
                                        )}  
                                                </div>
                                            </div>
                                        </div>
                                        
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className="mb-4">
                                                    <label className="cstm-label">City</label>
                                                    <input
                                                        type="text"
                                                        className="cstm-input"
                                                        placeholder="Enter city name"
                                                        name="city"
                                                        onChange={handleOnchange}
                                                        required=""
                                                    />
                                                       {err.city !== "" && (
                                                <span style={{ color: "red" }}>{err.city}</span>
                                            )}
                                                </div>
                                            </div>
                                         
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className="mb-4">
                                                    <label className="cstm-label">About Partner</label>
                                                    <textarea
                                                        name="about"
                                                        id="comments"
                                                        rows={5}
                                                        className="cstm-textarea"
                                                        placeholder="about partner"
                                                        defaultValue={""}
                                                        onChange={handleOnchange}
                                                    />
                                                    {err.about !== "" && (
                                            <span style={{ color: "red" }}>{err.about}</span>
                                        )}
                                                </div>
                                            </div>
                                        </div>
                                        
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className="mb-4">
                                                    <label className="cstm-label">Media</label>
                                                    <div className="cstm-media-up-img">
                                                        {/* <div className="cstm-media-up-img-in"> */}
                                                        {imagesPrev &&
                                                            imagesPrev.map((url, index) => (
                                                                <div className="cstm-media-up-img-in"
                                                                    onDragStart={(e) => dragStart(e, index)}
                                                                    onDragEnter={(e) => dragEnter(e, index)}
                                                                    onDragEnd={drop}
                                                                    key={index}
                                                                    draggable>
                                                                    <img src={url} id={index}
                                                                        onClick={() => setIsOpen(true)}
                                                                        data-toggle="modal" data-target="#myModal-imgGallary"
                                                                    />
                                                                    <span className="viewImage-option">
                                                                        <span>
                                                                            {" "}
                                                                            {/* <i
                                                                                    class="fa fa-trash"
                                                                                    aria-hidden="true"
                                                                                    onClick={() => deleteImages(index, "image")}
                                                                                ></i> */}
                                                                            <i class="fi fi-rr-cross-small"
                                                                                aria-hidden="true"
                                                                                onClick={() => deleteImages(index, "image")}
                                                                            ></i>
                                                                        </span>
                                                                    </span>
                                                                </div>
                                                            ))}
                                                        {/* </div> */}
                                                    </div>
                                                    <label className="cstm-media-up-upload">
                                                        <div className="cstm-upload-video-icon">
                                                            {/* <img src="./assets/images/img.png" /> */}
                                                            <span class="cstm-add-video-name">Add media</span>

                                                            <br />
                                                            <Dropzone
                                                                onDrop={(e) => handleImageChange(e)}
                                                                name="media"
                                                                multiple={true}
                                                                accept="image/png, image/jpeg, image/jpg"
                                                                maxSizeBytes="10485760"
                                                            >
                                                                {({ getRootProps, getInputProps }) => (

                                                                    <div {...getRootProps({ className: "viewtopicMusic-Images dropzone" })}>
                                                                        <div className="row-1 Main-video">
                                                                            <div className="music-icon add-video-text-msg-cstm">
                                                                                {/* <img src={Image} /> */}
                                                                                <span class="cstm-upload-video-upto">JPEG or PNG </span>
                                                                            </div>
                                                                            <div className="music-name">
                                                                                <label className="inputchoosefile">
                                                                                    <input {...getInputProps()} />
                                                                                    <span>
                                                                                        {" "}
                                                                                        {/* <h6>Drag & drop or click to add image.</h6> */}
                                                                                        {/* <h5>Please use Jpeg format of image</h5> */}
                                                                                    </span>
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                )}
                                                            </Dropzone>
                                                        </div>
                                                        {error.media !== "" && (
                                                            <span style={{ color: "red" }}>{error.media}</span>
                                                        )}
                                                        {/* <div className="cstm-upload-video-name"> */}
                                                        {/* <span className="cstm-add-video-name">Add media</span>
                                                            <span className="cstm-upload-video-upto">
                                                                JPEG or PNG{" "}
                                                            </span> */}
                                                        {/* </div> */}
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className="mb-2">
                                                    <div className="cstm-ldr-mn">

                                                        <button className="cstm-btn6">Add Profile</button>
                                                        {isLoading &&
                                                            <div id="btn-loader" className="btn-loader-main">
                                                                <img className="btn-loaderImg" src={loader} /></div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className="col-xl-4 col-lg-4 mt-1">
                                <div className="card  border rounded-md rounded">
                                    <div className="d-flex justify-content-between align-items-center p-3 border-bottom">
                                        <h5 className="mb-0">
                                            {" "}
                                            <img src={group} className="mr-2" /> Our
                                            Partners{" "}
                                        </h5>
                                    </div>
                                    <ul className="list-unstyled mb-0 p-3">
                                        {
                                            recentJoin.map((item) => (
                                                encyptId = CryptoJS.enc.Utf8.parse(item._id),// WordArray object
                                                recordIdEncrypt = CryptoJS.enc.Base64.stringify(encyptId),
                                                <>
                                                    <li className="mb-3">
                                                        <div className="d-flex align-items-center justify-content-between">
                                                            <div className="d-flex align-items-center">
                                                                <img
                                                                    src={item.profile ? "https://anywherehealth.s3.amazonaws.com/" + item.profile : plusImg}
                                                                    className="cstm-rjd-photo"
                                                                />
                                                                <div className="ms-3">
                                                                    <h5 className="text-dark mb-0 d-block">
                                                                        {item.name}
                                                                    </h5>
                                                                    <span className="cstm-for-best">{item.city}</span>
                                                                </div>
                                                            </div>
                                                            <div className="cstm-ro-icon">

                                                                <Link to={`/partner-profile?id=${getMenuID}&record_id=${recordIdEncrypt}`} className="cstm-eye mrn-rt"><i class="fi fi-rr-eye" aria-hidden="true"></i></Link>
                                                            </div>
                                                        </div>
                                                    </li>
                                                </>
                                            ))
                                        }
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    )
}
export default AddPartner

