import React, { useState, useEffect } from "react";
import Header from "../Navbar/Header";
import NavLinkHeader from "../Navbar/NavLinkHeader";
import Services from "../../Services/auth.service";
import swal from "sweetalert";
import $, { map } from "jquery";
import loader from "../../assets/images/loder.gif";
import { Link, useNavigate, useLocation } from "react-router-dom";
import pn from "../../assets/images/pn.png";
import ms from "../../assets/images/ms.png";
// import plusImg from "../../assets/images/plusImg.png";
import plusImg from "../../assets/images/plus.png";
import Star from "../Doctors/Star";

import Dropzone from "react-dropzone";
import { findAllByTestId } from "@testing-library/react";
const CryptoJS = require("crypto-js");

const PharmacyProfile = () => {
    const history = useNavigate();
    const getid = useLocation().search;
    const getMenuID = new URLSearchParams(getid).get("id");
    var decryptId = CryptoJS.enc.Base64.parse(getMenuID);
    var menuID = CryptoJS.enc.Utf8.stringify(decryptId);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingSuspend, setIsLoadingSuspend] = useState(false);

    const OpenTabKey = new URLSearchParams(getid).get("openTab");

    //decrypt recordid
    const gatRecordID = new URLSearchParams(getid).get("record_id");
    var decryptRecordId = CryptoJS.enc.Base64.parse(gatRecordID);
    var recordID = CryptoJS.enc.Utf8.stringify(decryptRecordId);

    const [hideimagePrev, setHideimagePrev] = useState();
    const [imagePrev, setImagePrev] = useState();
    const [pharmacyImage, setPharmacyImage] = useState();
    const [timing, setTiming] = useState([{}]);
    const [reviewData, setReviewData] = useState([]);
    const [totalReview, setTotalReview] = useState();
    const [averageRating, setAverageRating] = useState();
    const [errMessage, setErrMessage] = useState("");
    const [totalEarning, setTotalEarning] = useState("")

    const [formFields, setFormFields] = useState({
        name: "",
        image: "",
        email: "",
        phone_number: "",
        address: "",
        pincode: "",
        about: "",


    });


    const [err, setErr] = useState({
        name: "",
        image: "",
        email: "",
        phone_number: "",
        address: "",
        pincode: "",
        about: "",
    });

    const [suspendField, setSuspendField] = useState({
        subject: "",
        reason: ""
    })
    const [suspendErr, setSuspendErr] = useState({
        subject: "",
        reason: ""
    })


    useEffect(() => {
        const loggedInUser = localStorage.getItem("token");
        !loggedInUser && history("/");
        getPharmacyProfileData();
        getPharmacyReviewData();
    }, []);

    const getPharmacyProfileData = () => {
        Services.getPharmacyById(menuID, recordID).then((response) => {
            // return false
            setFormFields(response.data.data.labpharm_details)
            setTotalEarning(response.data.data.total_earning)
            if (response.data.data.labpharm_details.timings.length !== 0) {

                setTiming(response.data.data.labpharm_details.timings[0])
            }
            setImagePrev(response.data.data.labpharm_details.profileImg);
            setSuspendField({ ['subject']: response.data.data.labpharm_details.subject, ['reason']: response.data.data.labpharm_details.reason })
        })
            .catch((err) => {
            })
    }

    const getPharmacyReviewData = () => {

        Services.getPharmacyReviewListById(menuID, recordID).then((response) => {
            

            if (response.data.status === true) {

                var Review = response.data.data[0];
                setReviewData(Review.reiviews);
                setTotalReview(Review.review_count);
                setAverageRating(Review.totalrating);
                setErrMessage("")
            } else {
                setErrMessage(response.data.message)

            }

        })
            .catch((err) => {
                
            })
    }



    const imageUpload = (e) => {
        e.preventDefault();
        setHideimagePrev(true)
        err.image = e.target.value.length > 0 ? "" : "Please select image";
        setImagePrev(URL.createObjectURL(e.target.files[0]));
        let pharmacyImage = e.target.files[0];
        setPharmacyImage(pharmacyImage);
        setErr({ ...err });
    };



    const handleSuspendOnChange = (e) => {
        const { name, value } = e.target;
        switch (name) {
            case "subject":
                suspendErr.subject = value.length > 0 ? "" : "Enter subject";
                break;
            case "reason":
                suspendErr.reason = value.length > 0 ? "" : "Enter reason";
                break;
            default:
                break;
        }

        setSuspendField({ ...suspendField, [name]: value });
        setSuspendErr({ ...suspendErr });
    }

    const handleSuspendSubmit = (e) => {

        e.preventDefault();

        if (suspendField.subject === "" || suspendField.subject === undefined) {
            suspendErr.subject = "Enter subject";
        } else {
            suspendErr.subject = "";
        }
        if (suspendField.reason === "" || suspendField.reason === undefined) {
            suspendErr.reason = "Enter reason";
        } else {
            suspendErr.reason = "";
        }
        setSuspendErr({ ...suspendErr });

        if (suspendErr.subject === "" && suspendErr.reason === "") {

            var bodyData = {
                "id": recordID,
                "subject": suspendField.subject,
                "reason": suspendField.reason
            }
            
            // return false;
            setIsLoadingSuspend(true)
            Services.suspendPharmacy(menuID, bodyData).then((response) => {
                
                setIsLoadingSuspend(false)
                if (response.data.status === true) {
                    swal("Success", response.data.message, "success");
                    history(`/suspended-pharmacy?id=${getMenuID}`);
                    setIsLoadingSuspend(false)
                } else {
                    setIsLoadingSuspend(false)

                    swal("Failed", response.data.message, "error");
                }
            }).catch((err) => {
                setIsLoadingSuspend(false)

                swal("Failed", err.response.data.message, "error");
            })
        }
    }
    const handleOnchange = (e) => {
        const { value, name } = e.target;

        switch (name) {
            case "name":
                // 
                err.name = value.length > 0 ? "" : "Enter your name";

                break;
            case "email":
                err.email = value.length > 0 ? "" : "Enter your email";

                err.email = !new RegExp(
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                ).test(value)
                    ? "Enter a valid email address"
                    : "";
                break;

            case "pincode":
                err.pincode = value.length > 0 ? "" : "Enter your pincode";
                break;

            case "address":
                err.address = value.length > 0 ? "" : "Enter your address!";
                break;

            case "about":
                err.about = value.length > 0 ? "" : "Enter your about";
                break;

            case "phone_number":
                if (value.length !== 10) {
                    // we will set the error state
                    err.phone_number = "phone atleast have 10 number";
                } else if (!/^[0-9\b]+$/.test(value)) {
                    err.phone_number = "Enter only number";
                } else if (value.length === "") {
                    err.phone_number = "Please enter a number";
                } else {
                    err.phone_number = "";
                }
                break;

            default:
                break;
        }

        setFormFields({ ...formFields, [name]: value });
        setErr({ ...err });

        
        
    };

    const handleOnSubmit = (e) => {
        e.preventDefault();

        if (formFields.name === "") {
            err.name = "Enter pharmacy name";
        } else {
            err.name = "";
        }
        if (formFields.email === "") {
            err.email = "Enter email";
        } else {
            err.email = "";
        }
        if (formFields.phone_number === "") {
            err.phone_number = "Enter phone number";
        } else {
            err.phone_number = "";
        }
        if (formFields.address === "") {
            err.address = "Write address";
        } else {
            err.address = "";
        }
        if (formFields.pincode === "") {
            err.pincode = "Enter pincode";
        } else {
            err.pincode = "";
        }
        if (pharmacyImage === "") {
            err.image = "Select pharmacy image";
        } else {
            err.image = "";
        }

        if (formFields.about === "") {
            err.about = "Write about";
        } else {
            err.about = "";
        }
        setErr({ ...err });
        

        if (
            err.name === "" &&
            err.image === "" &&
            // err.email === "" &&
            err.phone_number === "" &&
            err.pincode === "" &&
            err.address === "" &&
            err.about === ""
        ) {
            let formData = new FormData();

            formData.append("name", formFields.name);
            formData.append("phone_number", formFields.phone_number);
            formData.append("pincode", formFields.pincode);
            formData.append("image", pharmacyImage ? pharmacyImage : imagePrev);
            formData.append("about", formFields.about);
            formData.append("address", formFields.address);
            formData.append("isActive", "true");
            formData.append("id", recordID);
            // formData.append("latitude", "28.3505");
            // formData.append("longitude", "77.5351");
            setIsLoading(true)
            Services.editPharmacy(menuID, formData)
                .then((response) => {
                    setIsLoading(false)
                    
                    if (response.data.status === true) {
                        
                        swal("Success", response.data.message, "success");
                        // $("#add-event")[0].reset();

                        history(`/pharmacy?id=${getMenuID}`);
                    } else {
                        setIsLoading(false)

                        swal("Failed", response.data.message, "error");
                    }
                })
                .catch((err) => {
                    
                    setIsLoading(false)

                    swal("Failed", err.response.data.message, "error");
                });
            //   }
        }
    };

    return (
        <main className="page-content">
            <Header title="Pharmacy Profile" main_title="Pharmacy" />

            <div className="container-fluid">
                <div className="layout-specing">
                    <div className="d-flex justify-content-between align-items-center mb-3">
                        <h4 className="mb-0">Pharmacy Profile</h4>
                        <NavLinkHeader
                            title1="Pharmacy List"
                            title1_link={`/pharmacy?id=${getMenuID}`}
                            title2="Pharmacy Profile"
                        />
                    </div>
                    <div className="cstm-doctor-profile  rounded-md  border">
                        <div className="cstm-doctor-profile-left">
                            <div className="">
                                <div className="mb-3  mt-5 text-center">

                                    {/* <img className="csatm-od-photo" src={"https://anywherehealth.s3.amazonaws.com/"+imagePrev} /> */}
                                    {imagePrev && !hideimagePrev ? (
                                        <img
                                            className="csatm-od-photo"
                                            width="200"
                                            height="200"
                                            src={"https://anywherehealth.s3.amazonaws.com/" + imagePrev}
                                        />
                                    ) : (
                                        <img src={imagePrev ? imagePrev : plusImg} className="csatm-od-photo" />
                                    )}
                                </div>
                                <h6 className="mb-1 text-center">{formFields.name}</h6>
                                <p className="mb-4 text-center">{formFields.address}</p>

                                <div className="d-flex text-center p-3  cstm-vp-te">
                                    <div className="col-md-12">
                                        <h4 className="mb-0">₦{totalEarning}</h4>
                                        <p className="mb-0">Total Earning</p>
                                    </div>

                                </div>

                            </div>
                            <div className="p-3">
                                <h6 className="mb-2">About Pharmacy</h6>
                                <div className="title2">{formFields.about}</div>
                            </div>


                            <div className="p-3 pt-1 pb-1">
                                <h6>Contact Info</h6>
                            </div>


                            <div className="p-3 pt-1 cstm-ci-box">
                                <img src={pn} className="cstm-cl-img" />
                                <div className="cstm-cl-mn">
                                    <span className="cstm-cl-mn-ttl">Phone:</span>
                                    <span className="cstm-cl-mn-sub">{formFields.phone_number}</span>
                                </div>
                            </div>

                            <div className="p-3 pt-1   cstm-ci-box">
                                <img src={ms} className="cstm-cl-img" />
                                <div className="cstm-cl-mn">
                                    <span className="cstm-cl-mn-ttl">Email address:</span>
                                    <span className="cstm-cl-mn-sub">{formFields.email}</span>
                                </div>
                            </div>

                            {
                                timing !== undefined &&
                                <>
                                    <div className="p-3 pt-1 pb-1">
                                        <h6>Pharmacy Time</h6>
                                    </div>
                                    {
                                        timing.sunday ?
                                            <>
                                                <div className="p-3 pt-1 pb-3">
                                                    <div className="title2">Sunday</div>
                                                    <div className="am-pm">{timing.sunday.startTime} AM -{timing.sunday.endTime} PM</div>
                                                </div>
                                            </>
                                            : ""
                                    }
                                    {
                                        timing.monday ?
                                            <>
                                                <div className="p-3 pt-1 pb-3">
                                                    <div className="title2">Monday</div>
                                                    <div className="am-pm">{timing.monday.startTime} AM -{timing.monday.endTime} PM</div>
                                                </div>
                                            </>
                                            : ""
                                    }
                                    {
                                        timing.tuesday ?
                                            <>
                                                <div className="p-3 pt-1 pb-3">
                                                    <div className="title2">Tuesday</div>
                                                    <div className="am-pm">{timing.tuesday.startTime} AM -{timing.tuesday.endTime} PM</div>
                                                </div>
                                            </>
                                            : ""
                                    }
                                    {
                                        timing.wednesday ?
                                            <>
                                                <div className="p-3 pt-1 pb-3">
                                                    <div className="title2">Wednesday</div>
                                                    <div className="am-pm">{timing.wednesday.startTime} AM -{timing.wednesday.endTime} PM</div>
                                                </div>
                                            </>
                                            : ""
                                    }
                                    {
                                        timing.thursday ?
                                            <>
                                                <div className="p-3 pt-1 pb-3">
                                                    <div className="title2">thursday</div>
                                                    <div className="am-pm">{timing.thursday.startTime} AM -{timing.thursday.endTime} PM</div>
                                                </div>
                                            </>
                                            : ""
                                    }
                                    {
                                        timing.friday ?
                                            <>
                                                <div className="p-3 pt-1 pb-3">
                                                    <div className="title2">Friday</div>
                                                    <div className="am-pm">{timing.friday.startTime} AM -{timing.friday.endTime} PM</div>
                                                </div>
                                            </>
                                            : ""
                                    }
                                    {
                                        timing.saturday ?
                                            <>
                                                <div className="p-3 pt-1 pb-3">
                                                    <div className="title2">Saturday</div>
                                                    <div className="am-pm">{timing.saturday.startTime} AM -{timing.saturday.endTime} PM</div>
                                                </div>
                                            </>
                                            : ""
                                    }
                                </>
                            }



                        </div>
                        <div className="cstm-doctor-profile-right">


                            <div className="cstm-tabs-pf">
                                {/* <button className="cstm-btn8 cstm-add-bonus-btn" data-bs-toggle="modal" data-bs-target="#viewappointment">Add Bonus</button> */}

                                <ul className="nav" id="pills-tab" role="tablist">
                                    <li className="nav-item">
                                        <a className={OpenTabKey !== 'account-setting' ? 'nav-link active' : 'nav-link'} id="overview-tab" data-bs-toggle="pill" href="#pills-overview" role="tab" aria-controls="pills-overview" aria-selected="false">
                                            Review
                                        </a>
                                    </li>

                                    <li className="nav-item">
                                        <a className={OpenTabKey === 'account-setting' ? 'nav-link active' : 'nav-link'} id="experience-tab" data-bs-toggle="pill" href="#AccountSetting" role="tab" aria-controls="pills-experience" aria-selected="false">
                                            Account Setting
                                        </a>
                                    </li>


                                </ul>

                                <div className="tab-content mt-2" id="pills-tabContent">



                                    <div className={OpenTabKey !== 'account-setting' ? 'tab-pane fade show active' : 'tab-pane fade'} id="pills-overview" role="tabpanel" aria-labelledby="overview-tab">
                                        <div className="py-2 px-3">
                                            <h5 className="mb-2">Reviews</h5>

                                            <div className="cstm-pts-rve">
                                                <div className="cstm-pts-rve-star">
                                                    {/* <ul className="list-unstyled mb-0">
                                                        <li className="list-inline-item"><i className="mdi mdi-star text-warning"></i></li>
                                                        <li className="list-inline-item"><i className="mdi mdi-star text-warning"></i></li>
                                                        <li className="list-inline-item"><i className="mdi mdi-star text-warning"></i></li>
                                                        <li className="list-inline-item"><i className="mdi mdi-star text-warning"></i></li>
                                                        <li className="list-inline-item"><i className="mdi mdi-star text-warning"></i></li>
                                                    </ul> */}
                                                                        <Star stars={averageRating} />

                                                </div>

                                                {/* <div className="cstm-pts-rve-star-nm">({averageRating ? averageRating : 0})</div> */}
                                                <div className="cstm-pts-rvepr">- {totalReview ? totalReview : 0} Review</div>
                                            </div>

                                            {errMessage !== "" && <p className="error-msg">{errMessage}</p>}

                                            {reviewData.length > 0 &&

                                                reviewData.map((review, index) => (
                                                    <>

                                                        <div className="cstm-reviews-in-main" key={index}>
                                                            <div className="cstm-reviews-in-main-left"><img src={review.profileImg ? "https://anywherehealth.s3.amazonaws.com/" + review.profileImg : plusImg} className="cstm-rv-user-photo" /></div>
                                                            <div className="cstm-reviews-in-main-right">
                                                                <div className="cstm-reviews-in-main-right-title">{review.reviewby_firstname ? review.reviewby_firstname : ""} {review.reviewby_surname ? review.reviewby_surname : ""}</div>

                                                                <div className="cstm-pts-rve">
                                                                    <div className="cstm-pts-rve-star">
                                                                        {/* <ul className="list-unstyled mb-0">
                                                                            <li className="list-inline-item"><i className="mdi mdi-star text-warning"></i></li>
                                                                            <li className="list-inline-item"><i className="mdi mdi-star text-warning"></i></li>
                                                                            <li className="list-inline-item"><i className="mdi mdi-star text-warning"></i></li>
                                                                            <li className="list-inline-item"><i className="mdi mdi-star text-warning"></i></li>
                                                                            <li className="list-inline-item"><i className="mdi mdi-star text-warning"></i></li>
                                                                        </ul> */}
                                                                        <Star stars={review.rating} />
                                                                    </div>
                                                                    <div className="cstm-pts-rve-star-nm">({review.rating ? review.rating : 0})</div>
                                                                </div>

                                                                <div className="cstm-reviews-in-main-right-cont">{review.comment ? review.comment : ""}</div>
                                                            </div>
                                                        </div>
                                                    </>
                                                ))}
                                        </div>
                                    </div>



                                    <div className={OpenTabKey === 'account-setting' ? 'tab-pane fade show active' : 'tab-pane fade'} id="AccountSetting" role="tabpanel" aria-labelledby="experience-tab">

                                        <div className="py-2 px-3">
                                            <h5 className="mb-3">Account Setting</h5>

                                            <form
                                                name="addPharmacy"
                                                id="addPharmacy"
                                                onSubmit={handleOnSubmit}
                                            >
                                                <div className="card  border rounded-md rounded p-4">
                                                    <div className="mb-4 cstm-profile-img d-flex  align-items-center">


                                                        <label className="cstm-upload-choose">
                                                            <div className="mr-5">

                                                                {imagePrev && !hideimagePrev ? (
                                                                    <img
                                                                        className="profile-upl-img"
                                                                        width="200"
                                                                        height="200"
                                                                        src={"https://anywherehealth.s3.amazonaws.com/" + imagePrev}
                                                                    />
                                                                ) : (
                                                                    <img src={imagePrev ? imagePrev : plusImg} className="profile-upl-img" />
                                                                )}
                                                            </div>
                                                            <div>
                                                                <input
                                                                    type="file"
                                                                    name="profile"
                                                                    onChange={imageUpload}
                                                                />
                                                                <h4>Upload Pharmacy Picture</h4>
                                                                <span className="cstm-for-best">
                                                                    For best results, use an image at least 600px by 600px
                                                                    in either .jpg or .png format
                                                                </span>
                                                            </div>
                                                        </label>


                                                    </div>
                                                    {err.image !== "" && (
                                                        <span style={{ color: "red" }}>{err.image}</span>
                                                    )}
                                                    <div className="row">
                                                        <div className="col-lg-12">
                                                            <div className="mb-4">
                                                                <label className="cstm-label">Pharmacy name</label>
                                                                <input
                                                                    type="text"
                                                                    className="cstm-input"
                                                                    placeholder="Enter pharmacy name"
                                                                    name="name"
                                                                    value={formFields.name}
                                                                    onChange={handleOnchange}
                                                                    required=""
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="row">
                                                        <div className="col-lg-6">
                                                            <div className="mb-4">
                                                                <label className="cstm-label">Email</label>
                                                                <input
                                                                    type="email"
                                                                    className="cstm-input"
                                                                    placeholder="Enter email"
                                                                    name="email"
                                                                    onChange={handleOnchange}
                                                                    value={formFields.email}

                                                                    required="" disabled
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6">
                                                            <div className="mb-4">
                                                                <label className="cstm-label">Contact no</label>
                                                                <input
                                                                    type="text"
                                                                    className="cstm-input"
                                                                    maxlength="10"
                                                                    placeholder="Enter phone no"
                                                                    name="phone_number"
                                                                    value={formFields.phone_number}
                                                                    onChange={handleOnchange}
                                                                    required=""
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="row">
                                                        <div className="col-lg-6">
                                                            <div className="mb-4">
                                                                <label className="cstm-label">Pharmacy address</label>
                                                                <input
                                                                    type="text"
                                                                    className="cstm-input"
                                                                    placeholder="Enter pharmacy address"
                                                                    onChange={handleOnchange}
                                                                    name="address"
                                                                    value={formFields.address}

                                                                    required=""
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6">
                                                            <div className="mb-4">
                                                                <label className="cstm-label">Pincode</label>
                                                                <input
                                                                    type="text"
                                                                    className="cstm-input"
                                                                    placeholder="Enter pincode"
                                                                    name="pincode"
                                                                    value={formFields.pincode}

                                                                    onChange={handleOnchange}
                                                                    required=""
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="row">
                                                        <div className="col-lg-12">
                                                            <div className="mb-4">
                                                                <label className="cstm-label">About pharmacy</label>
                                                                <textarea
                                                                    name="about"
                                                                    id="comments"
                                                                    rows="5"
                                                                    className="cstm-textarea"
                                                                    value={formFields.about}
                                                                    onChange={handleOnchange}
                                                                    placeholder="about pharmacy"
                                                                ></textarea>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="row">
                                                        <div className="col-lg-12">
                                                            <div className="mb-2 cstm-loader">
                                                                <div className="cstm-ldr-mn">
                                                                    <button className="cstm-btn6">Save Changes</button>
                                                                    {isLoading &&
                                                                        <div id="btn-loader" className="btn-loader-main">
                                                                            <img className="btn-loaderImg" src={loader} /></div>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>



                                            <hr />

                                            <h5 className="mb-2 cstm-suspend-account">Suspend Account</h5>
                                            <p className="text-muted">Do you want to suspend the account?</p>

                                            <form name="suspend-pharmacy-profile" id="suspend-pharmacy" onSubmit={handleSuspendSubmit}>

                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        <div className="mb-4">
                                                            <label className="cstm-label">Subject</label>
                                                            <input type="text" className="cstm-input" placeholder="Enter subject of suspend" name="subject" onChange={handleSuspendOnChange} required="" />
                                                        </div>
                                                        {suspendErr.subject !== "" && (
                                                            <span style={{ color: "red" }}>{suspendErr.subject}</span>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        <div className="mb-4">
                                                            <label className="cstm-label">Reasone Of Suspend</label>
                                                            <textarea name="reason" id="comments" rows="5" className="cstm-textarea" onChange={handleSuspendOnChange} placeholder="write reason"></textarea>
                                                        </div>
                                                        {suspendErr.reason !== "" && (
                                                            <span style={{ color: "red" }}>{suspendErr.reason}</span>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        <div className="mb-4">
                                                            <div className="cstm-ldr-mn">
                                                                <button className="cstm-btn7" data-bs-toggle="modal" data-bs-target="#PharmacyProfile">Suspend Account</button>
                                                                {isLoadingSuspend &&
                                                                    <div id="btn-loader" className="btn-loader-main">
                                                                        <img className="btn-loaderImg" src={loader} /></div>
                                                                }
                                                            </div>
                                                            {/* <button className="mr-3 cstm-btn7" data-bs-toggle="modal" data-bs-target="#PharmacyProfile">Suspend Account</button> */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    )
}

export default PharmacyProfile