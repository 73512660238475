import React from 'react'
import Header from '../Navbar/Header'

const PayrollSettings = () => {
    return (
        <>
            <div className="page-wrapper doctris-theme toggled">
                <main className="page-content bg-light">
                    <Header />
                    <div className="container-fluid">
                        <div className="layout-specing">
                            <div className="d-flex justify-content-between align-items-center mb-3">
                                <h4 className="mb-0">Payroll Settings</h4>
                                <div className="cstm-bre uppercase">
                                    Dashboard &gt; <a href="#"> Payroll setting</a>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card  rounded-md  border">
                                        <div className="p-3">
                                            <h5 className="mb-3">Add Doctor Payroll date</h5>
                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <div className="mb-4">
                                                        <label className="cstm-label">First Payoff</label>
                                                        <select className="cstm-select" name="City" id="City">
                                                            <option value="City">Select payoff day</option>
                                                            <option value="City2">Select payoff day 2</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="mb-4">
                                                        <label className="cstm-label">Second Payoff</label>
                                                        <select className="cstm-select" name="City" id="City">
                                                            <option value="City">Select payoff date</option>
                                                            <option value="City2">Select payoff date 2</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="pb-3 mt-2">
                                                <button className="mr-3 cstm-btn">Save changes</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            className="modal fade"
                            id="SubscriptionDetails"
                            tabIndex={-1}
                            aria-labelledby="exampleModalLabel1"
                            aria-hidden="true"
                        >
                            <div className="modal-dialog modal-dialog-centered">
                                <div className="modal-content">
                                    <div className="modal-header border-0 p-4">
                                        <h4 className="modal-title" id="exampleModalLabel1">
                                            Subscription Details{" "}
                                        </h4>
                                        <button
                                            type="button"
                                            className="btn-close"
                                            data-bs-dismiss="modal"
                                            aria-label="Close"
                                        />
                                    </div>
                                    <div className="modal-body p-4 pt-0">
                                        <div className="mb-2">
                                            <div className="row">
                                                <div className="col-lg-6 mb-4">
                                                    <label className="cstm-label">Subscription Name</label>
                                                    <div>
                                                        <b>Lore Ipusm</b>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 mb-4">
                                                    <label className="cstm-label">Plan Duration</label>
                                                    <div>
                                                        <b>12 Months</b>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <label className="cstm-label">Subscription Price</label>
                                                    <div>
                                                        <b>$5,262.00</b>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <label className="cstm-label">Subscription Status</label>
                                                    <div>
                                                        <span className="cstm-active-grren">Active</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
            <div
                className="modal fade"
                id="SubscriptionDetails"
                tabIndex={-1}
                aria-labelledby="exampleModalLabel1"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header border-0 p-4">
                            <h4 className="modal-title" id="exampleModalLabel1">
                                Subscription Details{" "}
                            </h4>
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            />
                        </div>
                        <div className="modal-body p-4 pt-0">
                            <div className="mb-2">
                                <div className="row">
                                    <div className="col-lg-6 mb-4">
                                        <label className="cstm-label">Subscription Name</label>
                                        <div>
                                            <b>Lore Ipusm</b>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 mb-4">
                                        <label className="cstm-label">Plan Duration</label>
                                        <div>
                                            <b>12 Months</b>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-6">
                                        <label className="cstm-label">Subscription Price</label>
                                        <div>
                                            <b>$5,262.00</b>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <label className="cstm-label">Subscription Status</label>
                                        <div>
                                            <span className="cstm-active-grren">Active</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PayrollSettings
