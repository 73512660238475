import React, { useState, useEffect } from "react";
import Header from "../Navbar/Header";
import NavLinkHeader from "../Navbar/NavLinkHeader";
import Services from "../../Services/auth.service";
import swal from "sweetalert";
import $ from "jquery";
import loader from "../../assets/images/loder.gif";
import { Link, useNavigate, useLocation } from "react-router-dom";
import pn from "../../assets/images/pn.png";
import ms from "../../assets/images/ms.png";
import plusImg from "../../assets/images/plus.png";
import deleteImg from "../../assets/images/delete.png";
import yes from "../../assets/images/yes.png";
const CryptoJS = require("crypto-js");

const ViewSuspendPharmacy = () => {
    const history = useNavigate();
    const getid = useLocation().search;
    const getMenuID = new URLSearchParams(getid).get("id");
    var decryptId = CryptoJS.enc.Base64.parse(getMenuID);
    var menuID = CryptoJS.enc.Utf8.stringify(decryptId);
    const OpenTabKey = new URLSearchParams(getid).get("openTab");

    //decrypt recordid
    const gatRecordID = new URLSearchParams(getid).get("record_id");
    var decryptRecordId = CryptoJS.enc.Base64.parse(gatRecordID);
    var recordID = CryptoJS.enc.Utf8.stringify(decryptRecordId);
    const [imagePrev, setImagePrev] = useState();
    const [timing, setTiming] = useState([{}]);
    const [reviewData, setReviewData] = useState([]);
    const [totalReview, setTotalReview] = useState();
    const [averageRating, setAverageRating] = useState();
    const [errMessage, setErrMessage] = useState("");
    const [totalEarning, setTotalEarning] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const [formFields, setFormFields] = useState({
        name: "",
        image: "",
        email: "",
        phone_number: "",
        address: "",
        pincode: "",
        about: "",

    });
    useEffect(() => {
        const loggedInUser = localStorage.getItem("token");
        !loggedInUser && history("/");
        getPharmacyProfileData();
        getPharmacyReviewData();
    }, []);

    const getPharmacyProfileData = () => {
        Services.getPharmacyById(menuID, recordID).then((response) => {
            setFormFields(response.data.data.labpharm_details)
            setTiming(response.data.data.labpharm_details.timings[0])
            setImagePrev(response.data.data.labpharm_details.profileImg);
            setTotalEarning(response.data.data.total_earning);
        })
            .catch((err) => {
            })
    }

    const getPharmacyReviewData = () => {

        Services.getPharmacyReviewListById(menuID, recordID).then((response) => {
            if (response.data.status === true) {

                var Review = response.data.data[0];
                setReviewData(Review.reiviews);
                setTotalReview(Review.review_count);
                setAverageRating(Review.totalrating);
                setErrMessage("")
            } else {
                setErrMessage(response.data.message)
            }

        })
            .catch((err) => {
            })
    }


    const handleDeleteAccount = () => {

        Services.deletePharmacy(menuID, recordID)
            .then((response) => {
                if (response.data.status === true) {
                    swal("Success", response.data.message, "success");
                    document.querySelector("#viewappointment .btn-close").click();
                    history(`/pharmacy?id=${getMenuID}`);


                } else {
                    swal("Failed", response.data.message, "error");
                }
            })
            .catch(function (err) {
                swal("Failed", err.response.data.message, "error");
            });
    };
    const handleUnsuspend = () => {
        setIsLoading(true)
        Services.unsuspendPharmacy(menuID, recordID)
            .then((response) => {
                setIsLoading(false)
                if (response.data.status === true) {
                    swal("Success", response.data.message, "success");
                    document.querySelector("#unsuspendmodal .btn-close").click();
                    history(`/pharmacy?id=${getMenuID}`);
                } else {
                    setIsLoading(false)

                    swal("Failed", response.data.message, "error");
                }
            })
            .catch(function (err) {
                setIsLoading(false)

                swal("Failed", err.response.data.message, "error");
            });
    };

    return (
        <main className="page-content">
            <Header title="Suspended Pharmacy Profile" main_title="Pharmacy" />

            <div className="container-fluid">
                <div className="layout-specing">
                    <div className="d-flex justify-content-between align-items-center mb-3">
                        <h4 className="mb-0">Suspend Pharmacy</h4>
                        <NavLinkHeader
                            title1="Suspended Pharmacy"
                            title1_link={`/suspended-pharmacy?id=${getMenuID}`}
                            title2="Suspended Pharmacy Profile"
                        />
                    </div>
                    <div className="cstm-doctor-profile  rounded-md  border">
                        <div className="cstm-doctor-profile-left">
                            <div className="">
                                <div className="mb-3  mt-5 text-center">
                                    <img src={imagePrev ? "https://anywherehealth.s3.amazonaws.com/" + imagePrev : plusImg} className="csatm-od-photo" />
                                </div>
                                <h6 className="mb-1 text-center">{formFields.name}</h6>
                                <p className="mb-4 text-center">{formFields.address}</p>

                                <div className="d-flex text-center p-3  cstm-vp-te">
                                    <div className="col-md-12">
                                        <h4 className="mb-0">${totalEarning}</h4>
                                        <p className="mb-0">Total Earning</p>
                                    </div>

                                </div>

                            </div>
                            <div className="p-3">
                                <h6 className="mb-2">About Pharmacy</h6>
                                <div className="title2">{formFields.about}</div>
                            </div>


                            <div className="p-3 pt-1 pb-1">
                                <h6>Contact Info</h6>
                            </div>


                            <div className="p-3 pt-1 cstm-ci-box">
                                <img src={pn} className="cstm-cl-img" />
                                <div className="cstm-cl-mn">
                                    <span className="cstm-cl-mn-ttl">Phone:</span>
                                    <span className="cstm-cl-mn-sub">{formFields.phone_number}</span>
                                </div>
                            </div>

                            <div className="p-3 pt-1   cstm-ci-box">
                                <img src={ms} className="cstm-cl-img" />
                                <div className="cstm-cl-mn">
                                    <span className="cstm-cl-mn-ttl">Email address:</span>
                                    <span className="cstm-cl-mn-sub">{formFields.email}</span>
                                </div>
                            </div>


                            {
                                timing !== undefined &&
                                <>
                                    <div className="p-3 pt-1 pb-1">
                                        <h6>Pharmacy Time</h6>
                                    </div>
                                    {
                                        timing.sunday ?
                                            <>
                                                <div className="p-3 pt-1 pb-3">
                                                    <div className="title2">Sunday</div>
                                                    <div className="am-pm">{timing.sunday.startTime} AM -{timing.sunday.endTime} PM</div>
                                                </div>
                                            </>
                                            : ""
                                    }
                                    {
                                        timing.monday ?
                                            <>
                                                <div className="p-3 pt-1 pb-3">
                                                    <div className="title2">Monday</div>
                                                    <div className="am-pm">{timing.monday.startTime} AM -{timing.monday.endTime} PM</div>
                                                </div>
                                            </>
                                            : ""
                                    }
                                    {
                                        timing.tuesday ?
                                            <>
                                                <div className="p-3 pt-1 pb-3">
                                                    <div className="title2">Tuesday</div>
                                                    <div className="am-pm">{timing.tuesday.startTime} AM -{timing.tuesday.endTime} PM</div>
                                                </div>
                                            </>
                                            : ""
                                    }
                                    {
                                        timing.wednesday ?
                                            <>
                                                <div className="p-3 pt-1 pb-3">
                                                    <div className="title2">Wednesday</div>
                                                    <div className="am-pm">{timing.wednesday.startTime} AM -{timing.wednesday.endTime} PM</div>
                                                </div>
                                            </>
                                            : ""
                                    }
                                    {
                                        timing.thursday ?
                                            <>
                                                <div className="p-3 pt-1 pb-3">
                                                    <div className="title2">thursday</div>
                                                    <div className="am-pm">{timing.thursday.startTime} AM -{timing.thursday.endTime} PM</div>
                                                </div>
                                            </>
                                            : ""
                                    }
                                    {
                                        timing.friday ?
                                            <>
                                                <div className="p-3 pt-1 pb-3">
                                                    <div className="title2">Friday</div>
                                                    <div className="am-pm">{timing.friday.startTime} AM -{timing.friday.endTime} PM</div>
                                                </div>
                                            </>
                                            : ""
                                    }
                                    {
                                        timing.saturday ?
                                            <>
                                                <div className="p-3 pt-1 pb-3">
                                                    <div className="title2">Saturday</div>
                                                    <div className="am-pm">{timing.saturday.startTime} AM -{timing.saturday.endTime} PM</div>
                                                </div>
                                            </>
                                            : ""
                                    }
                                </>
                            }



                        </div>
                        <div className="cstm-doctor-profile-right">


                            <div className="cstm-tabs-pf">
                                {/* <button className="cstm-btn8 cstm-add-bonus-btn" data-bs-toggle="modal" data-bs-target="#viewappointment">Add Bonus</button> */}

                                <ul className="nav" id="pills-tab" role="tablist">
                                    <li className="nav-item">
                                        <a className={OpenTabKey !== 'account-setting' ? 'nav-link active' : 'nav-link'} id="overview-tab" data-bs-toggle="pill" href="#pills-overview" role="tab" aria-controls="pills-overview" aria-selected="false">
                                            Review
                                        </a>
                                    </li>

                                    <li className="nav-item">
                                        <a className={OpenTabKey === 'account-setting' ? 'nav-link active' : 'nav-link'} id="experience-tab" data-bs-toggle="pill" href="#AccountSetting" role="tab" aria-controls="pills-experience" aria-selected="false">
                                            Account Setting
                                        </a>
                                    </li>


                                </ul>

                                <div className="tab-content mt-2" id="pills-tabContent">



                                    <div className={OpenTabKey !== 'account-setting' ? 'tab-pane fade show active' : 'tab-pane fade'} id="pills-overview" role="tabpanel" aria-labelledby="overview-tab">
                                        <div className="py-2 px-3">
                                            <h5 className="mb-2">Reviews</h5>

                                            <div className="cstm-pts-rve">
                                                <div className="cstm-pts-rve-star">
                                                    <ul className="list-unstyled mb-0">
                                                        <li className="list-inline-item"><i className="mdi mdi-star text-warning"></i></li>
                                                        <li className="list-inline-item"><i className="mdi mdi-star text-warning"></i></li>
                                                        <li className="list-inline-item"><i className="mdi mdi-star text-warning"></i></li>
                                                        <li className="list-inline-item"><i className="mdi mdi-star text-warning"></i></li>
                                                        <li className="list-inline-item"><i className="mdi mdi-star text-warning"></i></li>
                                                    </ul>
                                                </div>
                                                <div className="cstm-pts-rve-star-nm">({averageRating ? averageRating : 0})</div>
                                                <div className="cstm-pts-rvepr">-  {totalReview ? totalReview : 0} Review</div>
                                            </div>
                                            {errMessage !== "" && <p className="error-msg">{errMessage}</p>}


                                            {reviewData.length > 0 &&
                                                
                                                reviewData.map((review, index) => (
                                                <>

                                                    <div className="cstm-reviews-in-main" key={index}>
                                                        <div className="cstm-reviews-in-main-left"><img src={review.profileImg ? "https://anywherehealth.s3.amazonaws.com/" + review.profileImg : plusImg} className="cstm-rv-user-photo" /></div>
                                                        <div className="cstm-reviews-in-main-right">
                                                            <div className="cstm-reviews-in-main-right-title">{review.reviewby_firstname ? review.reviewby_firstname : ""} {review.reviewby_surname ? review.reviewby_surname : ""}</div>

                                                            <div className="cstm-pts-rve">
                                                                <div className="cstm-pts-rve-star">
                                                                    <ul className="list-unstyled mb-0">
                                                                        <li className="list-inline-item"><i className="mdi mdi-star text-warning"></i></li>
                                                                        <li className="list-inline-item"><i className="mdi mdi-star text-warning"></i></li>
                                                                        <li className="list-inline-item"><i className="mdi mdi-star text-warning"></i></li>
                                                                        <li className="list-inline-item"><i className="mdi mdi-star text-warning"></i></li>
                                                                        <li className="list-inline-item"><i className="mdi mdi-star text-warning"></i></li>
                                                                    </ul>
                                                                </div>
                                                                <div className="cstm-pts-rve-star-nm">({review.rating ? review.rating : 0})</div>
                                                            </div>

                                                            <div className="cstm-reviews-in-main-right-cont">{review.comment ? review.comment : ""}</div>
                                                        </div>
                                                    </div>
                                                </>
                                            ))}
                                        </div>
                                    </div>



                                    <div className={OpenTabKey === 'account-setting' ? 'tab-pane fade show active' : 'tab-pane fade'} id="AccountSetting" role="tabpanel" aria-labelledby="experience-tab">

                                        <div className="py-2 px-3">
                                            <h5 className="mb-3">Account Setting</h5>

                                            <div className="card  border rounded-md rounded p-4">
                                                {/* <div className="mb-4 cstm-profile-img d-flex  align-items-center">
                                                    <div className="mr-5">
                                                        <img src={imagePrev ? "https://anywherehealth.s3.amazonaws.com/" + imagePrev : plusImg} className="profile-upl-img" />
                                                    </div>
                                                </div> */}
                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        <div className="mb-4">
                                                            <label className="cstm-label">Pharmacy name</label>
                                                            <input
                                                                type="text"
                                                                className="cstm-input"
                                                                name="name"
                                                                value={formFields.name}
                                                                disabled
                                                            />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-lg-6">
                                                        <div className="mb-4">
                                                            <label className="cstm-label">Email</label>
                                                            <input
                                                                type="email"
                                                                className="cstm-input"
                                                                name="email"
                                                                value={formFields.email}
                                                                disabled
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <div className="mb-4">
                                                            <label className="cstm-label">Contact no</label>
                                                            <input
                                                                type="text"
                                                                className="cstm-input"
                                                                maxlength="10"
                                                                name="phone_number"
                                                                value={formFields.phone_number}
                                                                disabled

                                                            />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-lg-6">
                                                        <div className="mb-4">
                                                            <label className="cstm-label">Pharmacy address</label>
                                                            <input
                                                                type="text"
                                                                className="cstm-input"
                                                                name="address"
                                                                value={formFields.address}
                                                                disabled

                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <div className="mb-4">
                                                            <label className="cstm-label">Pincode</label>
                                                            <input
                                                                type="text"
                                                                className="cstm-input"
                                                                name="pincode"
                                                                value={formFields.pincode}
                                                                disabled
                                                            />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        <div className="mb-4">
                                                            <label className="cstm-label">About pharmacy</label>
                                                            <textarea
                                                                name="about"
                                                                id="comments"
                                                                rows="5"
                                                                className="cstm-textarea"
                                                                value={formFields.about}
                                                                disabled

                                                            ></textarea>
                                                        </div>
                                                    </div>
                                                    <div className="pb-3 mt-2">
                                                        <button className="mr-3 cstm-btn" data-bs-toggle="modal" data-bs-target="#unsuspendmodal">Unsuspended Account </button>
                                                        <button className="mr-3 cstm-btn7" data-bs-toggle="modal"
                                                            data-bs-target="#viewappointment">Delete Account</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
            </div>

            {/* unsuspend modal */}
            <div className="modal fade cstm-add-bonus" id="unsuspendmodal" tabindex="-1" aria-labelledby="exampleModalLabel1"
                aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header border-0 p-4">
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body p-4 pt-0">
                            <div className="mb-4">

                                <div className="text-center mb-4">
                                    <img src={yes} />
                                </div>
                                <h4 className="text-center mb-4">Are you sure want to unsuspend this Account?</h4>
                                <div className="text-center">
                                    <div className="cstm-ldr-mn">

                                        <button className="cstm-btn7" onClick={handleUnsuspend}>Unsuspend Account</button>
                                        {isLoading &&
                                            <div id="btn-loader" className="btn-loader-main">
                                                <img className="btn-loaderImg" src={loader} /></div>
                                        }
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            {/* delete modal */}
            <div className="modal fade cstm-add-bonus" id="viewappointment" tabindex="-1" aria-labelledby="exampleModalLabel1"
                aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header border-0 p-4">
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body p-4 pt-0">
                            <div className="mb-4">

                                <div className="text-center mb-4">
                                    <img src={deleteImg} />
                                </div>
                                <h4 className="text-center mb-4">Are you sure want to delete this Account?</h4>
                                <div className="text-center">
                                    <button className="cstm-btn7" onClick={handleDeleteAccount}>Delete Account</button>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </main>


        //delete account modal


    )
}

export default ViewSuspendPharmacy

