import React, { useEffect, useState } from 'react'
import logo from '../../assets/images/logo.png'
import { NavLink, useNavigate, useLocation } from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as Yup from "yup"; // used when validating with a pre-built solution
import swal from 'sweetalert';
import $ from 'jquery';
import { } from "react-router-dom";
import Services from '../../Services/auth.service';


function ResetPassword() {
    const getToken = useLocation().search;
    const ResetToken = new URLSearchParams(getToken).get("token");

    const history = useNavigate();
    useEffect(() => {
        //   const loggedInUser = localStorage.getItem("token");
        //   !loggedInUser && history("/");
    }, []);

    const validationSchema = Yup.object().shape({
        new_password: Yup.string().required('Enter new password'),
        confirm_password: Yup.string().oneOf([Yup.ref('new_password'), null], 'Confirm password not matched with password').required('Enter confirm password'),

    });
    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
        watch,
        getValues
    } = useForm({
        mode: "onchange",
        resolver: yupResolver(validationSchema)
    });
    const onSubmit = async (data, e) => {

        var dataValue = {

            "token": ResetToken,
            "password": data.new_password,
        }
        //   $(".loader-main").show();

        Services.adminResetPassword(dataValue)
            .then(response => {
                // $(".loader-main").hide();
                if (response.data.status == true) {
                    swal("Success", response.data.message, "success");
                    history("/")

                } else {
                    swal("Failed", response.data.message, "error");

                }
            }).catch(err => {
                swal("Failed ", err.response.data.message, "error");

            })
    }
    return (
        <>

            <section className="bg-home d-flex bg-light align-items-center cstm-fm-all">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-5 col-md-8">
                            <div className="card login-page bg-white shadow rounded border-0">
                                <div className="card-body">
                                    <div className="text-center mb-4">
                                        <NavLink to="/">
                                            <img src={logo} />
                                        </NavLink>
                                    </div>
                                    <h4>Reset Password</h4>
                                    <form onSubmit={handleSubmit(onSubmit)} className="login-form mt-4">
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className="mb-3">
                                                    <label className="cstm-label">
                                                        New Password <span className="text-danger">*</span>
                                                    </label>
                                                    <input type="password" id="new_password" className="cstm-input" {...register('new_password')} placeholder="Enter new password" name="new_password" required="" />
                                                    <span className="invalid-error-login" style={{ color: "red" }}>{errors.new_password?.message}</span>
                                                </div>
                                            </div>

                                            <div className="col-lg-12">
                                                <div className="mb-3">
                                                    <label className="cstm-label">
                                                        Confirm Password<span className="text-danger">*</span>
                                                    </label>
                                                    <input type="password" id="confirm_password" className="cstm-input" {...register('confirm_password')} placeholder="Enter confirm password" name="confirm_password" required="" />
                                                    <span className="invalid-error-login" style={{ color: "red" }}>{errors.confirm_password?.message}</span>
                                                </div>
                                            </div>

                                            <div className="col-lg-12">
                                                <div className="d-grid">
                                                    <button className="cstm-btn1">Update</button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default ResetPassword