import React, { useEffect, useState } from 'react'
import Header from '../Navbar/Header'
import NavLinkHeader from '../Navbar/NavLinkHeader'
import Services from "../../Services/auth.service";
import plusImg from "../../assets/images/plus.png";
import { Link, useNavigate, useLocation } from 'react-router-dom';
import photo from "../../assets/images/photo.png"
import group from "../../assets/images/group.png"
const CryptoJS = require("crypto-js");

const PartnerProfile = () => {

    const history = useNavigate();
    const getid = useLocation().search;
    const getMenuID = new URLSearchParams(getid).get("id");
    var decryptId = CryptoJS.enc.Base64.parse(getMenuID);
    var menuID = CryptoJS.enc.Utf8.stringify(decryptId);

    //decrypt recordid
    const gatRecordID = new URLSearchParams(getid).get("record_id");
    var decryptRecordId = CryptoJS.enc.Base64.parse(gatRecordID);
    var recordID = CryptoJS.enc.Utf8.stringify(decryptRecordId);

    const [partnerData, setPartnerData] = useState([])
    const [imagePrev, setImagePrev] = useState();
    const [mediaImg, setmediaImg] = useState([])
    const [recentJoin, setRecentJoin] = useState([]);

    var encyptId
    var recordIdEncrypt;

    encyptId = CryptoJS.enc.Utf8.parse(recordID) // WordArray object
    recordIdEncrypt = CryptoJS.enc.Base64.stringify(encyptId)

    useEffect(() => {
        const loggedInUser = localStorage.getItem("token");
        !loggedInUser && history("/");
        Services.getPartnerById(menuID, recordID).then((response) => {
            setPartnerData(response.data.data)
            setImagePrev(response.data.data.profile);
            setmediaImg(response.data.data.media);
        })
    }, [recordID])

    Services.getPartner(menuID)
        .then((response) => {
            setRecentJoin(response.data.data)
        })





    return (
        <div className="page-wrapper doctris-theme toggled">
            <main className="page-content">
                <Header />
                <div className="container-fluid">
                    <div className="layout-specing">
                        <div className="d-flex justify-content-between align-items-center mb-3">
                            <h4 className="mb-0">Partners</h4>
                            <NavLinkHeader title1="partners"
                                title1_link={`/partner-list?id=${getMenuID}`}
                                title2="partners profile" />
                        </div>
                        <div className="row">
                            <div className="col-xl-8 col-lg-8 mt-1">
                                <div className="card  border rounded-md rounded p-4 cstm-pt-ht">
                                    <div className="mb-4 cstm-profile-img d-flex  align-items-center">

                                        <div className="mr-5">
                                            <img src={imagePrev ? "https://anywherehealth.s3.amazonaws.com/" + imagePrev : plusImg} class="profile-upl-img" />
                                        </div>
                                        <div>
                                            <h4>{partnerData.name}</h4>
                                            <span className="cstm-for-best">{partnerData.city}</span>
                                        </div>
                                        {/* <div className="cstm-patners-edit">
                                            <Link  to={`/edit-partner?id=${getMenuID}&record_id=${recordIdEncrypt}`} class="cstm-chekmank"><i class="fi-rr-pencil" aria-hidden="true"></i></Link>
                                        </div> */}
                                    </div>

                                    {/* <span className="cstm-ptr-title">{partnerData.aboutus}</span> */}
                                    <span className="cstm-ptr-title">About Partner</span>

                                    <span className="cstm-ptr-prg">
                                        {partnerData.aboutus}
                                    </span>
                                    {mediaImg !== undefined && mediaImg.length > 0 && <span className="cstm-ptr-title">Media</span>}
                                    <div className="cstm-ptr-gallery">

                                        {
                                            mediaImg !== undefined && mediaImg.map((item, index) => (
                                                <>
                                                    <img src={item.file ? "https://anywherehealth.s3.amazonaws.com/" + item.file : plusImg} class="cstm-ptr-gallery" />
                                                </>
                                            ))
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4 mt-1">
                                <div className="card  border rounded-md rounded">
                                    <div className="d-flex justify-content-between align-items-center p-3 border-bottom">
                                        <h5 className="mb-0">
                                            {" "}
                                            <img src={group} className="mr-2" /> Our
                                            Partners{" "}
                                        </h5>
                                    </div>
                                    <ul className="list-unstyled mb-0 p-3">
                                        {
                                            recentJoin.map((item ,index) => (
                                                encyptId = CryptoJS.enc.Utf8.parse(item._id),// WordArray object
                                                recordIdEncrypt = CryptoJS.enc.Base64.stringify(encyptId),
                                                <>
                                                    <li className="mb-3" key={index}>
                                                        <div className="d-flex align-items-center justify-content-between">
                                                            <div className="d-flex align-items-center">
                                                                <img
                                                                    src={item.profile ? "https://anywherehealth.s3.amazonaws.com/" + item.profile : plusImg} 
                                                                    className="cstm-rjd-photo"
                                                                />
                                                                <div className="ms-3">
                                                                    <h5 className="text-dark mb-0 d-block">
                                                                        {item.name}
                                                                    </h5>
                                                                    <span className="cstm-for-best">{item.city}</span>
                                                                </div>
                                                            </div>
                                                            <div className="cstm-ro-icon">

                                                                <Link to={`/partner-profile?id=${getMenuID}&record_id=${recordIdEncrypt}`} className="cstm-eye mrn-rt"><i class="fi fi-rr-eye" aria-hidden="true"></i></Link>
                                                            </div>
                                                        </div>
                                                    </li>
                                                </>
                                            ))
                                        }
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    )
}
export default PartnerProfile
