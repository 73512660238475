import React from 'react'
import logo from '../../assets/images/logo.png'
// import { Link, NavLink } from "react-router-dom";
import { Route, NavLink, Link, Routes, useLocation } from 'react-router-dom';
import { map } from 'jquery';
var CryptoJS = require("crypto-js");
export default function SideBar({ showText }) {

   const location = useLocation();
   const currentPath = location.pathname;
   const role_resources = JSON.parse(localStorage.getItem("role_resources") || "[]")

   const dashboardId = "62c428065856aa82a235bee4";
   var encyptDashboardId = CryptoJS.enc.Utf8.parse(dashboardId); // WordArray object
   var dashboardIdEncrypt = CryptoJS.enc.Base64.stringify(encyptDashboardId);

   return (
      <>
         <nav id="sidebar" className={`sidebar-wrapper ${showText ? "sd-active" : ""}`}>
            <div className="sidebar-content">
               <div className="sidebar-brand">
                  <NavLink to="/">
                     <img src={logo} />
                  </NavLink>
               </div>
               <ul className="sidebar-menu">
                  <li>
                     <NavLink to={`/dashboard?id=${dashboardIdEncrypt}`} className={({ isActive }) => (isActive ? 'active-nav' : '')}><i class="fi fi-rr-dashboard"></i> Dashboard</NavLink>
                  </li>

                  {/* --------------------------------------------------------------------------------- */}
                  {role_resources.length > 0 && role_resources.map((menu) => {
                     // Encrypt

                     var encyptId = CryptoJS.enc.Utf8.parse(menu._id); // WordArray object
                     var menuIdEncrypt = CryptoJS.enc.Base64.stringify(encyptId);
                     return (
                        <>
                           {/* {

                              menu.resource_name === "Dashboard" &&

                              <li>
                                 <NavLink to={`/dashboard?id=${menuIdEncrypt}`} className={({ isActive }) => (isActive ? 'active-nav' : '')}><i class="fi fi-rr-dashboard"></i> {menu.resource_name}</NavLink>
                              </li>
                           } */}
                           {
                              menu.resource_name === "Patients" &&

                              <li>
                                 <NavLink to={`/patients?id=${menuIdEncrypt}`} className={({ isActive }) => (isActive ? 'active-nav' : '')}><i class="fi fi-rr-wheelchair"></i> Patients</NavLink>
                              </li>
                           }

                           {
                              menu.resource_name === "Doctors" &&

                              <li className={`sidebar-dropdown ${(currentPath === "/doctors" || currentPath === "/add-doctor" || currentPath === "/edit-profile" || currentPath === "/pending-doctors" || currentPath === "/suspended-doctors" || currentPath === "/view-suspend-doctor" || currentPath === "/doctor-payroll" || currentPath === "/appointment" || currentPath === "/payroll-history") ? "active" : ''}`}>
                                 <a href="javascript:void(0)" className="main_menu"><i class="fi fi-rr-stethoscope"></i> Doctors</a>
                                 <div className={`sidebar-submenu ${(currentPath === "/doctors" || currentPath === "/add-doctor" || currentPath === "/edit-profile" || currentPath === "/pending-doctors" || currentPath === "/suspended-doctors" || currentPath === "/view-suspend-doctor" || currentPath === "/doctor-payroll" || currentPath === "/appointment" || currentPath === "/payroll-history") ? "d-block" : ''}`}>
                                    <ul>
                                       <li>
                                          <NavLink to={`/doctors?id=${menuIdEncrypt}`} className={({ isActive }) => isActive ? "active-nav-in" : ''}>All Doctor</NavLink>
                                       </li>
                                       <li>
                                          <NavLink to={`/pending-doctors?id=${menuIdEncrypt}`} className={({ isActive }) => isActive ? "active-nav-in" : ''}>Pending Approvals</NavLink>
                                       </li>
                                       <li>
                                          <NavLink to={`/suspended-doctors?id=${menuIdEncrypt}`} className={({ isActive }) => isActive ? "active-nav-in" : ''}>Suspended Doctors</NavLink>
                                       </li>
                                       <li>
                                          <NavLink to={`/doctor-payroll?id=${menuIdEncrypt}`} className={({ isActive }) => isActive ? "active-nav-in" : ''}>Payroll Setting</NavLink>
                                       </li>
                                       <li>
                                          <NavLink to={`/appointment?id=${menuIdEncrypt}`} className={({ isActive }) => isActive ? "active-nav-in" : ''}>Cost Setting</NavLink>
                                       </li>
                                       <li>
                                          <NavLink to={`/payroll-history?id=${menuIdEncrypt}`} className={({ isActive }) => isActive ? "active-nav-in" : ''}>Payroll History</NavLink>
                                       </li>
                                    </ul>
                                 </div>
                              </li>
                           }

                           {
                              menu.resource_name === "Pharmacy" &&
                              <li className={`sidebar-dropdown ${(currentPath === "/pharmacy" || currentPath === "/add-pharmacy" || currentPath === "/edit-pharmacy" || currentPath === "/suspended-pharmacy") ? "active" : ''}`}>
                                 <a href="javascript:void(0)" className="main_menu"><i class="fi fi-rr-pharmacy"></i> Pharmacy</a>
                                 <div className={`sidebar-submenu ${(currentPath === "/pharmacy" || currentPath === "/add-pharmacy" || currentPath === "/edit-pharmacy" || currentPath === "/suspended-pharmacy") ? "d-block" : ''}`}>
                                    <ul>
                                       <li>
                                          <NavLink to={`pharmacy?id=${menuIdEncrypt}`} className={({ isActive }) => isActive ? "active-nav-in" : ''}>All Pharmacy</NavLink>
                                       </li>
                                       {/* <li>
                                          <NavLink to={``} className={({ isActive }) => isActive ? "active-nav-in" : ''}>Add Pharmacy</NavLink>
                                       </li> */}
                                       <li>
                                          <NavLink to={`suspended-pharmacy?id=${menuIdEncrypt}`} className={({ isActive }) => isActive ? "active-nav-in" : ''}>Suspended Pharmacy</NavLink>
                                       </li>

                                    </ul>
                                 </div>
                              </li>
                           }

                           {
                              menu.resource_name === "Laboratory" &&
                              <li className={`sidebar-dropdown ${(currentPath === "/laboratory" || currentPath === "/add-laboratory" || currentPath === "/edit-laboratory" || currentPath === "/suspended-laboratory-pharmacy" || currentPath === "/laboratory-payroll" || currentPath === "/laboratory-payroll-history" ) ? "active" : ''}`}>
                                 <a href="javascript:void(0)" className="main_menu"><i class="fi fi-rr-test-tube"></i> Laboratory</a>
                                 <div className={`sidebar-submenu ${(currentPath === "/laboratory" || currentPath === "/add-laboratory" || currentPath === "/edit-laboratory" || currentPath === "/suspended-laboratory-pharmacy" || currentPath === "/laboratory-payroll" || currentPath === "/laboratory-payroll-history" )? "d-block" : ''}`}>
                                    <ul>
                                       <li>
                                          <NavLink to={`/laboratory?id=${menuIdEncrypt}`} className={({ isActive }) => isActive ? "active-nav-in" : ''}>All Laboratory</NavLink>
                                       </li>
                                       <li>
                                          <NavLink to={`/suspended-laboratory?id=${menuIdEncrypt}`} className={({ isActive }) => isActive ? "active-nav-in" : ''}>Suspended</NavLink>
                                       </li>
                                       <li>
                                          <NavLink to={`/laboratory-payroll?id=${menuIdEncrypt}`} className={({ isActive }) => isActive ? "active-nav-in" : ''}>Payroll Setting</NavLink>
                                       </li>
                                       <li>
                                          <NavLink to={`/laboratory-payroll-history?id=${menuIdEncrypt}`} className={({ isActive }) => isActive ? "active-nav-in" : ''}>Payroll History</NavLink>
                                       </li>
                                    </ul>
                                 </div>
                              </li>
                           }

                           {
                              menu.resource_name === "Partners" &&
                              <li>
                                 <NavLink to={`partner-list?id=${menuIdEncrypt}`} className={({ isActive }) => (isActive ? 'active-nav' : '')}><i class="fi fi-rr-briefcase"></i> Partners</NavLink>
                              </li>
                           }
      
                           {
                              menu.resource_name === "FAQ" &&
                              <li>
                                 <NavLink to={`/faq?id=${menuIdEncrypt}`} className={({ isActive }) => (isActive ? 'active-nav' : '')}><i class="fi fi-rr-question-square"></i> FAQ</NavLink>
                              </li>
                           }

                           {
                              menu.resource_name === "Security Question" &&
                              <li>
                                 <NavLink to={`/security-question?id=${menuIdEncrypt}`} className={({ isActive }) => (isActive ? 'active-nav' : '')}><i class="fi fi-rr-text-check"></i>Security Question</NavLink>
                              </li>
                           }

                           {
                              menu.resource_name === "Questionnaire" &&
                              <li>
                                 <NavLink to={`/questionnaire?id=${menuIdEncrypt}`} className={({ isActive }) => (isActive ? 'active-nav' : '')}><i class="fi fi-rr-bulb"></i>Questionnaire</NavLink>
                              </li>
                           }

                           {
                              menu.resource_name === "Review" &&
                              <li>
                                 <NavLink to={`/review?id=${menuIdEncrypt}`} className={({ isActive }) => (isActive ? 'active-nav' : '')}><i class="fi fi-rr-star"></i> Review</NavLink>
                              </li>
                           }

                           {
                              menu.resource_name === "Subscription" &&
                              <li>
                                 <NavLink to={`/subscription-list?id=${menuIdEncrypt}`} className={({ isActive }) => (isActive ? 'active-nav' : '')}><i class="fi fi-rr-bookmark"></i> Subscription List</NavLink>
                              </li>
                           }

                           {/* {
                              menu.resource_name === "Review" &&

                              <li>
                                 <NavLink to="#" className={({ isActive }) => (isActive ? 'active-nav' : '')}><i class="fi fi-rr-star"></i> Review</NavLink>
                              </li>
                           } */}
                           {
                              menu.resource_name === "Onboarding Video" &&
                              <li>
                                 <NavLink to={`onboarding-video?id=${menuIdEncrypt}`} className={({ isActive }) => (isActive ? 'active-nav' : '')}><i class="fi fi-rr-play-alt"></i>Onboarding Video</NavLink>
                              </li>
                           }

                           {
                              menu.resource_name === "Tearms & Conditions" &&
                              <li>
                                 <NavLink to={`terms-condition?id=${menuIdEncrypt}`} className={({ isActive }) => (isActive ? 'active-nav' : '')}><i class="fi fi-rr-shield-exclamation"></i> Terms & Condition</NavLink>
                              </li>
                           }

                           {
                              menu.resource_name === "Revenue" &&
                              <li className={`sidebar-dropdown ${(currentPath === "/doctors") ? "active" : ''}`}>
                                 <a href="javascript:void(0)" className="main_menu"><i class="fi fi-rr-chart-histogram"></i> Revenue</a>
                                 <div className={`sidebar-submenu ${(currentPath === "/doctors") ? "d-block" : ''}`}>
                                    <ul>
                                       <li>
                                          <NavLink to={`revenue-dashboard?id=${menuIdEncrypt}`}  className={({ isActive }) => isActive ? "active-nav-in" : ''}>Revenue Dashboard</NavLink>
                                       </li>
                                       {/* <li>
                                          <NavLink to={`payroll-setting`} className={({ isActive }) => isActive ? "active-nav-in" : ''}>Payroll Setting</NavLink>
                                       </li>
                                       <li>
                                          <NavLink to={``} className={({ isActive }) => isActive ? "active-nav-in" : ''}>Bonus Reports</NavLink>
                                       </li> */}

                                    </ul>
                                 </div>
                              </li>
                           }

                           {
                              menu.resource_name === "Language" &&
                              <li>
                                 <NavLink to={`language-list?id=${menuIdEncrypt}`} className={({ isActive }) => (isActive ? 'active-nav' : '')}><i class="fi fi-rr-letter-case"></i> Language</NavLink>
                              </li>
                           }

                           {
                              menu.resource_name === "Country" &&
                              <li>
                                 <NavLink to={`country-list?id=${menuIdEncrypt}`} className={({ isActive }) => (isActive ? 'active-nav' : '')}><i class="fi fi-rr-world"></i> Country</NavLink>
                              </li>
                           }

                           {
                              menu.resource_name === "Specialization" &&
                              <li>
                                 <NavLink to={`specialization?id=${menuIdEncrypt}`} className={({ isActive }) => (isActive ? 'active-nav' : '')}><i class="fi fi-rr-trophy"></i> Specialization</NavLink>
                              </li>
                           }

                           {
                              menu.resource_name === "Report Type" &&
                              <li>
                                 <NavLink to={`/report-type?id=${menuIdEncrypt}`} className={({ isActive }) => (isActive ? 'active-nav' : '')}><i class="fi fi-rr-document"></i> Plan Features</NavLink>
                              </li>
                           }

                           {
                              menu.resource_name === "Admin Setting" &&
                              <li>
                                 <NavLink to={`/role-list?id=${menuIdEncrypt}`} className={({ isActive }) => (isActive ? 'active-nav' : '')}><i class="fi fi-rr-settings"></i> Admin Setting</NavLink>
                              </li>
                           }
                        </>
                     )
                  })}

                  {/* --------------------------------------------------------------------------------- */}
               </ul>
            </div>
         </nav>

      </>
   )
}

