import React, { useState, useEffect } from "react";
import Header from "../Navbar/Header";
import NavLinkHeader from "../Navbar/NavLinkHeader";
import Services from "../../Services/auth.service";
import swal from "sweetalert";
import $ from "jquery";
import loader from "../../assets/images/loder.gif";
import { Link, useNavigate, useLocation } from "react-router-dom";
import pn from "../../assets/images/pn.png";
import ms from "../../assets/images/ms.png";
import photo from "../../assets/images/photo.png";
import plusImg from "../../assets/images/plus.png";
import deleteImg from "../../assets/images/delete.png";
import moment from "moment";          
import { countrycode } from "../Comman/countrycode";   

const CryptoJS = require("crypto-js");

const ViewSuspendedDoctor = () => {
    const history = useNavigate();      
    const getid = useLocation().search;
    const getMenuID = new URLSearchParams(getid).get("id");
    var decryptId = CryptoJS.enc.Base64.parse(getMenuID);
    var menuID = CryptoJS.enc.Utf8.stringify(decryptId);
    const OpenTabKey = new URLSearchParams(getid).get("openTab");
    const [isLoading, setIsLoading] = useState(false);

    //decrypt recordid
    const gatRecordID = new URLSearchParams(getid).get("record_id");
    var decryptRecordId = CryptoJS.enc.Base64.parse(gatRecordID);
    var recordID = CryptoJS.enc.Utf8.stringify(decryptRecordId);
    const [imagePrev, setImagePrev] = useState();
    const [reviewData, setReviewData] = useState([]);
    const [totalReview, setTotalReview] = useState();
    const [averageRating, setAverageRating] = useState();
    const [errMessage, setErrMessage] = useState("");

    const [country, setCountry] = useState([{}]);
    const [specialization, setSpecialization] = useState([{}]);
    const [physicianCare, setPhysicianCare] = useState(false);
    const [doctorProfile, setDoctorProfile] = useState({});
    const [doctorAllData, setDoctorAllData] = useState({});
    const [specializationCategory, setSpecializationCategory] = useState([]);
    const [documentsData, setDocumentsData] = useState([]);
    const [totalEarning, setTotalEarning] = useState();
    const [totalPatient, setTotalPatient] = useState();
    var DocumentExtension;
    const [formFields, setFormFields] = useState({})
    useEffect(() => {
        const loggedInUser = localStorage.getItem("token");
        !loggedInUser && history("/");
        getDoctorProfileData();
        getDoctorReviewData();
        getSpecializationData();
        getCountryData();
    }, []);
    const getCountryData = () => {
        Services.getCountry(menuID).then((response) => {
            if (response.data.status === true) {
                if (response.data.data.length > 0) {

                    setCountry(response.data.data)
                }
            }

        }).catch((err) => {
        })
    }
    const getSpecializationData = () => {
        Services.getSpecialization(menuID).then((response) => {
            if (response.data.status === true) {
                if (response.data.data.length > 0) {

                    setSpecialization(response.data.data)
                }
            }

        }).catch((err) => {
        })
    }

    const getDoctorReviewData = () => {

        Services.getDoctorReviewListById(menuID, recordID).then((response) => {

            if (response.data.status === true) {
                var Review = response.data.data;
                setReviewData(Review.result);
                setTotalReview(Review.review_count);
                setAverageRating(Review.average[0].average);

                setErrMessage("")
            } else {
                setErrMessage(response.data.message)

            }
        })
            .catch((err) => {
            })
    }

    const getDoctorProfileData = () => {
        Services.getDoctorById(menuID, recordID).then((response) => {
            setFormFields(response.data.data.signup_otherdetail_id)
            setImagePrev(response.data.data.signup_otherdetail_id.profileImg ? response.data.data.signup_otherdetail_id.profileImg : "");
            setPhysicianCare(response.data.data.signup_otherdetail_id.primary_care_physician)
            setDoctorProfile(response.data.data.signup_otherdetail_id)
            setDoctorAllData(response.data.data)
            setTotalEarning(response.data.data.total_earning)
            setTotalPatient(response.data.data.total_patients)
            var SpecializedCategory = response.data.data.specialization_ids
            setSpecializationCategory(SpecializedCategory)
            if (response.data.data.documents_id.length > 0) {
                setDocumentsData(response.data.data.documents_id)
            }

        }).catch((err) => {
        })
    }



    const handleDeleteAccount = () => {
        var bodyData = {
            "doctor_id": recordID
        }

        Services.deleteDoctorAccount(menuID, bodyData)
            .then((response) => {
                if (response.data.status === true) {
                    // setFilterData(response.data.data);
                    swal("Success", response.data.message, "success");
                    document.querySelector("#viewappointment .btn-close").click();

                    history(`/suspended-doctors?id=${getMenuID}`);


                } else {
                    swal("Failed", response.data.message, "error");
                }
            })
            .catch(function (err) {
                swal("Failed", err.response.data.message, "error");
            });
    };
    const handleUnsuspend = () => {
        var bodyData = {
            "doctor_id": recordID
        }
        setIsLoading(true)
        Services.unsuspendDoctor(menuID, bodyData)
            .then((response) => {
                setIsLoading(false)
                if (response.data.status === true) {
                    document.querySelector("#unsuspendmodal .btn-close").click();
                    swal("Success", response.data.message, "success");
                    history(`/doctors?id=${getMenuID}`);

                } else {
                    setIsLoading(false)

                    swal("Failed", response.data.message, "error");
                }
            })
            .catch(function (err) {
                setIsLoading(false)

                swal("Failed", err.response.data.message, "error");
            });
    };

    return (
        <main className="page-content">
            <Header title="Suspended Doctor Profile" main_title="Doctor" />

            <div className="container-fluid">
                <div className="layout-specing">
                    <div className="d-flex justify-content-between align-items-center mb-3">
                        <h4 className="mb-0">Suspend Doctor</h4>
                        <NavLinkHeader
                            title1="Suspended Doctor"
                            title1_link={`/suspended-doctors?id=${getMenuID}`}
                            title2="Suspended Doctor Profile"
                        />
                    </div>
                    <div className="cstm-doctor-profile  rounded-md  border">
                        <div className="cstm-doctor-profile-left">
                            <div className="">

                                <div className="mb-3  mt-5 text-center"><img className="csatm-od-photo" src={doctorProfile.profileImg ? "https://anywherehealth.s3.amazonaws.com/" + doctorProfile.profileImg : plusImg} /></div>
                                <h6 className="mb-1 text-center">{doctorProfile.surname && doctorProfile.surname} {doctorProfile.first_name && doctorProfile.first_name}</h6>
                                <p className="mb-4 text-center">{doctorProfile.address && doctorProfile.address}</p>

                                <div className="d-flex text-center p-3  cstm-vp-te">
                                    <div className="col-md-6">
                                        <h4 className="mb-0">{totalPatient}</h4>
                                        <p className="mb-0">Visited Patients</p>
                                    </div>
                                    <div className="col-md-6">
                                        <h4 className="mb-0">₦{totalEarning}</h4>
                                        <p className="mb-0">Total Earning</p>
                                    </div>
                                </div>

                            </div>
                            {/* <div className="p-3">
                                <h6 className="mb-2">About Doctor</h6>
                                <div className="title2">{formFields.about}</div>
                            </div> */}
                            <div className="p-3 pt-1">
                                <h6 className="mb-3">Specialized Categories</h6>
                                <div>
                                    {
                                        specializationCategory.length > 0 &&
                                        specializationCategory.map((item, index) => (
                                            <span className="cstm-sc-label" key={index}>{item.specialization}</span>
                                        ))
                                    }
                                </div>
                            </div>


                            <div className="p-3 pt-1 pb-1">
                                <h6>Contact Info</h6>
                            </div>


                            <div className="p-3 pt-1 cstm-ci-box">
                                <img src={pn} className="cstm-cl-img" />
                                <div className="cstm-cl-mn">
                                    <span className="cstm-cl-mn-ttl">Phone:</span>
                                    <span className="cstm-cl-mn-sub">{doctorProfile.phone_number}</span>
                                </div>
                            </div>

                            <div className="p-3 pt-1   cstm-ci-box">
                                <img src={ms} className="cstm-cl-img" />
                                <div className="cstm-cl-mn">
                                    <span className="cstm-cl-mn-ttl">Email address:</span>
                                    <span className="cstm-cl-mn-sub">{doctorProfile.email}</span>
                                </div>
                            </div>
                        </div>
                        <div className="cstm-doctor-profile-right">


                            <div className="cstm-tabs-pf">
                                {/* <button className="cstm-btn8 cstm-add-bonus-btn" data-bs-toggle="modal" data-bs-target="#viewappointment">Add Bonus</button> */}

                                <ul className="nav" id="pills-tab" role="tablist">
                                    <li className="nav-item">
                                        <a className={OpenTabKey === 'overview' ? 'nav-link active' : 'nav-link'} id="overview-tab" data-bs-toggle="pill" href="#pills-experience" role="tab" aria-controls="pills-experience" aria-selected="false">
                                            Overview
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a className={OpenTabKey !== 'account-setting' && OpenTabKey !== 'overview' ? 'nav-link active' : 'nav-link'} id="overview-tab1" data-bs-toggle="pill" href="#pills-overview" role="tab" aria-controls="pills-overview" aria-selected="false">
                                            Review
                                        </a>
                                    </li>

                                    <li className="nav-item">
                                        <a className={OpenTabKey === 'account-setting' && OpenTabKey !== 'overview' ? 'nav-link active' : 'nav-link'} id="experience-tab" data-bs-toggle="pill" href="#AccountSetting" role="tab" aria-controls="pills-experience" aria-selected="false">
                                            Account Setting
                                        </a>
                                    </li>
                                </ul>

                                <div className="tab-content mt-2" id="pills-tabContent">

                                    <div className={OpenTabKey !== 'account-setting' ? 'tab-pane fade show active' : 'tab-pane fade'} id="pills-experience" role="tabpanel"
                                        aria-labelledby="overview-tab">
                                        <div className="py-2 px-3">

                                            <h5 className="mb-3">Submitted Documents</h5>

                                            <div className="cstm-sd-main">
                                                {
                                                    documentsData ? documentsData.map((doc, index) => (
                                                        DocumentExtension = doc.files[0].split(".").pop(),
                                                        <>
                                                            <div className="cstm-sd-main-block">
                                                                <div className="cstm-sd-mb-img"><img className="cstm-documentImg" src={doc.files[0] !== "" && (DocumentExtension === "jpg" || DocumentExtension === "png" || DocumentExtension === "jpeg") && "https://anywherehealth.s3.amazonaws.com/" + doc.files[0]} /></div>
                                                                <h6 className="mt-3 mb-0">{doc.document_category_id.category}</h6>
                                                                {/* <div className="approve-reject-btn">
                                                                    <button onClick={() => handleApproveRejectDocument(doc._id, doc.document_category_id._id, doc.doctor_id, "approved")} className="cstm-chekmank document-btn"><i className="fi fi-rr-check" aria-hidden="true"></i></button>
                                                                    <button onClick={() => handleApproveRejectDocument(doc._id, doc.document_category_id._id, doc.doctor_id, "rejected")} className="cstm-cross mrn-rt document-btn"><i className="fi fi-rr-cross"></i>
                                                                    </button>
                                                                </div> */}
                                                            </div>

                                                        </>
                                                    )) : ""
                                                }

                                            </div>

                                            <div className="pb-3">
                                                <h6 className="mb-2">Experiance</h6>
                                                <div className="title2">{doctorAllData.experience ? `Total ${doctorAllData.experience}` : `Total 0 year`}</div>

                                            </div>

                                            <div className="pb-3">
                                                <h6 className="mb-2">Joining Date</h6>
                                                <div className="title2">{moment(doctorAllData.createdAt).format('DD MMM, YYYY')}</div>

                                            </div>

                                            {/* <div className="pb-0">
                                                <h6 className="mb-2">Time Table</h6>
                                            </div> */}

                                            {/* <div className="table-responsive bg-white rounded">
                                                <table className="table mb-0 table-center">
                                                    <tr>
                                                        <th className="border-bottom border-top w-27">Days</th>
                                                        <th className="border-bottom border-top w-24">Morning</th>
                                                        <th className="border-bottom border-top w-25">Afternoon</th>
                                                        <th className="border-bottom border-top w-24">Evening</th>
                                                    </tr>
                                                    <tr>
                                                        <td>Monday</td>
                                                        <td>9:00 AM - 11:30 AM</td>
                                                        <td>3:00 PM - 6:00 PM</td>
                                                        <td>8:00 PM - 11:00PM</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Tuesday</td>
                                                        <td>9:00 AM - 11:30 AM</td>
                                                        <td>3:00 PM - 6:00 PM</td>
                                                        <td>8:00 PM - 11:00PM</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Wednesday</td>
                                                        <td>9:00 AM - 11:30 AM</td>
                                                        <td>3:00 PM - 6:00 PM</td>
                                                        <td>8:00 PM - 11:00PM</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Thrusday</td>
                                                        <td>9:00 AM - 11:30 AM</td>
                                                        <td>3:00 PM - 6:00 PM</td>
                                                        <td>8:00 PM - 11:00PM</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Friday</td>
                                                        <td>9:00 AM - 11:30 AM</td>
                                                        <td>3:00 PM - 6:00 PM</td>
                                                        <td>8:00 PM - 11:00PM</td>
                                                    </tr>
                                                    <tr className="cstm-tb-red">
                                                        <td>Saturday</td>
                                                        <td>9:00 AM - 11:30 AM</td>
                                                        <td>3:00 PM - 6:00 PM</td>
                                                        <td>-</td>
                                                    </tr>
                                                    <tr className="cstm-tb-red">
                                                        <td>Sunday</td>
                                                        <td>9:00 AM - 11:30 AM</td>
                                                        <td>3:00 PM - 6:00 PM</td>
                                                        <td>-</td>
                                                    </tr>
                                                </table>
                                            </div> */}
                                        </div>

                                    </div>



                                    <div className={(OpenTabKey !== 'account-setting' && OpenTabKey !== 'overview') ? 'tab-pane fade show active' : 'tab-pane fade'} id="pills-overview" role="tabpanel" aria-labelledby="overview-tab1">
                                        <div className="py-2 px-3">
                                            <h5 className="mb-2">Reviews</h5>

                                            <div className="cstm-pts-rve">
                                                <div className="cstm-pts-rve-star">
                                                    <ul className="list-unstyled mb-0">
                                                        <li className="list-inline-item"><i className="mdi mdi-star text-warning"></i></li>
                                                        <li className="list-inline-item"><i className="mdi mdi-star text-warning"></i></li>
                                                        <li className="list-inline-item"><i className="mdi mdi-star text-warning"></i></li>
                                                        <li className="list-inline-item"><i className="mdi mdi-star text-warning"></i></li>
                                                        <li className="list-inline-item"><i className="mdi mdi-star text-warning"></i></li>
                                                    </ul>
                                                </div>
                                                <div className="cstm-pts-rve-star-nm">({averageRating ? averageRating : 0})</div>
                                                <div className="cstm-pts-rvepr">-  {totalReview ? totalReview : 0} Review</div>
                                            </div>
                                            {errMessage !== "" && <p className="error-msg">{errMessage}</p>}


                                            {
                                                reviewData.length > 0 &&
                                                reviewData.map((review, index) => (
                                                    <>
                                                        <div className="cstm-reviews-in-main" key={index}>
                                                            <div className="cstm-reviews-in-main-left"><img src={review.reviewBy.signup_otherdetail_id.profileImg ? "https://anywherehealth.s3.amazonaws.com/" + review.reviewBy.signup_otherdetail_id.profileImg : plusImg} className="cstm-rv-user-photo" /></div>
                                                            <div className="cstm-reviews-in-main-right">
                                                                <div className="cstm-reviews-in-main-right-title">{review.reviewBy.signup_otherdetail_id.first_name ? review.reviewBy.signup_otherdetail_id.first_name : ""} {review.reviewBy.signup_otherdetail_id.surname ? review.reviewBy.signup_otherdetail_id.surname : ""}</div>
                                                                <div className="cstm-pts-rve">
                                                                    <div className="cstm-pts-rve-star">
                                                                        <ul className="list-unstyled mb-0">
                                                                            <li className="list-inline-item"><i className="mdi mdi-star text-warning"></i></li>
                                                                            <li className="list-inline-item"><i className="mdi mdi-star text-warning"></i></li>
                                                                            <li className="list-inline-item"><i className="mdi mdi-star text-warning"></i></li>
                                                                            <li className="list-inline-item"><i className="mdi mdi-star text-warning"></i></li>
                                                                            <li className="list-inline-item"><i className="mdi mdi-star text-warning"></i></li>
                                                                        </ul>
                                                                    </div>
                                                                    <div className="cstm-pts-rve-star-nm">({review.rating ? review.rating : 0})</div>
                                                                </div>
                                                                <div className="cstm-reviews-in-main-right-cont">{review.comment ? review.comment : ""}</div>
                                                            </div>
                                                        </div>
                                                    </>
                                                ))}
                                        </div>
                                    </div>



                                    <div className={OpenTabKey === 'account-setting' ? 'tab-pane fade show active' : 'tab-pane fade'} id="AccountSetting" role="tabpanel" aria-labelledby="experience-tab">

                                        <div className="py-2 px-3">
                                            <h5 className="mb-3">Account Setting</h5>


                                            <div className="card  border rounded-md rounded p-4">
                                                {/* <div className="mb-4 cstm-profile-img d-flex  align-items-center">
                                                    <div className="mr-5">
                                                        {imagePrev ? (
                                                            <img
                                                                className="profile-upl-img"
                                                                width="200"
                                                                height="200"
                                                                src={"https://anywherehealth.s3.amazonaws.com/" + imagePrev}
                                                            />
                                                        ) : (
                                                            <img src={plusImg} className="profile-upl-img" />
                                                        )}
                                                    </div>
                                                </div> */}
                                                <div className="row">
                                                    <div className="col-lg-6">
                                                        <div className="mb-4">
                                                            <label className="cstm-label">Firts Name</label>
                                                            <input type="text" disabled value={formFields.first_name} className="cstm-input" placeholder="Enter first name" name="first_name"
                                                                required="" />

                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <div className="mb-4">
                                                            <label className="cstm-label">Surname</label>
                                                            <input type="text" disabled value={formFields.surname} className="cstm-input" placeholder="Enter surname" name="surname"
                                                                required="" />

                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-lg-6">
                                                        <div className="mb-4">
                                                            <label className="cstm-label">Email</label>
                                                            <input
                                                                type="email"
                                                                className="cstm-input"
                                                                placeholder="Enter email"
                                                                name="email"
                                                                disabled

                                                                value={formFields.email}
                                                                required=""
                                                            />

                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <div className="mb-4">
                                                            <label className="cstm-label">Country</label>
                                                            <select className="cstm-select" disabled value={formFields.country} name="country" >
                                                                {country.map(item => (
                                                                    <option key={item._id} value={item._id}>{item.country}</option>
                                                                ))}
                                                            </select>

                                                        </div>
                                                    </div>
                                                </div>



                                                <div className="row">
                                                    <div className="col-lg-6">
                                                        <div className="mb-4">
                                                            <label className="cstm-label">Gender</label>
                                                            <select className="cstm-select" disabled value={formFields.gender} name="gender" >
                                                                <option value="male">Male</option>
                                                                <option value="female">Female</option>
                                                            </select>

                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <div className="mb-4">
                                                            <label className="cstm-label">Age</label>
                                                            <input
                                                                type="text"
                                                                className="cstm-input"
                                                                maxlength="2"
                                                                minlength="2"
                                                                placeholder="Enter age"
                                                                name="age"
                                                                value={formFields.age}

                                                                required=""
                                                                disabled
                                                            />

                                                        </div>
                                                    </div>
                                                </div>


                                                <div className="row">
                                                    <div className="col-lg-6">
                                                        <div className="mb-4">
                                                            <label className="cstm-label">Code</label>
                                                            <select className="cstm-select" disabled name="country_code" value={formFields.country_code} >
                                                                {countrycode.map((item, i) => (<option key={i} value={item.value}>{item.label}</option>))}
                                                            </select>

                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <div className="mb-4">
                                                            <label className="cstm-label">Contact no</label>
                                                            <input
                                                                type="text"
                                                                className="cstm-input"
                                                                maxlength="10"
                                                                placeholder="Enter phone no"
                                                                name="phone_number"
                                                                value={formFields.phone_number}

                                                                required=""
                                                                disabled
                                                            />

                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        <div className="mb-4">
                                                            <label className="cstm-label">Address</label>
                                                            <input
                                                                type="text"
                                                                className="cstm-input"
                                                                placeholder="Enter address"

                                                                value={formFields.address}
                                                                name="address"
                                                                required=""
                                                                disabled
                                                            />
                                                        </div>
                                                    </div>

                                                </div>
                                                <div className="row">

                                                    <div className="col-lg-12">
                                                        <div className="mb-4">
                                                            <label className="cstm-label">Specialization</label>
                                                            <select className="cstm-select" disabled name="specialization_ids" value={formFields.specialization_ids} >
                                                                {specialization.map(item => (
                                                                    <option key={item._id} value={item._id}>{item.specialization}</option>
                                                                ))}
                                                            </select>

                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="row">
                                                    <div class="col-lg-12">
                                                        <div class="mb-4">
                                                            <input type="checkbox" checked={physicianCare} name="primary_care_physician"
                                                                required="" disabled />Is Doctor For Primary Care?
                                                        </div>


                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="pb-3 mt-2">
                                                    {/* <div className="cstm-ldr-mn"> */}
                                                    <button className="mr-3 cstm-btn" data-bs-toggle="modal" data-bs-target="#unsuspendmodal">Unsuspended Account </button>
                                                    {/* {isLoading &&
                                                            <div id="btn-loader" className="btn-loader-main">
                                                                <img className="btn-loaderImg" src={loader} /></div>
                                                        } */}
                                                    {/* </div> */}
                                                    <button className="mr-3 cstm-btn7" data-bs-toggle="modal" data-bs-target="#viewappointment">Delete Account</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
            </div>

            {/* unsuspend modal */}
            <div className="modal fade cstm-add-bonus" id="unsuspendmodal" tabindex="-1" aria-labelledby="exampleModalLabel1"
                aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header border-0 p-4">
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body p-4 pt-0">
                            <div className="mb-4">

                                <div className="text-center mb-4">
                                    <img src={deleteImg} />
                                </div>
                                <h4 className="text-center mb-4">Are you sure want to unsuspend this Account?</h4>
                                <div className="text-center">
                                    <div className="cstm-ldr-mn">

                                        <button className="cstm-btn7" onClick={handleUnsuspend}>Unsuspend Account</button>
                                        {isLoading &&
                                            <div id="btn-loader" className="btn-loader-main">
                                                <img className="btn-loaderImg" src={loader} /></div>
                                        }
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            {/* delete modal */}
            <div className="modal fade cstm-add-bonus" id="viewappointment" tabindex="-1" aria-labelledby="exampleModalLabel1"
                aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header border-0 p-4">
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body p-4 pt-0">
                            <div className="mb-4">

                                <div className="text-center mb-4">
                                    <img src={deleteImg} />
                                </div>
                                <h4 className="text-center mb-4">Are you sure want to delete Account?</h4>
                                <div className="text-center">
                                    <button className="cstm-btn7" onClick={handleDeleteAccount}>Delete Account</button>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </main>


        //delete account modal


    )
}

export default ViewSuspendedDoctor

