import React from 'react'
import Header from '../Navbar/Header'

const Payrollhistory = () => {
    return (
        <div className="page-wrapper doctris-theme toggled">
            <main className="page-content">
                <Header />
                <div className="container-fluid">
                    <div className="layout-specing">
                        <div className="d-flex justify-content-between align-items-center mb-3">
                            <h4 className="mb-0">History of Payroll</h4>
                            <div className="cstm-bre uppercase">
                                Dashboard &gt; <a href="#">Payroll setting</a>
                            </div>
                        </div>
                        <div className="cstm-doctor-profile  rounded-md  border">
                            <div className="cstm-faq-only">
                                <div className="cstm-tabs-pf">
                                    <ul className="nav" id="pills-tab" role="tablist">
                                        <li className="nav-item">
                                            <a
                                                className="nav-link active"
                                                id="overview-tab"
                                                data-bs-toggle="pill"
                                                href="#Doctor"
                                                role="tab"
                                                aria-controls="Doctor"
                                                aria-selected="false"
                                            >
                                                Doctor Payroll
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a
                                                className="nav-link"
                                                id="experience-tab"
                                                data-bs-toggle="pill"
                                                href="#Pharmacy"
                                                role="tab"
                                                aria-controls="Pharmacy"
                                                aria-selected="false"
                                            >
                                                Laboratory Payroll
                                            </a>
                                        </li>
                                    </ul>
                                    <div className="tab-content" id="pills-tabContent">
                                        <div
                                            className="tab-pane fade show active"
                                            id="Doctor"
                                            role="tabpanel"
                                            aria-labelledby="overview-tab"
                                        >
                                            <div className="d-flex justify-content-between align-items-center p-3 border-bottom">
                                                <div className="col-md-3">
                                                    <select
                                                        className="cstm-select cstm-om-slc"
                                                        name="City"
                                                        id="City"
                                                    >
                                                        <option value="City">Select category</option>
                                                        <option value="City2">Select category 2</option>
                                                    </select>
                                                </div>
                                                <div className="faq-add-bonus">
                                                    <button className="cstm-btn">Add Payroll</button>
                                                </div>
                                            </div>
                                            <div className="d-flex justify-content-between align-items-center">
                                                <div className="col-md-12 col-lg-12">
                                                    <div className="table-responsive bg-white rounded">
                                                        <table className="table mb-0 table-center">
                                                            <tbody>
                                                                <tr>
                                                                    <th className="border-bottom w-4">No.</th>
                                                                    <th className="border-bottom w-36">Category</th>
                                                                    <th className="border-bottom w-14">
                                                                        Based Price
                                                                    </th>
                                                                    <th className="border-bottom w-16">
                                                                        Price Per Patient
                                                                    </th>
                                                                    <th className="border-bottom w-10">&nbsp;</th>
                                                                </tr>
                                                                <tr>
                                                                    <td className="fw-bold">1</td>
                                                                    <td>General Physicians</td>
                                                                    <td>100</td>
                                                                    <td>450</td>
                                                                    <td>
                                                                        <a href="#" className="cstm-chekmank">
                                                                            <i className="fi fi-rr-pencil" />
                                                                        </a>
                                                                        <a href="#" className="cstm-cross mrn-rt">
                                                                            <i className="fi fi-rr-trash" />
                                                                        </a>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="fw-bold">2</td>
                                                                    <td>General Physicians</td>
                                                                    <td>100</td>
                                                                    <td>450</td>
                                                                    <td>
                                                                        <a href="#" className="cstm-chekmank">
                                                                            <i className="fi fi-rr-pencil" />
                                                                        </a>
                                                                        <a href="#" className="cstm-cross mrn-rt">
                                                                            <i className="fi fi-rr-trash" />
                                                                        </a>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="fw-bold">3</td>
                                                                    <td>General Physicians</td>
                                                                    <td>100</td>
                                                                    <td>450</td>
                                                                    <td>
                                                                        <a href="#" className="cstm-chekmank">
                                                                            <i className="fi fi-rr-pencil" />
                                                                        </a>
                                                                        <a href="#" className="cstm-cross mrn-rt">
                                                                            <i className="fi fi-rr-trash" />
                                                                        </a>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="fw-bold">4</td>
                                                                    <td>General Physicians</td>
                                                                    <td>100</td>
                                                                    <td>450</td>
                                                                    <td>
                                                                        <a href="#" className="cstm-chekmank">
                                                                            <i className="fi fi-rr-pencil" />
                                                                        </a>
                                                                        <a href="#" className="cstm-cross mrn-rt">
                                                                            <i className="fi fi-rr-trash" />
                                                                        </a>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="fw-bold">5</td>
                                                                    <td>General Physicians</td>
                                                                    <td>100</td>
                                                                    <td>450</td>
                                                                    <td>
                                                                        <a href="#" className="cstm-chekmank">
                                                                            <i className="fi fi-rr-pencil" />
                                                                        </a>
                                                                        <a href="#" className="cstm-cross mrn-rt">
                                                                            <i className="fi fi-rr-trash" />
                                                                        </a>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="fw-bold">6</td>
                                                                    <td>General Physicians</td>
                                                                    <td>100</td>
                                                                    <td>450</td>
                                                                    <td>
                                                                        <a href="#" className="cstm-chekmank">
                                                                            <i className="fi fi-rr-pencil" />
                                                                        </a>
                                                                        <a href="#" className="cstm-cross mrn-rt">
                                                                            <i className="fi fi-rr-trash" />
                                                                        </a>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="fw-bold">7</td>
                                                                    <td>General Physicians</td>
                                                                    <td>100</td>
                                                                    <td>450</td>
                                                                    <td>
                                                                        <a href="#" className="cstm-chekmank">
                                                                            <i className="fi fi-rr-pencil" />
                                                                        </a>
                                                                        <a href="#" className="cstm-cross mrn-rt">
                                                                            <i className="fi fi-rr-trash" />
                                                                        </a>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="fw-bold">8</td>
                                                                    <td>General Physicians</td>
                                                                    <td>100</td>
                                                                    <td>450</td>
                                                                    <td>
                                                                        <a href="#" className="cstm-chekmank">
                                                                            <i className="fi fi-rr-pencil" />
                                                                        </a>
                                                                        <a href="#" className="cstm-cross mrn-rt">
                                                                            <i className="fi fi-rr-trash" />
                                                                        </a>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="fw-bold">9</td>
                                                                    <td>General Physicians</td>
                                                                    <td>100</td>
                                                                    <td>450</td>
                                                                    <td>
                                                                        <a href="#" className="cstm-chekmank">
                                                                            <i className="fi fi-rr-pencil" />
                                                                        </a>
                                                                        <a href="#" className="cstm-cross mrn-rt">
                                                                            <i className="fi fi-rr-trash" />
                                                                        </a>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="fw-bold">10</td>
                                                                    <td>General Physicians</td>
                                                                    <td>100</td>
                                                                    <td>450</td>
                                                                    <td>
                                                                        <a href="#" className="cstm-chekmank">
                                                                            <i className="fi fi-rr-pencil" />
                                                                        </a>
                                                                        <a href="#" className="cstm-cross mrn-rt">
                                                                            <i className="fi fi-rr-trash" />
                                                                        </a>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className="tab-pane fade"
                                            id="Pharmacy"
                                            role="tabpanel"
                                            aria-labelledby="experience-tab"
                                        >
                                            <div className="d-flex justify-content-between align-items-center p-3">
                                                Coming soon...
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row text-center">
                            <div className="col-12 mt-4">
                                <div className="d-md-flex align-items-center text-center justify-content-between">
                                    <span className="text-muted me-3">Showing 1 - 10 out of 50</span>
                                    <ul className="pagination justify-content-center mb-0 mt-3 mt-sm-0">
                                        <li className="page-item">
                                            <a
                                                className="page-link"
                                                href="javascript:void(0)"
                                                aria-label="Previous"
                                            >
                                                Prev
                                            </a>
                                        </li>
                                        <li className="page-item active">
                                            <a className="page-link" href="javascript:void(0)">
                                                1
                                            </a>
                                        </li>
                                        <li className="page-item">
                                            <a className="page-link" href="javascript:void(0)">
                                                2
                                            </a>
                                        </li>
                                        <li className="page-item">
                                            <a className="page-link" href="javascript:void(0)">
                                                3
                                            </a>
                                        </li>
                                        <li className="page-item">
                                            <a
                                                className="page-link"
                                                href="javascript:void(0)"
                                                aria-label="Next"
                                            >
                                                Next
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </div>


    )
}

export default Payrollhistory
