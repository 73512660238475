import React from 'react'

const Star = (averageRating) => {

 const ratingstar =  Array.from({length:5},(elem,index) => {

    let number = index + 0.5;

    return(
        <span key={index}  >
           
            {
                averageRating.stars >= index + 1 ? <i className="mdi mdi-star text-warning" /> : averageRating.stars >= number ? <i className="mdi  mdi-star-half text-warning" /> : <i className="mdi  mdi-star-outline text-warning" />
            }
        </span>
    )

})

  return (
  
    <div className="cstm-pts-rve">
    <div className="cstm-pts-rve-star">
        <ul className="list-unstyled mb-0">
          {ratingstar}
        </ul>
    </div>
</div>

  )
}

export default Star
