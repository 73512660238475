import React, { useEffect, useState } from 'react'
import logo from '../../assets/images/logo.png'
import { NavLink } from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as Yup from "yup"; // used when validating with a pre-built solution
import swal from 'sweetalert';
import $ from 'jquery';
import { useNavigate, Navigate } from "react-router-dom";
import Services from '../../Services/auth.service';

const ForgotPassword = () => {
    const history = useNavigate();

    useEffect(() => {
        const loggedInUser = localStorage.getItem("token");
        !loggedInUser && history("/forgotpassword");
    }, []);

    const validationSchema = Yup.object().shape({
        admin_email: Yup.string().email('Please enter proper email format!').required('Please enter an email!'),
    });

    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm({
        mode: 'onchange',
        resolver: yupResolver(validationSchema)
    });

    const onSubmit = async (data, e) => {
        var dataValue = {
            "email": data.admin_email,
        }
        Services.adminForgotPassword(dataValue)
            .then(response => {
                // return false;
                if (response.data.status === true) {
                    swal("Success", response.data.message, "success");
                    // history("/")

                } else {
                    swal("Failed", response.data.message, "error");

                }
            }).catch(err => {
                swal("Failed", err.response.data.message, "error");


            })
    }

    return (
        <>
            <section className="bg-home d-flex bg-light align-items-center cstm-fm-all">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-5 col-md-8">
                            <div className="card login-page bg-white shadow rounded border-0">
                                <div className="card-body">
                                    <div className="text-center mb-4 cstm-f-logo">
                                        {/* <a href="#">
                                              <img src={logo} />
                                        </a> */}

                                        <NavLink> <img src={logo} alt="" /> </NavLink>

                                    </div>
                                    <h4>Forgot Password</h4>
                                    <form noValidate onSubmit={handleSubmit(onSubmit)} className="login-form mt-4"
                                    >
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className="mb-4">
                                                    <label className="cstm-label">Email<span className="text-danger">*</span></label>
                                                    <input
                                                        className="cstm-input"
                                                        type="email"
                                                        {...register('admin_email')}
                                                        placeholder="Enter your email"
                                                        name="admin_email"
                                                        required=""
                                                    />
                                                     <span className="invalid-error-login" style={{ color: "red" }}>{errors.admin_email?.message}</span>

                                                </div>
                                            </div>
                                            <div className="col-lg-12">
                                                <div className="d-grid">
                                                    <button className="cstm-btn1">Submit</button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            {/**/}
                        </div>{" "}
                        {/*end col*/}
                    </div>
                    {/*end row*/}
                </div>{" "}
                {/*end container*/}
            </section>
            {/*end section*/}
        </>

    )
}

export default ForgotPassword
