import React, { useState, useEffect } from 'react';
import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import './assets/css/style.min.css'

import Sidebar from './Componants/Navbar/SideBar'
import SidebarLayout from './Componants/Navbar/SidebarLayout';

import Login from './Componants/adminpanel/Login';
import ForgotPassword from './Componants/adminpanel/ForgotPassword';
import ResetPassword from './Componants/adminpanel/reset-password';
import Dashboard from './Componants/DashBoard/Index';
import Patients from './Componants/Patients/Index';
import FAQ from './Componants/FAQ/Index';
import RoleListing from './Componants/Admin Setting/role-listing';
import AddAdminUser from './Componants/Admin Setting/add-adminuser';
import EditAdminUser from './Componants/Admin Setting/edit-adminuser';
import OnBoardingVideoList from './Componants/OnBordingVideo/Index';

import Doctors from './Componants/Doctors/index';
import PendingDoctorList from './Componants/Doctors/pending-list';
import SuspendedDoctorList from './Componants/Doctors/suspended-list';
import AddDoctor from './Componants/Doctors/add-doctor';
import ViewPendingDoctorProfile from './Componants/Doctors/view-pending-profile';
import ViewSuspendedDoctor from './Componants/Doctors/view-suspended-doctor';
import EditProfile from './Componants/Doctors/edit-profile';
import SecurityQuestion from './Componants/SecurityQuestion/SecurityQuestion';
import Appointment from './Componants/Doctors/Appointment';

import Pharmacy from './Componants/Pharmacy/Index';
import AddPharmacy from './Componants/Pharmacy/add-pharmacy';
import EditPharmacy from './Componants/Pharmacy/pharmacy-profile';
import SuspendedPharmacy from './Componants/Pharmacy/suspended-profile-list';
import ViewSuspendPharmacy from './Componants/Pharmacy/view-suspend-pharmacy';
import PharmacyProfile from './Componants/Pharmacy/pharmacy-profile';

import Laboratory from './Componants/Laboratory/Index';
import AddLaboratory from './Componants/Laboratory/add-laboratory';
import EditLaboratory from './Componants/Laboratory/laboratory-profile';
import SuspendedLaboratory from './Componants/Laboratory/suspended-laboratory-profile-list';
import ViewSuspendLaboratory from './Componants/Laboratory/view-suspend-laboratory';
import LaboratoryProfile from './Componants/Laboratory/laboratory-profile';

import LanguageList from './Componants/Language/language-list';
import CountryList from './Componants/Country/Country-list';
import Specialization from './Componants/Specialization/Specialization';

import TermsAndCondition from './Componants/TermsAndCondition/TermsAndCondition';
import SubscriptionList from './Componants/Subscription/subscription-Listing';
import AddSubscription from './Componants/Subscription/add-subscription';
import EditSubscription from './Componants/Subscription/edit-subscription';
import RevenueDashboard from './Componants/Revenue/RevenueDashboard';
import PayrollSettings from './Componants/Revenue/Payroll-settings';
import Payrollhistory from './Componants/Revenue/Payroll-history';

import PartnerList from './Componants/Partner/Partner-List';
import PartnerProfile from './Componants/Partner/Partner-Profile';
import ReportTypeList from './Componants/ReportType/Index';
import AddPartner from './Componants/Partner/Add-Partner';

import Questionary from './Componants/Questionnaire/Questionnaire'
import Review from './Componants/Review/Review'
import DoctorPayroll from './Componants/Doctors/Doctor-Payroll';
import LaboratoryPayroll from './Componants/Laboratory/Laboratory-Payroll';
import PayrollHistory from './Componants/Doctors/Payroll-History';
import LaboratoryPayrollHistory from './Componants/Laboratory/Payroll-History';

function App() {

  const [loginUser, setLoginUser] = useState(false)
  useEffect(() => {
    var loggedInUser = localStorage.getItem("token");
    if (loggedInUser) {
      setLoginUser(true);
    } else {
      setLoginUser(false);
    }

  }, []);
  console.log = console.warn = console.error = () => { };
  return (

    <div className="page-wrapper doctris-theme toggled">
      <Routes>
        <Route exact path='/' element={loginUser ? <Navigate to="/dashboard" /> : <Login />} />
        <Route exact path='forgotpassword' element={!loginUser && <ForgotPassword />} />
        <Route exact path='reset-password' element={<ResetPassword />} />

        <Route element={<SidebarLayout />}>
          <Route exact path='dashboard' element={<Dashboard />} />
          <Route exact path='patients' element={<Patients />} />
          <Route exact path='faq' element={<FAQ />} />
          <Route exact path='role-list' element={<RoleListing />} />
          <Route exact path='add-adminuser' element={<AddAdminUser />} />
          <Route exact path='edit-adminuser' element={<EditAdminUser />} />
          <Route exact path='onboarding-video' element={<OnBoardingVideoList />} />

          <Route exact path='doctors' element={<Doctors />} />
          <Route exact path='add-doctor' element={<AddDoctor />} />
          <Route exact path='pending-doctors' element={<PendingDoctorList />} />
          <Route exact path='suspended-doctors' element={<SuspendedDoctorList />} />
          <Route exact path='pending-doctor-profile' element={<ViewPendingDoctorProfile />} />
          <Route exact path='view-suspend-doctor' element={<ViewSuspendedDoctor />} />
          <Route exact path='edit-profile' element={<EditProfile />} />
          <Route exact path='doctor-payroll' element={<DoctorPayroll />} />
          <Route exact path='appointment' element={<Appointment />} />
          <Route exact path='payroll-history' element={<PayrollHistory />} />


          <Route exact path='pharmacy' element={<Pharmacy />} />
          <Route exact path='add-pharmacy' element={<AddPharmacy />} />
          <Route exact path='edit-pharmacy' element={<EditPharmacy />} />
          <Route exact path='suspended-pharmacy' element={<SuspendedPharmacy />} />
          <Route exact path='view-suspend-pharmacy' element={<ViewSuspendPharmacy />} />
          <Route exact path='pharmacy-profile' element={<PharmacyProfile />} />

          <Route exact path='laboratory' element={<Laboratory />} />
          <Route exact path='add-laboratory' element={<AddLaboratory />} />
          <Route exact path='edit-laboratory' element={<EditLaboratory />} />
          <Route exact path='suspended-laboratory' element={<SuspendedLaboratory />} />
          <Route exact path='view-suspend-laboratory' element={<ViewSuspendLaboratory />} />
          <Route exact path='laboratory-profile' element={<LaboratoryProfile />} />
          <Route exact path='laboratory-payroll' element={<LaboratoryPayroll />} />
          <Route exact path='laboratory-payroll-history' element={<LaboratoryPayrollHistory />} />

          <Route exact path='report-type' element={<ReportTypeList />} />

          <Route exact path='language-list' element={<LanguageList />} />
          <Route exact path='country-list' element={<CountryList />} />
          <Route exact path='specialization' element={<Specialization />} />

          {/* Terms&Condition */}
          <Route exact path='terms-condition' element={<TermsAndCondition />} />

          {/* Subscription */}
          <Route exact path='subscription-list' element={<SubscriptionList />} />
          <Route exact path='add-subscription' element={<AddSubscription />} />
          <Route exact path='edit-subscription' element={<EditSubscription />} />

          {/* revenue */}
          <Route exact path='revenue-dashboard' element={<RevenueDashboard />} />
          <Route exact path='payroll-setting' element={<PayrollSettings />} />
          <Route exact path='payroll-history' element={<Payrollhistory />} />

          {/* {/ partner /} */}
          <Route exact path='partner-list' element={<PartnerList />} />
          <Route exact path='partner-profile' element={<PartnerProfile />} />
          <Route exact path='add-partner' element={<AddPartner />} />


          {/* {/ Security question /} */}
          <Route exact path='security-question' element={<SecurityQuestion />} />
          <Route exact path='questionnaire' element={<Questionary />} />

          {/* {/ review /} */}
          <Route exact path='review' element={<Review />} />

          {/* appointment */}

          <Route path="*" element={<>404 Not Found</>} />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
