import React, { usestate, useEffect, useState } from 'react'
import Header from '../Navbar/Header'
import NavLinkHeader from '../Navbar/NavLinkHeader'
import plusImg from "../../assets/images/plus.png";
import Services from "../../Services/auth.service";
import loader from "../../assets/images/loder.gif";
import { Link, useNavigate, useLocation } from 'react-router-dom';
import swal from "sweetalert";
import deleteImg from "../../assets/images/delete.png";



import { map } from 'jquery';

const CryptoJS = require("crypto-js");

const PartnerList = () => {

    const history = useNavigate();

    const getid = useLocation().search;
    const getMenuID = new URLSearchParams(getid).get("id");
    var decryptId = CryptoJS.enc.Base64.parse(getMenuID);
    var menuID = CryptoJS.enc.Utf8.stringify(decryptId);

    const [partnerData, setPartnerData] = useState([])
    const [isLoading, setIsLoading] = useState(true);
    const [selectedPartner, setSelectedPartner] = useState();


    var encyptId
    var recordIdEncrypt;

    const pageLoadData = () => {
        Services.getPartner(menuID)
            .then((response) => {
                setIsLoading(false)
                setPartnerData(response.data.data)
            })
    }

    useEffect(() => {
        const loggedInUser = localStorage.getItem("token");
        !loggedInUser && history("/");
        pageLoadData();
    }, [])

    const searchItems = (searchValue) => {
        if (searchValue.length !== 0) {

            const filteredData = partnerData.filter((item) => {
                return Object.values(item).join('').toLowerCase().includes(searchValue.toLowerCase())
            })
            setPartnerData(filteredData)
        } else {
            pageLoadData();
        }
    }

    const onClickDelete = () => {
        Services.deletePartner(menuID, selectedPartner)
            .then((response) => {
                if (response.data.status === true) {
                    document.querySelector("#cancelappointment .btn-close").click();
                    // setTimeout(() => {
                    //     window.location.reload()
                    // }, 1500)
                    swal("Success", response.data.message, "success");
                    pageLoadData();
                    // getData();
                }
                else {
                    swal("Failed", response.data.message, "error");
                }
            })
            .catch(function (err) {
                swal("Failed", err.response.data.message, "error");
            });
    };

    return (
        <>
            <div className="page-wrapper doctris-theme toggled">
                <main className="page-content">
                    <Header />
                    <div className="container-fluid">
                        <div className="layout-specing">
                            <div className="d-flex justify-content-between align-items-center mb-3">
                                <h4 className="mb-0">Partners List</h4>
                                <NavLinkHeader title1="partner-list" />
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card  rounded-md  border">
                                        <div className="d-flex justify-content-between align-items-center p-3 border-bottom">
                                            <div className="col-md-8">
                                                <div className="row row ">
                                                    <div className="col-md-5">
                                                        <i className="fi fi-rr-search cstm-search-ro" />
                                                        <input
                                                            name="name"
                                                            id="name"
                                                            type="text"
                                                            className="cstm-input-seacrh"
                                                            placeholder="Search Partners"
                                                            onChange={(e) => searchItems(e.target.value)}
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div>
                                                {/* <button className="cstm-btn">Add Partners</button> */}

                                                <Link to={`/add-partner?id=${getMenuID}`} class="mr-3 cstm-btn6">
                                                    Add Partners
                                                </Link>
                                            </div>
                                        </div>
                                        <div className="d-flex justify-content-between align-items-center p-3">
                                            <div className="cstm-partners-list">
                                                {
                                                    partnerData.map((item, index) => (
                                                        encyptId = CryptoJS.enc.Utf8.parse(item._id), // WordArray object
                                                        recordIdEncrypt = CryptoJS.enc.Base64.stringify(encyptId),

                                                        <div className="cstm-partners-list-main" key={index}>
                                                            <div className="cstm-partners-list-main-img">
                                                                <img src={item.profile ? "https://anywherehealth.s3.amazonaws.com/" + item.profile : plusImg} />

                                                            </div>
                                                            <div className="cstm-partners-list-main-title">
                                                                {item.name}
                                                            </div>
                                                            <div className="cstm-partners-list-main-cont">
                                                                {item.aboutus.substring(0, 80)}
                                                                <div className='cstm-pl-read-more'>
                                                                    <Link to={`/partner-profile?id=${getMenuID}&record_id=${recordIdEncrypt}`}>
                                                                        Read more
                                                                    </Link>
                                                                </div>
                                                                {/* <a href="#">Read more</a> */}
                                                            </div>

                                                            <div className="cstm-partners-list-main-btn">
                                                                <Link to={`/partner-profile?id=${getMenuID}&record_id=${recordIdEncrypt}`} class="mr-3 cstm-btn6">
                                                                    View Details
                                                                </Link>
                                                                {/* <button className="mr-3 cstm-btn6">View Details</button> */}
                                                                <Link
                                                                    to={" "}
                                                                    onClick={() => setSelectedPartner(item._id)}
                                                                    class="mr-3 cstm-btn6"
                                                                    data-bs-toggle="modal"
                                                                    data-bs-target="#cancelappointment"
                                                                >
                                                                    Delete
                                                                </Link>
                                                            </div>

                                                        </div>
                                                    )
                                                    )
                                                }

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {!isLoading ? partnerData.length === 0 &&
                                    <div className="NoRecord-cstm">No record found!</div> : <div className="NoRecord-cstm"> <img className="pageloader" src={loader} /></div>}
                            </div>
                        </div>
                    </div>
                </main>


                <div className="modal fade cstm-add-bonus" id="cancelappointment" tabindex="-1" aria-labelledby="exampleModalLabel1" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header border-0 p-4">
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body p-4 pt-0">
                                <div className="mb-4">

                                    <div className="text-center mb-4">
                                        <img src={deleteImg} />
                                    </div>
                                    <h4 className="text-center mb-4">Are you sure want to delete Partner?</h4>
                                    <div className="text-center">
                                        <button className="cstm-btn7" onClick={onClickDelete}>Delete Partner</button>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
export default PartnerList