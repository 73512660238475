import React, { useEffect, useState } from 'react'
import Header from '../Navbar/Header'
import Services from "../../Services/auth.service";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState, ContentState } from "draft-js";
import { Link, useNavigate, useLocation } from "react-router-dom";
import htmlToDraft from 'html-to-draftjs';
import { convertToHTML } from 'draft-convert';
import swal from "sweetalert";
import loader from "../../assets/images/loder.gif";
import NavLinkHeader from '../Navbar/NavLinkHeader';
import toolbar from '../../config/toolbar';
import localization from '../../config/localization';

const CryptoJS = require("crypto-js")

const TermsAndCondition = () => {

    const history = useNavigate();
    const getid = useLocation().search;
    const getMenuID = new URLSearchParams(getid).get("id");
    var decryptId = CryptoJS.enc.Base64.parse(getMenuID);
    var menuID = CryptoJS.enc.Utf8.stringify(decryptId);
    const [isLoading, setIsLoading] = useState(false);
    const [iisLoading, setIisLoading] = useState(false);

    // //decrypt recordid
    // const gatRecordID = new URLSearchParams(getid).get("record_id");
    // var decryptRecordId = CryptoJS.enc.Base64.parse(gatRecordID);
    // var recordID = CryptoJS.enc.Utf8.stringify(decryptRecordId);

    const [content, setContent] = useState(EditorState.createEmpty());
    const [contenthtml, setContenthtml] = useState();

    const [policycontent, setPolicyContent] = useState(EditorState.createEmpty());
    const [policycontenthtml, setPolicyContenthtml] = useState();

    useEffect(() => {
        const loggedInUser = localStorage.getItem("token");
        !loggedInUser && history("/");
        setTimeout(() => {
            loader(false);
        }, 2500)


        Services.getTermsConditionByKey(menuID, "terms-and-condition3").then((response) => {
            if (response.data.status === true) {
                if (response.data.data.value !== null) {
                    const contentBlock = htmlToDraft(response.data.data.value);
                    const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
                    const editorState = EditorState.createWithContent(contentState);
                    setContent(editorState);
                }
            }
        }).
            catch((err) => {
            })

        //Privacy Policy
        Services.getprivacypolicy(menuID).then((response) => {
            if (response.data.status === true) {
                if (response.data.data.value !== null) {
                    const policycontentBlock = htmlToDraft(response.data.data.value);
                    const policycontentState = ContentState.createFromBlockArray(policycontentBlock.contentBlocks);
                    const policyeditorState = EditorState.createWithContent(policycontentState);
                    setPolicyContent(policyeditorState);
                }
            }
        }).
            catch((err) => {
            })


    }, [])

    const handleOnSubmitUpdate = (e) => {
        e.preventDefault();
        var bodyData = {
            "key": "terms-and-condition3",
            "value": contenthtml
        }
        setIsLoading(true)
        Services.editTermsCondition(menuID, bodyData).then((response) => {
            setIsLoading(false)
            if (response.data.status === true) {
                swal("Success", response.data.message, "success");
            } else {
                swal("Failed", response.data.message, "error");
            }
        }).catch((err) => {
            setIsLoading(false)
        })
    }

    const onEditorStateChange = (editorState) => {
        setContenthtml(convertToHTML(editorState.getCurrentContent()));
        setContent(editorState)
    };

    // policy edit

    const handleOnSubmitPolicyUpdate = (e) => {
        e.preventDefault();
        var bodyData = {
            "key": "privacy-policy",
            "value": policycontenthtml
        }
        setIisLoading(true)
        Services.editPrivacyPolicy(menuID, bodyData).then((response) => {
            setIisLoading(false)
            if (response.data.status === true) {
                swal("Success", response.data.message, "success");
            } else {
                swal("Failed", response.data.message, "error");
            }
        }).catch((err) => {
            setIisLoading(false)
        })
    }

    const onEditorPolicyStateChange = (policyeditorState) => {

        setPolicyContenthtml(convertToHTML(policyeditorState.getCurrentContent()));
        setPolicyContent(policyeditorState)
    };

    return (
        <>
            <main className="page-content bg-light">
                <Header title="Terms & Condition" main_title="Help Center/Support" />
                <div className="container-fluid">
                    <div className="layout-specing">
                        <div className="d-flex justify-content-between align-items-center mb-3">
                            <h4 className="mb-0">Term & Condition And privacy policy</h4>
                            <NavLinkHeader title1="Term & Condition" />
                        </div>

                        <div className="row">
                            <div className="col-md-12">
                                <div className="card border-0 shadow rounded">
                                    <div className="d-flex justify-content-between align-items-center p-3 border-bottom">
                                        <h4 className="mb-0">Terms & Condition</h4>
                                    </div>

                                    <div className="p-3 col-md-10 col-lg-12 cstm-terms-condidtion">
                                        <form id="" onSubmit={handleOnSubmitUpdate}>
                                            <Editor
                                                editorState={content}
                                                onEditorStateChange={onEditorStateChange}
                                                toolbarClassName="editor-toolbar"
                                                wrapperClassName="editor-wrapper"
                                                editorClassName="editor"
                                                toolbar={toolbar}
                                                localization={localization}

                                            />
                                            <div className="d-flex">
                                                <div className="cstm-ldr-mn">
                                                    <button className="cstm-btn" type="submit">
                                                        Submit
                                                    </button>
                                                    {isLoading &&
                                                        <div id="btn-loader" className="btn-loader-main">
                                                            <img className="btn-loaderImg" src={loader} />
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="layout-specing mtop">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card border-0 shadow rounded">
                                    <div className="d-flex justify-content-between align-items-center p-3 border-bottom">
                                        <h4 className="mb-0">Privacy Policy</h4>
                                    </div>

                                    <div className="p-3 col-md-10 col-lg-12 cstm-terms-condidtion">
                                        <form id="" onSubmit={handleOnSubmitPolicyUpdate}>
                                            <Editor
                                                editorState={policycontent}
                                                onEditorStateChange={onEditorPolicyStateChange}
                                                toolbarClassName="editor-toolbar"
                                                wrapperClassName="editor-wrapper"
                                                editorClassName="editor"
                                                toolbar={toolbar}
                                                localization={localization}
                                            // toolbar={{
                                            //     inline: { inDropdown: true },
                                            //     list: { inDropdown: true },
                                            //     textAlign: { inDropdown: true },
                                            //     link: { inDropdown: true },
                                            //     history: { inDropdown: true },
                                            // image: { uploadCallback: uploadImageCallBack, alt: { present: true, mandatory: true } },
                                            // }}
                                            />
                                            {/* <span style={{ color: "red" }}>{showErrorMessage}</span> */}

                                            <div className="d-flex">
                                                <div className="cstm-ldr-mn">

                                                    <button className="cstm-btn" type="submit">
                                                        Submit
                                                    </button>
                                                    {iisLoading &&
                                                        <div id="btn-loader1" className="btn-loader-main">
                                                            <img className="btn-loaderImg" src={loader} />
                                                        </div>
                                                    }
                                                </div>
                                            </div>

                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    )


}

export default TermsAndCondition