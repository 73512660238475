import React, { useEffect, useState } from 'react';
import Header from '../Navbar/Header'
import NavLinkHeader from '../Navbar/NavLinkHeader'
import Services from "../../Services/auth.service";
import swal from "sweetalert";
import loader from '../../assets/images/loder.gif'
import { useTable, usePagination, useGlobalFilter } from "react-table";
import { Link, useNavigate, useLocation } from 'react-router-dom';
import moment from "moment";
import deleteImg from "../../assets/images/delete.png";
import plusImg from "../../assets/images/plus.png";

import Star from '../Doctors/Star';

const CryptoJS = require("crypto-js");

function Table({ columns, data }) {
    // const getid = useLocation().search;
    // const getMenuID = new URLSearchParams(getid).get("id");
    // var decryptId = CryptoJS.enc.Base64.parse(getMenuID);
    // var menuID = CryptoJS.enc.Utf8.stringify(decryptId);
    const [showLoader, setLoader] = useState(true);
    useEffect(() => {
        setTimeout(() => {
            setLoader(false);
        }, 1500)
    }, [])
    const props = useTable(
        {
            columns,
            data,
            initialState: { pageIndex: 0, pageSize: 20 }
        },
        useGlobalFilter, // useGlobalFilter!
        usePagination
    );
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        setGlobalFilter,
        // state,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        // setPageSize,

        state: { pageIndex, pageSize, globalFilter }
    } = props;

    let firstRecord = pageIndex * pageSize + 1;
    let lastRecord = firstRecord + pageSize - 1;

    return (
        <>
            {/* ============== */}
            <div className="row">
                <div className="col-md-12">
                    <div className="card  rounded-md  border">
                        <div className="d-flex justify-content-between align-items-center p-3 border-bottom">
                            <div className="col-md-8">
                                <div className="row row ">
                                    <div className="col-md-5">
                                        <i className="fi fi-rr-search cstm-search-ro" />
                                        <input
                                            name="serch-adminuser"
                                            id="serch-adminuser"
                                            type="text"
                                            class="cstm-input-seacrh"
                                            value={globalFilter || ""}
                                            onChange={(e) => setGlobalFilter(e.target.value)}
                                            placeholder="Search review"
                                        />
                                    </div>
                                    {/* <div class="col-md-3">
                                        <select class="cstm-select cstm-om-slc" name="user_type"  onChange={handleOnChangeFilter} id="user_type">
                                            <option value="doctor">Doctor</option>
                                            <option value="pharmacy">Pharmacy</option>
                                        </select>
                                    </div> */}
                                </div>
                            </div>
                        </div>

                        <div className="col-md-12 col-lg-12">
                            <div className="table-responsive bg-white rounded cstm-ldr">
                                <table
                                    {...getTableProps()}
                                    className="table mb-0 table-center"
                                >
                                    <thead>
                                        {" "}
                                        {headerGroups.map((headerGroup) => (
                                            <tr {...headerGroup.getHeaderGroupProps()}>
                                                {headerGroup.headers.map((column) => (
                                                    <th className={`border-bottom cstm-userheading ${column.Header === "No" ? "w-3" : ''}${column.Header === "Doctor Name" ? "w-20" : ''}${column.Header === "Age" ? "w-8" : ''}${column.Header === "Gender" ? "w-8" : ''}${column.Header === "Email" ? "w-18" : ''}${column.Header === "Address" ? "w-30" : ''}${column.Header === "Date" ? "w-14" : ''}${column.Header === "Actions" ? "actions3" : ''}`} {...column.getHeaderProps()}>
                                                        {column.render("Header")}
                                                        {/* Render the columns filter UI */}
                                                    </th>
                                                ))}
                                            </tr>
                                        ))}
                                    </thead>
                                    <tbody {...getTableBodyProps()}>
                                        {
                                            page.length > 0 && page.map((row, i) => {
                                                prepareRow(row);
                                                return (
                                                    <tr {...row.getRowProps()} className="cstm-Tabledesign">
                                                        {row.cells.map((cell) => {
                                                            return (
                                                                <td {...cell.getCellProps()}>
                                                                    {cell.render("Cell")}
                                                                </td>
                                                            );
                                                        })}
                                                    </tr>
                                                );
                                            })}
                                    </tbody>
                                </table>
                                {!showLoader ? page.length === 0 &&
                                    <div className="NoRecord-cstm">No record found!</div> : <div className="NoRecord-cstm"> <img className="pageloader" src={loader} /></div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row text-center">
                <div className="col-12 mt-4">
                    <div className="d-md-flex align-items-center text-center justify-content-between">
                        <span className="text-muted me-3">
                            {page.length !== 0 && `Showing ${firstRecord} -${" "}
                  ${lastRecord < rows.length ? lastRecord : rows.length} out of${" "}
                  ${rows.length}`}
                        </span>
                        <ul className="pagination justify-content-center mb-0 mt-3 mt-sm-0">
                            {pageOptions.length !== 1 && page.length !== 0 && (<li class="page-item">
                                <a
                                    class="page-link"
                                    onClick={() => previousPage()}
                                    disabled={!canPreviousPage}
                                    href="javascript:void(0)"
                                >
                                    Prev
                                </a>
                            </li>)}
                            {
                                pageOptions.map(pgnumber => {
                                    return (
                                        pgnumber !== pageCount && pageCount !== 1 && page.length !== 0 &&
                                        <li className={`page-item ${pageIndex === pgnumber ? 'active' : ''}`} ><a className="page-link" href="javascript:void(0)" onClick={() => gotoPage(pgnumber)} > {pgnumber + 1} </a></li>)
                                })
                            }
                            {pageOptions.length !== 1 && page.length !== 0 && (<li class="page-item">
                                <a
                                    class="page-link"
                                    onClick={() => nextPage()}
                                    disabled={!canNextPage}
                                    href="javascript:void(0)"
                                >
                                    Next
                                </a>
                            </li>)}
                        </ul>
                    </div>
                </div>
            </div>
        </>
    );
}

const Review = () => {
    const history = useNavigate();
    const getid = useLocation().search;
    const getMenuID = new URLSearchParams(getid).get("id");
    var decryptId = CryptoJS.enc.Base64.parse(getMenuID);
    var menuID = CryptoJS.enc.Utf8.stringify(decryptId);

    const [selectedId, setselectedId] = useState();
    const [data, setdata] = useState([]);
    const [filterData, setFilterData] = useState([]);
    const [viewreview, setviewreview] = useState("")

    var DataArray = [];
    var datareview = [];

    const columns = [
        {
            Header: 'No',
            accessor: 'serial'
        },
        {
            Header: "Name",
            accessor: "fullname"
        },
        {
            Header: "Email",
            accessor: "email"
        },
        {
            Header: "Review Given To",
            accessor: "user_role"
        },
        {
            Header: "Rating",
            accessor: "rating"
        },
        {
            Header: "Joined Date",
            accessor: "createdAt"
        },
        {
            Header: "Actions",

            Cell: (row) => {
                // var encyptId = CryptoJS.enc.Utf8.parse(row.cell.row.original._id); // WordArray object
                // var recordIdEncrypt = CryptoJS.enc.Base64.stringify(encyptId);
                return (
                    <div>
                        <Link to="" onClick={() => setviewreview(row.cell.row.original.comment)} data-bs-toggle="modal" data-bs-target="#ViewReview" class="cstm-eye"><i class="fi fi-rr-eye" aria-hidden="true"></i></Link>
                        {/* <Link to={`/edit-profile?id=${getMenuID}&record_id=${recordIdEncrypt}&openTab=account-setting`} class="cstm-chekmank"><i class="fi-rr-pencil" aria-hidden="true"></i></Link> */}
                        <Link to="" onClick={() => setselectedId(row.cell.row.original._id)} className="cstm-cross mrn-rt" data-bs-toggle="modal" data-bs-target="#DeleteReview">
                            <i className="fi fi-rr-trash"></i>
                        </Link>
                    </div>
                );
            },
        },
    ];

    function createData(_id, fullname, email, user_role, rating, createdAt, comment, Actions) {
        return {
            _id,
            fullname,
            email,
            user_role,
            rating,
            createdAt,
            comment,
        };
    }
    // No of Index 
    // data.forEach((data_id, index) => { data_id.serial = index + 1; });
    filterData.forEach((data_id, index) => { data_id.serial = index + 1; });

    useEffect(() => {
        const loggedInUser = localStorage.getItem("token");
        !loggedInUser && history("/");
        ReviewList()
    }, []);

    const ReviewList = () => {
        Services.getReview(menuID, {
            "categories": "all"
        })
            .then((response) => {
                // return false 
                if (response.data.status = 200) {
                    var arr = response.data.data;
                    if (response.data.data.length > 0) {
                        var newarr = [];

                        for (var i = 0; i < arr.length; i++) {

                            var date = moment(arr[i].createdAt).format('DD MMM, YYYY')
                            var reviewDetail = arr[i].reviewInfo[0].reviewBy.signup_otherdetail_id
                            if (reviewDetail !== null) {
                                var fullName = reviewDetail.surname && reviewDetail.first_name ?
                                    <>
                                        <img className="cstm-patientImg" src={reviewDetail.profileImg !== "" ? "https://anywherehealth.s3.amazonaws.com/" + reviewDetail.profileImg : plusImg} />
                                        {reviewDetail.surname} {reviewDetail.first_name}
                                    </> : <>{reviewDetail.surname ? reviewDetail.surname : ""} {reviewDetail.first_name ? reviewDetail.first_name : ""}</>
                                newarr[i] = createData(
                                    arr[i].reviewInfo[0]._id,
                                    fullName,
                                    reviewDetail.email,
                                    arr[i].user_role,
                                    // arr[i].reviewInfo[0].rating,
                                    <Star stars={arr[i].reviewInfo[0].rating} />,
                                    date,
                                    arr[i].reviewInfo[0].comment,

                                );
                            }
                            // }
                        }
                        newarr.map((data1) => {
                            DataArray = [...DataArray, data1]
                        })
                        setdata(DataArray);
                        setFilterData(DataArray)

                    }
                } else {
                }
            })
            .catch(function (err) {
            });
    }


    const handleDelete = () => {

        var bodyData = {
            "review_id": selectedId
        }

        Services.deleteReview(menuID, bodyData)
            .then((response) => {
                if (response.data.status === true) {
                    document.querySelector("#DeleteReview .btn-close").click();
                    ReviewList()
                    swal("Success", response.data.message, "success");
                    // history(`/suspended-doctors?id=${getMenuID}`);

                } else {
                    swal("Failed", response.data.message, "error");
                }
            })
            .catch(function (err) {
                swal("Failed", err.response.data.message, "error");
            });
    };

    const handleOnChangeFilter = (e) => {
        var type = e.target.value;
        if (type === "all") {
            setFilterData(data)
        } else {
            setFilterData(
                data.filter((doctordata, i) => {
                    return doctordata.user_role === type
                }
                ))
        }
    }

    return (
        <>
            <main className="page-content bg-light">
                <Header />
                <div className="container-fluid">
                    <div className="layout-specing">
                        <div className="d-flex justify-content-between align-items-center mb-3">
                            <h4 className="mb-0">Review </h4>
                            <NavLinkHeader title1="review" />
                        </div>
                        <div class="col-md-3 mb-3 cstm-dp-mn">
                            <select class="cstm-select cstm-om-slc" name="user_role" onChange={handleOnChangeFilter} id="user_role">
                                <option value="all" selected>All</option>
                                <option value="Doctor">Doctor</option>
                                <option value="pharmacy">Pharmacy</option>
                                <option value="laboratory">Laboratory</option>
                            </select>
                        </div>
                        <Table columns={columns} data={filterData} />
                    </div>
                </div>
            </main>

            {/* view modal */}

            <div class="modal fade cstm-add-bonus" id="ViewReview" tabIndex="-1" aria-labelledby="exampleModalLabel1" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header border-0 p-4">
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body p-4 pt-0">
                            <div class="mb-4">
                                <div className="cstm-ve-label">Comment</div>
                                 <div className='cstm-ve'>{viewreview}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Delete Review Modul */}

            <div class="modal fade cstm-add-bonus" id="DeleteReview" tabIndex="-1" aria-labelledby="exampleModalLabel1" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header border-0 p-4">
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body p-4 pt-0">
                            <div class="mb-4">
                                <div class="text-center mb-4">
                                    <img src={deleteImg} />
                                </div>
                                <h4 class="text-center mb-4">Are you sure want to delete this Review?</h4>
                                <div class="text-center">
                                    <button class="cstm-btn7" data-bs-toggle="modal" onClick={handleDelete}>Delete Review</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
export default Review;