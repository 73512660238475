import React, { useState, useEffect } from 'react'
import Header from '../Navbar/Header'
import Services from "../../Services/auth.service";
import totalrevenue from '../../assets/images/total-revenue.png'
import totaldoctors from '../../assets/images/total-doctors.png'
import pharmacyprofessionals from '../../assets/images/pharmacy-professionals.png'
import labprofessionals from '../../assets/images/lab-professionals.png'
import ragraf from '../../assets/images/ra-graf.svg'
import swal from "sweetalert";
import remainingbalance from '../../assets/images/total.png'
import stethoscope from '../../assets/images/stethoscope.png'
import dummy from '../../assets/images/dummy.png'
import yes from '../../assets/images/yes.png'
import plusImg from "../../assets/images/plus.png";

import wheelchair from '../../assets/images/wheelchair.png'
import revenuemap from '../../assets/images/revenue-map.svg'
import totalexpense from '../../assets/images/total-expense.png'
import { Link, useNavigate, useLocation } from "react-router-dom";

//chart 
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    Title,
    Tooltip,
    Filler,
    Legend
} from 'chart.js';
import { Bar, Line } from 'react-chartjs-2';
// import {  } from 'react-chartjs-2';
import faker from 'faker';
import Star from '../Doctors/Star';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    Title,
    Tooltip,
    Filler,
    Legend
);
const CryptoJS = require("crypto-js");


const Dashboard = () => {

    const history = useNavigate();
    const getid = useLocation().search;
    const getMenuID = new URLSearchParams(getid).get("id");
    var decryptId = CryptoJS.enc.Base64.parse(getMenuID);
    var menuID = CryptoJS.enc.Utf8.stringify(decryptId);

    // var getDoctorMenuID = "62c54e58126a5fd54247192c";
    var doctorMenuID = "62c54e58126a5fd54247192c";

    var encyptId;
    var recordIdEncrypt;

    const [doctorReviewResource, setDoctorReviewResource] = useState(true)
    const [doctorId, setDoctorId] = useState()
    const [coutedData, setCountedData] = useState({});
    const [doctorRequestData, setDoctorRequestData] = useState([]);
    const [barChartData, setBarChartData] = useState([]);
    const [specializationCategory, setSpecializationCategory] = useState([]);

    const [patientReviewData, setPatientReviewData] = useState([]);
    //chart
    const [yearFilter, setYearFilter] = useState(new Date().getFullYear());
    const [labels, setLabels] = useState([]);
    const [malePatientData, setMalePatientData] = useState([]);
    const [femalePatientData, setFemalePatientData] = useState([]);
    const [declainData, setDeclainData] = useState([]);

    // page id get
    // var encyptId;
    // var menuIdEncrypt;
    // const [doctorPgId,setDoctorPgId]=useState("");
    // const [reviewPgId,setReviewPgId]=useState("");

    const [err, setErr] = useState({
        declain_done: "",
        reason: ""
    })

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: 'Chart.js Bar Chart',
            },
        },
    };

    //line chart

    const chartoptions = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: 'Chart.js Line Chart',
            },
        },
    };



    // const role_resources = JSON.parse(localStorage.getItem("role_resources") || "[]")    
    // role_resources.length > 0 && role_resources.map((menu) => (
    //      encyptId = CryptoJS.enc.Utf8.parse(menu._id), // WordArray object
    //      menuIdEncrypt = CryptoJS.enc.Base64.stringify(encyptId),

    //     menu.resource_name === "Doctor" && menu.resource_name === "Review" && setDoctorReviewResource(true),

    //     menu.resource_name === "Doctor" && setDoctorPgId(menuIdEncrypt),
    //     menu.resource_name === "Review" && setReviewPgId(menuIdEncrypt)
    // )
    // )

    useEffect(() => {
        var loggedInUser = localStorage.getItem("token");
        !loggedInUser && history("/")
        getDashboardCountData();
        getDoctorRequestData();
        getPatientReviewData();
        getChartData(yearFilter);
    }, [yearFilter]);


    //count data
    const getDashboardCountData = () => {
        Services.getDashboardData(menuID)
            .then((response) => {
                if (response.data.status === true) {
                    setCountedData(response.data.data)
                } else {
                    swal("Failed", response.data.message, "error");
                }
            })
            .catch((err) => {
                swal("Failed", err.response.data.message, "error");
            });
    }
    //chart data

    const getChartData = (yearFilter) => {
        Services.getDashboardChartData(menuID, yearFilter)
            .then((response) => {
                if (response.data.status === true) {
                    setBarChartData(response.data.data)
                    var BarChartData = response.data.data;
                    const labelsData = BarChartData.map((chartData, i) => { return chartData.month });
                    const maleData = BarChartData.map((chartData, i) => { return chartData.male });
                    const femaleData = BarChartData.map((chartData, i) => { return chartData.female });
                    setLabels(labelsData);
                    setMalePatientData(maleData);
                    setFemalePatientData(femaleData)
                } else {
                    swal("Failed", response.data.message, "error");
                }
            })
            .catch((err) => {
                swal("Failed", err.response.data.message, "error");
            });
    }

    const handleYearOnChange = (e) => {
        var year = e.target.value
        setYearFilter(year);
    }

    const chartData = {
        labels,
        datasets: [
            {
                label: 'Male',
                data: malePatientData,
                backgroundColor: 'rgba(69, 124, 233, 1)',
            },
            {
                label: 'Female',
                data: femalePatientData,
                backgroundColor: 'rgba(242, 188, 124, 1)',
            },
        ],
    };


    //chart line

    const labelses = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

    const linedata = {
        labels,
        datasets: [
            {
                fill: true,
                label: 'Revenue',
                data: labelses.map(() => faker.datatype.number({ min: 0, max: 0 })),
                borderColor: '#3281e9',
                backgroundColor: '#ebf2fd',
            },
        ],
    };


    //pending doctor request data
    const getDoctorRequestData = () => {
        Services.getDoctorRequestDashboard(menuID).then((response) => {
            if (response.data.status === true) {
                var datas = response.data.data
                const sort = datas.sort().reverse()
                setDoctorRequestData(sort);
            } else {
                swal("Failed", response.data.message, "error");
            }
        })
            .catch((err) => {
                swal("Failed", err.response.data.message, "error");
            });
    }
    //patient review data list
    const getPatientReviewData = () => {
        Services.getPatientReviewDashboard(menuID).then((response) => {
            if (response.data.status === true) {
                var datas = response.data.data
                const sort = datas.sort().reverse()
                setPatientReviewData(sort);
                setPatientReviewData(response.data.data)
            } else {
            }
        })
            .catch((err) => {
                swal("Failed", err.response.data.message, "error");
            });
    }

    const handleRecordId = (doctorRecordId) => {
        setDoctorId(doctorRecordId);
    }

    const handleApproveAccount = () => {

        var bodyData = {
            "doctor_id": doctorId
        }

        Services.approveDoctorAccount(doctorMenuID, bodyData)
            .then((response) => {
                getDoctorRequestData();
                document.querySelector("#ApproveAccount .btn-close").click();
                swal("Success", "Account Approve Successfully", "success");
            })
            .catch(function (err) {
            })
    }
    const handleOnChange = (e) => {
        const { name, value } = e.target;
        if (name === "declain_done") {
            var status = e.target.checked;
            e.target.checked === true ? err.declain_done = "" : err.declain_done = "you need to check the checkbox"
            setDeclainData({ ...declainData, declain_done: status })
        }

        if (name === "reason") {
            value.length > 0 ? err.reason = "" : err.reason = "Write the declain reason"
            setDeclainData({ ...declainData, "reason": value })
        }
        setErr({ ...err })
    }

    const handleSubmit = (e) => {
        e.eventDefault();
        if (declainData.reason === "") {
            err.reason = "Write the declain reason"
        } else {
            err.reason = ""
        }
        if (declainData.declain_done === false) {
            err.declain_done = "you need to check the checkbox"
        } else {
            err.declain_done = ""
        }
        setErr({ ...err })
    }

    const handleDeclain = () => {
        if (declainData.declain_done === true) {

            var bodyData = {
                "doctor_id": doctorId,
                "account_decline_reasons": declainData.reason
            }
            // return false;
            Services.declainDoctorAccount(menuID, bodyData)
                .then((response) => {
                    getDoctorRequestData()
                    document.querySelector("#DeclineAccount .btn-close").click();
                    swal("Success", response.data.message, "success");

                })
                .catch(function (err) {
                })
        }

    }



    return (
        <>
            {/* <div className="page-wrapper doctris-theme toggled">
                <SideBar /> */}
            <main className="page-content bg-light">
                <Header />
                <div className="container-fluid">
                    <div className="layout-specing">
                        <h4 className="mb-4">Dashboard</h4>
                        <div className="row cstm-dsb-box">
                            <div className="col-xl-3 col-lg-6 col-md-3">
                                <div className="card features feature-primary border  rounded-md p-3">
                                    <Link to={`/patients?id=NjJjNTRlMzMxMjZhNWZkNTQyNDcxOTJh`} className="text-muted mb-0 cstm-tx14">
                                        <div className="d-flex align-items-center">
                                            <div className="cstm-icon4">
                                                <img src={totalrevenue} />
                                            </div>
                                            <div className="flex-1 ms-3" >
                                                <h4 className="mb-0">{coutedData.total_patients}</h4>
                                                {/* <p className="text-muted mb-0 cstm-tx14">Total Patients</p> */}
                                                Total Patients
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-6 col-md-3">
                                <div className="card features feature-primary border rounded-md p-3">
                                    <Link to={`/doctors?id=NjJjNTRlNTgxMjZhNWZkNTQyNDcxOTJj`} className="text-muted mb-0 cstm-tx14">
                                        <div className="d-flex align-items-center">
                                            <div className="cstm-icon4">
                                                <img src={totaldoctors} />
                                            </div>
                                            <div className="flex-1 ms-3">
                                                <h4 className="mb-0">{coutedData.total_doctor}</h4>
                                                Total Doctors
                                                {/* <p className="text-muted mb-0 cstm-tx14">Total Doctors</p> */}
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-6 col-md-3">
                                <div className="card features feature-primary border rounded-md p-3">
                                    <Link to={`/pharmacy?id=NjJjNTRlNjgxMjZhNWZkNTQyNDcxOTJl`} className="text-muted mb-0 cstm-tx14">
                                        <div className="d-flex align-items-center">
                                            <div className="cstm-icon4">
                                                <img src={pharmacyprofessionals} />
                                            </div>
                                            <div className="flex-1 ms-3">
                                                <h4 className="mb-0">{coutedData.total_pharmacy_professinals}</h4>
                                                {/* <p className="text-muted mb-0 cstm-tx14">
                                                Pharmacy Professionals
                                            </p> */}
                                                Pharmacy Professionals
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-6 col-md-3">
                                <div className="card features feature-primary border rounded-md p-3">
                                    <Link className="text-muted mb-0 cstm-tx14" to={`/laboratory?id=NjJjNTRlN2IxMjZhNWZkNTQyNDcxOTMw`} >
                                        <div className="d-flex align-items-center">
                                            <div className="cstm-icon4">
                                                <img src={labprofessionals} />
                                            </div>

                                            <div className="flex-1 ms-3">
                                                <h4 className="mb-0">{coutedData.total_lab_professinals}</h4>
                                                Lab Professionals
                                                {/* <p className="text-muted mb-0 cstm-tx14">Lab Professionals</p> */}
                                            </div>

                                        </div>
                                    </Link>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-xl-7 col-lg-7 mt-4">
                                <div className="card border rounded-md p-3">
                                    <div className="d-flex justify-content-between align-items-center mb-3">
                                        <h5 className="align-items-center mb-0">
                                            Patients Visit By Gender
                                        </h5>
                                        <div className="mb-0 position-relative">
                                            <select className="form-select form-control" value={yearFilter} onChange={handleYearOnChange} id="yearchart">
                                                <option value="2022">2022</option>
                                                <option value="2023">2023</option>
                                                <option value="2024">2024</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="cstm-revenue-analytics">
                                        {/* <img src={ragraf} /> */}
                                        <Bar options={options} data={chartData} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-5 col-lg-5 mt-4">
                                <div className="card  border rounded-md height-full">
                                    <div className="d-flex justify-content-between align-items-center p-3 border-bottom mb-2">
                                        <h5 className="align-items-center mb-0">Revenue</h5>
                                        <div className="mb-0 position-relative">
                                            <button className="cstm-btn3">View Report</button>
                                        </div>
                                    </div>
                                    <div className="d-flex p-3 align-items-center mt-4 mb-5">
                                        <div className="col-xl-2 col-lg-2">
                                            <span className="cstm-rvn-pc">₦0</span>
                                            {/* <p className="text-muted mb-0 cstm-tx14">Lab Professionals</p> */}
                                        </div>
                                        <div className="col-xl-10 col-lg-10">
                                            <Line options={chartoptions} data={linedata} />
                                        </div>
                                    </div>
                                    <div className="d-flex p-3 align-items-centerm-2 justify-content-between">
                                        <div className="d-flex align-items-center">
                                            <div className="cstm-icon4">
                                                <img src={totalexpense} />
                                            </div>
                                            <div className="flex-1 ms-3">
                                                <h4 className="mb-0">₦0</h4>
                                                <p className="text-muted mb-0 cstm-tx14">Total expense</p>
                                            </div>
                                        </div>
                                        <div className="d-flex align-items-center">
                                            <div className="cstm-icon4">
                                                <img src={remainingbalance} />
                                            </div>
                                            <div className="flex-1 ms-3">
                                                <h4 className="mb-0">₦0</h4>
                                                <p className="text-muted mb-0 cstm-tx14">
                                                    Remaining balance
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {
                            doctorReviewResource &&
                            <div className="row">
                                <div className="col-xl-6 col-lg-6 mt-4">
                                    <div className="card  border rounded-md rounded">
                                        <div className="d-flex justify-content-between align-items-center p-3 border-bottom">
                                            <h5 className="mb-0">
                                                {" "}
                                                <img
                                                    src={stethoscope}
                                                    className="mr-2"
                                                />{" "}
                                                Doctor Requests <span className="cstm-dr">({doctorRequestData.length})</span>
                                            </h5>
                                            {/* <Link to={`/pending-doctors?id=${doctorPgId}`} className='cstm-btn4'>View All</Link>                                                                                                                                                                                                                                                 */}
                                        </div>
                                        <ul className="list-unstyled mb-0 p-3 cstm-dsb-bullet-list csrm-ldr">

                                            {
                                                doctorRequestData.length > 0 ? doctorRequestData.slice(0, 5).map((doctor, i = 5) => (

                                                    encyptId = CryptoJS.enc.Utf8.parse(doctor._id), // WordArray object
                                                    recordIdEncrypt = CryptoJS.enc.Base64.stringify(encyptId),
                                                    <>

                                                        <li className="mb-3">
                                                            <div className="d-flex align-items-center justify-content-between" >
                                                                <div className="d-inline-flex">
                                                                    <img className="cstm-rco-photo" src={doctor.signup_otherdetail_id.profileImg !== "" ? "https://anywherehealth.s3.amazonaws.com/" + doctor.signup_otherdetail_id.profileImg : plusImg} />
                                                                    <div className="ms-3">
                                                                        <h5 className="text-dark mb-0 d-block">
                                                                            {doctor.signup_otherdetail_id.first_name} {doctor.signup_otherdetail_id.surname}
                                                                        </h5>
                                                                        <small className="text-muted">
                                                                            {doctor.specialization_ids[0].specialization}
                                                                        </small>
                                                                    </div>
                                                                </div>
                                                                <div className="cstm-ro-icon">
                                                                    <Link to={`/pending-doctor-profile?id=${getMenuID}&record_id=${recordIdEncrypt}`} className="cstm-eye"><i className="fi fi-rr-eye" aria-hidden="true"></i></Link>

                                                                    <Link to="" onClick={() => handleRecordId(doctor._id)} data-bs-toggle="modal" data-bs-target="#ApproveAccount" className="cstm-chekmank"><i className="fi fi-rr-check" aria-hidden="true"></i></Link>

                                                                    <Link to="" className="cstm-cross mrn-rt" onClick={() => handleRecordId(doctor._id)} data-bs-toggle="modal" data-bs-target="#DeclineAccount"><i className="fi fi-rr-cross"></i></Link>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    </>
                                                )) : <div className="NoRecord-cstm">No record found!</div>
                                            }
                                        </ul>
                                    </div>
                                </div>

                                <div className="col-xl-6 col-lg-6 mt-4">
                                    <div className="card  border rounded-md rounded">
                                        <div className="d-flex justify-content-between align-items-center p-3 border-bottom">
                                            <h5 className="mb-0">
                                                {" "}
                                                <img
                                                    src={wheelchair}
                                                    className="mr-2"
                                                />{" "}
                                                Patients Reviews
                                            </h5>
                                            <Link to={`/review?id=NjJjNTRlNTgxMjZhNWZkNTQyNDcxOTJj`} className='cstm-btn4'>View All</Link>
                                        </div>

                                        <ul className="list-unstyled mb-0 p-3 cstm-dsb-bullet-list csrm-ldr">

                                            {
                                                patientReviewData.length > 0 ? patientReviewData.slice(0, 5).map((patient, index) => (


                                                    encyptId = CryptoJS.enc.Utf8.parse(patient._id),  // WordArray object
                                                    recordIdEncrypt = CryptoJS.enc.Base64.stringify(encyptId),
                                                    <>
                                                        <li className="d-flex align-items-center justify-content-between mb-3">
                                                            <div className="d-flex align-items-center">
                                                                <img className="cstm-rco-photo" src={patient.doctorInfo[0] !== undefined && patient.doctorInfo[0].signup_otherdetail_id !== null ? "https://anywherehealth.s3.amazonaws.com/" + patient.doctorInfo[0].signup_otherdetail_id.profileImg : plusImg} />

                                                                <div className="flex-1 ms-3">

                                                                    {patient.doctorInfo[0] !== undefined && patient.doctorInfo[0].signup_otherdetail_id !== null ?
                                                                        <span className="d-block h5 mb-0">{patient.doctorInfo[0].signup_otherdetail_id.surname} {patient.doctorInfo[0].signup_otherdetail_id.first_name}</span>
                                                                        : ""
                                                                    }

                                                                    <small className="text-muted">{patient.doctorInfo[0] !== undefined ? patient.doctorInfo[0].specialization_ids[0].specialization:''}</small>

                                                                    <ul className="list-unstyled mb-0">

                                                                        <li className="list-inline-item text-muted">  <Star stars={patient.averageRating[0].average} /></li>

                                                                    </ul>

                                                                </div>
                                                            </div>


                                                            <p className="cstm-ls-cnt">{patient.review_count} patients</p>

                                                        </li>
                                                    </>

                                                )) : <div className="NoRecord-cstm">No record found!</div>
                                            }



                                            {/* <li className="d-flex align-items-center justify-content-between mb-3">

                                                <div className="d-flex align-items-center">
                                                    <img
                                                        src={dummy}
                                                        className="avatar avatar-small rounded-circle border shadow"
                                                        alt=""
                                                    />

                                                    <div className="flex-1 ms-3">

                                                        <span className="d-block h5 mb-0">Dr. Calvin Carlo</span>

                                                        <small className="text-muted">Orthopedic</small>

                                                        <ul className="list-unstyled mb-0">

                                                            <li className="list-inline-item">
                                                                <i className="mdi mdi-star text-warning" />
                                                            </li>

                                                            <li className="list-inline-item">
                                                                <i className="mdi mdi-star text-warning" />
                                                            </li>

                                                            <li className="list-inline-item">
                                                                <i className="mdi mdi-star text-warning" />
                                                            </li>

                                                            <li className="list-inline-item">
                                                                <i className="mdi mdi-star text-warning" />
                                                            </li>

                                                            <li className="list-inline-item">
                                                                <i className="mdi mdi-star text-warning" />
                                                            </li>

                                                            <li className="list-inline-item text-muted">(45)</li>

                                                        </ul>

                                                    </div>

                                                </div>

                                                <p className="cstm-ls-cnt">150 Patients</p>

                                            </li> */}

                                        </ul>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>

                {/* <!-- Declain account modal --> */}

                <div class="modal fade" id="DeclineAccount" tabindex="-1" aria-labelledby="exampleModalLabel1" aria-hidden="true">

                    <div class="modal-dialog modal-dialog-centered">

                        <div class="modal-content">

                            <div class="modal-header border-0 p-4">
                                <h4 class="modal-title" id="exampleModalLabel1">Decline Account</h4>
                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div class="modal-body p-4 pt-0">
                                <form onSubmit={handleSubmit}>

                                    <div class="mb-3">
                                        <label class="cstm-label">Reasons of decline</label>
                                        <textarea name="reason" onChange={handleOnChange} id="comments" rows="4" class="cstm-textarea" placeholder="write your reasons"></textarea>
                                    </div>
                                    {/* {err.reason !==""} */}
                                    <div class="mb-4">
                                        <label><input type="checkbox" onChange={handleOnChange} name="declain_done" value="" /> Are you sure decline this Account?</label>
                                    </div>

                                    <div class="d-flex justify-content-end">
                                        <button type="button" onClick={handleDeclain} className="cstm-btn7">Send</button>
                                    </div>

                                </form>
                            </div>
                        </div>
                    </div>
                </div>

                {/* aprove sucess modal */}
                <div className="modal fade cstm-add-bonus" id="ApproveAccount" tabindex="-1" aria-labelledby="exampleModalLabel1" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">

                        <div className="modal-content">

                            <div className="modal-header border-0 p-4">
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>

                            <div className="modal-body p-4 pt-0">
                                <div className="mb-4">

                                    <div className="text-center mb-4">
                                        <img src={yes} />
                                    </div>

                                    <h4 className="text-center mb-4">Are you sure want to approve this Doctor?</h4>

                                    <div className="text-center">
                                        <button className="cstm-btn7" onClick={handleApproveAccount}>Approve Doctor</button>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </main>
            {/* </div> */}


        </>
    )
}

export default Dashboard