import React, { useState, useEffect } from "react";
import Header from "../Navbar/Header";
import SideBar from "../Navbar/SideBar";
import NavLinkHeader from "../Navbar/NavLinkHeader";
import Services from "../../Services/auth.service";
import swal from "sweetalert";
import loader from "../../assets/images/loder.gif";
import { Link, useNavigate, useLocation } from "react-router-dom";
import plusImg from "../../assets/images/plus.png";
import stethoscopeImg from "../../assets/images/stethoscope.png";
import dummy from "../../assets/images/dummy.png";
const CryptoJS = require("crypto-js");

const AddLaboratory = () => {
  const history = useNavigate();
  const getid = useLocation().search;
  const getMenuID = new URLSearchParams(getid).get("id");
  var decryptId = CryptoJS.enc.Base64.parse(getMenuID);
  var menuID = CryptoJS.enc.Utf8.stringify(decryptId);
  const [isLoading, setIsLoading] = useState(false);

  var encyptId;
  var recordIdEncrypt;

  const [imagePrev, setImagePrev] = useState();
  const [pharmacyImage, setPharmacyImage] = useState();
  const [recentJoin, setRecentJoin] = useState([]);


  useEffect(() => {
    const loggedInUser = localStorage.getItem("token");
    !loggedInUser && history("/");
    getRecentJoinData();

  }, []);
  const getRecentJoinData = () => {

    Services.getRecentJoinLaboratory(menuID).then((response) => {
      setRecentJoin(response.data.data)

    }).catch((err) => {
    })

  }

  const [sundayStartTime, setSundayStartTime] = useState('00:00')
  const [sundayEndTime, setSundayEndTime] = useState('00:00')

  const [mondayStartTime, setMondayStartTime] = useState('00:00')
  const [mondayEndTime, setMondayEndTime] = useState('00:00')

  const [tuesdayStartTime, setTuesdayStartTime] = useState('00:00')
  const [tuesdayEndTime, setTuesdayEndTime] = useState('00:00')

  const [wednesdayStartTime, setWednesdayStartTime] = useState('00:00')
  const [wednesdayEndTime, setWednesdayEndTime] = useState('00:00')

  const [thursdayStartTime, setThursdayStartTime] = useState('00:00')
  const [thursdayEndTime, setThursdayEndTime] = useState('00:00')

  const [fridayStartTime, setFridayStartTime] = useState('00:00')
  const [fridayEndTime, setFridayEndTime] = useState('00:00')

  const [saturdayStartTime, setSaturdayStartTime] = useState('00:00')
  const [saturdayEndTime, setSaturdayEndTime] = useState('00:00')

  const [formFields, setFormFields] = useState({
    name: "",
    image: "",
    email: "",
    phone_number: "",
    address: "",
    pincode: "",
    about: "",

  });


  const [err, setErr] = useState({
    name: "",
    image: "",
    email: "",
    phone_number: "",
    address: "",
    pincode: "",
    about: "",
  });

  const imageUpload = (e) => {
    e.preventDefault();
    err.image = e.target.value.length > 0 ? "" : "Please select image";
    setImagePrev(URL.createObjectURL(e.target.files[0]));
    let pharmacyImage = e.target.files[0];
    setPharmacyImage(pharmacyImage);
    setErr({ ...err });
  };

  const handleOnchange = (e) => {
    const { value, name } = e.target;

    switch (name) {
      case "name":
        err.name = value.length > 0 ? "" : "Enter your name";

        break;
      case "email":
        err.email = value.length > 0 ? "" : "Enter your email";

        err.email = !new RegExp(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        ).test(value)
          ? "Enter a valid email address"
          : "";
        break;

      case "pincode":
        err.pincode = value.length > 0 ? "" : "Enter your pincode";
        break;

      case "address":
        err.address = value.length > 0 ? "" : "Enter your address!";
        break;

      case "about":
        err.about = value.length > 0 ? "" : "Enter your about";
        break;

      case "phone_number":
        if (value.length !== 10) {
          // we will set the error state
          err.phone_number = "phone atleast have 10 number";
        } else if (!/^[0-9\b]+$/.test(value)) {
          err.phone_number = "Enter only number";
        } else if (value.length === "") {
          err.phone_number = "Please enter a number";
        } else {
          err.phone_number = "";
        }
        break;

      default:
        break;
    }

    setFormFields({ ...formFields, [name]: value });
    setErr({ ...err });

  };

  const handleOnSubmit = (e) => {
    e.preventDefault();

    if (formFields.name === "") {
      err.name = "Enter laboratory name";
    } else {
      err.name = "";
    }
    if (formFields.email === "") {
      err.email = "Enter email";
    } else {
      err.email = "";
    }
    if (formFields.phone_number === "") {
      err.phone_number = "Enter phone number";
    } else {
      err.phone_number = "";
    }
    if (formFields.address === "") {
      err.address = "Write address";
    } else {
      err.address = "";
    }
    if (formFields.pincode === "") {
      err.pincode = "Enter pincode";
    } else {
      err.pincode = "";
    }
    if (pharmacyImage === "") {
      err.image = "Select laboratory image";
    } else {
      err.image = "";
    }

    if (formFields.about === "") {
      err.about = "Write about";
    } else {
      err.about = "";
    }
    setErr({ ...err });

    if (
      err.name === "" &&
      err.image === "" &&
      err.email === "" &&
      err.phone_number === "" &&
      err.pincode === "" &&
      err.address === "" &&
      err.about === ""
    ) {


      // var addPharmacy = {
      //   "name": formFields.name,
      //   "email": formFields.email,
      //   "phone_number": formFields.phone_number,
      //   image:pharmacyImage,
      //   "pincode": formFields.pincode,
      //   "address": formFields.address,
      //   "about": formFields.about,
      // };
      let formData = new FormData();    //formdata object
      // return false

      formData.append("name", formFields.name);
      formData.append("email", formFields.email);
      formData.append("phone_number", formFields.phone_number);
      formData.append("pincode", formFields.pincode);
      formData.append("image", pharmacyImage);
      formData.append("about", formFields.about);
      formData.append("address", formFields.address);
      if (sundayEndTime !== "" && sundayEndTime !== "") {

        formData.append("hours[sunday][startTime]", sundayStartTime);
        formData.append("hours[sunday][endTime]", sundayEndTime);
      }

      if (mondayStartTime !== "" && mondayEndTime !== "") {

        formData.append("hours[monday][startTime]", mondayStartTime);
        formData.append("hours[monday][endTime]", mondayEndTime);
      }

      if (tuesdayStartTime !== "" && tuesdayEndTime !== "") {

        formData.append("hours[tuesday][startTime]", tuesdayStartTime);
        formData.append("hours[tuesday][endTime]", tuesdayEndTime);
      }

      if (wednesdayStartTime !== "" && wednesdayEndTime !== "") {

        formData.append("hours[wednesday][startTime]", wednesdayStartTime);
        formData.append("hours[wednesday][endTime]", wednesdayEndTime);
      }

      if (thursdayStartTime !== "" && thursdayEndTime !== "") {
        formData.append("hours[thursday][startTime]", thursdayStartTime);
        formData.append("hours[thursday][endTime]", thursdayEndTime);

      }
      if (fridayStartTime !== "" && fridayEndTime !== "") {

        formData.append("hours[friday][startTime]", fridayStartTime);
        formData.append("hours[friday][endTime]", fridayEndTime);
      }

      if (saturdayStartTime !== "" && saturdayEndTime !== "") {

        formData.append("hours[saturday][startTime]", saturdayStartTime);
        formData.append("hours[saturday][endTime]", saturdayEndTime);
      }
      setIsLoading(true)
      Services.addLaboratory(menuID, formData)
        .then((response) => {
          setIsLoading(false)
          if (response.data.status === true) {
            swal("Success", response.data.message, "success")
            history(`/laboratory?id=${getMenuID}`);
          } else {
            setIsLoading(false)
            swal("Failed", response.data.message, "error");
          }
        })
        .catch((err) => {
          setIsLoading(false)
          swal("Failed", err.response.data.message, "error");
        });
      //   }
    }
  };
  return (
    <>
      <main class="page-content">
        <Header title="Add Laboratory" main_title="Laboratory" />

        <div class="container-fluid">
          <div class="layout-specing">
            <div class="d-flex justify-content-between align-items-center mb-3">
              <h4 class="mb-0">Add Laboratory</h4>
              <NavLinkHeader
                title1="Laboratory List"
                title1_link={`/laboratory?id=${getMenuID}`}
                title2="Add Laboratory"
              />
            </div>
            <div class="row">
              <div class="col-xl-8 col-lg-8 mt-4">
                <form
                  name="addlaboratory"
                  id="addlaboratory"
                  onSubmit={handleOnSubmit}
                >
                  <div class="card  border rounded-md rounded p-4">
                    <div class="mb-4 cstm-profile-img d-flex  align-items-center">
               
                    <label className="cstm-upload-choose">
                      <div class="mr-5">
                        {imagePrev ? (
                          <img
                            className="profile-upl-img"
                            src={imagePrev}
                          />
                        ) : (
                          <img src={plusImg} class="profile-upl-img" />
                        )}
                        {/* <img src={plusImg} class="profile-upl-img" /> */}
                      </div>
                      <div>
                        <input
                          type="file"
                          name="profile"
                          onChange={imageUpload}
                        />
                        <h4>Upload Laboratory Picture</h4>
                        <span class="cstm-for-best">
                          For best results, use an image at least 600px by 600px
                          in either .jpg or .png format
                        </span>
                      </div>
                      </label>
            
                    </div>
                    {err.image !== "" && (
                      <span style={{ color: "red" }}>{err.image}</span>
                    )}
                    <div class="row">
                      <div class="col-lg-12">
                        <div class="mb-4">
                          <label class="cstm-label">Laboratory name</label>
                          <input
                            type="text"
                            class="cstm-input"
                            placeholder="Enter laboratory name"
                            name="name"
                            onChange={handleOnchange}
                            required=""
                          />
                              {err.name !== "" && (
                      <span style={{ color: "red" }}>{err.name}</span>
                    )}
                        </div>
                      </div>
                    </div>
                

                    <div class="row">
                      <div class="col-lg-6">
                        <div class="mb-4">
                          <label class="cstm-label">Email</label>
                          <input
                            type="email"
                            class="cstm-input"
                            placeholder="Enter email"
                            name="email"
                            onChange={handleOnchange}
                            required=""
                          />
                             {err.email !== "" && (
                        <span style={{ color: "red" }}>{err.email}</span>
                      )}
                        </div>
                      </div>
                   
                      <div class="col-lg-6">
                        <div class="mb-4">
                          <label class="cstm-label">Contact no</label>
                          <input
                            type="text"
                            class="cstm-input"
                            maxlength="10"
                            placeholder="Enter phone no"
                            name="phone_number"
                            onChange={handleOnchange}
                            required=""
                          />
                             {err.phone_number !== "" && (
                        <span style={{ color: "red" }}>{err.phone_number}</span>
                      )}
                        </div>
                      </div>
                   
                    </div>

                    <div class="row">
                      <div class="col-lg-6">
                        <div class="mb-4">
                          <label class="cstm-label">Laboratory address</label>
                          <input
                            type="text"
                            class="cstm-input"
                            placeholder="Enter laboratory address"
                            onChange={handleOnchange}
                            name="address"
                            required=""
                          />
                           {err.address !== "" && (
                        <span style={{ color: "red" }}>{err.address}</span>
                      )}
                        </div>
                      </div>
                     
                      <div class="col-lg-6">
                        <div class="mb-4">
                          <label class="cstm-label">Pincode</label>
                          <input
                            type="text"
                            class="cstm-input"
                            placeholder="Enter pincode"
                            name="pincode"
                            onChange={handleOnchange}
                            required=""
                          />
                           {err.pincode !== "" && (
                        <span style={{ color: "red" }}>{err.pincode}</span>
                      )}
                        </div>
                      </div>
                     
                    </div>

                    {/* <div class="row">
                      <div class="col-lg-12">
                        <div class="mb-4">
                          <label class="cstm-label">Pharmacy address</label>
                          <input
                            type="text"
                            class="cstm-input"
                            placeholder="Enter pharmacy address"
                            onChange={handleOnchange}
                            name="address"
                            required=""
                          />
                        </div>
                      </div> 
                    </div>*/}

                    <div class="row">
                      <div class="col-lg-12">
                        <div class="mb-4">
                          <label class="cstm-label">About laboratory</label>
                          <textarea
                            name="about"
                            id="comments"
                            rows="5"
                            class="cstm-textarea"
                            onChange={handleOnchange}
                            placeholder="about laboratory"
                          ></textarea>
                             {err.about !== "" && (
                      <span style={{ color: "red" }}>{err.about}</span>
                    )}
                        </div>
                      </div>
                    </div>
                 

                    {/* <div class="row">
                      <div class="col-lg-12">
                        <div class="mb-4">
                          <label class="cstm-label">Select Time</label><br />
                          <label class="cstm-label">Sunday</label><br />
                          <label class="cstm-label">Start Time</label><br />
                          <input type="time" onChange={ev => setSundayStartTime(ev.target.value)} class="cstm-input" id="time1" name="sundayStartTime" />
                          <label class="cstm-label">End Time</label>
                          <input type="time" onChange={ev => setSundayEndTime(ev.target.value)} class="cstm-input" id="time2" name="sundayEndTime" />
                        </div>
                      </div>
                    </div> */}

                    <div class="row">
                      <div class="col-lg-6">
                        <div class="mb-4">
                          <label class="cstm-label">Sunday Start Time</label>
                          <input type="time" onChange={ev => setSundayStartTime(ev.target.value)} class="cstm-input" id="time1" name="sundayStartTime" />

                        </div>
                      </div>
                      <div class="col-lg-6">
                        <div class="mb-4">
                          <label class="cstm-label">Sunday End Time</label>
                          <input type="time" onChange={ev => setSundayEndTime(ev.target.value)} class="cstm-input" id="time2" name="sundayEndTime" />

                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-lg-6">
                        <div class="mb-4">
                          <label class="cstm-label">Monday Start Time</label>
                          <input type="time" onChange={ev => setMondayStartTime(ev.target.value)} class="cstm-input" id="time1" name="mondayStartTime" />

                        </div>
                      </div>
                      <div class="col-lg-6">
                        <div class="mb-4">
                          <label class="cstm-label">Monday End Time</label>
                          <input type="time" onChange={ev => setMondayEndTime(ev.target.value)} class="cstm-input" id="time2" name="mondayEndTime" />

                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-lg-6">
                        <div class="mb-4">
                          <label class="cstm-label">Tuesday Start Time</label>
                          <input type="time" onChange={ev => setTuesdayStartTime(ev.target.value)} class="cstm-input" id="time1" name="tuesdayStartTime" />

                        </div>
                      </div>
                      <div class="col-lg-6">
                        <div class="mb-4">
                          <label class="cstm-label">Tuesday End Time</label>
                          <input type="time" onChange={ev => setTuesdayEndTime(ev.target.value)} class="cstm-input" id="time2" name="tuesdayEndTime" />

                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-lg-6">
                        <div class="mb-4">
                          <label class="cstm-label">Wednesday Start Time</label>
                          <input type="time" onChange={ev => setWednesdayStartTime(ev.target.value)} class="cstm-input" id="time1" name="wednesdayStartTime" />

                        </div>
                      </div>
                      <div class="col-lg-6">
                        <div class="mb-4">
                          <label class="cstm-label">Wednesday End Time</label>
                          <input type="time" onChange={ev => setWednesdayEndTime(ev.target.value)} class="cstm-input" id="time2" name="wednesdayEndTime" />

                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-lg-6">
                        <div class="mb-4">
                          <label class="cstm-label">Thursday Start Time</label>
                          <input type="time" onChange={ev => setThursdayStartTime(ev.target.value)} class="cstm-input" id="time1" name="thursdayStartTime" />

                        </div>
                      </div>
                      <div class="col-lg-6">
                        <div class="mb-4">
                          <label class="cstm-label">Thursday End Time</label>
                          <input type="time" onChange={ev => setThursdayEndTime(ev.target.value)} class="cstm-input" id="time2" name="thursdayEndTime" />

                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-lg-6">
                        <div class="mb-4">
                          <label class="cstm-label">Friday Start Time</label>
                          <input type="time" onChange={ev => setFridayStartTime(ev.target.value)} class="cstm-input" id="time1" name="fridayStartTime" />

                        </div>
                      </div>
                      <div class="col-lg-6">
                        <div class="mb-4">
                          <label class="cstm-label">Friday End Time</label>
                          <input type="time" onChange={ev => setFridayEndTime(ev.target.value)} class="cstm-input" id="time2" name="fridayEndTime" />

                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-lg-6">
                        <div class="mb-4">
                          <label class="cstm-label">Saturday Start Time</label>
                          <input type="time" onChange={ev => setSaturdayStartTime(ev.target.value)} class="cstm-input" id="time1" name="saturdayStartTime" />

                        </div>
                      </div>
                      <div class="col-lg-6">
                        <div class="mb-4">
                          <label class="cstm-label">Saturday End Time</label>
                          <input type="time" onChange={ev => setSaturdayEndTime(ev.target.value)} class="cstm-input" id="time2" name="saturdayEndTime" />

                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-12">
                        <div class="mb-2 cstm-loader">
                        <div className="cstm-ldr-mn">
                          <button class="mr-3 cstm-btn6">Add laboratory</button>
                          {isLoading &&
                            <div id="btn-loader" className="btn-loader-main">
                              <img className="btn-loaderImg" src={loader} /></div>
                          }
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <div class="col-xl-4 col-lg-4 mt-4">
                <div class="card  border rounded-md rounded">
                  <div class="d-flex justify-content-between align-items-center p-3 border-bottom">
                    <h5 class="mb-0">
                      {" "}
                      <img src={stethoscopeImg} class="mr-2" /> Recent Joined
                      Doctors{" "}
                    </h5>
                  </div>
                  <ul class="list-unstyled mb-0 p-3">
                    {
                      recentJoin.map((item) => (
                        encyptId = CryptoJS.enc.Utf8.parse(item._id),// WordArray object
                        recordIdEncrypt = CryptoJS.enc.Base64.stringify(encyptId),
                        <>
                          <li className="mb-3">
                            <div className="d-flex align-items-center justify-content-between">
                              <div className="d-flex align-items-center">
                                <img
                                  src={item.profileImg ? "https://anywherehealth.s3.amazonaws.com/" + item.profileImg : dummy}
                                  className="cstm-rjd-photo"
                                />
                                <div className="ms-3">
                                  <h5 className="text-dark mb-0 d-block">
                                    {item.name}
                                  </h5>
                                  <small className="text-muted">{item.address ? item.address : ''}</small>
                                </div>
                              </div>
                              <div className="cstm-ro-icon">

                                <Link to={`/laboratory-profile?id=${getMenuID}&record_id=${recordIdEncrypt}`} className="cstm-eye mrn-rt"><i class="fi fi-rr-eye" aria-hidden="true"></i></Link>
                                {/* 
                          <a href="#" className="cstm-eye mrn-rt">
                            <i className="fi fi-rr-eye"></i>
                          </a> */}
                              </div>
                            </div>
                          </li>
                        </>
                      ))
                    }
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};
export default AddLaboratory;

