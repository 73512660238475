import React, { useState, useEffect } from "react";
import Header from "../Navbar/Header";
import NavLinkHeader from "../Navbar/NavLinkHeader";
import Services from "../../Services/auth.service";
import swal from "sweetalert";
import loader from "../../assets/images/loder.gif";
import { Link, useNavigate, useLocation } from "react-router-dom";
import plusImg from "../../assets/images/plus.png";
import stethoscopeImg from "../../assets/images/stethoscope.png";
import dummy from "../../assets/images/dummy.png";
import { countrycode } from "../Comman/countrycode";
import moment from "moment";

const CryptoJS = require("crypto-js");


const AddDoctor = () => {
  const history = useNavigate();
  const getid = useLocation().search;
  const getMenuID = new URLSearchParams(getid).get("id");
  var decryptId = CryptoJS.enc.Base64.parse(getMenuID);
  var menuID = CryptoJS.enc.Utf8.stringify(decryptId);

  const [isLoading, setIsLoading] = useState(false);

  const [imagePrev, setImagePrev] = useState();
  const [doctorImage, setDoctorImage] = useState("");
  const [recentJoin, setRecentJoin] = useState([]);
  const [country, setCountry] = useState([{}]);
  const [specialization, setSpecialization] = useState([{}]);
  const [physicianCare, setPhysicianCare] = useState(false);

  const [documentCat, setDocumentCat] = useState([{}])
  const [documentIdFile, setDocumentIdFile] = useState({})
  const DocumentArray = [];

  var encyptId;
  var recordIdEncrypt;
  // const [value, setValue] = useState()

  useEffect(() => {
    const loggedInUser = localStorage.getItem("token");
    !loggedInUser && history("/");
    getRecentJoinData();
    getCountryData();
    getSpecializationData();
    getDocumentCategory();



  }, []);

  const getDocumentCategory = () => {
    Services.getDocumentCategory(menuID).then((response) => {
      setDocumentCat(response.data.data)

    }).catch((err) => {
    })
  }

  const getRecentJoinData = () => {

    Services.getRecentJoinDoctors(menuID).then((response) => {
      setRecentJoin(response.data.data)

    }).catch((err) => {
    })

  }

  const getCountryData = () => {
    Services.getCountry(menuID).then((response) => {
      if (response.data.status === true) {
        if (response.data.data.length > 0) {

          setCountry(response.data.data)
        }
      }

    }).catch((err) => {
    })
  }
  const getSpecializationData = () => {
    Services.getSpecialization(menuID).then((response) => {
      if (response.data.status === true) {
        if (response.data.data.length > 0) {

          setSpecialization(response.data.data)
        }
      }

    }).catch((err) => {
    })
  }

  const [formFields, setFormFields] = useState({
    first_name: "",
    surname: "",
    email: "",
    password: "",
    Cpassword: "",
    country_code: "",
    phone_number: "",
    age: "",
    gender: "",
    address: "",
    country: "",
    primary_care_physician: "",
    profileImg: "",
    specialization_ids: "",
    total_file: "",
    aboutUs: "",
  });


  const [err, setErr] = useState({

    first_name: "",
    surname: "",
    email: "",
    password: "",
    Cpassword: "",
    country_code: "",
    phone_number: "",
    age: "",
    gender: "",
    address: "",
    country: "",
    primary_care_physician: "",
    profileImg: "",
    specialization_ids: "",
    total_file: "",
    documentSubmit: "",
    aboutUs: "",
  });

  const imageUpload = (e) => {
    e.preventDefault();
    err.profileImg = e.target.value.length > 0 ? "" : "Please select image";
    setImagePrev(URL.createObjectURL(e.target.files[0]));
    let doctorImage = e.target.files[0];
    setDoctorImage(doctorImage);
    setErr({ ...err });
  };

  const handleCheck = (e) => {
    if (e.target.checked === true) {
      setPhysicianCare(true)
      err.primary_care_physician = ""
    } else {
      setPhysicianCare(false)
      err.primary_care_physician = "check require"
    }
  }

  // document upload

  const handleOnchangeDocument = (e, index, documentId) => {


    setDocumentIdFile({ ...documentIdFile, [`uploadfile[${index}][id]`]: documentId, [`uploadfile[${index}][file]`]: e.target.files[0] })
  }


  const handleOnchange = (e) => {
    const { value, name } = e.target;


    switch (name) {
      case "first_name":
        err.first_name = value.length > 0 ? "" : "Enter your first name";
        break;
      case "surname":
        err.surname = value.length > 0 ? "" : "Enter your surname";
        break;
      case "email":
        err.email = value.length > 0 ? "" : "Enter your email";

        err.email = !new RegExp(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        ).test(value)
          ? "Enter a valid email address"
          : "";
        break;
      case "country_code":
        err.country_code = value.length > 0 ? "" : "Select country code";
        break;
      case "country":
        err.country = value.length > 0 ? "" : "Select country";
        break;
      case "password":
        err.password = value.length > 0 ? "" : "Enter password";
        break;
      case "Cpassword":
        // err.Cpassword = value.length > 0 ? "" : "Enter confirm password";

        err.Cpassword = value.length > 0 && value === formFields.password ? "" : "Enter same password"

        break;
      case "gender":
        err.gender = value.length > 0 ? "" : "Select gender";
        break;

      case "address":
        err.address = value.length > 0 ? "" : "Enter your address";
        break;
      case "specialization_ids":
        err.specialization_ids = value.length > 0 ? "" : "Select specialization";
        break;
      case "phone_number":
        if (value.length !== 10) {
          // we will set the error state
          err.phone_number = "phone atleast have 10 number";
        } else if (!/^[0-9\b]+$/.test(value)) {
          err.phone_number = "Enter only number";
        } else if (value.length === "") {
          err.phone_number = "Please enter a number";
        } else {
          err.phone_number = "";
        }
        break;
      case "age":
        if (value <= 20) {
          err.age = "Age should be greater than 20";
        }
        else if (!/^[0-9\b]+$/.test(value)) {
          err.age = "Enter only number";
        } else if (value.length === "") {
          err.age = "Please enter age";
        } else {
          err.age = "";
        }
        break;

      default:
        break;
    }

    setFormFields({ ...formFields, [name]: value });
    setErr({ ...err });

  };
  if (documentIdFile !== undefined) {
    Object.entries(documentIdFile).forEach(([key, value]) =>
      DocumentArray.push({ name: key, data: value })
    )
  }
  const handleOnSubmit = (e) => {
    e.preventDefault();

    if (formFields.first_name === "") {
      err.first_name = "Enter your first name";
    } else {
      err.first_name = "";
    }
    if (formFields.surname === "") {
      err.surname = "Enter your surname";
    } else {
      err.surname = "";
    }
    if (formFields.gender === "") {
      err.gender = "Select gender";
    } else {
      err.gender = "";
    }
    if (formFields.password === "") {
      err.password = "Enter your password";
    } else {
      err.password = "";
    }
    if (formFields.Cpassword === "") {
      err.Cpassword = "Enter confirm password";
    } else {
      err.Cpassword = "";
    }
    if (formFields.email === "") {
      err.email = "Enter email";
    } else {
      err.email = "";
    }
    if (formFields.country_code === "") {
      err.country_code = "Select country code";
    } else {
      err.country_code = "";
    }
    if (formFields.country === "") {
      err.country = "Select country";
    } else {
      err.country = "";
    }
    if (formFields.specialization_ids === "") {
      err.specialization_ids = "Select specialization";
    } else {
      err.specialization_ids = "";
    }
    if (formFields.phone_number === "") {
      err.phone_number = "Enter phone number";
    } else {
      err.phone_number = "";
    }
    if (formFields.age === "") {
      err.age = "Enter age";
    } else {
      err.age = "";
    }
    if (formFields.address === "") {
      err.address = "Write address";
    } else {
      err.address = "";
    }
    if (doctorImage === "") {
      err.profileImg = "Select doctor image";
    } else {
      err.profileImg = "";
    }
    if (physicianCare === true) {
      err.primary_care_physician = ""
    } else {
      err.primary_care_physician = "Primary care of physician is required"
    }
    if (DocumentArray.length < 2 * documentCat.length) {
      err.documentSubmit = "Upload all document"
    } else {
      err.documentSubmit = ""
    }
    setErr({ ...err });

    if (
      err.first_name === "" &&
      err.surname === "" &&
      err.email === "" &&
      err.password === "" &&
      err.Cpassword === "" &&
      err.country_code === "" &&
      err.phone_number === "" &&
      err.age === "" &&
      err.gender === "" &&
      err.country === "" &&
      err.address === "" &&
      err.primary_care_physician === "" &&
      err.profileImg === "" &&
      err.specialization_ids === ""
    ) {
      let formData = new FormData();
      // return false

      formData.append("first_name", formFields.first_name);
      formData.append("surname", formFields.surname);
      formData.append("email", formFields.email);
      formData.append("password", formFields.password);
      formData.append("country_code", formFields.country_code);
      formData.append("phone_number", formFields.phone_number);
      formData.append("age", formFields.age);
      formData.append("gender", formFields.gender);
      formData.append("address", formFields.address);
      formData.append("country", formFields.country);
      formData.append("primary_care_physician", physicianCare);
      formData.append("user_role", "doctor");
      formData.append("profileImg", doctorImage);
      formData.append("specialization_ids", formFields.specialization_ids);
      formData.append("total_file", documentCat.length);
      formData.append("aboutUs", formFields.aboutUs);
      // formData.append("62e365797a037bd1d24e74eb", doctorImage);
      // formData.append("62e36364592ecfb0bbbc05d9", formFields.specialization_ids);
      // formData.append("62e3636e7e22e665f479e1fc", formFields.specialization_ids);

      DocumentArray.map((doc) => {
        formData.append(doc.name, doc.data);
      })

      var bodyData = {
        "first_name": formFields.first_name,
        "surname": formFields.surname,
        "email": formFields.email,
        "password": formFields.password,
        "country_code": formFields.country_code,
        "phone_number": formFields.phone_number,
        "age": formFields.age,
        "gender": formFields.gender,
        "address": formFields.address,
        "country": formFields.country,
        "primary_care_physician": physicianCare,
        "user_role": "doctor",
        "profileImg": doctorImage,
        "specialization_ids": formFields.specialization_ids,
        "total_file": documentCat.length,
        "aboutUs": formFields.aboutUs,
      }
      // return false
      setIsLoading(true)
      Services.addDoctor(menuID, formData)
        .then((response) => {
          setIsLoading(false)
          if (response.data.status === true) {
            swal("Success", response.data.message, "success");
            // history(`/pharmacy?id=${getMenuID}`);
          } else {
            setIsLoading(false)

            swal("Failed", response.data.message, "error");
          }
        })
        .catch((err) => {
          setIsLoading(false)
          swal("Failed", err.response.data.message, "error");
        });
    }
  };



  return (
    <>
      <main className="page-content">
        <Header title="Add Doctor" main_title="Doctor" />

        <div className="container-fluid">
          <div className="layout-specing">
            <div className="d-flex justify-content-between align-items-center mb-3">
              <h4 className="mb-0">Add a Doctor</h4>
              <NavLinkHeader
                title1="Doctor List"
                title1_link={`/doctors?id=${getMenuID}`}
                title2="Add Doctor"
              />
            </div>
            <div className="row">
              <div className="col-xl-8 col-lg-8 mt-4">
                <form
                  name="addDoctors"
                  id="addDoctors"
                  onSubmit={handleOnSubmit}
                  autoComplete="off"
                >
                  <div className="card  border rounded-md rounded p-4">
                    <div className="mb-4 cstm-profile-img d-flex  align-items-center">
                      <label className="cstm-upload-choose">
                        <div className="mr-5">
                          {imagePrev ? (
                            <img
                              className="profile-upl-img"
                              src={imagePrev}
                            />
                          ) : (
                            <img src={plusImg} className="profile-upl-img" />
                          )}
                          {/* <img src={plusImg} className="profile-upl-img" /> */}
                        </div>
                        <div>
                          <input
                            type="file"
                            name="profile"
                            onChange={imageUpload}
                          />
                          <h4>Upload Picture</h4>
                          <span className="cstm-for-best">
                            For best results, use an image at least 600px by 600px in either .jpg or .png format
                          </span>
                        </div>
                      </label>
                    </div>


                    {err.profileImg !== "" && (
                      <span class="cstm-uld" style={{ color: "red" }}>{err.profileImg}</span>
                    )}

                    <div className="row">
                      <div className="col-lg-6">
                        <div className="mb-4">
                          <label className="cstm-label">First name</label>
                          <input type="text" className="cstm-input" onChange={handleOnchange} placeholder="Enter first name" name="first_name"
                            required="" />
                          {err.first_name !== "" && (
                            <span style={{ color: "red" }}>{err.first_name}</span>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="mb-4">
                          <label className="cstm-label">Surname</label>
                          <input type="text" className="cstm-input" onChange={handleOnchange} placeholder="Enter surname" name="surname"
                            required="" />
                          {err.surname !== "" && (
                            <span style={{ color: "red" }}>{err.surname}</span>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-6">
                        <div className="mb-4">
                          <label className="cstm-label">Email</label>
                          <input
                            type="email"
                            autoComplete="email"
                            className="cstm-input"
                            placeholder="Enter email"
                            name="email"
                            onChange={handleOnchange}
                            required=""
                          />
                          {err.email !== "" && (
                            <span style={{ color: "red" }}>{err.email}</span>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="mb-4">
                          <label className="cstm-label">Password</label>
                          <input
                            type="password"
                            className="cstm-input"
                            maxlength="10"
                            autoComplete="new-password"
                            placeholder="Enter password"
                            name="password"
                            onChange={handleOnchange}
                            required=""
                          />
                          {err.password !== "" && (
                            <span style={{ color: "red" }}>{err.password}</span>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-6">
                        <div className="mb-4">
                          <label className="cstm-label">Confirm Password</label>
                          <input
                            type="password"
                            className="cstm-input"
                            maxlength="10"
                            placeholder="Enter confirm password"
                            name="Cpassword"
                            onChange={handleOnchange}
                            required=""
                          />
                          {err.Cpassword !== "" && (
                            <span style={{ color: "red" }}>{err.Cpassword}</span>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="mb-4">
                          <label className="cstm-label">Country</label>
                          <select className="cstm-select" name="country" onChange={handleOnchange}>
                            <option value="">Select Country</option>
                            {country.map(item => (
                              <option key={item._id} value={item._id}>{item.country}</option>
                            ))}
                          </select>
                          {err.country !== "" && (
                            <span style={{ color: "red" }}>{err.country}</span>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-6">
                        <div className="mb-4">
                          <label className="cstm-label">Gender</label>
                          <select className="cstm-select" name="gender" onChange={handleOnchange}>
                            <option value="">Select Gender </option>
                            <option value="male">Male</option>
                            <option value="female">Female</option>
                          </select>
                          {err.gender !== "" && (
                            <span style={{ color: "red" }}>{err.gender}</span>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="mb-4">
                          <label className="cstm-label">Age</label>
                          <input
                            type="text"
                            className="cstm-input"
                            maxlength="2"
                            minlength="2"
                            placeholder="Enter age"
                            name="age"
                            onChange={handleOnchange}
                            required=""
                          />
                          {err.age !== "" && (
                            <span style={{ color: "red" }}>{err.age}</span>
                          )}
                        </div>
                        
                      </div>


                    </div>

                    <div className="row">
                      <div className="col-lg-6">
                        <div className="mb-4">
                          <label className="cstm-label">Code</label>
                          <select className="cstm-select" name="country_code" onChange={handleOnchange}>
                            <option value="">Select Country Code</option>

                            {countrycode.map((item, i) => (<option key={i} value={item.value}>{item.label}</option>))}
                          </select>
                          {err.country_code !== "" && (
                            <span style={{ color: "red" }}>{err.country_code}</span>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="mb-4">
                          <label className="cstm-label">Contact no</label>
                          <input
                            type="text"
                            className="cstm-input"
                            maxlength="10"
                            placeholder="Enter phone no"
                            name="phone_number"
                            onChange={handleOnchange}
                            required=""
                          />
                          {err.phone_number !== "" && (
                            <span style={{ color: "red" }}>{err.phone_number}</span>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="mb-4">
                          <label className="cstm-label">Address</label>
                          <input
                            type="text"
                            className="cstm-input"
                            placeholder="Enter address"
                            onChange={handleOnchange}
                            name="address"
                            required=""
                          />

                          {err.address !== "" && (
                            <span style={{ color: "red" }}>{err.address}</span>
                          )}
                        </div>
                      </div>
                    </div>
                    
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="mb-4">
                          <label className="cstm-label">About us</label>
                          <textarea
                              className="cstm-about-textarea"
                              placeholder="Enter about us"
                              onChange={handleOnchange}
                              value={formFields.aboutUs}
                              name="aboutUs"
                              rows={5}
                              required=""
                          ></textarea>

                        </div>
                      </div>
                    </div>


                    {/* documet upload  */}
                    <div className="row cstm-upload-dcument-mn">
                      <label className="cstm-label">Upload Document</label>

                      {
                        documentCat.map((document, index) => (
                          <div className="col-lg-4 mb-4">

                            {/* <h2>{document.category}</h2> */}
                            <input
                              type="text"
                              className="cstm-input"
                              // onChange={handleOnchange}
                              name="doc-id"
                              required=""
                              value={document.category}
                              disabled
                            />

                            <input
                              type="file"
                              onChange={(e) => handleOnchangeDocument(e, index, document._id)}
                              name="doc-file"
                              required=""
                            />
                          </div>
                        ))
                      }
                      {err.documentSubmit !== "" && (
                        <span className="cstm-uld" style={{ color: "red" }}>{err.documentSubmit}</span>
                      )}
                    </div>

                    <div className="row">
                      <div className="col-lg-12">
                        <div className="mb-4">
                          <label className="cstm-label">Specialization</label>
                          <select className="cstm-select" name="specialization_ids" onChange={handleOnchange}>
                            <option value="">Select Specialization</option>
                            {specialization.map(item => (
                              <option key={item._id} value={item._id}>{item.specialization}</option>
                            ))}
                          </select>
                          {err.specialization_ids !== "" && (
                            <span style={{ color: "red" }}>{err.specialization_ids}</span>
                          )}
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-lg-12">
                        <div class="mb-4">
                          <input type="checkbox" onChange={handleCheck} name="primary_care_physician"
                            required="" />Primary Care Doctor?
                        </div>
                        {err.primary_care_physician !== "" && (
                          <span class="cstm-pco" style={{ color: "red" }}>{err.primary_care_physician}</span>
                        )}
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-12">
                        <div className="mb-2 cstm-loader">
                          <div className="cstm-ldr-mn">
                            <button className="cstm-btn6">Add a Doctor</button>
                            {isLoading &&
                              <div id="btn-loader" className="btn-loader-main">
                                <img className="btn-loaderImg" src={loader} /></div>
                            }
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </form>
              </div>

              <div className="col-xl-4 col-lg-4 mt-4">
                <div className="card  border rounded-md rounded">
                  <div className="d-flex justify-content-between align-items-center p-3 border-bottom">
                    <h5 className="mb-0">
                      <img src={stethoscopeImg} className="mr-2" /> Recent Joined
                      Doctor
                    </h5>
                  </div>
                  <ul className="list-unstyled mb-0 p-3">
                    {
                      recentJoin.map((item) => (
                        encyptId = CryptoJS.enc.Utf8.parse(item._id),// WordArray object
                        recordIdEncrypt = CryptoJS.enc.Base64.stringify(encyptId),

                        <>
                          <li className="mb-3">
                            <div className="d-flex align-items-center justify-content-between">
                              <div className="d-flex align-items-center">
                                <img
                                  src={item.signup_otherdetail_id !== null && item.signup_otherdetail_id.profileImg ? "https://anywherehealth.s3.amazonaws.com/" + item.signup_otherdetail_id.profileImg : dummy}
                                  className="cstm-patientImg"
                                />
                                <div className="ms-3">
                                  <h5 className="text-dark mb-0 d-block">
                                    {item.signup_otherdetail_id !== null && <> {item.signup_otherdetail_id.surname ? item.signup_otherdetail_id.surname : ""}  {item.signup_otherdetail_id.surname ? item.signup_otherdetail_id.first_name : ""}</>}
                                  </h5>
                                  <small className="text-muted">{item.signup_otherdetail_id !== null && item.signup_otherdetail_id.address ? item.signup_otherdetail_id.address : ''}</small>
                                </div>
                              </div>
                              <div className="cstm-ro-icon">
                                <Link to={`/pharmacy-profile?id=${getMenuID}&record_id=${recordIdEncrypt}`} className="cstm-eye mrn-rt"><i className="fi fi-rr-eye" aria-hidden="true"></i></Link>
                              </div>
                            </div>
                          </li>
                        </>
                      ))
                    }
                  </ul>
                </div>
              </div>

            </div>
          </div>
        </div>
      </main>
    </>
  );
};
export default AddDoctor;
