import React, { useState, useEffect } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
var CryptoJS = require("crypto-js");

const NavLinkHeader = (props) => {

  const dashboardId = "62c428065856aa82a235bee4";
  var encyptDashboardId = CryptoJS.enc.Utf8.parse(dashboardId); // WordArray object
  var dashboardIdEncrypt = CryptoJS.enc.Base64.stringify(encyptDashboardId);
  return (
    
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        {
          (props.title1 || props.title2)  && (
            <>
              <li class="breadcrumb-item"><Link to={`/dashboard?id=${dashboardIdEncrypt}`}>Dashboard</Link></li>
              {props.title1 && <li class="breadcrumb-item">{props.title1_link ? <Link to={props.title1_link}>{props.title1}</Link> : <a href="javascript:void(0)"> {props.title1} </a>}</li>}
              {props.title2 && <li class="breadcrumb-item"><a href="javascript:void(0)">{props.title2} </a></li>}

            </>
          )}
      </ol>

    </nav>


  )
}

export default NavLinkHeader
