import React, { useState, useEffect } from "react";
import Header from '../Navbar/Header'
import NavLinkHeader from '../Navbar/NavLinkHeader'
import Services from "../../Services/auth.service";
import swal from "sweetalert";
import $ from "jquery";
import loader from "../../assets/images/loder.gif";
import { Link, useNavigate, useLocation } from "react-router-dom";
const CryptoJS = require("crypto-js");

const AddSubscription = () => {

    setTimeout(() => {
        $("#subscription-price").keypress(function (e) {
            var charCode = (e.which) ? e.which : e.keyCode;
            if (charCode > 31 && (charCode < 48 || charCode > 57)) {
                return false;
            }
            return true;

        });

    }, 500);

    const history = useNavigate();
    const getid = useLocation().search;
    const getMenuID = new URLSearchParams(getid).get("id");
    var decryptId = CryptoJS.enc.Base64.parse(getMenuID);
    var menuID = CryptoJS.enc.Utf8.stringify(decryptId);
    const [isLoading, setIsLoading] = useState(false);
    const [reportData, setReportData] = useState([])
    const [checkedreport, setCheckedreport] = useState([])


    const [formFields, setFormFields] = useState({
        name: "",
        price: "",
        planDuration: "",
        status: "",

    });


    const [err, setErr] = useState({
        name: "",
        price: "",
        planDuration: "",
        status: "",
        includedReports: "",
    });


    useEffect(() => {
        const loggedInUser = localStorage.getItem("token");
        !loggedInUser && history("/");

        getReportData();
    }, []);


    const getReportData = () => {
        Services.getReports().then((response) => {
            setReportData(response.data.data)
        })
    }
    const handleOnChange = (e) => {
        const { value, name } = e.target;

        switch (name) {
            case "name":
                err.name = value.length > 0 ? "" : "Enter subscription name";
                break;
            case "status":
                err.status = value.length > 0 ? "" : "Select status";
                break;
            case "planDuration":
                err.planDuration = value.length > 0 ? "" : "Select plan duration";
                break;
            case "price":
                err.price = value.length > 0 ? "" : "Enter price";
                break;

            default:
                break;
        }

        setFormFields({ ...formFields, [name]: value });
        setErr({ ...err });

    };

    const handleReport = (e) => {
        var checkedvalue = e.target.value
        if (e.target.checked) {
            setCheckedreport([...checkedreport, checkedvalue])
        }
    }

    const handleOnSubmit = (e) => {
        e.preventDefault();

        if (formFields.name === "") {
            err.name = "Enter Subscription name";
        } else {
            err.name = "";
        }
        if (formFields.price === "") {
            err.price = "Enter price";
        } else {
            err.price = "";
        }
        if (formFields.planDuration === "") {
            err.planDuration = "Select planDuration";
        } else {
            err.planDuration = "";
        }
        if (formFields.status === "") {
            err.status = "Select status";
        } else {
            err.status = "";
        }
        if (checkedreport.length > 0) {
            err.includedReports = "";
        } else {
            err.includedReports = "Select Resources";
        }

        setErr({ ...err });

        if (
            err.name === "" &&
            err.price === "" &&
            err.planDuration === "" &&
            err.status === ""

        ) {

            var formdata = {
                "name": formFields.name,
                "amount": formFields.price,
                "planDuration": formFields.planDuration,
                "status": formFields.status,
                "includedReports": checkedreport
            }
            setIsLoading(true)
            Services.addSubscription(menuID, formdata)
                .then((response) => {
                    setIsLoading(false)
                    if (response.data.status === true) {
                        swal("Success", response.data.message, "success");
                        history(`/subscription-list?id=${getMenuID}`);
                    } else {
                        setIsLoading(false)
                        swal("Failed", response.data.message, "error");
                    }
                })
                .catch((err) => {
                    setIsLoading(false)
                    swal("Failed", err.response.data.message, "error");
                });
        }
    };

    return (
        <>
            <div className="page-wrapper doctris-theme toggled">
                <main className="page-content bg-light">
                    <Header />
                    <div className="container-fluid">
                        <div className="layout-specing">
                            <div className="d-flex justify-content-between align-items-center mb-3">
                                <h4 className="mb-0">Add Subscription Plan </h4>

                                <NavLinkHeader title1="Subscription Plan List" title1_link={`/subscription-list?id=${getMenuID}`} title2="Add Subscription" />
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card  rounded-md  border">
                                        <div className="py-2 px-3">
                                            <h5 className="mb-3">Add Subscription Plan</h5>

                                            <form id="subscription-add" onSubmit={handleOnSubmit}>
                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        <div className="mb-4">
                                                            <label className="cstm-label">Subscription Name</label>
                                                            <input
                                                                type="text"
                                                                className="cstm-input"
                                                                placeholder="enter your subscription name"
                                                                name="name"
                                                                onChange={handleOnChange}
                                                                required=""
                                                            />
                                                            {err.name !== "" && (<span style={{ color: "red" }}> {err.name} </span>)}

                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-lg-6">
                                                        <div className="mb-4">
                                                            <label className="cstm-label">Subscription Price</label>
                                                            <input
                                                                type="text"
                                                                id="subscription-price"
                                                                maxLength="6"
                                                                className="cstm-input"
                                                                placeholder="enter your subscription price"
                                                                name="price"
                                                                onChange={handleOnChange}

                                                                required=""
                                                            />
                                                            {err.price !== "" && (<span style={{ color: "red" }}> {err.price} </span>)}

                                                        </div>

                                                    </div>
                                                    <div className="col-lg-6">
                                                        <div className="mb-4">
                                                            <label className="cstm-label">Plan Duration</label>

                                                            <select
                                                                name="planDuration"
                                                                className="cstm-select"
                                                                aria-label="Default select example"
                                                                onChange={handleOnChange}
                                                            >
                                                                <option value="" selected>
                                                                    Select Plan Duration
                                                                </option>
                                                                <option value="monthly">Monthly</option>
                                                                <option value="yearly">Yearly</option>

                                                            </select>
                                                            {err.planDuration !== "" && (<span style={{ color: "red" }}> {err.planDuration} </span>)}

                                                        </div>

                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-lg-6">
                                                        <div className="mb-4">
                                                            <label className="cstm-label">
                                                                Subscription Status
                                                            </label>
                                                            <select
                                                                name="status"
                                                                className="cstm-select cstm-status"
                                                                aria-label="Default select example"
                                                                onChange={handleOnChange}
                                                            >
                                                                <option value="" selected>
                                                                    Select status
                                                                </option>
                                                                <option value="true">Active</option>
                                                                <option value="false">Deactivated</option>

                                                            </select>
                                                            {err.status !== "" && (<span style={{ color: "red" }}> {err.status} </span>)}

                                                        </div>

                                                    </div>
                                                </div>
                                                <hr className="mt-2 mb-4" />
                                                <h5 className="mb-3">Subscription Resources</h5>
                                                <div className="row mb-5">
                                                    {
                                                        reportData.length !== 0 && reportData.map((report, i) => (
                                                            <div className="col-lg-4 mb-3">
                                                                <div className="row">
                                                                    <div className="col-lg-7">
                                                                        <b>{report.name}</b>
                                                                    </div>
                                                                    <div className="col-lg-5">
                                                                        <div className="cstm-switch">
                                                                            <label className="switch">
                                                                                <input type="checkbox" id={report._id} value={report._id} onChange={handleReport} name="includedReports[]" />
                                                                                <span className="slider round" />
                                                                            </label>

                                                                        </div>
                                                                    </div>

                                                                </div>

                                                            </div>
                                                        ))
                                                    }
                                                    {err.includedReports !== "" && (<span style={{ color: "red" }}> {err.includedReports} </span>)}

                                                </div>


                                                <div className="pb-3 mt-2 cstm-loader">
                                                    <div className="cstm-ldr-mn">

                                                        <button className="cstm-btn6">
                                                            Create Subscription
                                                        </button>
                                                        {isLoading &&
                                                            <div id="btn-loader" className="btn-loader-main">
                                                                <img className="btn-loaderImg" src={loader} /></div>
                                                        }
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
            {/* //view modal */}
            {/* <div
                className="modal fade"
                id="viewappointment"
                tabIndex={-1}
                aria-labelledby="exampleModalLabel1"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header border-0 p-4">
                            <h4 className="modal-title" id="exampleModalLabel1">
                                Subscription Details{" "}
                            </h4>
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            />
                        </div>
                        <div className="modal-body p-4 pt-0">
                            <div className="mb-2">
                                <div className="row">
                                    <div className="col-lg-6 mb-4">
                                        <label className="cstm-label">Subscription Name</label>
                                        <div>
                                            <b>Lore Ipusm</b>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 mb-4">
                                        <label className="cstm-label">Plan Duration</label>
                                        <div>
                                            <b>12 Months</b>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-6">
                                        <label className="cstm-label">Subscription Price</label>
                                        <div>
                                            <b>$5,262.00</b>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <label className="cstm-label">Subscription Status</label>
                                        <div>
                                            <span className="cstm-active-grren">Active</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
        </>
    )
}

export default AddSubscription
