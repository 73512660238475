import { logDOM } from "@testing-library/react";
import axios from "axios";
import authHeader from "./auth.header";

// const API_URL = "http://3.14.173.90/";

const API_URL = "https://staging.antwalle.com/";
// const API_URL = "http://localhost:8080/";


//dashboard
const getDashboardData = (menuID) => {
  return axios.get(API_URL + "api/AS/dashboard-data", { headers: { 'x-resource-id': menuID, "Authorization": localStorage.getItem("token") } });
};
const getDoctorRequestDashboard = (menuID) => {
  return axios.get(API_URL + "api/AS/Pending-Doctor-List", { headers: { 'x-resource-id': menuID, "Authorization": localStorage.getItem("token") } });
};
const getDashboardChartData = (menuID,filterYear) => {
  return axios.get(API_URL + "api/AS/patients-visit-by-gender-chart/"+filterYear, { headers: { 'x-resource-id': menuID, "Authorization": localStorage.getItem("token") } });
};
const getPatientReviewDashboard = (menuID) => {
  return axios.get(API_URL + "api/AS/patients-reviews", { headers: { 'x-resource-id': menuID, "Authorization": localStorage.getItem("token") } });
};

// revenue dashboard

const getPurchesSubscription = (menuID) => {
  return axios.get(API_URL + "api/AS/revenue/Recently-Purchased-Subscriptions", { headers: { 'x-resource-id': menuID, "Authorization": localStorage.getItem("token") } });
};
const getRecentlyWithdrawal = (menuID) => {
  return axios.get(API_URL + "api/AS/revenue/Recently-Withdrawal", { headers: { 'x-resource-id': menuID, "Authorization": localStorage.getItem("token") } });
};



// admin setting
const adminLogin = (adminLogin) => {
  return axios.put(API_URL + "api/AS/user/signin", adminLogin, { headers: authHeader() });
};
const adminLogout = () => {
  return axios.put(API_URL + "api/AS/user/me/logout", { headers: authHeader() });
};
const adminForgotPassword = (adminForgotPassword) => {
  return axios.put(API_URL + "api/AS/forgot-password-email", adminForgotPassword, { headers: authHeader() });
};
const adminResetPassword = (adminResetPassword) => {
  return axios.put(API_URL + "api/AS/update-password", adminResetPassword, { headers: authHeader() });
};


//faq
const getFaq = (menuID) => {
  return axios.get(API_URL + "api/AS/faq-list", { headers: { 'x-resource-id': menuID, "Authorization": localStorage.getItem("token") } });
};
const addFaq = (menuID, AddFaq) => {
  return axios.post(API_URL + "api/AS/faq/create", AddFaq, { headers: { 'x-resource-id': menuID, "Authorization": localStorage.getItem("token") } });
};
const deleteFaq = (menuID, deleteFaq) => {
  return axios.put(API_URL + "api/AS/faq/delete/" + deleteFaq, '', { headers: { 'x-resource-id': menuID, "Authorization": localStorage.getItem("token") } });
};
const editFaq = (menuID, faqId, editFaqData) => {
  return axios.put(API_URL + "api/AS/faq/update/" + faqId, editFaqData, { headers: { 'x-resource-id': menuID, "Authorization": localStorage.getItem("token") } });
};


//role manage
const addRole = (menuID, addRole) => {
  return axios.post(API_URL + "api/AS/faq/create", addRole, { headers: { 'x-resource-id': menuID, "Authorization": localStorage.getItem("token") } });
};
const getResources = (menuID) => {
  return axios.get(API_URL + "api/AS/resource/all", { headers: { 'x-resource-id': menuID, "Authorization": localStorage.getItem("token") } });
};
const getAdminRole = (menuID) => {
  return axios.get(API_URL + "api/AS/user/role/all", { headers: { 'x-resource-id': menuID, "Authorization": localStorage.getItem("token") } });
};
const getAdminUSerData = (menuID) => {
  return axios.get(API_URL + "api/AS/users/all", { headers: { 'x-resource-id': menuID, "Authorization": localStorage.getItem("token") } });
};
const addAdminUSer = (menuID, addAdminUSer) => {
  return axios.post(API_URL + "api/AS/user/create", addAdminUSer, { headers: { 'x-resource-id': menuID, "Authorization": localStorage.getItem("token") } });
};
const deleteAdminRole = (menuID, deleteId) => {
  return axios.delete(API_URL + "api/AS/user/delete/" + deleteId, { headers: { 'x-resource-id': menuID, "Authorization": localStorage.getItem("token") } });
};
const getAdminUSerById = (menuID, editId) => {
  return axios.get(API_URL + "api/AS/user/" + editId, { headers: { 'x-resource-id': menuID, "Authorization": localStorage.getItem("token") } });
};
const editAdminUSer = (menuID, recordId, editAdminUSer) => {
  return axios.put(API_URL + "api/AS/user/edit/" + recordId, editAdminUSer, { headers: { 'x-resource-id': menuID, "Authorization": localStorage.getItem("token") } });
};


//onboarding videos
const getVideoData = (menuID) => {
  return axios.get(API_URL + "api/AS/onboard-video-list", { headers: { 'x-resource-id': menuID, "Authorization": localStorage.getItem("token") } });
};
const addVideo = (menuID, addVideo) => {
  return axios.post(API_URL + "api/AS/onboard-video/create", addVideo, { headers: { 'x-resource-id': menuID, "Authorization": localStorage.getItem("token") } });
};
const deleteVideo = (menuID, deleteId) => {
  return axios.patch(API_URL + "api/AS/onboard-video/delete/" + deleteId, '', { headers: { 'x-resource-id': menuID, "Authorization": localStorage.getItem("token") } });
};
const getVideoById = (menuID, editId) => {
  return axios.get(API_URL + "api/AS/onboard-video/" + editId, { headers: { 'x-resource-id': menuID, "Authorization": localStorage.getItem("token") } });
};
const editVideo = (menuID, recordId, editVideo) => {
  return axios.put(API_URL + "api/AS/onboard-video/edit", editVideo, { headers: { 'x-resource-id': menuID, "Authorization": localStorage.getItem("token") } });
};


//patient list
const getPatientData = (menuID) => {
  return axios.get(API_URL + "api/AS/membership/patientDetails", { headers: { 'x-resource-id': menuID, "Authorization": localStorage.getItem("token") } });
};
const deletePatient = (menuID, deletePatient) => {
  return axios.post(API_URL + "api/AS/membership/deletePatient", deletePatient, { headers: { 'x-resource-id': menuID, "Authorization": localStorage.getItem("token") } });
};
  

// language
const addLanguage = (menuID, addLanguage) => {
  return axios.post(API_URL + "api/AS/language/create", addLanguage, { headers: { 'x-resource-id': menuID, "Authorization": localStorage.getItem("token") } });
};
const getLanguage = (menuID) => {
  return axios.get(API_URL + "api/AS/languages", { headers: { 'x-resource-id': menuID, "Authorization": localStorage.getItem("token") } });
};
const deleteLanguage = (menuID, selectedId) => {
  return axios.put(API_URL + "api/AS/language/delete/" + selectedId, {}, { headers: { 'x-resource-id': menuID, "Authorization": localStorage.getItem("token") } });
};
const editlanguage = (menuID, editId, editlanguage) => {
  return axios.put(API_URL + "api/AS/language/edit/" + editId, editlanguage, { headers: { 'x-resource-id': menuID, "Authorization": localStorage.getItem("token") } });
};
const getLanguageById = (menuID, getLanguageById) => {
  return axios.get(API_URL + "/api/AS/language/" + getLanguageById, { headers: { 'x-resource-id': menuID, "Authorization": localStorage.getItem("token") } });
};


// contry
const addCountry = (menuID, addCountry) => {
  return axios.post(API_URL + "api/AS/country/create", addCountry, { headers: { 'x-resource-id': menuID, "Authorization": localStorage.getItem("token") } });
};
const getCountry = (menuID) => {
  return axios.get(API_URL + "api/AS/countries", { headers: { 'x-resource-id': menuID, "Authorization": localStorage.getItem("token") } });
};
const deleteCountry = (menuID, selectedId) => {
  return axios.put(API_URL + "api/AS/country/delete/" + selectedId, {}, { headers: { 'x-resource-id': menuID, "Authorization": localStorage.getItem("token") } });
};
const editCountry = (menuID, editId, editCountry) => {
  return axios.put(API_URL + "api/AS/country/edit/" + editId, editCountry, { headers: { 'x-resource-id': menuID, "Authorization": localStorage.getItem("token") } });
};


// Specialization
const addSpecialization = (menuID, addSpecialization) => {
  return axios.post(API_URL + "api/AS/specialization/create", addSpecialization, { headers: { 'x-resource-id': menuID, "Authorization": localStorage.getItem("token") } });
};
const getSpecialization = (menuID) => {
  return axios.get(API_URL + "api/AS/specializations", { headers: { 'x-resource-id': menuID, "Authorization": localStorage.getItem("token") } });
};
const deleteSpecialization = (menuID, selectedId) => {
  return axios.put(API_URL + "api/AS/specialization/delete/" + selectedId, {}, { headers: { 'x-resource-id': menuID, "Authorization": localStorage.getItem("token") } });
};
const editSpecialization = (menuID, editId, editSpecialization) => {
  return axios.put(API_URL + "api/AS/specialization/edit/" + editId, editSpecialization, { headers: { 'x-resource-id': menuID, "Authorization": localStorage.getItem("token") } });
};


//pharmacy
const getPharmacy = (menuID) => {
  return axios.get(API_URL + "api/AS/ulp/pharmacy/all", { headers: { 'x-resource-id': menuID, "Authorization": localStorage.getItem("token") } });
};
const getSuspendedPharmacy = (menuID) => {
  return axios.get(API_URL + "api/AS/ulp/pharmacy/suspendedList", { headers: { 'x-resource-id': menuID, "Authorization": localStorage.getItem("token") } });
};
const addPharmacy = (menuID, addPharmacy) => {
  return axios.post(API_URL + "api/AS/ulp/pharmacy/create", addPharmacy, { headers: { 'x-resource-id': menuID, "Authorization": localStorage.getItem("token") } });
};
const editPharmacy = (menuID, editPharmacy) => {
  return axios.put(API_URL + "api/AS/ulp/pharmacy/update-profile-byadmin", editPharmacy, { headers: { 'x-resource-id': menuID, "Authorization": localStorage.getItem("token") } });
};
const deletePharmacy = (menuID, selectedId) => {
  return axios.put(API_URL + "api/AS/ulp/pharmacy/delete/" + selectedId, {}, { headers: { 'x-resource-id': menuID, "Authorization": localStorage.getItem("token") } });
};
const suspendPharmacy = (menuID, suspendPharmacy) => {
  return axios.put(API_URL + "api/AS/ulp/pharmacy/suspend", suspendPharmacy, { headers: { 'x-resource-id': menuID, "Authorization": localStorage.getItem("token") } });
};
const unsuspendPharmacy = (menuID, selectedId) => {
  return axios.put(API_URL + "api/AS/ulp/pharmacy/unsuspend/" + selectedId, {}, { headers: { 'x-resource-id': menuID, "Authorization": localStorage.getItem("token") } });
};
const getPharmacyById = (menuID, viewId) => {
  return axios.get(API_URL + "api/AS/ulp/pharmacy/singleview/" + viewId, { headers: { 'x-resource-id': menuID, "Authorization": localStorage.getItem("token") } });
};
const getRecentJoinPharmacy = (menuID) => {
  return axios.get(API_URL + "api/AS/ulp/pharmacy/recentall", { headers: { 'x-resource-id': menuID, "Authorization": localStorage.getItem("token") } });
};


//laboratory
const getLaboratory = (menuID) => {
  return axios.get(API_URL + "api/AS/ulp/laboratory/all", { headers: { 'x-resource-id': menuID, "Authorization": localStorage.getItem("token") } });
};
const getSuspendedLaboratory = (menuID) => {
  return axios.get(API_URL + "api/AS/ulp/laboratory/suspendedList", { headers: { 'x-resource-id': menuID, "Authorization": localStorage.getItem("token") } });
};
const addLaboratory = (menuID, addLaboratory) => {
  return axios.post(API_URL + "api/AS/ulp/laboratory/create", addLaboratory, { headers: { 'x-resource-id': menuID, "Authorization": localStorage.getItem("token") } });
};
const editLaboratory = (menuID, editLaboratory) => {
  return axios.put(API_URL + "api/AS/ulp/laboratory/update-profile-byadmin", editLaboratory, { headers: { 'x-resource-id': menuID, "Authorization": localStorage.getItem("token") } });
};
const deleteLaboratory = (menuID, selectedId) => {
  return axios.put(API_URL + "api/AS/ulp/laboratory/delete/" + selectedId, {}, { headers: { 'x-resource-id': menuID, "Authorization": localStorage.getItem("token") } });
};
const suspendLaboratory = (menuID, selectedId) => {
  return axios.put(API_URL + "api/AS/ulp/laboratory/suspend/" + selectedId, {}, { headers: { 'x-resource-id': menuID, "Authorization": localStorage.getItem("token") } });
};
const unsuspendLaboratory = (menuID, selectedId) => {
  return axios.put(API_URL + "api/AS/ulp/laboratory/unsuspend/" + selectedId, {}, { headers: { 'x-resource-id': menuID, "Authorization": localStorage.getItem("token") } });
};
const getLaboratoryById = (menuID, viewId) => {
  return axios.get(API_URL + "api/AS/ulp/laboratory/singleview/" + viewId, { headers: { 'x-resource-id': menuID, "Authorization": localStorage.getItem("token") } });
};
const getRecentJoinLaboratory = (menuID) => {
  return axios.get(API_URL + "api/AS/ulp/laboratory/recentall", { headers: { 'x-resource-id': menuID, "Authorization": localStorage.getItem("token") } });
};

// Lab Payroll

const getlabpay = (menuID,bodyData) => {
  return axios.get(API_URL + "api/AS/LabPayout/pending-payout-lab-list/?filter_month=" + bodyData, { headers: { 'x-resource-id': menuID, "Authorization": localStorage.getItem("token") } });
};

const totalPayoutAmountForLab = (menuID, totalPayoutAmountForLab) => {
  return axios.post(API_URL + "api/AS/LabPayout/get-total-pay-amount", totalPayoutAmountForLab, { headers: { 'x-resource-id': menuID, "Authorization": localStorage.getItem("token") } });
};

const addLabPayout = (menuID, addLabPayout) => {
  return axios.post(API_URL + "api/AS/LabPayout/pay-to-lab", addLabPayout, { headers: { 'x-resource-id': menuID, "Authorization": localStorage.getItem("token") } });
};

const LaboratoryPayrollHidtory = (menuID,bodyData) => {
  return axios.get(API_URL + "api/AS/LabPayout/paid-payout-lab-list/?filter_month=" + bodyData, { headers: { 'x-resource-id': menuID, "Authorization": localStorage.getItem("token") } });
};

//subscription
const getSubscription = (menuID) => {
  return axios.get(API_URL + "api/AS/subscription-plans", { headers: { 'x-resource-id': menuID, "Authorization": localStorage.getItem("token") } });
};
const addSubscription = (menuID, addSubscription) => {
  return axios.post(API_URL + "api/AS/subscription-plan/create", addSubscription, { headers: { 'x-resource-id': menuID, "Authorization": localStorage.getItem("token") } });
};
const editSubscription = (menuID, editSubscription) => {
  return axios.put(API_URL + "api/AS/subscription-plan/update", editSubscription, { headers: { 'x-resource-id': menuID, "Authorization": localStorage.getItem("token") } });
};
const getSubscriptionById = (menuID, viewId) => {
  return axios.get(API_URL + "api/AS/subscription-plan/" + viewId, { headers: { 'x-resource-id': menuID, "Authorization": localStorage.getItem("token") } });
};
const deleteSubscription = (menuID, deleteSubscription) => {
  return axios.put(API_URL + "api/AS/subscription-plan/delete/" + deleteSubscription, '', { headers: { 'x-resource-id': menuID, "Authorization": localStorage.getItem("token") } });
};




//report data for subscription
const getReports = (menuID) => {
  return axios.get(API_URL + "api/AS/reporttype/all", { headers: { 'x-resource-id': menuID, "Authorization": localStorage.getItem("token") } });
};
const addReportType = (menuID, addReportType) => {
  return axios.post(API_URL + "api/AS/reporttype/create", addReportType, { headers: { 'x-resource-id': menuID, "Authorization": localStorage.getItem("token") } });
};
const deleteReportType = (menuID, selectedId) => {
  return axios.put(API_URL + "api/AS/reporttype/delete/" + selectedId, {}, { headers: { 'x-resource-id': menuID, "Authorization": localStorage.getItem("token") } });
};


//tearm and condition 
const editTermsCondition = (menuID, editTermsCondition) => {
  return axios.post(API_URL + "api/AS/option/terms-and-condition", editTermsCondition, { headers: { 'x-resource-id': menuID, "Authorization": localStorage.getItem("token") } });
};
const getTermsConditionByKey = (menuID, getTermsConditionByKey) => {
  return axios.get(API_URL + "api/AS/option/get-terms-and-condition/"+getTermsConditionByKey, { headers: { 'x-resource-id': menuID, "Authorization": localStorage.getItem("token") } });
};

// privacy policy

const getprivacypolicy = (menuID) => {
  return axios.get(API_URL + "api/AS/option/get-privacy-policy/privacy-policy", { headers: { 'x-resource-id': menuID, "Authorization": localStorage.getItem("token") } });
};
const editPrivacyPolicy = (menuID, editPrivacyPolicy) => {
  return axios.post(API_URL + "api/AS/option/privacy-policy", editPrivacyPolicy, { headers: { 'x-resource-id': menuID, "Authorization": localStorage.getItem("token") } });
};


const getPharmacyReviewListById = (menuID, recordID) => {
  return axios.get(API_URL + "api/AS/ulp/pharmacy/reviewlists/" + recordID, { headers: { 'x-resource-id': menuID, "Authorization": localStorage.getItem("token") } });
};
const getLaboratoryReviewListById = (menuID, recordID) => {
  return axios.get(API_URL + "api/AS/ulp/laboratory/reviewlists/" + recordID, { headers: { 'x-resource-id': menuID, "Authorization": localStorage.getItem("token") } });
};


//partner
const getPartner = (menuID) => {
  return axios.get(API_URL + "api/AS/partner/get-All", { headers: { 'x-resource-id': menuID, "Authorization": localStorage.getItem("token") } });
};
const getPartnerById = (menuID, viewId) => {
  return axios.get(API_URL + "api/AS/partner/get/" + viewId, { headers: { 'x-resource-id': menuID, "Authorization": localStorage.getItem("token") } });
};
const addPartner = (menuID, addPartner) => {
  return axios.post(API_URL + "api/AS/partner/add", addPartner, { headers: { 'x-resource-id': menuID, "Authorization": localStorage.getItem("token") } });
};
const editPartner = (menuID, editPartner) => {
  return axios.post(API_URL + "api/AS/partner/update/", editPartner, { headers: { 'x-resource-id': menuID, "Authorization": localStorage.getItem("token") } });
};
const deletePartner = (menuID, selectedId) => {
  return axios.put(API_URL + "api/AS/partner/delete/" + selectedId, {}, { headers: { 'x-resource-id': menuID, "Authorization": localStorage.getItem("token") } });
};


// Doctor 
const getDoctors = (menuID) => {
  return axios.get(API_URL + "api/AS/doctors_list", { headers: { 'x-resource-id': menuID, "Authorization": localStorage.getItem("token") } });
};
const getSuspendedDoctors = (menuID) => {
  return axios.get(API_URL + "api/AS/Suspend_doctors_list", { headers: { 'x-resource-id': menuID, "Authorization": localStorage.getItem("token") } });
};
const getPendingDoctors = (menuID) => {
  return axios.get(API_URL + "api/AS/Pending_doctor_Request_list", { headers: { 'x-resource-id': menuID, "Authorization": localStorage.getItem("token") } });
};

const getDoctorById = (menuID, viewId) => {
  return axios.get(API_URL + "api/AS/doctor/" + viewId, { headers: { 'x-resource-id': menuID, "Authorization": localStorage.getItem("token") } });
};

const addDoctor = (menuID, addDoctor) => {
  return axios.post(API_URL + "api/AS/add-doctor", addDoctor, { headers: { 'x-resource-id': menuID, "Authorization": localStorage.getItem("token") } });
};
const editDoctor = (menuID, editDoctor) => {
  return axios.post(API_URL + "api/AS/edit-doctor", editDoctor, { headers: { 'x-resource-id': menuID, "Authorization": localStorage.getItem("token") } });
};
const getDoctorReviewListById = (menuID, recordID) => {
  return axios.get(API_URL + "api/AS/doctor-profile-review/" + recordID, { headers: { 'x-resource-id': menuID, "Authorization": localStorage.getItem("token") } });
};
const getRecentJoinDoctors = (menuID) => {
  return axios.get(API_URL + "api/AS/Recent_joined_Doctor_list", { headers: { 'x-resource-id': menuID, "Authorization": localStorage.getItem("token") } });
};

const deleteDoctorAccount = (menuID, deleteDoctorAccount) => {
  return axios.post(API_URL + "api/AS/delete-Doctor-Ac", deleteDoctorAccount, { headers: { 'x-resource-id': menuID, "Authorization": localStorage.getItem("token") } });
};
const suspendDoctor = (menuID, suspendDoctor) => {
  return axios.post(API_URL + "api/AS/Suspend-Doctor-Ac", suspendDoctor, { headers: { 'x-resource-id': menuID, "Authorization": localStorage.getItem("token") } });
};
const unsuspendDoctor = (menuID, unsuspendDoctor) => {
  return axios.post(API_URL + "api/AS/UnSuspend-Doctor-Ac", unsuspendDoctor, { headers: { 'x-resource-id': menuID, "Authorization": localStorage.getItem("token") } });
};
const addEditBonus = (menuID, addEditBonus) => {
  return axios.post(API_URL + "api/AS/bonus", addEditBonus, { headers: { 'x-resource-id': menuID, "Authorization": localStorage.getItem("token") } });
};

const getattendPatiantList = (menuID,bodyData) => {
  return axios.get(API_URL + "api/AS/doctorAttendPatient/doctor-Attend-Patient-list/?filter_month=" + bodyData, { headers: { 'x-resource-id': menuID, "Authorization": localStorage.getItem("token") } });
};

const totalPayoutAmount = (menuID, totalPayoutAmount) => {
  return axios.post(API_URL + "api/AS/doctorAttendPatient/get-total-pay-amount", totalPayoutAmount, { headers: { 'x-resource-id': menuID, "Authorization": localStorage.getItem("token") } });
 };

const addPayout = (menuID, addPayout) => {
  return axios.post(API_URL + "api/AS/doctorAttendPatient/pay-to-doctors", addPayout, { headers: { 'x-resource-id': menuID, "Authorization": localStorage.getItem("token") } });
};

const DoctorPayrollHidtory = (menuID,bodyData) => {
  return axios.get(API_URL + "api/AS/doctorAttendPatient/doctor-payout-history/?filter_month=" + bodyData, { headers: { 'x-resource-id': menuID, "Authorization": localStorage.getItem("token") } });
};


//approve reject doctor
const approveDoctorAccount = (menuID, approveDoctorAccount) => {
  return axios.post(API_URL + "api/AS/approve-Doctor-AC", approveDoctorAccount, { headers: { 'x-resource-id': menuID, "Authorization": localStorage.getItem("token") } });
};
const declainDoctorAccount = (menuID, declainDoctorAccount) => {
  return axios.post(API_URL + "api/AS/decline-Doctor-Ac", declainDoctorAccount, { headers: { 'x-resource-id': menuID, "Authorization": localStorage.getItem("token") } });
};
const approveDocument = (menuID, approveDocument) => {
  return axios.put(API_URL + "api/AS/verify_document/fordoctor", approveDocument, { headers: { 'x-resource-id': menuID, "Authorization": localStorage.getItem("token") } });
};


// Security Question
const addSecurityQuestion = (menuID, addSecurityQuestion) => {
  return axios.post(API_URL + "api/AS/security/question/create", addSecurityQuestion, { headers: { 'x-resource-id': menuID, "Authorization": localStorage.getItem("token") } });
};
const getSecurityQuestion = (menuID) => {
  return axios.get(API_URL + "api/AS/security/questions", { headers: { 'x-resource-id': menuID, "Authorization": localStorage.getItem("token") } });
};
const deletegetSecurityQuestion = (menuID, selectedId) => {
  return axios.put(API_URL + "api/AS/security/question/delete/" + selectedId, {}, { headers: { 'x-resource-id': menuID, "Authorization": localStorage.getItem("token") } });
};
const editgetSecurityQuestion = (menuID, editId, editgetSecurityQuestion) => {
  return axios.put(API_URL + "api/AS/security/question/edit/" + editId, editgetSecurityQuestion, { headers: { 'x-resource-id': menuID, "Authorization": localStorage.getItem("token") } });
};


//document category get
const getDocumentCategory = (menuID) => {
  return axios.get(API_URL + "api/AS/document_categories", { headers: { 'x-resource-id': menuID, "Authorization": localStorage.getItem("token") } });
};


// questionnaire
const getquestionnaire = (menuID) => {
  return axios.get(API_URL + "api/AS/questionnaires", { headers: { 'x-resource-id': menuID, "Authorization": localStorage.getItem("token") } });
};
const addquestionnaire = (menuID, addquestionnaire) => {
  return axios.post(API_URL + "api/AS/questionnaire/create", addquestionnaire, { headers: { 'x-resource-id': menuID, "Authorization": localStorage.getItem("token") } });
};
const getQuestionaryById = (menuID, recordId) => {
  return axios.get(API_URL + "api/AS/questionnaire/" + recordId, { headers: { 'x-resource-id': menuID, "Authorization": localStorage.getItem("token") } });
};
const deletQuestionary = (menuID, recordId) => {
  return axios.put(API_URL + "api/AS/questionnaire/delete/" + recordId, {}, { headers: { 'x-resource-id': menuID, "Authorization": localStorage.getItem("token") } });
};
const editQuestionary = (menuID, recordId, editQuestionary) => {
  return axios.put(API_URL + "api/AS/questionnaire/edit/" + recordId, editQuestionary, { headers: { 'x-resource-id': menuID, "Authorization": localStorage.getItem("token") } });
};


// Review
const getReview = (menuID, getReview) => {
  return axios.post(API_URL + "api/AS/get-Review-with-filter", getReview, { headers: { 'x-resource-id': menuID, "Authorization": localStorage.getItem("token") } });
};
const deleteReview = (menuID, deleteReview) => {
  return axios.post(API_URL + "api/AS/delete-Review", deleteReview, { headers: { 'x-resource-id': menuID, "Authorization": localStorage.getItem("token") } });
};


// Doctor Payout
const getDoctorPayoutByKey = (menuID, getDoctorPayoutByKey) => {
  return axios.post(API_URL + "api/AS/payout/add", getDoctorPayoutByKey, { headers: { 'x-resource-id': menuID, "Authorization": localStorage.getItem("token") } });
};
const editDoctorPayout = (menuID) => {
  return axios.get(API_URL + "api/AS/payout/get/payout-amount", { headers: { 'x-resource-id': menuID, "Authorization": localStorage.getItem("token") } });
};

//USSD Patients
const getUssdPatient = (menuID) => {
  return axios.get(API_URL + "api/AS/option/get-hourswise-doctorpayout/doctor-hourwise-payout-amount", { headers: { 'x-resource-id': menuID, "Authorization": localStorage.getItem("token") } });
};
const editUssaPatient = (menuID, editUssaPatient) => {
  return axios.post(API_URL + "api/AS/option/addorupdate_hourswise_doctorpayout", editUssaPatient, { headers: { 'x-resource-id': menuID, "Authorization": localStorage.getItem("token") } });
};


// add globally bonus to doctor

const doctorgoballybonus = (menuID, doctorgoballybonus) => {
  return axios.post(API_URL + "api/AS/add-bonus-globlly", doctorgoballybonus, { headers: { 'x-resource-id': menuID, "Authorization": localStorage.getItem("token") } });
};


export default {

  //dashboard
  getDashboardData,
  getDoctorRequestDashboard,
  getDashboardChartData,
  getPatientReviewDashboard,
  getPurchesSubscription,
  getRecentlyWithdrawal,
  
  //admin login
  adminLogin,
  adminLogout,
  adminForgotPassword,
  adminResetPassword,

  //faq
  getFaq,
  addFaq,
  deleteFaq,
  editFaq,

  //doctors

  getDoctors,
  getDoctorReviewListById,
  getSuspendedDoctors,
  getPendingDoctors,
  addDoctor,
  editDoctor,
  getDoctorById,
  getRecentJoinDoctors,
  approveDoctorAccount,
  declainDoctorAccount,
  addEditBonus,

  deleteDoctorAccount,
  suspendDoctor,
  unsuspendDoctor,

  approveDocument,

  getattendPatiantList,
  totalPayoutAmount,
  addPayout,
  DoctorPayrollHidtory,

  //role
  addRole,
  getResources,
  getAdminRole,
  getAdminUSerData,
  deleteAdminRole,
  addAdminUSer,
  getAdminUSerById,
  editAdminUSer,

  //onBoarding videos

  getVideoData,
  addVideo,
  deleteVideo,
  getVideoById,
  editVideo,
  getPatientData,
  deletePatient,

  // Language  
  addLanguage,
  getLanguage,
  deleteLanguage,
  editlanguage,
  getLanguageById,

  // Country
  addCountry,
  getCountry,
  deleteCountry,
  editCountry,

  // Specialization
  addSpecialization,
  getSpecialization,
  deleteSpecialization,
  editSpecialization,

  //pharmacy

  getPharmacy,
  getSuspendedPharmacy,
  addPharmacy,
  editPharmacy,
  deletePharmacy,
  getPharmacyById,
  suspendPharmacy,
  unsuspendPharmacy,
  getRecentJoinPharmacy,

  getPharmacyReviewListById,
  getLaboratoryReviewListById,

  //laboratory
  getLaboratory,
  getSuspendedLaboratory,
  addLaboratory,
  editLaboratory,
  deleteLaboratory,
  getLaboratoryById,
  suspendLaboratory,
  unsuspendLaboratory,
  getRecentJoinLaboratory,

  getlabpay,
  totalPayoutAmountForLab,
  addLabPayout,
  LaboratoryPayrollHidtory,

  //subscription

  getSubscription,
  addSubscription,
  editSubscription,
  getSubscriptionById,
  deleteSubscription,

  //report type
  getReports,
  addReportType,
  deleteReportType,

  //term-condition
  editTermsCondition,
  getTermsConditionByKey,
  getprivacypolicy,
  editPrivacyPolicy,

  //partner
  getPartner,
  addPartner,
  getPartnerById,
  editPartner,
  deletePartner,

  //security question

  addSecurityQuestion,
  getSecurityQuestion,
  deletegetSecurityQuestion,
  editgetSecurityQuestion,

  getDocumentCategory,

  getquestionnaire,
  addquestionnaire,
  deletQuestionary,
  getQuestionaryById,
  editQuestionary,

  //review
  getReview,
  deleteReview,

  // Doctor Payout
  editDoctorPayout,
  getDoctorPayoutByKey,

  // USSD Patient Appointment fee
  getUssdPatient,
  editUssaPatient,
  doctorgoballybonus,
}
