import React, { useEffect, useState } from 'react'
import { useNavigate, Navigate } from "react-router-dom";
import { NavLink } from 'react-router-dom'
import * as Yup from "yup"; // used when validating with a pre-built solution
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import swal from 'sweetalert';
import $ from 'jquery';

import logo from '../../assets/images/logo.png'
import loader from '../../assets/images/loder.gif'

import Services from '../../Services/auth.service';
import {Buffer} from 'buffer';

var CryptoJS = require("crypto-js");


const Login = () => {

    const history = useNavigate();
    const [showLoader, setLoader] = useState(false);
    var email_remember = localStorage.getItem("email");
    var password_remember = localStorage.getItem("password");
    const [remeberMeCheck, setRemeberMeCheck] = useState(false)

    const dashboardId = "62c428065856aa82a235bee4";
    var encyptDashboardId = CryptoJS.enc.Utf8.parse(dashboardId); // WordArray object
    var dashboardIdEncrypt = CryptoJS.enc.Base64.stringify(encyptDashboardId);

    useEffect(() => {
        if (email_remember !== "" && password_remember !== "") {
            setRemeberMeCheck(true)
        } else {
            setRemeberMeCheck(false)
        }

        const loggedInUser = localStorage.getItem("token");
        loggedInUser && history(`/dashboard?id=${dashboardIdEncrypt}`);
    }, []);

    const onChangeRememberMe = (e) => {
        setRemeberMeCheck(e.target.checked)
    }

    const validationSchema = Yup.object().shape({
        admin_email: Yup.string().email('Please enter proper email format!').required('Please enter an email!'),
        admin_password: Yup.string().required('Please enter the password!')
    });

    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm({
        mode: 'onchange',
        resolver: yupResolver(validationSchema)
    });

   
    const onSubmit = async (data, e) => {
        
        const pwd = data.admin_password

        const encrypted = Buffer.from(pwd, 'base64').toString('binary')

        var dataValue = {
            "email": data.admin_email,
            "password": encrypted
        }
        if (data.RememberMe) {
            localStorage.setItem("email", data.admin_email);
            localStorage.setItem("password", encrypted);
        } else {
            localStorage.setItem("email", "");
            localStorage.setItem("password", "");
        }
        setLoader(true)
        Services.adminLogin(dataValue)
            .then(response => {
                setLoader(false)
                if (response.data.status === true) {
                    localStorage.setItem("token", response.data.data.token);
                    localStorage.setItem("user_id", response.data.data.user._id);
                    localStorage.setItem("role_resources", JSON.stringify(response.data.data.user.role_resources));

                    swal("Success", response.data.message, "success");
                    history(`/dashboard?id=${dashboardIdEncrypt}`)

                } else {
                    setLoader(false)
                    swal("Failed", response.data.message, "error");

                }
            }).catch(err => {
                setLoader(false)
                swal("Failed", err.response.data.message, "error");


            })
    }
    return (
        <>

            <section className="bg-home d-flex bg-light align-items-center cstm-fm-all">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-5 col-md-8">
                            <div className="card login-page bg-white shadow rounded border-0">
                                <div className="card-body">
                                    <div className="text-center mb-4 login-logo">
                                        {/* <a href="#">
                                            <img src={logo} />
                                        </a> */}

                                        <NavLink to="/">
                                            <img src={logo} />
                                        </NavLink>
                                    </div>
                                    <h4>Sign in your account</h4>
                                    <form noValidate onSubmit={handleSubmit(onSubmit)} className="login-form mt-4">
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className="mb-4">
                                                    <label className="cstm-label">Email<span className="text-danger">*</span></label>
                                                    <input type="email" {...register('admin_email')} name="admin_email" id="admin_email" defaultValue={email_remember} className="cstm-input" placeholder="Enter your email" required="" />
                                                    <span className="invalid-error-login" style={{ color: "red" }}>{errors.admin_email?.message}</span>
                                                </div>
                                            </div>
                                            <div className="col-lg-12">
                                                <div className="mb-3">
                                                    <label className="cstm-label">
                                                        Password<span className="text-danger">*</span>
                                                    </label>
                                                    <input type="password" id="admin_password" className="cstm-input" {...register('admin_password')} defaultValue={password_remember} placeholder="Enter your password" name="admin_password" required="" />
                                                    <span className="invalid-error-login" style={{ color: "red" }}>{errors.admin_password?.message}</span>
                                                </div>
                                            </div>
                                            <div className="col-lg-12  mb-4">
                                                <div className="d-flex justify-content-between">
                                                    <div className="cstm-remeber-me">
                                                        <label>
                                                            <input type="checkbox" checked={remeberMeCheck} name="RememberMe" {...register('RememberMe')} onChange={(e) => onChangeRememberMe(e)} /> Remember me
                                                        </label>
                                                    </div>
                                                    {/* <a href="#l">Forgot password ?</a> */}
                                                    <NavLink activeClassName="active_class" to="/Forgotpassword">Forgot password ?</NavLink>
                                                </div>
                                            </div>

                                            {/* <div className="row"> */}
                                            <div className="col-lg-12">
                                                <div className="mb-2 cstm-loader ">
                                                    <div className="cstm-ldr-mn login-ldr">
                                                        <button className="cstm-btn6 login-ldr-btn">Sign in</button>
                                                        {showLoader &&
                                                            <div className="btn-loader-main login-btn-div">
                                                                <img className="btn-loaderImg login-img" src={loader} /></div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            {/* </div> */}
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>

    )
}

export default Login


