import React from 'react'
import user from '../../assets/images/user.png'
import plusImg from "../../assets/images/plus.png";
import Services from '../../Services/auth.service';

const Header = () => {
    const logout = () => {
        localStorage.removeItem("token");
        localStorage.removeItem("user_id");
        localStorage.removeItem("role_resources");

        Services.adminLogout().then((response)=>{
        }).catch((err)=>{
            
        })

    };
    return (
        <>
            {/* <div class="page-wrapper doctris-theme toggled"> */}
            <main class="page-content bg-light">
                <div class="top-header">
                    <div class="header-bar d-flex justify-content-end border-bottom">

                        <ul class="list-unstyled mb-0">
                            <li class="list-inline-item mb-0 ms-2">
                                <a href="javascript:void(0)" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight">
                                    <div class="btn btn-icon btn-pills btn-soft-primary"><i class="fi fi-rr-bell"></i></div>
                                </a>
                            </li>
                            <li class="list-inline-item mb-0 ms-2">
                                <div class="dropdown dropdown-primary">
                                    <button type="button" class="btn btn-pills dropdown-toggle p-0" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <img src={plusImg} class="cstm-user-main-photo" alt="" />
                                        <i class="fi fi-rr-caret-down cstm-icon-mn"></i>
                                    </button>
                                    <div class="dropdown-menu dd-menu dropdown-menu-end csrm-user-dv">
                                        <a href="" onClick={logout} className="active-us">
                                            Logout
                                        </a>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </main>
            {/* </div> */}

        </>
    )
}

export default Header
