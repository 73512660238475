import React, { useEffect, useState } from 'react';
import Header from '../Navbar/Header'
import SideBar from '../Navbar/SideBar'
import NavLinkHeader from '../Navbar/NavLinkHeader'
import Services from "../../Services/auth.service";
import swal from "sweetalert";
import loader from '../../assets/images/loder.gif'
import { useTable, usePagination, useGlobalFilter } from "react-table";
import { Link, useNavigate, useLocation } from 'react-router-dom';
import moment from "moment";
import deleteImg from "../../assets/images/delete.png";
const CryptoJS = require("crypto-js");

function Table({ columns, data }) {
    const getid = useLocation().search;
    const getMenuID = new URLSearchParams(getid).get("id");
    var decryptId = CryptoJS.enc.Base64.parse(getMenuID);
    var menuID = CryptoJS.enc.Utf8.stringify(decryptId);
    const [showLoader, setLoader] = useState(true);
    useEffect(() => {
        setTimeout(() => {
            setLoader(false);
        }, 2500)
    }, [])
    const props = useTable(
        {
            columns,
            data,
            initialState: { pageIndex: 0, pageSize: 20 }
        },
        useGlobalFilter, // useGlobalFilter!
        usePagination
    );
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        setGlobalFilter,
        state,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,


        state: { pageIndex, pageSize, globalFilter }
    } = props;

    let firstRecord = pageIndex * pageSize + 1;
    let lastRecord = firstRecord + pageSize - 1;

    return (
        <>

            {/* ============== */}
            <div className="row">
                <div className="col-md-12">
                    <div className="card  rounded-md  border">
                        <div className="d-flex justify-content-between align-items-center p-3 border-bottom">
                            <div className="col-md-8">
                                <div className="row row ">
                                    <div className="col-md-5">
                                        <i className="fi fi-rr-search cstm-search-ro" />
                                        <input
                                            name="serch-adminuser"
                                            id="serch-adminuser"
                                            type="text"
                                            class="cstm-input-seacrh"
                                            value={globalFilter || ""}
                                            onChange={(e) => setGlobalFilter(e.target.value)}
                                            placeholder="Search Test Type"
                                        />
                                    </div>
                                    {/* <div className="col-md-3">
                          <select
                            className="cstm-select cstm-om-slc"
                            name="City"
                            id="City"
                          >
                            <option value="City">Categories</option>
                            <option value="City2">Categories 2</option>
                          </select>
                        </div> */}
                                </div>
                            </div>
                            <div>
                                <Link to={""}
                                    data-bs-toggle="modal"
                                    data-bs-target="#AddReport123"
                                >
                                    <button class="cstm-btn">Add Test Type</button>
                                </Link>
                            </div>
                            {/* <div>
                      <button className="cstm-btn">Add Doctors</button>
                    </div> */}
                        </div>
                        <div className="col-md-12 col-lg-12">
                            <div className="table-responsive bg-white rounded cstm-ldr">
                                <table
                                    {...getTableProps()}
                                    className="table mb-0 table-center"
                                >
                                    <thead>
                                        {" "}
                                        {headerGroups.map((headerGroup) => (
                                            <tr {...headerGroup.getHeaderGroupProps()}>
                                                {headerGroup.headers.map((column) => (
                                                    <th className={`border-bottom cstm-userheading ${column.Header === "No" ? "w-3" : ''}${column.Header === "Topic Name" ? "w-15" : ''}${column.Header === "Description" ? "w-35" : ''}${column.Header === "Rating" ? "w-14" : ''}${column.Header === "Date" ? "w-14" : ''}${column.Header === "Actions" ? "actions3" : ''}`} {...column.getHeaderProps()}>
                                                        {column.render("Header")}
                                                        {/* Render the columns filter UI */}
                                                    </th>
                                                ))}
                                            </tr>
                                        ))}
                                    </thead>
                                    <tbody {...getTableBodyProps()}>
                                        {
                                            page.length > 0 && page.map((row, i) => {
                                                prepareRow(row);
                                                return (
                                                    <tr {...row.getRowProps()} className="cstm-Tabledesign">
                                                        {row.cells.map((cell) => {
                                                            return (
                                                                <td {...cell.getCellProps()}>
                                                                    {cell.render("Cell")}
                                                                </td>
                                                            );
                                                        })}
                                                    </tr>
                                                );
                                            })}
                                    </tbody>

                                </table>
                                {!showLoader ? page.length === 0 &&
                                    <div className="NoRecord-cstm">No record found!</div> : <div className="NoRecord-cstm"> <img className="pageloader" src={loader} /></div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row text-center">
                <div className="col-12 mt-4">
                    <div className="d-md-flex align-items-center text-center justify-content-between">
                        <span className="text-muted me-3">
                            {page.length !== 0 && `Showing ${firstRecord} -${" "}
                  ${lastRecord < rows.length ? lastRecord : rows.length} out of${" "}
                  ${rows.length}`}</span>
                        <ul className="pagination justify-content-center mb-0 mt-3 mt-sm-0">
                            {pageOptions.length !== 1 && page.length !== 0 && (<li class="page-item">
                                <a
                                    class="page-link"
                                    onClick={() => previousPage()}
                                    disabled={!canPreviousPage}
                                    href="javascript:void(0)"
                                >
                                    Prev
                                </a>
                            </li>)}
                            {

                                pageOptions.map(pgnumber => {
                                    return (
                                        pgnumber !== pageCount && pageCount !== 1 && page.length !== 0 &&
                                        <li className={`page-item ${pageIndex == pgnumber ? 'active' : ''}`} ><a className="page-link" href="javascript:void(0)" onClick={() => gotoPage(pgnumber)} > {pgnumber + 1} </a></li>)

                                })
                            }
                            {pageOptions.length !== 1 && page.length !== 0 && (<li class="page-item">
                                <a
                                    class="page-link"
                                    onClick={() => nextPage()}
                                    disabled={!canNextPage}
                                    href="javascript:void(0)"
                                >
                                    Next
                                </a>
                            </li>)}
                        </ul>
                    </div>
                </div>
            </div>
        </>
    );
}


const Index = () => {
    const history = useNavigate();
    const getid = useLocation().search;
    const getMenuID = new URLSearchParams(getid).get("id");
    var decryptId = CryptoJS.enc.Base64.parse(getMenuID);
    var menuID = CryptoJS.enc.Utf8.stringify(decryptId);
    const [isLoading, setIsLoading] = useState(false);


    const [selectedId, setselectedId] = useState();
    const [data, setdata] = useState([]);

    const [reportName, setReportName] = useState({
        "name": ""
    });
    const [reportPrice, setReportPrice] = useState({
        "price": ""
    });

    const [err, setErr] = useState({
        "name": "",
        "price": ""
    })

    const onCloseButton = () => {
        setReportPrice({
            "price":""
        })
        setReportName({
            "name":""
        }) 
    }

    var DataArray = [];

    const columns = [

        {
            Header: 'No',
            accessor: 'serial'
        },

        {
            Header: "Test Type",
            accessor: "name"
        },

        {
            Header: "price",
            accessor: "price"
        },

        {
            Header: "Actions",

            Cell: (row) => {

                var encyptId = CryptoJS.enc.Utf8.parse(row.cell.row.original._id); // WordArray object
                var recordIdEncrypt = CryptoJS.enc.Base64.stringify(encyptId);
                return (
                    <div>
                        <Link to="" onClick={() => setselectedId(row.cell.row.original._id)} className="cstm-cross mrn-rt" data-bs-toggle="modal" data-bs-target="#cancelappointment">
                            <i className="fi fi-rr-trash"></i>
                        </Link>
                    </div>
                );
            },
        },

    ];


    function createData(_id, name, price, action) {
        return {
            _id,
            name,
            price,
        };
    }
    // No of Index 
    data.forEach((data_id, index) => { data_id.serial = index + 1; });

    useEffect(() => {
        const loggedInUser = localStorage.getItem("token");
        !loggedInUser && history("/");
        ReportTypeData()
    }, []);

    const ReportTypeData = () => {

        Services.getReports(menuID)
            .then((response) => {
                if (response.data.status = true) {
                    var arr = response.data.data;
                    if (response.data.data.length > 0) {
                        var newarr = [];
                        for (var i = 0; i < arr.length; i++) {
                            var doller = `₦`;
                            newarr[i] = createData(
                                arr[i]._id,
                                arr[i].name,
                               ` ${ doller }${ arr[i].price}`,
                            );
                        }
                        newarr.map((data1) => {
                            DataArray = [...DataArray, data1]
                        })
                        setdata(DataArray);
                    }
                } else {
                }
            })
            .catch(function (err) {
            });
    }
    const handleOnChange = (e) => {
        const { value, name } = e.target;
        if (name === "name") {
            err.name = value.length > 0 ? "" : "Enter Test Type";
        }
        if (name === "price") {
            err.price = !/^(?=.*?\d)?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/.test(value) ? "Enter valid number" : "";
            // err.price = value.length > 0 ? "" : "Enter Price" ;
        }
       
        setErr({ ...err })
        setReportName({ ...reportName, [name]: value });

        setReportPrice({ ...reportPrice, [name]: value });

    }

    const handleOnSubmit = (e) => {
        e.preventDefault();
        if (reportName.name === "") {
            err.name = "Enter Test Type";
        }
        if (reportPrice.price === "") {
            err.price = "Enter Price";
        }
        else {
            err.name = "";
            err.price = "";
        }
        setErr({ ...err });
        if (err.name === "" && err.price === "") {

            var formdata = {
                "name": reportName.name,
                "price": reportPrice.price,
            }

            setIsLoading(true)
            Services.addReportType(menuID, formdata)
                .then((response) => {
                    setIsLoading(false)
                    if (response.data.status === true) {
                        swal("Success", response.data.message, "success");
                        document.querySelector("#AddReport123 .btn-close").click();
                        ReportTypeData()
                      
                    } else {
                        setIsLoading(false)
                        swal("Failed", response.data.message, "error");
                       
                    }
                })
                .catch((err) => {
                    setIsLoading(false)
                    swal("Failed", err.response.data.message, "error");
                });
        }
    }

    const handleDelete = () => {

        Services.deleteReportType(menuID, selectedId)
            .then((response) => {
                if (response.data.status === true) {
                    swal("Success", response.data.message, "success");
                    document.querySelector("#cancelappointment .btn-close").click();
                    ReportTypeData()

                } else {
                    swal("Failed", response.data.message, "error");
                }
            })
            .catch(function (err) {
                swal("Failed", err.response.data.message, "error");
            });
    };


    return (
        <>
            <main className="page-content bg-light">
                <Header />
                <div className="container-fluid">
                    <div className="layout-specing">
                        <div className="d-flex justify-content-between align-items-center mb-3">
                            <h4 className="mb-0">Plan Features</h4>
                            <NavLinkHeader title1="Test Type" />
                        </div>
                        <Table columns={columns} data={data} />
                    </div>
                </div>

                {/* add Report type modal */}

                <div className="modal fade" id="AddReport123" tabindex="-1" aria-labelledby="exampleModalLabel1" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header border-0 p-4">

                                <h4 className="modal-title" id="exampleModalLabel1">Add Test Type</h4>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={onCloseButton}></button>
                            </div>
                            <form onSubmit={handleOnSubmit} id="add-reportType">
                                <div className="modal-body p-4 pt-0">
                                    <div className="mb-3">
                                        <label className="cstm-label">Test Type Name<span className="cstm-star">*</span></label>
                                        <input type="text" onChange={handleOnChange} className="cstm-input" placeholder="Enter Test type name" value={reportName.name} name="name" required="" />
                                        {err.name !== "" && (
                                            <span style={{ color: "red" }}>
                                                {err.name}
                                            </span>
                                        )}
                                    </div>
                                    <div className="mb-3">
                                        <label className="cstm-label">Price<span className="cstm-star">*</span></label>
                                        <input type="text" onChange={handleOnChange} className="cstm-input" placeholder="Enter Price" name="price" value={reportPrice.price} required="" />
                                        {err.price !== "" && (
                                            <span style={{ color: "red" }}>
                                                {err.price}
                                            </span>
                                        )}
                                    </div>
                                    <div className="d-flex justify-content-end">
                                        <div className="cstm-ldr-mn">
                                            <button className=" cstm-btn7">Add Test Type</button>
                                            {isLoading &&
                                                <div id="btn-loader" className="btn-loader-main">
                                                    <img className="btn-loaderImg" src={loader} />
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                {/* delete modal */}
                <div class="modal fade cstm-add-bonus" id="cancelappointment" tabindex="-1" aria-labelledby="exampleModalLabel1"
                    aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered">
                        <div class="modal-content">
                            <div class="modal-header border-0 p-4">
                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" ></button>
                            </div>
                            <div class="modal-body p-4 pt-0">
                                <div class="mb-4">

                                    <div class="text-center mb-4">
                                        <img src={deleteImg} />
                                    </div>
                                    <h4 class="text-center mb-4">Are you sure want to delete this Test Type?</h4>
                                    <div class="text-center">
                                        <button class="cstm-btn7" onClick={handleDelete}>Delete Test</button>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </main>

        </>
    )
}
export default Index;

