import React, { useEffect, useState } from 'react';
import Header from '../Navbar/Header'
import NavLinkHeader from '../Navbar/NavLinkHeader'
import Services from "../../Services/auth.service";
import swal from "sweetalert";
import loader from '../../assets/images/loder.gif'
import { useTable, usePagination, useGlobalFilter } from "react-table";
import { Link, useNavigate, useLocation } from 'react-router-dom';
import moment from "moment";
import plusImg from "../../assets/images/plus.png";


const CryptoJS = require("crypto-js");

function Table({ columns, data }) {
  const getid = useLocation().search;
  const getMenuID = new URLSearchParams(getid).get("id");
  var decryptId = CryptoJS.enc.Base64.parse(getMenuID);
  var menuID = CryptoJS.enc.Utf8.stringify(decryptId);
  const [showLoader, setLoader] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoader(false);
    }, 1500)
  }, [])
  const props = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 20 }
    },
    useGlobalFilter, // useGlobalFilter!
    usePagination
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setGlobalFilter,
    state,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,


    state: { pageIndex, pageSize, globalFilter }
  } = props;

  let firstRecord = pageIndex * pageSize + 1;
  let lastRecord = firstRecord + pageSize - 1;

  return (
    <>

      {/* ============== */}
      <div className="row">
        <div className="col-md-12">
          <div className="card  rounded-md  border">
            <div className="d-flex justify-content-between align-items-center p-3 border-bottom">
              <div className="col-md-8">
                <div className="row row ">
                  <div className="col-md-5">
                    <i className="fi fi-rr-search cstm-search-ro" />
                    <input
                      name="serch-adminuser"
                      id="serch-adminuser"
                      type="text"
                      class="cstm-input-seacrh"
                      value={globalFilter || ""}
                      onChange={(e) => setGlobalFilter(e.target.value)}
                      placeholder="Search Doctor"
                    />
                  </div>
                </div>
              </div>
              <div>
                <Link
                  to={`/add-doctor?id=${getMenuID}`}
                >
                  <button class="cstm-btn">Add a Doctor</button>
                </Link>
              </div>
            </div>
            <div className="col-md-12 col-lg-12">
              <div className="table-responsive bg-white rounded cstm-ldr">
                <table
                  {...getTableProps()}
                  className="table mb-0 table-center"
                >
                  <thead>

                    {headerGroups.map((headerGroup) => (
                      <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column) => (
                          <th className={`border-bottom cstm-userheading ${column.Header === "No" ? "w-3" : ''}${column.Header === "Doctor Name" ? "w-20" : ''}${column.Header === "Age" ? "w-8" : ''}${column.Header === "Gender" ? "w-8" : ''}${column.Header === "Email" ? "w-18" : ''}${column.Header === "Address" ? "w-30" : ''}${column.Header === "Date" ? "w-14" : ''}${column.Header === "Actions" ? "actions3" : ''}`} {...column.getHeaderProps()}>
                            {column.render("Header")}
                            {/* Render the columns filter UI */}
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody {...getTableBodyProps()}>
                    {
                      page.length > 0 && page.map((row, i) => {
                        prepareRow(row);
                        return (
                          <tr {...row.getRowProps()} className="cstm-Tabledesign">
                            {row.cells.map((cell) => {
                              return (
                                <td {...cell.getCellProps()}>
                                  {cell.render("Cell")}
                                </td>
                              );
                            })}
                          </tr>
                        );
                      })}
                  </tbody>

                </table>
                {!showLoader ? page.length === 0 &&
                  <div className="NoRecord-cstm">No record found!</div> : <div className="NoRecord-cstm"> <img className="pageloader" src={loader} /></div>
                }
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row text-center">
        <div className="col-12 mt-4">
          <div className="d-md-flex align-items-center text-center justify-content-between">
            <span className="text-muted me-3">
              {page.length !== 0 && `Showing ${firstRecord} -${" "}
                  ${lastRecord < rows.length ? lastRecord : rows.length} out of${" "}
                  ${rows.length}`}</span>
            <ul className="pagination justify-content-center mb-0 mt-3 mt-sm-0">
              {pageOptions.length !== 1 && page.length !== 0 && (<li class="page-item">
                <a
                  class="page-link"
                  onClick={() => previousPage()}
                  disabled={!canPreviousPage}
                  href="javascript:void(0)"
                >
                  Prev
                </a>
              </li>)}
              {
                pageOptions.map(pgnumber => {
                  return (
                    pgnumber !== pageCount && pageCount !== 1 && page.length !== 0 &&
                    <li className={`page-item ${pageIndex == pgnumber ? 'active' : ''}`} ><a className="page-link" href="javascript:void(0)" onClick={() => gotoPage(pgnumber)} > {pgnumber + 1} </a></li>)
                })
              }
              {pageOptions.length !== 1 && page.length !== 0 && (<li class="page-item">
                <a
                  class="page-link"
                  onClick={() => nextPage()}
                  disabled={!canNextPage}
                  href="javascript:void(0)"
                >
                  Next
                </a>
              </li>)}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}


const Index = () => {
  const history = useNavigate();
  const getid = useLocation().search;
  const getMenuID = new URLSearchParams(getid).get("id");
  var decryptId = CryptoJS.enc.Base64.parse(getMenuID);
  var menuID = CryptoJS.enc.Utf8.stringify(decryptId);

  const [data, setdata] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [iisLoading, setIisLoading] = useState(false);
  const [globallybonusErr, setGloballybonusErr] = useState("")
  const [globallybonusinputValue, setGloballybonusinputValue] = useState();

  var DataArray = [];

  const columns = [
    {
      Header: 'No',
      accessor: 'serial'
    },
    {
      Header: "Name",
      accessor: "fullname"
    },
    {
      Header: "Age",
      accessor: "age"
    },
    {
      Header: "Gender",
      accessor: "gender"
    },
    {
      Header: "Email",
      accessor: "email"
    },
    {
      Header: "Address",
      accessor: "address"
    },
    {
      Header: "Created Date",
      accessor: "createdAt"
    },
    {
      Header: "Actions",

      Cell: (row) => {

        var encyptId = CryptoJS.enc.Utf8.parse(row.cell.row.original._id); // WordArray object
        var recordIdEncrypt = CryptoJS.enc.Base64.stringify(encyptId);
        return (
          <div>
            <Link to={`/edit-profile?id=${getMenuID}&record_id=${recordIdEncrypt}&openTab=overview`} class="cstm-eye"><i class="fi fi-rr-eye" aria-hidden="true"></i></Link>
            <Link to={`/edit-profile?id=${getMenuID}&record_id=${recordIdEncrypt}&openTab=account-setting`} class="cstm-chekmank"><i class="fi-rr-pencil" aria-hidden="true"></i></Link>
            <Link to={`/edit-profile?id=${getMenuID}&record_id=${recordIdEncrypt}&openTab=account-setting`} className="cstm-cross mrn-rt"><i className="fi fi-rr-ban"></i>
            </Link>
          </div>
        );
      },
    },

  ];


  function createData(_id, fullname, age, gender, email, address, createdAt, action) {
    return {
      _id,
      fullname,
      age,
      gender,
      email,
      address,
      createdAt
    };
  }
  // No of Index 
  data.forEach((data_id, index) => { data_id.serial = index + 1; });

  useEffect(() => {
    const loggedInUser = localStorage.getItem("token");
    !loggedInUser && history("/");
    DoctorList()
  }, []);

  const DoctorList = () => {

    Services.getDoctors(menuID)
      .then((response) => {
        // return false
        if (response.data.status = true) {
          var arr = response.data.data;
          if (response.data.data.length > 0) {
            var newarr = [];
            for (var i = 0; i < arr.length; i++) {
              var date = moment(arr[i].createdAt).format('DD MMM, YYYY')
              var doctorDetail = arr[i].signup_otherdetail_id
              // if(arr[i].isSuspend === false){

              if (doctorDetail !== null) {

                var fullName = doctorDetail.surname && doctorDetail.first_name ?
                  <>
                    <img className="cstm-patientImg" src={doctorDetail.profileImg !== "" ? "https://anywherehealth.s3.amazonaws.com/" + doctorDetail.profileImg : plusImg} /> {doctorDetail.surname} {doctorDetail.first_name}
                  </> : <>{doctorDetail.surname} {doctorDetail.first_name}</>

                newarr[i] = createData(
                  arr[i]._id,
                  fullName,
                  doctorDetail.age,
                 <p style={{textTransform:"capitalize" , color:"black"}}>{doctorDetail.gender}</p>,
                  doctorDetail.email,
                  doctorDetail.address,
                  date,
                );
              }
              // }
            }
            newarr.map((data1) => {
              DataArray = [...DataArray, data1]
            })

            const sort = DataArray.sort().reverse()
            setdata(sort);
          }
        } else {
        }
      })
      .catch(function (err) {
      });
  }

  // globally bonus add

  const handleGloballyBonusOnChange = (e) => {
    var globallybonus = e.target.value
    if (!/^(?=.*?\d)?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/.test(globallybonus)) {
      setGloballybonusErr("Enter valid ammount")
    } else if (globallybonus === "") {
      setGloballybonusErr("Please enter amount")
    } else {
      setGloballybonusErr("")
    }
    setGloballybonusinputValue(globallybonus)
  }
  const handleOnSubmitGloballyBonusUpdate = (e) => {
    e.preventDefault()
    if (globallybonusinputValue === "") {
      setGloballybonusErr("Enter ammount")
    } else {
      setGloballybonusErr("")
    }
    if (globallybonusErr === "") {
      var bodyData = {
        "bonus": globallybonusinputValue
      }
      setIisLoading(true)
      Services.doctorgoballybonus(menuID, bodyData)
        .then((response) => {

          setIisLoading(false)
          if (response.data.status === true) {
            swal("Success", response.data.message, "success");
            document.querySelector("#AddBonus .btn-close").click();

            setGloballybonusinputValue(globallybonusinputValue)
          } else {
            setIisLoading(false)
            swal("Failed", response.data.message, "error");
          }
        })
        .catch((err) => {
          setIisLoading(false)
          swal("Failed", err.response.data.message, "error");
        });
    }
  }


  return (
    <>
      <main className="page-content bg-light">
        <Header />
        <div className="container-fluid">
          <div className="layout-specing">
            <div className="d-flex justify-content-between align-items-center mb-3">
              <h4 className="mb-0">Doctors</h4>
              <NavLinkHeader title1="Doctor List" />
            </div>
            
            <div className="cstm-abt">
            <button className="cstm-btn" data-bs-toggle="modal" data-bs-target="#AddBonus">Add Bonus To All Doctors</button>
            </div>
            {/* <button class="cstm-btn" >Add Bonus To All Doctors</button> */}

            <Table columns={columns} data={data} />
          </div>
        </div>

        <div class="modal fade cstm-add-bonus" id="AddBonus" tabindex="-1" aria-labelledby="exampleModalLabel1"
                aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header border-0 p-4">
                            <h4 class="modal-title" id="exampleModalLabel1">Add Bonus</h4>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body p-4 pt-0">
                            <form id="add-bonus" onSubmit={handleOnSubmitGloballyBonusUpdate}>
                                <div class="mb-4">
                                    <label class="cstm-label">For One Month</label>
                                    <div class="cstm-ab-icon">
                                        <span class="cstm-ab-icon-fill">₦</span>
                                        <input name="bonus" id="bonus" value={globallybonusinputValue} onChange={handleGloballyBonusOnChange} type="text" class="cstm-input" placeholder="00.00" />
                                        <p>Amount Ex. ₦10 , ₦10.50 , ₦100.50</p>
                                        {globallybonusErr !== "" && (
                                            <span style={{ color: "red" }}>{globallybonusErr}</span>
                                        )}
                                    </div>
                                </div>

                                <div class="d-flex justify-content-end">
                                    <button class=" cstm-btn">Add Bonus</button>
                                </div>
                            </form>

                        </div>
                    </div>
                </div>
            </div>
      </main>

    </>
  )
}
export default Index;
