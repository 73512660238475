import React, { useEffect, useState } from 'react';
import Header from '../Navbar/Header'
import SideBar from '../Navbar/SideBar'
import NavLinkHeader from '../Navbar/NavLinkHeader'
import Services from "../../Services/auth.service";
import swal from "sweetalert";
import loader from '../../assets/images/loder.gif'
import { useTable, usePagination, useGlobalFilter } from "react-table";
import { Link, useNavigate, useLocation } from 'react-router-dom';
import moment from "moment";
import deleteImg from "../../assets/images/delete.png";
import plusImg from "../../assets/images/plus.png";
import user from '../../assets/images/user.png'
//download pdf
import jsPDF from "jspdf";
import "jspdf-autotable";
const CryptoJS = require("crypto-js");


function Table({ columns, data }) {
    const getid = useLocation().search;
    const getMenuID = new URLSearchParams(getid).get("id");
    var decryptId = CryptoJS.enc.Base64.parse(getMenuID);
    var menuID = CryptoJS.enc.Utf8.stringify(decryptId);
    const [showLoader, setLoader] = useState(true);
    useEffect(() => {
        setTimeout(() => {
            setLoader(false);
        }, 2500)
    }, [])
    const props = useTable(
        {
            columns,
            data,
            initialState: { pageIndex: 0, pageSize: 20 }
        },
        useGlobalFilter, // useGlobalFilter!
        usePagination
    );
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        setGlobalFilter,
        state,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,


        state: { pageIndex, pageSize, globalFilter }
    } = props;

    let firstRecord = pageIndex * pageSize + 1;
    let lastRecord = firstRecord + pageSize - 1;

    //download data with pdf

    const exportPDF = (test) => {
        const unit = "pt";
        const size = "A4"; // Use A1, A2, A3 or A4
        const orientation = "portrait"; // portrait or landscape
        const marginLeft = 40;
        const doc = new jsPDF(orientation, unit, size);
        doc.setFontSize(15);
        const title = "Patient Details";
        const headers = [["No", "First name", "Gender", "Email", "Created Date"]];
        const data = page.map(elt => [elt.original.serial, elt.original.name, elt.original.gender.props.children, elt.original.email, elt.original.createdAt]);
        let content = {
            startY: 50,
            head: headers,
            body: data
        };
        doc.text(title, marginLeft, 40);
        doc.autoTable(content);
        doc.save("patient-details.pdf")
    }

    return (
        <>

            {/* ============== */}
            <div className="row">
                <div className="col-md-12">
                    <div className="card  rounded-md  border">
                        <div className="d-flex justify-content-between align-items-center p-3 border-bottom">
                            <div className="col-md-8">
                                <div className="row row ">
                                    <div className="col-md-5">
                                        <i className="fi fi-rr-search cstm-search-ro" />
                                        <input
                                            name="serch-adminuser"
                                            id="serch-adminuser"
                                            type="text"
                                            class="cstm-input-seacrh"
                                            value={globalFilter || ""}
                                            onChange={(e) => setGlobalFilter(e.target.value)}
                                            placeholder="Search patient"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="row row ">
                                    <div className="row justify-content-end">
                                        <div className="col-md-3">
                                            <div className="cstm-icon-download">
                                                <button className="cstm-btn5" onClick={() => exportPDF()}>
                                                    <i className="fi fi-rr-download" />
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12 col-lg-12">
                            <div className="table-responsive bg-white rounded cstm-ldr">
                                <table
                                    {...getTableProps()}
                                    className="table mb-0 table-center"
                                >
                                    <thead>
                                        {" "}
                                        {headerGroups.map((headerGroup) => (
                                            <tr {...headerGroup.getHeaderGroupProps()}>
                                                {headerGroup.headers.map((column) => (
                                                    <th className={`border-bottom cstm-userheading ${column.Header === "No" ? "w-3" : ''}${column.Header === "Topic Name" ? "w-15" : ''}${column.Header === "Description" ? "w-35" : ''}${column.Header === "Rating" ? "w-14" : ''}${column.Header === "Date" ? "w-14" : ''}${column.Header === "Actions" ? "actions3" : ''}`} {...column.getHeaderProps()}>
                                                        {column.render("Header")}
                                                        {/* Render the columns filter UI */}
                                                    </th>
                                                ))}
                                            </tr>
                                        ))}
                                    </thead>
                                    <tbody {...getTableBodyProps()}>
                                        {
                                            page.length > 0 && page.map((row, i) => {
                                                prepareRow(row);
                                                return (
                                                    <tr {...row.getRowProps()} className="cstm-Tabledesign">
                                                        {row.cells.map((cell) => {
                                                            return (
                                                                <td {...cell.getCellProps()}>
                                                                    {cell.render("Cell")}
                                                                </td>
                                                            );
                                                        })}
                                                    </tr>
                                                );
                                            })}
                                    </tbody>

                                </table>
                                {!showLoader ? page.length === 0 &&
                                    <div className="NoRecord-cstm">No record found!</div> : <div className="NoRecord-cstm"> <img className="pageloader" src={loader} /></div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row text-center">
                <div className="col-12 mt-4">
                    <div className="d-md-flex align-items-center text-center justify-content-between">
                        <span className="text-muted me-3">
                            {page.length !== 0 && `Showing ${firstRecord} -${" "}
                  ${lastRecord < rows.length ? lastRecord : rows.length} out of${" "}
                  ${rows.length}`}</span>
                        <ul className="pagination justify-content-center mb-0 mt-3 mt-sm-0">
                            {pageOptions.length !== 1 && page.length !== 0 && (<li class="page-item">
                                <a
                                    class="page-link"
                                    onClick={() => previousPage()}
                                    disabled={!canPreviousPage}
                                    href="javascript:void(0)"
                                >
                                    Prev
                                </a>
                            </li>)}
                            {

                                pageOptions.map(pgnumber => {
                                    return (
                                        pgnumber !== pageCount && pageCount !== 1 && page.length !== 0 &&
                                        <li className={`page-item ${pageIndex == pgnumber ? 'active' : ''}`} ><a className="page-link" href="javascript:void(0)" onClick={() => gotoPage(pgnumber)} > {pgnumber + 1} </a></li>)

                                })
                            }
                            {pageOptions.length !== 1 && page.length !== 0 && (<li class="page-item">
                                <a
                                    class="page-link"
                                    onClick={() => nextPage()}
                                    disabled={!canNextPage}
                                    href="javascript:void(0)"
                                >
                                    Next
                                </a>
                            </li>)}
                        </ul>
                    </div>
                </div>
            </div>
        </>
    );
}


const Patients = () => {
    const history = useNavigate();
    const getid = useLocation().search;
    const getMenuID = new URLSearchParams(getid).get("id");
    var decryptId = CryptoJS.enc.Base64.parse(getMenuID);
    var menuID = CryptoJS.enc.Utf8.stringify(decryptId);

    const [selectedId, setselectedId] = useState();
    const [data, setdata] = useState([]);
    var DataArray = [];

    const columns = [

        {
            Header: 'No',
            accessor: 'serial'
        },
        {
            Header: "Name",
            accessor: "first_name"
        },
        {
            Header: "Gender",
            accessor: "gender"
        },
        {
            Header: "Email",
            accessor: "email"
        },
        {
            Header:"Subscription Name",
            accessor:"subscriptionplans_details"
        },
        {
            Header: "Phone",
            accessor: "phone_number"
        },
        {
            Header:"Type",
            accessor:"is_ussd"
        },
        {
            Header: "Created Date",
            accessor: "createdAt"
        },
        {
            Header: "Actions",

            Cell: (row) => {

                var encyptId = CryptoJS.enc.Utf8.parse(row.cell.row.original._id); // WordArray object
                var recordIdEncrypt = CryptoJS.enc.Base64.stringify(encyptId);
                return (
                    <div>

                        <Link to="" onClick={() => setselectedId(row.cell.row.original._id)} className="cstm-cross mrn-rt" data-bs-toggle="modal" data-bs-target="#cancelappointment">
                            <i className="fi fi-rr-trash"></i>
                        </Link>
                    </div>
                );
            },
        },
    ];


    function createData(_id, first_name, gender, email,subscriptionplans_details, phone_number, is_ussd,createdAt, name, action) {
        return {
            _id,
            first_name,
            gender,
            email,
            subscriptionplans_details,
            phone_number,
            is_ussd,
            createdAt,
            name
        };
    }
    // No of Index 
    data.forEach((data_id, index) => { data_id.serial = index + 1; });

    useEffect(() => {
        const loggedInUser = localStorage.getItem("token");
        !loggedInUser && history("/");
        PatientData()
    }, []);

    const PatientData = () => {

        Services.getPatientData(menuID)
            .then((response) => {
                // return false
                if (response.data.status = true) {
                    var arr = response.data.data;
                    var subscription;
                     var ussdregostration;
                    if (response.data.data.length > 0) {
                        var newarr = [];
                        for (var i = 0; i < arr.length; i++) {
                            if (arr[i].subscriptionplans_details.length > 0){
                                subscription = arr[i].subscriptionplans_details[0].name;
                            }
                            else{
                                subscription ="-";
                            }
                        
                            if (arr[i].user_details.length > 0) {
                                if (arr[i].user_details[0] !== " ") {
                                   
                                ussdregostration = arr[i].user_details[0]?.is_ussd || '-'  


                                }
                                // else{
                                //     ussdregostration ="-";
                                // }
                                
                                var date = moment(arr[i].user_details[0].createdAt).format('DD MMM, YYYY');
                                var nameWithImage = arr[i].user_details[0].first_name ?
                                    <>
                                        <img className="cstm-patientImg" src={arr[i].user_details[0].profileImg ? "https://anywherehealth.s3.amazonaws.com/" + arr[i].user_details[0].profileImg : plusImg} /> {arr[i].user_details[0].first_name}
                                    </>
                                    :
                                    arr[i].user_details[0].first_name


                                newarr[i] = createData(
                                    arr[i]._id,
                                    nameWithImage,
                                    <p style={{textTransform:"capitalize" , color:"black"}}>{ arr[i].user_details[0].gender}</p>,
                                    arr[i].user_details[0].email,
                                    subscription,
                                    arr[i].user_details[0].phone_number,
                                   <p style={{textTransform:'capitalize',color:"black"}}>{ussdregostration}</p> ,
                                    date,
                                    arr[i].user_details[0].first_name,
                                    );
                                }
                        }
                        newarr.map((data1) => {
                            DataArray = [...DataArray, data1]
                        })
                        const sort = DataArray.sort().reverse()
                        setdata(sort);
                    }
                } else {
                }
            })
            .catch(function (err) {
            });

    }


    const handleDelete = () => {
        var bodydata = {
            "confirm": true,
            "patient_id": selectedId
        }

        Services.deletePatient(menuID, bodydata)
            .then((response) => {
                if (response.data.status === true) {
                    swal("Success", response.data.message, "success");
                    document.querySelector("#cancelappointment .btn-close").click();
                    PatientData()


                } else {
                    swal("Failed", response.data.message, "error");
                }
            })
            .catch(function (err) {
                swal("Failed", err.response.data.message, "error");
            });
    };

    return (
        <>
            <main className="page-content bg-light">
                <Header />
                <div className="container-fluid">
                    <div className="layout-specing">
                        <div className="d-flex justify-content-between align-items-center mb-3">
                            <h4 className="mb-0">Patients</h4>
                            <NavLinkHeader title1="Patients" />
                        </div>
                        <Table columns={columns} data={data} />
                    </div>
                </div>

                {/* delete modal */}
                <div class="modal fade cstm-add-bonus" id="cancelappointment" tabindex="-1" aria-labelledby="exampleModalLabel1"
                    aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered">
                        <div class="modal-content">
                            <div class="modal-header border-0 p-4">
                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div class="modal-body p-4 pt-0">
                                <div class="mb-4">

                                    <div class="text-center mb-4">
                                        <img src={deleteImg} />
                                    </div>
                                    <h4 class="text-center mb-4">Are you sure want to delete this Patient?</h4>
                                    <div class="text-center">
                                        <button class="cstm-btn7" onClick={handleDelete}>Delete Patient</button>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    )
}
export default Patients;
