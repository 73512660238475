import React, { useState, useEffect, useRef } from "react";
import Header from "../Navbar/Header";
import NavLinkHeader from "../Navbar/NavLinkHeader";
import Services from "../../Services/auth.service";
import swal from "sweetalert";
import $ from "jquery";
import loader from "../../assets/images/loder.gif";
import { Link, useNavigate, useLocation } from "react-router-dom";
import pn from "../../assets/images/pn.png";
import ms from "../../assets/images/ms.png";
import photo from "../../assets/images/photo.png";
import plusImg from "../../assets/images/plus.png";
import deleteImg from "../../assets/images/delete.png";
import pdfImage from '../../assets/images/download.png'
import Star from "./Star";

import moment from "moment";
import { countrycode } from "../Comman/countrycode";
import docxImage from "../../assets/images/docs.png";

const CryptoJS = require("crypto-js");

const EditProfile = () => {
    const history = useNavigate();
    const getid = useLocation().search;
    const getMenuID = new URLSearchParams(getid).get("id");
    var decryptId = CryptoJS.enc.Base64.parse(getMenuID);
    var menuID = CryptoJS.enc.Utf8.stringify(decryptId);
    const OpenTabKey = new URLSearchParams(getid).get("openTab");

    //decrypt recordid
    const gatRecordID = new URLSearchParams(getid).get("record_id");
    var decryptRecordId = CryptoJS.enc.Base64.parse(gatRecordID);
    var recordID = CryptoJS.enc.Utf8.stringify(decryptRecordId);

    const [hideimagePrev, setHideimagePrev] = useState();
    const [imagePrev, setImagePrev] = useState();
    const [reviewData, setReviewData] = useState([]);
    const [totalReview, setTotalReview] = useState();
    const [averageRating, setAverageRating] = useState();
    const [errMessage, setErrMessage] = useState("");
    const [visitPatient, setvisitPatient] = useState("");
    const [totralEarning, settotralEarning] = useState("");


    const [doctorProfile, setDoctorProfile] = useState({});
    const [doctorAllData, setDoctorAllData] = useState({});
    const [specializationCategory, setSpecializationCategory] = useState([]);
    const [documentsData, setDocumentsData] = useState([]);
    var DocumentExtension;

    const [doctorImage, setDoctorImage] = useState();
    const [country, setCountry] = useState([{}]);
    const [specialization, setSpecialization] = useState([{}]);
    const [physicianCare, setPhysicianCare] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingSuspend, setIsLoadingSuspend] = useState(false);
    const [imagepath, setImagepath] = useState("")

    //bonus
    const [bonusAmount, setBonusAmount] = useState("");
    const [bonusErr, setBonusErr] = useState("")

    //suspend account
    const [suspendField, setSuspendField] = useState({
        subject: "",
        reason: ""
    })
    const [suspendErr, setSuspendErr] = useState({
        subject: "",
        reason: ""
    })

    const [formFields, setFormFields] = useState({

        first_name: "",
        surname: "",
        email: "",
        password: "",
        Cpassword: "",
        country_code: "",
        phone_number: "",
        age: "",
        gender: "",
        address: "",
        country: "",
        primary_care_physician: "",
        profileImg: "",
        specialization_ids: "",
        total_file: "",
        aboutUs: "",
    });

    const [err, setErr] = useState({
        first_name: "",
        surname: "",
        email: "",
        password: "",
        Cpassword: "",
        country_code: "",
        phone_number: "",
        age: "",
        gender: "",
        address: "",
        country: "",
        primary_care_physician: "",
        profileImg: "",
        specialization_ids: "",
        total_file: ""
    });


    useEffect(() => {
        const loggedInUser = localStorage.getItem("token");
        !loggedInUser && history("/");
        getDoctorProfileData();
        getCountryData();
        getSpecializationData();
        getDoctorReviewData();
    }, []);

    const getDoctorReviewData = () => {

        Services.getDoctorReviewListById(menuID, recordID).then((response) => {

            if (response.data.status === true) {
                var Review = response.data.data;
                setReviewData(Review.result);
                setTotalReview(Review.review_count);
                setAverageRating(Review.average[0].average);
                setErrMessage("")
            } else {
                setErrMessage(response.data.message)

            }
        })
            .catch((err) => {
            })
    }
    const getDoctorProfileData = () => {
        Services.getDoctorById(menuID, recordID).then((response) => {
            if (response.data.status === true) {
                setFormFields(response.data.data.signup_otherdetail_id)
                setBonusAmount(response.data.data.bonus ? response.data.data.bonus : "")
                setImagePrev(response.data.data.signup_otherdetail_id.profileImg ? response.data.data.signup_otherdetail_id.profileImg : "");
                setvisitPatient(response.data.data.total_patients)
                settotralEarning(response.data.data.total_earning)
                setPhysicianCare(response.data.data.signup_otherdetail_id.primary_care_physician)
                setDoctorProfile(response.data.data.signup_otherdetail_id)
                setDoctorAllData(response.data.data)
                var SpecializedCategory = response.data.data.specialization_ids
                setSpecializationCategory(SpecializedCategory)
                if (response.data.data.documents_id.length > 0) {

                    setDocumentsData(response.data.data.documents_id)
                }
            }

        }).catch((err) => {
        })
    }
    const getCountryData = () => {
        Services.getCountry(menuID).then((response) => {
            if (response.data.status === true) {
                if (response.data.data.length > 0) {

                    setCountry(response.data.data)
                }
            }

        }).catch((err) => {
        })
    }
    const getSpecializationData = () => {
        Services.getSpecialization(menuID).then((response) => {
            if (response.data.status === true) {
                if (response.data.data.length > 0) {

                    setSpecialization(response.data.data)
                }
            }

        }).catch((err) => {
        })
    }



    const imageUpload = (e) => {
        e.preventDefault();
        setHideimagePrev(true)

        err.profileImg = e.target.value.length > 0 ? "" : "Please select image";
        setImagePrev(URL.createObjectURL(e.target.files[0]));
        let doctorImage = e.target.files[0];
        setDoctorImage(doctorImage);
        setErr({ ...err });
    };

    const handleCheck = (e) => {
        if (e.target.checked === true) {
            setPhysicianCare(true)
            err.primary_care_physician = ""
        } else {
            setPhysicianCare(false)
            err.primary_care_physician = "check require"
        }
    }

    //suspend

    const handleSuspendOnChange = (e) => {
        const { name, value } = e.target;

        switch (name) {
            case "subject":
                suspendErr.subject = value.length > 0 ? "" : "Enter subject";
                break;
            case "reason":
                suspendErr.reason = value.length > 0 ? "" : "Enter reason";
                break;
            default:
                break;
        }

        setSuspendField({ ...suspendField, [name]: value });
        setSuspendErr({ ...suspendErr });
    }

    const handleSuspendSubmit = (e) => {

        e.preventDefault();

        if (suspendField.subject === "" || suspendField.subject === undefined) {
            suspendErr.subject = "Enter subject";
        } else {
            suspendErr.subject = "";
        }
        if (suspendField.reason === "" || suspendField.reason === undefined) {
            suspendErr.reason = "Enter reason";
        } else {
            suspendErr.reason = "";
        }
        setSuspendErr({ ...suspendErr });

        if (suspendErr.subject === "" && suspendErr.reason === "") {

            var bodyData = {
                "doctor_id": recordID,
                "account_suspend_subject": suspendField.subject,
                "account_suspend_reasons": suspendField.reason
            }


            // return false;
            setIsLoadingSuspend(true)

            Services.suspendDoctor(menuID, bodyData).then((response) => {
                setIsLoadingSuspend(false)

                if (response.data.status === true) {
                    swal("Success", response.data.message, "success");
                    history(`/suspended-doctors?id=${getMenuID}`);
                } else {
                    setIsLoadingSuspend(false)

                    swal("Failed", response.data.message, "error");
                }
            }).catch((err) => {
                setIsLoadingSuspend(false)

                swal("Failed", err.response.data.message, "error");
            })
        }
    }

    const handleOnchange = (e) => {
        const { value, name } = e.target;

        switch (name) {
            case "first_name":
                err.first_name = value.length > 0 ? "" : "Enter your first name";
                break;
            case "surname":
                err.surname = value.length > 0 ? "" : "Enter your surname";
                break;
            case "email":
                err.email = value.length > 0 ? "" : "Enter your email";

                err.email = !new RegExp(
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                ).test(value)
                    ? "Enter a valid email address"
                    : "";
                break;
            case "country_code":
                err.country_code = value.length > 0 ? "" : "Select country code";
                break;
            case "country":
                err.country = value.length > 0 ? "" : "Select country";
                break;

            case "gender":
                err.gender = value.length > 0 ? "" : "Select gender";
                break;

            case "address":
                err.address = value.length > 0 ? "" : "Enter your address";
                break;
            case "specialization_ids":
                err.specialization_ids = value.length > 0 ? "" : "Select specialization";
                break;
            case "phone_number":
                if (value.length !== 10) {
                    // we will set the error state
                    err.phone_number = "phone atleast have 10 number";
                } else if (!/^[0-9\b]+$/.test(value)) {
                    err.phone_number = "Enter only number";
                } else if (value.length === "") {
                    err.phone_number = "Please enter a number";
                } else {
                    err.phone_number = "";
                }
                break;
            case "age":
                if (value <= 20) {
                    err.age = "Age should be greater than 20";
                }
                else if (!/^[0-9\b]+$/.test(value)) {
                    err.age = "Enter only number";
                } else if (value.length === "") {
                    err.age = "Please enter age";
                } else {
                    err.age = "";
                }
                break;

            default:
                break;
        }

        setFormFields({ ...formFields, [name]: value });
        setErr({ ...err });

    };

    const handleOnSubmit = (e) => {
        e.preventDefault();

        if (formFields.first_name === "") {
            err.first_name = "Enter your first name";
        } else {
            err.first_name = "";
        }
        if (formFields.surname === "") {
            err.surname = "Enter your surname";
        } else {
            err.surname = "";
        }
        if (formFields.gender === "") {
            err.gender = "Select gender";
        } else {
            err.gender = "";
        }

        if (formFields.email === "") {
            err.email = "Enter email";
        } else {
            err.email = "";
        }
        if (formFields.country_code === "") {
            err.country_code = "Select country code";
        } else {
            err.country_code = "";
        }
        if (formFields.country === "") {
            err.country = "Select country";
        } else {
            err.country = "";
        }
        if (formFields.specialization_ids === "") {
            err.specialization_ids = "Select specialization";
        } else {
            err.specialization_ids = "";
        }
        if (formFields.phone_number === "") {
            err.phone_number = "Enter phone number";
        } else {
            err.phone_number = "";
        }
        if (formFields.age === "") {
            err.age = "Enter age";
        } else {
            err.age = "";
        }
        if (formFields.address === "") {
            err.address = "Write address";
        } else {
            err.address = "";
        }
        if (doctorImage === "") {
            err.profileImg = "Select doctor image";
        } else {
            err.profileImg = "";
        }

        if (physicianCare === true) {
            err.primary_care_physician = ""
        } else {
            err.primary_care_physician = "Primary care of physician is required"
        }
        setErr({ ...err });

        if (
            err.first_name === "" &&
            err.surname === "" &&
            err.email === "" &&
            err.country_code === "" &&
            err.phone_number === "" &&
            err.age === "" &&
            err.gender === "" &&
            err.country === "" &&
            err.address === "" &&
            err.primary_care_physician === "" &&
            err.profileImg === "" &&
            err.specialization_ids === ""
        ) {
            let formData = new FormData();
            // return false

            formData.append("first_name", formFields.first_name);
            formData.append("surname", formFields.surname);
            formData.append("email", formFields.email);
            formData.append("country_code", formFields.country_code);
            formData.append("phone_number", formFields.phone_number);
            formData.append("age", formFields.age);
            formData.append("gender", formFields.gender);
            formData.append("address", formFields.address);
            formData.append("country", formFields.country);
            formData.append("primary_care_physician", physicianCare);
            formData.append("user_role", "doctor");
            formData.append("profileImg", doctorImage ? doctorImage : imagePrev);
            formData.append("specialization_ids", formFields.specialization_ids);
            formData.append("total_file", "2");
            formData.append("62e365797a037bd1d24e74eb", doctorImage);
            formData.append("id", recordID);
            formData.append("aboutUs", formFields.aboutUs);
            
            // formData.append("62e36364592ecfb0bbbc05d9", formFields.specialization_ids);
            // formData.append("62e3636e7e22e665f479e1fc", formFields.specialization_ids);

            var bodyData = {
                "first_name": formFields.first_name,
                "surname": formFields.surname,
                "email": formFields.email,
                "country_code": formFields.country_code,
                "phone_number": formFields.phone_number,
                "age": formFields.age,
                "gender": formFields.gender,
                "address": formFields.address,
                "country": formFields.country,
                "primary_care_physician": physicianCare,
                "user_role": "doctor",
                "profileImg": doctorImage ? doctorImage : imagePrev,
                "specialization_ids": formFields.specialization_ids,
                "aboutUs": formFields.aboutUs
            }
            // return false
            setIsLoading(true)
            Services.editDoctor(menuID, formData)
                .then((response) => {
                    setIsLoading(false)
                    if (response.data.status === true) {
                        swal("Success", response.data.message, "success");
                        history(`/doctors?id=${getMenuID}`);
                    } else {
                        setIsLoading(false)

                        swal("Failed", response.data.message, "error");
                    }
                })
                .catch((err) => {
                    setIsLoading(false)
                    swal("Failed", err.response.data.message, "error");
                });
        }
    };

    const handleBonusOnChange = (e) => {
        var bonus = e.target.value

        if (!/^(?=.*?\d)?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/.test(bonus)) {
            setBonusErr("Enter valid ammount")
        } else if (bonus === "") {
            setBonusErr("Please enter a bonus amount")
        } else {
            setBonusErr("")
            setBonusAmount(bonus)
        }

    }
    const handleBonusSubmit = (e) => {
        e.preventDefault()
        if (bonusAmount === "") {
            setBonusErr("Enter bonus ammount")
        } else {
            setBonusErr("")
        }
        if (bonusErr === "") {

            var bodyData = {
                "doctor_id": recordID,
                "bonus": bonusAmount
            }
            // return false
            Services.addEditBonus(menuID, bodyData)
                .then((response) => {
                    if (response.data.status === true) {
                        swal("Success", response.data.message, "success");
                        document.querySelector("#AddBonus .btn-close").click();
                        setBonusAmount("");
                    } else {
                        swal("Failed", response.data.message, "error");
                    }
                })
                .catch((err) => {
                    swal("Failed", err.response.data.message, "error");
                });
        }
    }

    const onCloseButton = () => {
        setBonusAmount("")
    }


    return (
        <main className="page-content">
            <Header title="Doctor Profile" main_title="Doctor" />

            <div className="container-fluid">
                <div className="layout-specing">
                    <div className="d-flex justify-content-between align-items-center mb-3">
                        <h4 className="mb-0">Doctor Profile</h4>
                        <NavLinkHeader
                            title1="Doctor List"
                            title1_link={`/doctors?id=${getMenuID}`}
                            title2="Doctor Profile"
                        />
                    </div>
                    <div className="cstm-doctor-profile  rounded-md  border">
                        <div className="cstm-doctor-profile-left">
                            <div className="">
                                <div className="mb-3  mt-5 text-center">
                                    {/* <img className="csatm-od-photo" src={doctorProfile.profileImg ? "https://anywherehealth.s3.amazonaws.com/" + doctorProfile.profileImg : photo} /> */}
                                    {imagePrev && !hideimagePrev ? (
                                        <img
                                            className="csatm-od-photo"
                                            width="200"
                                            height="200"
                                            src={"https://anywherehealth.s3.amazonaws.com/" + imagePrev}
                                        />
                                    ) : (
                                        <img src={imagePrev ? imagePrev : plusImg} className="csatm-od-photo" />
                                    )}
                                </div>
                                <h6 className="mb-1 text-center">{doctorProfile.surname && doctorProfile.surname} {doctorProfile.first_name && doctorProfile.first_name}</h6>
                                <p className="mb-4 text-center">{doctorProfile.address && doctorProfile.address}</p>

                                <div className="d-flex text-center p-3  cstm-vp-te">
                                    <div className="col-md-6">
                                        <h4 className="mb-0">{visitPatient}</h4>
                                        <p className="mb-0">Visited Patients</p>
                                    </div>
                                    <div className="col-md-6">
                                        <h4 className="mb-0">₦{totralEarning}</h4>
                                        <p className="mb-0">Total Earning</p>
                                    </div>
                                </div>

                            </div>
                            {/* <div className="p-3">
                                <h6 className="mb-2">About Doctor</h6>
                                <div className="title2">{formFields.about}</div>
                            </div> */}
                            <div className="p-3 pt-1">
                                <h6 className="mb-3">Specialized Categories</h6>
                                <div>
                                    {specializationCategory.length > 0 &&
                                        specializationCategory.map((item, index) => (
                                            <span className="cstm-sc-label" key={index}>{item.specialization}</span>
                                        ))
                                    }
                                </div>
                            </div>


                            <div className="p-3 pt-1 pb-1">
                                <h6>Contact Info</h6>
                            </div>


                            <div className="p-3 pt-1 cstm-ci-box">
                                <img src={pn} className="cstm-cl-img" />
                                <div className="cstm-cl-mn">
                                    <span className="cstm-cl-mn-ttl">Phone:</span>
                                    <span className="cstm-cl-mn-sub">{doctorProfile.phone_number}</span>
                                </div>
                            </div>

                            <div className="p-3 pt-1   cstm-ci-box">
                                <img src={ms} className="cstm-cl-img" />
                                <div className="cstm-cl-mn">
                                    <span className="cstm-cl-mn-ttl">Email address:</span>
                                    <span className="cstm-cl-mn-sub">{doctorProfile.email}</span>
                                </div>
                            </div>
                        </div>
                        <div className="cstm-doctor-profile-right">


                            <div className="cstm-tabs-pf">
                                <button className="cstm-btn8 cstm-add-bonus-btn" data-bs-toggle="modal" data-bs-target="#AddBonus">Add Bonus</button>

                                <ul className="nav" id="pills-tab" role="tablist">
                                    <li className="nav-item">
                                        <a className={OpenTabKey === 'overview' ? 'nav-link active' : 'nav-link'} id="overview-tab" data-bs-toggle="pill" href="#pills-experience" role="tab" aria-controls="pills-experience" aria-selected="false">
                                            Overview
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a className={OpenTabKey !== 'account-setting' && OpenTabKey !== 'overview' ? 'nav-link active' : 'nav-link'} id="overview-tab1" data-bs-toggle="pill" href="#pills-overview" role="tab" aria-controls="pills-overview" aria-selected="false">
                                            Review
                                        </a>
                                    </li>

                                    <li className="nav-item">
                                        <a className={OpenTabKey === 'account-setting' && OpenTabKey !== 'overview' ? 'nav-link active' : 'nav-link'} id="experience-tab" data-bs-toggle="pill" href="#AccountSetting" role="tab" aria-controls="pills-experience" aria-selected="false">
                                            Account Setting
                                        </a>
                                    </li>
                                </ul>

                                <div className="tab-content mt-2" id="pills-tabContent">

                                    <div className={OpenTabKey !== 'account-setting' ? 'tab-pane fade show active' : 'tab-pane fade'} id="pills-experience" role="tabpanel"
                                        aria-labelledby="overview-tab">
                                        <div className="py-2 px-3">

                                            <h5 className="mb-3">Submitted Documents</h5>

                                            <div className="cstm-sd-main">
                                                {
                                                    documentsData ? documentsData.map((doc, index, verify_status) => (

                                                        // DocumentExtension = doc.files[0].split(".").pop(),
                                                        <>
                                                            {
                                                                doc.files.map((item) => (
                                                                    DocumentExtension = item.split(".").pop(),
                                                                    <div class="cstm-sd-main-block d-flex">
                                                                        <div className="cstm-icon-download2">
                                                                            {/* <button className="cstm-btn5" onClick={() => downlodeImg(index)} > */}
                                                                            <a href={"https://anywherehealth.s3.amazonaws.com/" + item} download >
                                                                                <i className="fi fi-rr-download" />
                                                                            </a>
                                                                            {/* </button> */}
                                                                        </div>
                                                                        <div className="documentdis">
                                                                            {item !== "" && DocumentExtension === "pdf" && <embed src={pdfImage} height="100%" width="100%" />}

                                                                            <div class="cstm-sd-mb-img"><img className="cstm-documentImg" src={item !== "" && (DocumentExtension === "jpg" || DocumentExtension === "png" || DocumentExtension === "jpeg") ? "https://anywherehealth.s3.amazonaws.com/" + item : DocumentExtension === "docx" && docxImage || DocumentExtension === "doc" && docxImage} /></div>
                                                                        </div>
                                                                        <h6 class="mt-3 mb-0 titledoc">{doc.document_category_id.category}</h6>
                                                                    </div>
                                                                ))
                                                            }
                                                        </>
                                                    )) : ""
                                                }

                                            </div>

                                            <div className="pb-3">
                                                <h6 className="mb-2">Experiance</h6>
                                                <div className="title2">{doctorAllData.experience ? `Total ${doctorAllData.experience}` : `Total 0 year`}</div>

                                            </div>

                                            <div className="pb-3">
                                                <h6 className="mb-2">Joining Date</h6>
                                                <div className="title2">{moment(doctorAllData.createdAt).format('DD MMM, YYYY')}</div>

                                            </div>

                                            {/* <div className="pb-0">
                                                <h6 className="mb-2">Time Table</h6>
                                            </div> */}

                                            {/* <div className="table-responsive bg-white rounded">
                                                <table className="table mb-0 table-center">
                                                    <tr>
                                                        <th className="border-bottom border-top w-27">Days</th>
                                                        <th className="border-bottom border-top w-24">Morning</th>
                                                        <th className="border-bottom border-top w-25">Afternoon</th>
                                                        <th className="border-bottom border-top w-24">Evening</th>
                                                    </tr>
                                                    <tr>
                                                        <td>Monday</td>
                                                        <td>9:00 AM - 11:30 AM</td>
                                                        <td>3:00 PM - 6:00 PM</td>
                                                        <td>8:00 PM - 11:00PM</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Tuesday</td>
                                                        <td>9:00 AM - 11:30 AM</td>
                                                        <td>3:00 PM - 6:00 PM</td>
                                                        <td>8:00 PM - 11:00PM</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Wednesday</td>
                                                        <td>9:00 AM - 11:30 AM</td>
                                                        <td>3:00 PM - 6:00 PM</td>
                                                        <td>8:00 PM - 11:00PM</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Thrusday</td>
                                                        <td>9:00 AM - 11:30 AM</td>
                                                        <td>3:00 PM - 6:00 PM</td>
                                                        <td>8:00 PM - 11:00PM</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Friday</td>
                                                        <td>9:00 AM - 11:30 AM</td>
                                                        <td>3:00 PM - 6:00 PM</td>
                                                        <td>8:00 PM - 11:00PM</td>
                                                    </tr>
                                                    <tr className="cstm-tb-red">
                                                        <td>Saturday</td>
                                                        <td>9:00 AM - 11:30 AM</td>
                                                        <td>3:00 PM - 6:00 PM</td>
                                                        <td>-</td>
                                                    </tr>
                                                    <tr className="cstm-tb-red">
                                                        <td>Sunday</td>
                                                        <td>9:00 AM - 11:30 AM</td>
                                                        <td>3:00 PM - 6:00 PM</td>
                                                        <td>-</td>
                                                    </tr>
                                                </table>
                                            </div> */}
                                        </div>

                                    </div>



                                    <div className={(OpenTabKey !== 'account-setting' && OpenTabKey !== 'overview') ? 'tab-pane fade show active' : 'tab-pane fade'} id="pills-overview" role="tabpanel" aria-labelledby="overview-tab1">
                                        <div className="py-2 px-3">
                                            <h5 className="mb-2">Reviews</h5>

                                            <div className="cstm-pts-rve">
                                                <div className="cstm-pts-rve-star">
                                                    <ul className="list-unstyled mb-0">
                                                        {/* <li className="list-inline-item"><i className="mdi mdi-star text-warning"></i></li>
                                                        <li className="list-inline-item"><i className="mdi mdi-star text-warning"></i></li>
                                                        <li className="list-inline-item"><i className="mdi mdi-star text-warning"></i></li>
                                                    <li className="list-inline-item"><i className="mdi  mdi-star-half text-warning"></i></li> */}

                                                        <li className="list-inline-item">
                                                            <Star stars={averageRating} />
                                                        </li>


                                                    </ul>
                                                </div>
                                                <div className="cstm-pts-rve-star-nm">({averageRating ? averageRating : 0})</div>
                                                <div className="cstm-pts-rvepr">-  {totalReview ? totalReview : 0} Review</div>
                                            </div>
                                            {errMessage !== "" && <p className="error-msg">{errMessage}</p>}


                                            {
                                                reviewData.length > 0 &&
                                                reviewData.map((review, index) => (
                                                    <>

                                                        <div className="cstm-reviews-in-main" key={index}>
                                                            <div className="cstm-reviews-in-main-left"><img src={review.reviewBy.signup_otherdetail_id.profileImg ? "https://anywherehealth.s3.amazonaws.com/" + review.reviewBy.signup_otherdetail_id.profileImg : plusImg} className="cstm-rv-user-photo" /></div>
                                                            <div className="cstm-reviews-in-main-right">
                                                                <div className="cstm-reviews-in-main-right-title">{review.reviewBy.signup_otherdetail_id.first_name ? review.reviewBy.signup_otherdetail_id.first_name : ""} {review.reviewBy.signup_otherdetail_id.surname ? review.reviewBy.signup_otherdetail_id.surname : ""}</div>

                                                                <div className="cstm-pts-rve">
                                                                    <div className="cstm-pts-rve-star">
                                                                        {/* <ul className="list-unstyled mb-0">
                                                                            <li className="list-inline-item"><i className="mdi mdi-star text-warning"></i></li>
                                                                            <li className="list-inline-item"><i className="mdi mdi-star text-warning"></i></li>
                                                                            <li className="list-inline-item"><i className="mdi mdi-star text-warning"></i></li>
                                                                            <li className="list-inline-item"><i className="mdi mdi-star text-warning"></i></li>
                                                                            <li className="list-inline-item"><i className="mdi mdi-star text-warning"></i></li>
                                                                        </ul> */}
                                                                        <Star stars={review.rating} />

                                                                    </div>
                                                                    <div className="cstm-pts-rve-star-nm">({review.rating ? review.rating : 0})</div>
                                                                </div>

                                                                <div className="cstm-reviews-in-main-right-cont">{review.comment ? review.comment : ""}</div>
                                                            </div>
                                                        </div>
                                                    </>
                                                ))}
                                        </div>
                                    </div>



                                    <div className={OpenTabKey === 'account-setting' ? 'tab-pane fade show active' : 'tab-pane fade'} id="AccountSetting" role="tabpanel" aria-labelledby="experience-tab">

                                        <div className="py-2 px-3">
                                            <h5 className="mb-3">Account Setting</h5>
                                            <form
                                                name="addDoctors"
                                                id="addDoctors"
                                                onSubmit={handleOnSubmit}
                                            >
                                                <div className="card  border rounded-md rounded p-4">
                                                    <div className="mb-4 cstm-profile-img d-flex  align-items-center">

                                                        <label className="cstm-upload-choose">
                                                            <div className="mr-5">
                                                                {imagePrev && !hideimagePrev ? (
                                                                    <img
                                                                        className="profile-upl-img"
                                                                        width="200"
                                                                        height="200"
                                                                        src={"https://anywherehealth.s3.amazonaws.com/" + imagePrev}
                                                                    />
                                                                ) : (
                                                                    <img src={imagePrev ? imagePrev : plusImg} className="profile-upl-img" />
                                                                )}
                                                            </div>
                                                            <div>
                                                                <input
                                                                    type="file"
                                                                    name="profile"
                                                                    onChange={imageUpload}
                                                                />
                                                                <h4>Upload Picture</h4>
                                                                <span className="cstm-for-best">
                                                                    For best results, use an image at least 600px by 600px in either .jpg or .png format
                                                                </span>
                                                            </div>
                                                        </label>

                                                    </div>
                                                    {err.profileImg !== "" && (
                                                        <span style={{ color: "red" }}>{err.profileImg}</span>
                                                    )}

                                                    <div className="row">
                                                        <div className="col-lg-6">
                                                            <div className="mb-4">
                                                                <label className="cstm-label">First  name</label>
                                                                <input type="text" value={formFields.first_name} className="cstm-input" onChange={handleOnchange} placeholder="Enter first name" name="first_name"
                                                                    required="" />
                                                                {err.first_name !== "" && (
                                                                    <span style={{ color: "red" }}>{err.first_name}</span>
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6">
                                                            <div className="mb-4">
                                                                <label className="cstm-label">Surname</label>
                                                                <input type="text" value={formFields.surname} className="cstm-input" onChange={handleOnchange} placeholder="Enter surname" name="surname"
                                                                    required="" />
                                                                {err.surname !== "" && (
                                                                    <span style={{ color: "red" }}>{err.surname}</span>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="row">
                                                        <div className="col-lg-6">
                                                            <div className="mb-4">
                                                                <label className="cstm-label">Email</label>
                                                                <input
                                                                    type="email"
                                                                    className="cstm-input"
                                                                    placeholder="Enter email"
                                                                    name="email"
                                                                    onChange={handleOnchange}
                                                                    value={formFields.email}
                                                                    disabled
                                                                    required=""
                                                                />
                                                                {err.email !== "" && (
                                                                    <span style={{ color: "red" }}>{err.email}</span>
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6">
                                                            <div className="mb-4">
                                                                <label className="cstm-label">Country</label>
                                                                <select className="cstm-select" value={formFields.country} name="country" onChange={handleOnchange}>
                                                                    {country.map(item => (
                                                                        <option key={item._id} value={item._id}>{item.country}</option>
                                                                    ))}
                                                                </select>
                                                                {err.country !== "" && (
                                                                    <span style={{ color: "red" }}>{err.country}</span>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>



                                                    <div className="row">
                                                        <div className="col-lg-6">
                                                            <div className="mb-4">
                                                                <label className="cstm-label">Gender</label>
                                                                <select className="cstm-select" value={formFields.gender} name="gender" onChange={handleOnchange}>
                                                                    <option value="male">Male</option>
                                                                    <option value="female">Female</option>
                                                                </select>
                                                                {err.gender !== "" && (
                                                                    <span style={{ color: "red" }}>{err.gender}</span>
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6">
                                                            <div className="mb-4">
                                                                <label className="cstm-label">Age</label>
                                                                <input
                                                                    type="text"
                                                                    className="cstm-input"
                                                                    maxlength="2"
                                                                    minlength="2"
                                                                    placeholder="Enter age"
                                                                    name="age"
                                                                    value={formFields.age}
                                                                    onChange={handleOnchange}
                                                                    required=""
                                                                />
                                                                {err.age !== "" && (
                                                                    <span style={{ color: "red" }}>{err.age}</span>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>


                                                    <div className="row">
                                                        <div className="col-lg-6">
                                                            <div className="mb-4">
                                                                <label className="cstm-label">Code</label>
                                                                <select className="cstm-select" name="country_code" value={formFields.country_code} onChange={handleOnchange}>
                                                                    {countrycode.map((item, i) => (<option key={i} value={item.value}>{item.label}</option>))}
                                                                </select>
                                                                {err.country_code !== "" && (
                                                                    <span style={{ color: "red" }}>{err.country_code}</span>
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6">
                                                            <div className="mb-4">
                                                                <label className="cstm-label">Contact no</label>
                                                                <input
                                                                    type="text"
                                                                    className="cstm-input"
                                                                    maxlength="10"
                                                                    placeholder="Enter phone no"
                                                                    name="phone_number"
                                                                    value={formFields.phone_number}
                                                                    onChange={handleOnchange}
                                                                    required=""
                                                                />
                                                                {err.phone_number !== "" && (
                                                                    <span style={{ color: "red" }}>{err.phone_number}</span>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-lg-12">
                                                            <div className="mb-4">
                                                                <label className="cstm-label">Address</label>
                                                                <input
                                                                    type="text"
                                                                    className="cstm-input"
                                                                    placeholder="Enter address"
                                                                    onChange={handleOnchange}
                                                                    value={formFields.address}
                                                                    name="address"
                                                                    required=""
                                                                />
                                                            </div>
                                                        </div>
                                                        {err.address !== "" && (
                                                            <span style={{ color: "red" }}>{err.address}</span>
                                                        )}

                                                    </div>
                                                    <div className="row">
                                                        <div className="col-lg-12">
                                                            <div className="mb-4">
                                                                <label className="cstm-label">About us</label>
                                                                <textarea
                                                                    className="cstm-about-textarea"
                                                                    placeholder="Enter about us"
                                                                    onChange={handleOnchange}
                                                                    value={formFields.aboutUs}
                                                                    name="aboutUs"
                                                                    rows={5}
                                                                    required=""
                                                                ></textarea>
                                                            </div>
                                                        </div>
                                                        {/* {err.address !== "" && (
                                                            <span style={{ color: "red" }}>{err.address}</span>
                                                        )} */}

                                                    </div>
                                                    <div className="row">

                                                        <div className="col-lg-12">
                                                            <div className="mb-4">
                                                                <label className="cstm-label">Specialization</label>
                                                                <select className="cstm-select" name="specialization_ids" value={formFields.specialization_ids} onChange={handleOnchange}>
                                                                    {specialization.map(item => (
                                                                        <option key={item._id} value={item._id}>{item.specialization}</option>
                                                                    ))}
                                                                </select>
                                                                {err.specialization_ids !== "" && (
                                                                    <span style={{ color: "red" }}>{err.specialization_ids}</span>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="row">
                                                        <div class="col-lg-12">
                                                            <div class="mb-4">
                                                                <input type="checkbox" checked={physicianCare} onChange={handleCheck} name="primary_care_physician"
                                                                    required="" />Primary Care Doctor?
                                                                {err.primary_care_physician !== "" && (
                                                                    <span className="cstm-it-doctor-msg" style={{ color: "red" }}>{err.primary_care_physician}</span>
                                                                )}
                                                            </div>


                                                        </div>
                                                    </div>

                                                    <div className="row">
                                                        <div className="col-lg-12">
                                                            <div className="mb-2 cstm-loader">
                                                                <div className="cstm-ldr-mn">
                                                                    <button className="mr-3 cstm-btn6">Save Changes</button>
                                                                    {isLoading &&
                                                                        <div id="btn-loader" className="btn-loader-main">
                                                                            <img className="btn-loaderImg" src={loader} /></div>
                                                                    }
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </form>

                                            <hr />

                                            <h5 className="mb-2 cstm-suspend-account">Suspend Account</h5>
                                            <p className="text-muted">Do you want to suspend the account?</p>

                                            <form name="suspend-pharmacy-profile" id="suspend-pharmacy" onSubmit={handleSuspendSubmit}>

                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        <div className="mb-4">
                                                            <label className="cstm-label">Subject</label>
                                                            <input type="text" className="cstm-input" placeholder="Enter subject of suspend" name="subject" onChange={handleSuspendOnChange} required="" />
                                                            {suspendErr.subject !== "" && (
                                                                <span style={{ color: "red" }}>{suspendErr.subject}</span>
                                                            )}
                                                        </div>

                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        <div className="mb-4">
                                                            <label className="cstm-label">Reasone Of Suspend</label>
                                                            <textarea name="reason" id="comments" rows="5" className="cstm-textarea" onChange={handleSuspendOnChange} placeholder="write reason"></textarea>
                                                            {suspendErr.reason !== "" && (
                                                                <span style={{ color: "red" }}>{suspendErr.reason}</span>
                                                            )}
                                                        </div>

                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        <div className="mb-4">
                                                            <div className="cstm-ldr-mn">
                                                                <button className="cstm-btn7" data-bs-toggle="modal" data-bs-target="#PharmacyProfile">Suspend Account</button>
                                                                {isLoadingSuspend &&
                                                                    <div id="btn-loader" className="btn-loader-main">
                                                                        <img className="btn-loaderImg" src={loader} /></div>
                                                                }
                                                            </div>
                                                            {/* <button className="mr-3 cstm-btn7" data-bs-toggle="modal" data-bs-target="#PharmacyProfile">Suspend Account</button> */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
            </div>

            {/* //add bonus modal */}
            <div class="modal fade cstm-add-bonus" id="AddBonus" tabindex="-1" aria-labelledby="exampleModalLabel1"
                aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header border-0 p-4">
                            <h4 class="modal-title" id="exampleModalLabel1">Add Bonus</h4>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={onCloseButton}></button>
                        </div>
                        <div class="modal-body p-4 pt-0">
                            <form id="add-bonus" onSubmit={handleBonusSubmit}>
                                <div class="mb-4">
                                    <label class="cstm-label">Add bonus amount</label>
                                    <div class="cstm-ab-icon">
                                        <span class="cstm-ab-icon-fill">₦</span>
                                        <input name="bonus" id="bonus" value={bonusAmount} onChange={handleBonusOnChange} type="text" class="cstm-input" placeholder="00.00" />
                                        <p>Amount Ex. ₦10 , ₦10.50 , ₦100.50</p>
                                        {bonusErr !== "" && (
                                            <span style={{ color: "red" }}>{bonusErr}</span>
                                        )}
                                    </div>
                                </div>

                                <div class="d-flex justify-content-end">
                                    <button class=" cstm-btn">Add Bonus</button>
                                </div>
                            </form>

                        </div>
                    </div>
                </div>
            </div>

        </main>


        //delete account modal


    )
}

export default EditProfile


