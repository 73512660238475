import React, { useEffect, useState } from 'react';
import Header from '../Navbar/Header'
import SideBar from '../Navbar/SideBar'
import NavLinkHeader from '../Navbar/NavLinkHeader'
import Services from "../../Services/auth.service";
import swal from "sweetalert";
import loader from '../../assets/images/loder.gif'
import { useTable, usePagination, useGlobalFilter } from "react-table";
import { Link, useNavigate, useLocation } from 'react-router-dom';
import moment from "moment";
import deleteImg from "../../assets/images/delete.png";
import user from '../../assets/images/user.png'
const CryptoJS = require("crypto-js");


function Table({ columns, data }) {
    const getid = useLocation().search;
    const getMenuID = new URLSearchParams(getid).get("id");
    //   var decryptId = CryptoJS.enc.Base64.parse(getMenuID);
    //   var menuID = CryptoJS.enc.Utf8.stringify(decryptId);
    const [showLoader, setLoader] = useState(true);

    useEffect(() => {
        setTimeout(() => {
            setLoader(false);
        }, 2500)
    }, [])
    const props = useTable(
        {
            columns,
            data,
            initialState: { pageIndex: 0, pageSize: 20 }
        },
        useGlobalFilter, // useGlobalFilter!
        usePagination
    );
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        setGlobalFilter,
        state,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,


        state: { pageIndex, pageSize, globalFilter }
    } = props;

    let firstRecord = pageIndex * pageSize + 1;
    let lastRecord = firstRecord + pageSize - 1;

    return (
        <>
            {/* ============== */}
            <div className="row">
                <div className="col-md-12">
                    <div className="card  rounded-md  border">
                        <div className="d-flex justify-content-between align-items-center p-3 border-bottom">
                            <div className="col-md-8">
                                <div className="row row ">
                                    <div className="col-md-5">
                                        <i className="fi fi-rr-search cstm-search-ro" />
                                        <input
                                            name="serch-adminuser"
                                            id="serch-adminuser"
                                            type="text"
                                            class="cstm-input-seacrh"
                                            value={globalFilter || ""}
                                            onChange={(e) => setGlobalFilter(e.target.value)}
                                            placeholder="Search question"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div>
                                <Link
                                    href="javascript:void(0)"
                                    data-bs-toggle="modal"
                                    data-bs-target="#AddLanguage"
                                >
                                    <button className="cstm-btn">Add Question</button>
                                </Link>
                            </div>
                        </div>
                        <div className="col-md-12 col-lg-12">
                            <div className="table-responsive bg-white rounded cstm-ldr">
                                <table
                                    {...getTableProps()}
                                    className="table mb-0 table-center"
                                >
                                    <thead>
                                        {" "}
                                        {headerGroups.map((headerGroup) => (
                                            <tr {...headerGroup.getHeaderGroupProps()}>
                                                {headerGroup.headers.map((column) => (
                                                    <th className={`border-bottom cstm-userheading ${column.Header === "No" ? "w-3" : ''}${column.Header === "Topic Name" ? "w-15" : ''}${column.Header === "Description" ? "w-35" : ''}${column.Header === "Rating" ? "w-14" : ''}${column.Header === "Date" ? "w-14" : ''}${column.Header === "Actions" ? "actions3" : ''}`} {...column.getHeaderProps()}>
                                                        {column.render("Header")}
                                                        {/* Render the columns filter UI */}
                                                    </th>
                                                ))}
                                            </tr>
                                        ))}
                                    </thead>
                                    <tbody {...getTableBodyProps()}>
                                        {
                                            page.length > 0 && page.map((row, i) => {
                                                prepareRow(row);
                                                return (
                                                    <tr {...row.getRowProps()} className="cstm-Tabledesign">
                                                        {row.cells.map((cell) => {
                                                            return (
                                                                <td {...cell.getCellProps()}>
                                                                    {cell.render("Cell")}
                                                                </td>
                                                            );
                                                        })}
                                                    </tr>
                                                );
                                            })}
                                    </tbody>

                                </table>
                                {!showLoader ? page.length === 0 &&
                                    <div className="NoRecord-cstm">No record found!</div> : <div className="NoRecord-cstm"> <img className="pageloader" src={loader} /></div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* pagination */}
            <div className="row text-center">
                <div className="col-12 mt-4">
                    <div className="d-md-flex align-items-center text-center justify-content-between">
                        <span className="text-muted me-3">
                            {page.length !== 0 && `Showing ${firstRecord} -${" "}
                  ${lastRecord < rows.length ? lastRecord : rows.length} out of${" "}
                  ${rows.length}`}</span>
                        <ul className="pagination justify-content-center mb-0 mt-3 mt-sm-0">
                            {pageOptions.length !== 1 && page.length !== 0 && (<li class="page-item">
                                <a
                                    class="page-link"
                                    onClick={() => previousPage()}
                                    disabled={!canPreviousPage}
                                    href="javascript:void(0)"
                                >
                                    Prev
                                </a>
                            </li>)}
                            {

                                pageOptions.map(pgnumber => {
                                    return (
                                        pgnumber !== pageCount && pageCount !== 1 && page.length !== 0 &&
                                        <li className={`page-item ${pageIndex == pgnumber ? 'active' : ''}`} ><a className="page-link" href="javascript:void(0)" onClick={() => gotoPage(pgnumber)} > {pgnumber + 1} </a></li>)

                                })
                            }
                            {pageOptions.length !== 1 && page.length !== 0 && (<li class="page-item">
                                <a
                                    class="page-link"
                                    onClick={() => nextPage()}
                                    disabled={!canNextPage}
                                    href="javascript:void(0)"
                                >
                                    Next
                                </a>
                            </li>)}
                        </ul>
                    </div>
                </div>
            </div>
        </>
    );
}


const Questionnaire = () => {
    const history = useNavigate();
    const getid = useLocation().search;
    const getMenuID = new URLSearchParams(getid).get("id");
    var decryptId = CryptoJS.enc.Base64.parse(getMenuID);
    var menuID = CryptoJS.enc.Utf8.stringify(decryptId);

    const [showAddQuestion, setAddQuestion] = useState('')
    const [showErrorMessage, setErrorMessage] = useState('')
    const [errOption, setErrOption] = useState('')

    const [selectedId, setselectedId] = useState();
    const [data, setdata] = useState([]);
    const [inputType, setInputType] = useState('')
    const [editId, setEditId] = useState()
    const [isLoading, setIsLoading] = useState(false);

    // add options

    const [inputList, setInputList] = useState("")
    const [isDisabled, setIsDisabled] = useState(false)

    var optionArray = [];

    const handleListAdd = () => {
        setInputList([
            ...inputList,
            {
                input: ""
            }
        ])
    }

    useEffect(() => {
        if (inputType === "text") {
            setIsDisabled(true)
        } else {
            setIsDisabled(false)
        }
    })
    const handleEditData = (ID) => {
        setEditId(ID)
        Services.getQuestionaryById(menuID, ID)
            .then(response => {
                if (response.data.status) {
                    var Getdata = response.data.data;
                    if (Getdata.input_type === "text") {
                        setIsDisabled(true)

                    } else {
                        setIsDisabled(false)
                    }
                    setInputType(Getdata.input_type)
                    setAddQuestion(Getdata.question)
                    var ArrayData = []
                    Getdata.option.map((single, i) => {
                        ArrayData.push({ "input": single })
                    })
                    setInputList([...ArrayData])
                }
            }).catch(err => {
                swal("Failed", err.response.data.message, "error");
            })

    }

    const handleOptionChange = (e) => {
        setInputType(e.target.value)
        if (e.target.value === "") {
            setErrOption("Select option ")

        } else {
            setErrOption("")

        }
    }

    const handleInputChange = (event, index) => {
        if (event.target.value === "") {
            setErrOption("Select option ")
        } else {
            setErrOption("")
            const { value, name } = event.target
            const newInputList = [...inputList]
            newInputList[index].input = value
        }
    }
    //remove input field
    const handleRemoveItem = (index) => {
        const newList = [...inputList]
        newList.splice(index, 1)
        setInputList(newList)
    }

    const handleInputChangeEdit = (e, index) => {
        const newInputList = [...inputList]
        newInputList[index].input = e.target.value
        setInputList(newInputList)
    }

    const onClickAddQuestion = () => {

        inputList !== "" && inputList.map((item) => {
            optionArray.push(item.input);
        })
        if (showAddQuestion === "") {
            setErrorMessage("Enter question ")
        } else {
            setErrorMessage("")
            if (inputType === "") {

                setErrOption("Select option")
            } else {
                setErrOption("")

            }
            if (showErrorMessage === "" && errOption === "") {

                var dataValue = {
                    "question": showAddQuestion,
                    "input_type": inputType,
                    "option": optionArray
                }
                setIsLoading(true)
                Services.addquestionnaire(menuID, dataValue)
                    .then(response => {
                        setIsLoading(false)
                        if (response.data.status) {
                            document.querySelector("#AddLanguage .btn-close").click();
                            swal("Success", response.data.message, "success");
                            getQuestion()
                        } else {
                            setIsLoading(false)
                            swal("Failed", response.data.message, "error");
                        }
                    }).catch(err => {
                        setIsLoading(false)
                        swal("Failed", err.response.data.message, "error");
                    })
            }
        }
    }
    const onClickEditQuestion = () => {
        inputList !== "" && inputList.map((item) => {
            optionArray.push(item.input);
        })

        if (showAddQuestion === "") {
            setErrorMessage("Enter question ")
        } else {
            setErrorMessage("")

            var dataValue =
            {
                "question": showAddQuestion,
                "input_type": inputType,
                "option": optionArray
            }
            setIsLoading(true)
            Services.editQuestionary(menuID, editId, dataValue)
                .then(response => {
                    setIsLoading(false)
                    if (response.data.status) {
                        document.querySelector("#editQuestion .btn-close").click();
                        swal("Success", response.data.message, "success");
                        getQuestion()
                    } else {
                        setIsLoading(false)
                        swal("Failed", response.data.message, "error");
                    }
                }).catch(err => {
                    setIsLoading(false)
                    swal("Failed", err.response.data.message, "error");
                })
        }
    }

    const onHandleChange = (e) => {
        if (e.target.value === "") {
            setErrorMessage("Enter question")
        } else {
            setErrorMessage("")
            setAddQuestion(e.target.value)
        }
    }

    const onCloseButton = () => {
        setErrorMessage("");
        setAddQuestion("");
    }

    var DataArray = [];

    const columns = [
        {
            Header: 'No',
            accessor: 'serial'
        },
        {
            Header: "Question",
            accessor: "Question"
        },
        {
            Header: "option",
            accessor: "option"
        },
        {
            Header: "Actions",

            Cell: (row) => {

                // var encyptId = CryptoJS.enc.Utf8.parse(row.cell.row.original._id); // WordArray object
                // var recordIdEncrypt = CryptoJS.enc.Base64.stringify(encyptId);
                return (
                    <div>

                        <Link to="" onClick={() => handleEditData(row.cell.row.original._id)} className="cstm-chekmank" data-bs-toggle="modal" data-bs-target="#editQuestion">
                            <i className="fi-rr-pencil"></i>
                        </Link>

                        <Link to="" onClick={() => setselectedId(row.cell.row.original._id)} className="cstm-cross mrn-rt" data-bs-toggle="modal" data-bs-target="#DeleteQuestion">
                            <i className="fi fi-rr-trash"></i>
                        </Link>

                    </div>
                );
            },
        },

    ];

    function createData(_id, Question, option) {
        return {
            _id,
            Question,
            option
        };
    }
    // No of Index 
    data.forEach((data_id, index) => { data_id.serial = index + 1; });
    useEffect(() => {
        const loggedInUser = localStorage.getItem("token");
        !loggedInUser && history("/");
        getQuestion()
    }, []);

    const getQuestion = () => {

        Services.getquestionnaire(menuID)
            .then((response) => {
                if (response.data.status === true) {
                    var arr = response.data.data;
                    if (arr.length > 0) {
                        var newarr = [];
                        for (var i = 0; i < arr.length; i++) {
                            newarr[i] = createData(
                                arr[i]._id,
                                arr[i].question,
                                arr[i].option.join(" , ")
                            );
                        }
                        newarr.map((data1) => {
                            DataArray = [...DataArray, data1]
                        })
                        setdata(DataArray);
                    }

                } else {
                }
            })
            .catch(function (err) {
            });
    }

    const handleDelete = () => {
        Services.deletQuestionary(menuID, selectedId)
            .then((response) => {
                if (response.data.status === true) {
                    swal("Success", response.data.message, "success");
                    document.querySelector("#DeleteQuestion .btn-close").click();
                    getQuestion()
                } else {
                    swal("Failed", response.data.message, "error");
                }
            })
            .catch(function (err) {
                swal("Failed", err.response.data.message, "error");
            });
    };

    return (
        <>

            <main className="page-content bg-light">
                <Header />
                <div className="container-fluid">
                    <div className="layout-specing">
                        <div className="d-flex justify-content-between align-items-center mb-3">
                            <h4 className="mb-0">Questionnaire</h4>
                            <NavLinkHeader title1="questionnaire" />
                        </div>
                        <Table columns={columns} data={data} />
                    </div>
                </div>
            </main>

            {/* add question modal */}

            <div className="modal fade" id="AddLanguage" tabindex="-1" aria-labelledby="exampleModalLabel1" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header border-0 p-4">
                            <h4 className="modal-title" id="exampleModalLabel1">Add Questionnaire</h4>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={onCloseButton}></button>
                        </div>

                        <div className="modal-body p-4 pt-0">
                            <div className="mb-3">
                                <label className="cstm-label">Write question<span className="cstm-star">*</span></label>
                                <input type="email" onChange={onHandleChange} className="cstm-input" value={showAddQuestion} placeholder="Enter question     " name="email" required="" />
                                {showErrorMessage !== "" && <span style={{ color: "red" }}>{showErrorMessage}</span>}
                            </div>

                            <div className="mb-3">
                                <label className="cstm-label">Select Option<span className="cstm-star">*</span></label>
                                <select name="question" className="cstm-select" id="question" onChange={handleOptionChange}>
                                    <option value="">Select Option</option>
                                    <option value="text">Text</option>
                                    <option value="checkbox">Checkbox</option>
                                </select>
                            </div>

                            {inputList.length > 0
                                ? inputList.map((inputVal, index) => (
                                    <div className="mb-3 option-input" key={index} >
                                        <input type="text"
                                            className="cstm-input"
                                            onChange={(event) => handleInputChange(event, index)}
                                        />
                                        <Link to="" onClick={() => handleRemoveItem(index)} className="cstm-cross delete-item"  >
                                            <i className="fi fi-rr-trash"></i>
                                        </Link>
                                    </div>
                                ))
                                : "No item in the list "}
                            <button className="cstm-btn7" onClick={handleListAdd} disabled={isDisabled}>
                                Add Answer
                            </button>

                            <div className="d-flex justify-content-end">
                                <div className="cstm-ldr-mn">
                                    <button onClick={onClickAddQuestion} className=" cstm-btn6">Add Question</button>
                                    {
                                        isLoading &&
                                        <div id="btn-loader" className="btn-loader-main">
                                            <img className="btn-loaderImg" src={loader} />
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* //test edit question */}
            <div className="modal fade" id="editQuestion" tabindex="-1" aria-labelledby="exampleModalLabel1" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header border-0 p-4">

                            <h4 className="modal-title" id="exampleModalLabel1"></h4>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={onCloseButton}></button>
                        </div>
                        <div className="modal-body p-4 pt-0">
                            <div className="mb-3">
                                <label className="cstm-label">Write question<span className="cstm-star">*</span></label>
                                <input type="text" onChange={onHandleChange} className="cstm-input" value={showAddQuestion} placeholder="Enter question     " name="email" required="" />
                                <span style={{ color: "red" }}>{showErrorMessage}</span>
                            </div>

                            <div className="mb-3">
                                <label className="cstm-label">Select Option<span className="cstm-star">*</span></label>
                                <select name="question" className="cstm-select" id="question" disabled value={inputType} onChange={handleOptionChange}>
                                    <option value="text">Text</option>
                                    <option value="checkbox">Checkbox</option>
                                </select>
                            </div>

                            {inputList.length > 0
                                ? inputList.map((inputVal, index) => (

                                    <div className="mb-3 option-input" key={index} >
                                        <input type="text"
                                            className="cstm-input"
                                            value={inputVal.input}
                                            onChange={(e) => handleInputChangeEdit(e, index)}
                                        />
                                        <Link to="" onClick={() => handleRemoveItem(index)} className="cstm-cross delete-item"  >
                                            <i className="fi fi-rr-trash"></i>
                                        </Link>
                                    </div>
                                ))
                                : <div className="no-item-in-the-list">"No item in the list"</div>}

                            <button className="cstm-btn7" onClick={handleListAdd} disabled={isDisabled}>
                                Add Answer
                            </button>

                            <div className="d-flex justify-content-end">
                                <div className="cstm-ldr-mn">
                                    <button onClick={onClickEditQuestion} className="cstm-btn6">Edit Question</button>
                                    {isLoading &&
                                        <div id="btn-loader" className="btn-loader-main">
                                            <img className="btn-loaderImg" src={loader} /></div>
                                    }
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            {/* end test modal */}

            {/* delete modal */}

            <div class="modal fade cstm-add-bonus" id="DeleteQuestion" tabindex="-1" aria-labelledby="exampleModalLabel1" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header border-0 p-4">
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body p-4 pt-0">
                            <div class="mb-4">
                                <div class="text-center mb-4">
                                    <img src={deleteImg} />
                                </div>
                                <h4 class="text-center mb-4">Are you sure want to delete this Question?</h4>
                                <div class="text-center">
                                    <button class="cstm-btn7" data-bs-toggle="modal" onClick={handleDelete}>Delete Question</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Questionnaire;