import React, { useState } from 'react'
import { Outlet } from 'react-router-dom'
import SideBar from './SideBar'
// import '../../assets/css/style.min.css'
const SidebarLayout = () => {
    const [showText, setShowText] = useState(true);

    return (
        <>
            {/* <SideBar /> */}
            <div className="hideshow"> <button className={`cstm-menu-mobile ${showText ? "mn-active" : ""}`} onClick={() => setShowText(!showText)}>
                <i class="fi fi-rr-menu-burger"></i><i class="fi fi-rr-cross"></i></button><SideBar showText={showText} />  </div>
            <Outlet />
        </>
    )
}

export default SidebarLayout