import React, { useEffect, useState } from 'react'
import Header from '../Navbar/Header'
import Services from "../../Services/auth.service";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import swal from "sweetalert";
import { Link, useNavigate, useLocation } from 'react-router-dom'
import NavLinkHeader from '../Navbar/NavLinkHeader';
import loader from "../../assets/images/loder.gif";
const CryptoJS = require("crypto-js")

const Appointment = () => {
    const history = useNavigate();
    const getid = useLocation().search;
    const getMenuID = new URLSearchParams(getid).get("id");
    var decryptId = CryptoJS.enc.Base64.parse(getMenuID);
    var menuID = CryptoJS.enc.Utf8.stringify(decryptId);
    const [isLoading, setIsLoading] = useState(false);
    const [iisLoading, setIisLoading] = useState(false);

    // //decrypt recordid                                                                                                          
    // const gatRecordID = new URLSearchParams(getid).get("record_id");                                                                                      
    // var decryptRecordId = CryptoJS.enc.Base64.parse(gatRecordID);
    // var recordID = CryptoJS.enc.Utf8.stringify(decryptRecordId);

    const [appointmentErr, setAppointmentErr] = useState("")
    const [inputValue, setValue] = useState();

    const [ussdappointmentErr, setUssdappointmentErr] = useState("")
    const [ussdinputValue, setUssdvalue] = useState();

    const [globallybonusErr, setGloballybonusErr] = useState("")
    const [globallybonusinputValue, setGloballybonusinputValue] = useState();

    useEffect(() => {

        const loggedInUser = localStorage.getItem("token");
        !loggedInUser && history("/");

        var bodyData = {
            "key": "payout-amount"
        }
        setIsLoading(true)
        Services.editDoctorPayout(menuID, bodyData).then((response) => {
            setIsLoading(false)
            if (response.data.status === true) {
                if (response.data.data.value !== null) {
                    const datavalue = response.data.data.value;
                    setValue(datavalue);
                }
            }
        }).
            catch((err) => {
                setIsLoading(false)
            })

        // USSD Patients
        var bodyData = {
            "key": "doctor-hourwise-payout-amount"
        }
        setIisLoading(true)
        Services.getUssdPatient(menuID, bodyData).then((response) => {
            setIisLoading(false)
            if (response.data.status === true) {
                if (response.data.data.value !== null) {
                    const ussddatavalue = response.data.data.value;
                    setUssdvalue(ussddatavalue);
                }
            }
        }).
            catch((err) => {
                setIsLoading(false)
            })

    }, [])

    const handleAppointmentOnChange = (e) => {
        var appointment = e.target.value
        if (!/^(?=.*?\d)?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/.test(appointment)) {
            setAppointmentErr("Enter valid ammount")
        } else if (appointment === "") {
            setAppointmentErr("Please enter amount")
        } else {
            setAppointmentErr("")
        }
        setValue(appointment)
    }
    const handleOnSubmitUpdate = (e) => {
        e.preventDefault()
        if (inputValue === "") {
            setAppointmentErr("Enter ammount")
        } else {
            setAppointmentErr("")
        }
        if (appointmentErr === "") {
            var bodyData = {
                "key": "payout-amount",
                "value": inputValue
            }
            setIsLoading(true)
            Services.getDoctorPayoutByKey(menuID, bodyData)
                .then((response) => {
                    setIsLoading(false)
                    if (response.data.status === true) {
                        swal("Success", response.data.message, "success");
                    } else {
                        setIsLoading(false)
                        swal("Failed", response.data.message, "error");
                    }
                })
                .catch((err) => {
                    setIsLoading(false)
                    swal("Failed", err.response.data.message, "error");
                });
        }
    }

    // USSD patiant

    const handleUssdAppointmentOnChange = (e) => {
        var ussdappointment = e.target.value
        if (!/^(?=.*?\d)?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/.test(ussdappointment)) {
            setUssdappointmentErr("Enter valid ammount")
        } else if (ussdappointment === "") {
            setUssdappointmentErr("Please enter amount")
        } else {
            setUssdappointmentErr("")
        }
        setUssdvalue(ussdappointment)
    }
    const handleOnSubmitUssdUpdate = (e) => {
        e.preventDefault()
        if (ussdinputValue === "") {
            setUssdappointmentErr("Enter ammount")
        } else {
            setUssdappointmentErr("")
        }
        if (ussdappointmentErr === "") {
            var bodyData = {
                "key": "doctor-hourwise-payout-amount",
                "value": ussdinputValue
            }
            setIisLoading(true)
            Services.editUssaPatient(menuID, bodyData)
                .then((response) => {
                    setIisLoading(false)
                    if (response.data.status === true) {
                        swal("Success", response.data.message, "success");
                    } else {
                        setIisLoading(false)
                        swal("Failed", response.data.message, "error");
                    }
                })
                .catch((err) => {
                    setIisLoading(false)
                    swal("Failed", err.response.data.message, "error");
                });
        }
    }

    // globally bonus add

    const handleGloballyBonusOnChange = (e) => {
        var globallybonus = e.target.value
        if (!/^(?=.*?\d)?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/.test(globallybonus)) {
            setGloballybonusErr("Enter valid ammount")
        } else if (globallybonus === "") {
            setGloballybonusErr("Please enter amount")
        } else {
            setGloballybonusErr("")
        }
        setGloballybonusinputValue(globallybonus)
    }
    const handleOnSubmitGloballyBonusUpdate = (e) => {
        e.preventDefault()
        if (globallybonusinputValue === "") {
            setGloballybonusErr("Enter ammount")
        } else {
            setGloballybonusErr("")
        }
        if (globallybonusErr === "") {
            var bodyData = {
                "bonus": globallybonusinputValue
            }
            setIisLoading(true)
            Services.doctorgoballybonus(menuID, bodyData)
                .then((response) => {

                    setIisLoading(false)
                    if (response.data.status === true) {
                        swal("Success", response.data.message, "success");
                        setGloballybonusinputValue(globallybonusinputValue)
                    } else {
                        setIisLoading(false)
                        swal("Failed", response.data.message, "error");
                    }
                })
                .catch((err) => {
                    setIisLoading(false)
                    swal("Failed", err.response.data.message, "error");
                });
        }
    }

    return (
        <>
            <main className="page-content bg-light">
                <Header title="Account setting" main_title="Help Center/Support" />
                <div className="container-fluid">
                    <div className="layout-specing">
                        <div className="d-flex justify-content-between align-items-center mb-3">
                            <h4 className="mb-0">Account setting</h4>
                            <NavLinkHeader title1="Add Appointment Amount" />
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card border-0 shadow rounded">
                                    {/* <div className="d-flex justify-content-between align-items-center p-3 border-bottom"> */}
                                    {/* <h4 className="mb-0">Account setting</h4> */}
                                    {/* </div> */}
                                    <div className="p-3 col-md-10 col-lg-12 cstm-terms-condidtion">
                                        <h5>Per Patient Appointment Fee</h5>
                                        <form id="" onSubmit={handleOnSubmitUpdate}>

                                            <div class="cstm-ab-icon">
                                                <span class="cstm-ab-icon-fill">₦</span>
                                                <input name="appointment" id="appointment" value={inputValue} onChange={handleAppointmentOnChange} type="text" class="cstm-input w-16" placeholder="00.00" />
                                                <p className='mb-0'>Amount Ex. ₦10 , ₦10.50 , ₦100.50</p>
                                                {appointmentErr !== "" && (
                                                    <span style={{ color: "red" }}>{appointmentErr}</span>
                                                )}
                                            </div>


                                            <div className="d-flex mt-3">
                                                <div className="cstm-ldr-mn">
                                                    <button className="cstm-btn" type="submit">
                                                        Submit
                                                    </button>
                                                    {isLoading &&
                                                        <div id="btn-loader" className="btn-loader-main">
                                                            <img className="btn-loaderImg" src={loader} />
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* USSD */}

                    <div className="layout-specing mtop">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card border-0 shadow rounded">
                                    {/* <div className="d-flex justify-content-between align-items-center p-3 border-bottom"> */}
                                    {/* <h4 className="mb-0">Account setting</h4> */}
                                    {/* </div> */}
                                    <div className="p-3 col-md-10 col-lg-12 cstm-terms-condidtion">
                                        <h5>USSD Doctor Call Credit Fee</h5>
                                        <form id="" onSubmit={handleOnSubmitUssdUpdate}>

                                            <div class="cstm-ab-icon">
                                                <span class="cstm-ab-icon-fill">₦</span>
                                                <input name="appointment" id="appointment" value={ussdinputValue} onChange={handleUssdAppointmentOnChange} type="text" class="cstm-input w-16" placeholder="00.00" />
                                                <p className='mb-0'>Per Minute</p>
                                                {ussdappointmentErr !== "" && (
                                                    <span style={{ color: "red" }}>{ussdappointmentErr}</span>
                                                )}
                                            </div>

                                            <div className="d-flex mt-3" >
                                                <div className="cstm-ldr-mn">
                                                    <button className="cstm-btn" type="submit">
                                                        Submit
                                                    </button>
                                                    {iisLoading &&
                                                        <div id="btn-loader" className="btn-loader-main">
                                                            <img className="btn-loaderImg" src={loader} />
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
            </main>
        </>
    )
}

export default Appointment