import React, { useState, useEffect } from "react";
import Header from '../Navbar/Header'
import NavLinkHeader from '../Navbar/NavLinkHeader'
import Services from "../../Services/auth.service";
import swal from "sweetalert";
import 'react-accessible-accordion/dist/fancy-example.css';
import $ from 'jquery';
import { Link } from "react-router-dom";
import deleteImg from "../../assets/images/delete.png";
import { useNavigate, useLocation, Navigate } from "react-router-dom"
import loader from '../../assets/images/loder.gif'
const CryptoJS = require("crypto-js");

const Index = () => {
  const history = useNavigate();
  const getid = useLocation().search;
  const gatMenuID = new URLSearchParams(getid).get("id");

  var decryptId = CryptoJS.enc.Base64.parse(gatMenuID);
  var menuID = CryptoJS.enc.Utf8.stringify(decryptId); // 'Hello world'

  const [isLoading, setIsLoading] = useState(false);
  const [userRole, setUserRole] = useState();

  //doctor faq
  var doctorFaq = [];
  const [doctorFaqData, setDoctorFaqData] = useState([]);

  //pharmacy
  var pharmacyFaq = [];
  const [pharmacyFaqData, setPharmacyFaqData] = useState([]);

  //laboratory
  var laboratoryFaq = [];
  const [laboratoryFaqData, setLaboratoryFaqData] = useState([]);

  var patientFaq = [];
  const [patientFaqData, setPatientFaqData] = useState([]);

  const [selectedFaqId, setSelectedFaqId] = useState();
  const [addVal, setAddVal] = useState({
    question: '',
    answer: ''
  });

  const [errAddVal, setErrAddVal] = useState({
    question: '',
    answer: ''
  });

  const [editVal, setEditVal] = useState({
    question1: '',
    answer1: '',
    faqId: ''
  });

  const [errEditVal, setErrEditVal] = useState({
    question1: '',
    answer1: ''
  });

  useEffect(() => {
    const loggedInUser = localStorage.getItem("token");
    !loggedInUser && history("/");
    getData();
  }, [])

  const getData = async () => {
    await Services.getFaq(menuID)
      .then((response) => {
        if (response.data.status === true) {
          var data = response.data.data;
          data.map(async (data1) => {
            if (await data1._id === "doctor") {
              setUserRole('doctor')
              doctorFaq = [...doctorFaqData, data1.list]
            }
            setDoctorFaqData(doctorFaq[0])
            if (await data1._id === "pharmacy") {
              setUserRole('pharmacy')
              pharmacyFaq = [...pharmacyFaqData, data1.list]
            }
            setPharmacyFaqData(pharmacyFaq[0])
            if (await data1._id === "patient") {
              setUserRole('patient')
              patientFaq = [...patientFaqData, data1.list]
            }
            setPatientFaqData(patientFaq[0])
            if (await data1._id === "laboratory") {
              setUserRole('laboratory')
              laboratoryFaq = [...laboratoryFaqData, data1.list]
            }
            setLaboratoryFaqData(laboratoryFaq[0])
          })

        }
      })
      .catch(function (err) {
        // swal("Failed", err.response.data.message, "error");
      });

  }
  const onclickAdd = (userrole) => {
    setUserRole(userrole)
    setAddVal({ ...addVal, ["question"]: "", ["answer"]: "" });
    $('#addfaqform')[0].reset();
    $('#answer').val('');
    $('.error-msg').hide();
  }

  const handleOnchangeAdd = (e) => {
    const { value, name } = e.target;
    if (name == "question") {
      errAddVal.question = value.length > 0 ? "" : "Enter question";
    }

    if (name == "answer") {
      errAddVal.answer = value.length > 0 ? "" : "Enter answer";
    }

    setAddVal({ ...addVal, [name]: value });
    setErrAddVal({ ...errAddVal });
  };

  const onSubmitAdd = async (e) => {
    e.preventDefault();
    $('.error-msg').show();
    if (addVal.question == "") {
      errAddVal.question = "Enter question";
    } else {
      errAddVal.question = "";
    }

    if (addVal.answer == "") {
      errAddVal.answer = "Enter answer";
    } else {
      errAddVal.answer = '';
    }

    setErrAddVal({ ...errAddVal });

    if (errAddVal.question === '' && errAddVal.answer === '') {
      var bodyData = {
        question: addVal.question,
        answer: addVal.answer,
        user: userRole
      };
      setIsLoading(true)
      Services.addFaq(menuID, bodyData).then((response) => {
        setIsLoading(false)
        if (response.data.status === true) {
          document.querySelector("#addfaqmodel .btn-close").click();
          swal("Success", response.data.message, "success");
          setTimeout(() => {
            window.location.reload()
          }, 1500)

        } else {
          setIsLoading(false)
          swal("Failed", response.data.message, "error");
        }
      }).catch((err) => {
        setIsLoading(false)
        swal("Failed", err.response.data.message, "error");
      });

    }
  };

  const onClickDelete = () => {
    Services.deleteFaq(menuID, selectedFaqId)
      .then((response) => {
        if (response.data.status === true) {
          document.querySelector("#cancelappointment .btn-close").click();
          // location.reload();
          // getData();
          setTimeout(() => {
            window.location.reload()
          }, 1000)
          swal("Success", response.data.message, "success");
        } else {
          swal("Failed", response.data.message, "error");

        }
      })
      .catch(function (err) {
        swal("Failed", err.response.data.message, "error");
      });
  };

  const setEditData = (_id, question, answer) => {
    setEditVal({ ...editVal, faqId: _id, question1: question, answer1: answer })
  }

  const handleOnchangeEdit = (e) => {
    const { value, name } = e.target;
    if (name == "question1") {
      errEditVal.question1 = value.length > 0 ? "" : "Enter question";
    }

    if (name == "answer1") {
      errEditVal.answer1 = value.length > 0 ? "" : "Enter answer";
    }

    setEditVal({ ...editVal, [name]: value });
    setErrEditVal({ ...errEditVal });
  };

  const onSubmitEdit = async (e) => {
    e.preventDefault();
    $('.error-msg').show();
    if (editVal.question1 == "") {
      errEditVal.question1 = "Enter question";
    } else {
      errEditVal.question1 = "";
    }

    if (editVal.answer1 == "") {
      errEditVal.answer1 = "Enter answer";
    } else {
      errEditVal.answer1 = '';
    }

    setErrEditVal({ ...errEditVal });

    if (errEditVal.question1 === '' && errEditVal.answer1 === '') {
      var bodyData = {
        question: editVal.question1,
        answer: editVal.answer1,
        user: userRole
      };
      setIsLoading(true)
      Services.editFaq(menuID, editVal.faqId, bodyData).then((response) => {
        setIsLoading(false)
        if (response.data.status === true) {
          document.querySelector("#editfaqmodel .btn-close").click();
          swal("Success", response.data.message, "success");
          // getData()
          setTimeout(() => {
            window.location.reload()
          }, 1500)

        } else {
          setIsLoading(false)
          swal("Failed", response.data.message, "error");
        }
      }).catch((err) => {
        setIsLoading(false)
        swal("Failed", err.response.data.message, "error");
      });

    }
  };

  return (
    <>

      <main className="page-content">
        <Header />
        <div className="container-fluid">
          <div className="layout-specing">
            <div className="d-flex justify-content-between align-items-center mb-3">
              <h4 className="mb-0">FAQ's</h4>
              <NavLinkHeader title1="FAQ" />
            </div>
            {/* ------------------ */}
            <div className="cstm-doctor-profile  rounded-md  border">
              <div className="cstm-faq-only">
                <div className="cstm-tabs-pf">
                  <ul className="nav" id="pills-tab" role="tablist">
                    <li className="nav-item">
                      <a className="nav-link active" id="overview-tab" data-bs-toggle="pill" href="#Doctor" role="tab" aria-controls="Doctor" aria-selected="false">
                        Doctor
                      </a>
                    </li>

                    <li className="nav-item">
                      <a className="nav-link" id="experience-tab" data-bs-toggle="pill" href="#Pharmacy" role="tab" aria-controls="Pharmacy" aria-selected="false">
                        Pharmacy
                      </a>
                    </li>

                    <li className="nav-item">
                      <a className="nav-link" id="experience-tab" data-bs-toggle="pill" href="#Laboratory" role="tab" aria-controls="Laboratory" aria-selected="false">
                        Laboratory
                      </a>
                    </li>

                    <li className="nav-item">
                      <a className="nav-link" id="experience-tab" data-bs-toggle="pill" href="#Users" role="tab" aria-controls="Users" aria-selected="false">
                        Users
                      </a>
                    </li>


                  </ul>
                  <div className="tab-content mt-2" id="pills-tabContent">
                    <div className="tab-pane fade show active" id="Doctor" role="tabpanel" aria-labelledby="overview-tab">
                      <div className="d-flex justify-content-between align-items-center p-3">
                        <h6 className="mb-0">FAQ</h6>
                        <div className="faq-add-bonus">
                          <a
                            href="javascript:void(0)"
                            data-bs-toggle="modal"
                            data-bs-target="#addfaqmodel"
                            onClick={() => onclickAdd("doctor")}
                          >
                            <button className="cstm-btn">Add FAQ</button>
                          </a>
                        </div>
                      </div>

                      <div className="accordion  p-3 cstm-faq-accordion" id="buying">
                        {/* {!isLoading ? doctorFaqData.length === 0  &&
                      <div className="NoRecord-cstm">No record found!</div> : <div className="NoRecord-cstm faq-loader"> <img className="pageloader" src={loader} /></div>} */}
                        {
                          doctorFaqData !== undefined && doctorFaqData.length > 0 &&  doctorFaqData.map((faq, index) =>
                          (<div className="accordion-item border rounded" key={index}>
                            <h2 className="accordion-header" id={`heading${index}`}>
                              <div className="cstm-doctor-faq-icon">
                                <Link
                                  className="cstm-chekmank mrn-rt2"
                                  data-bs-toggle="modal"
                                  data-bs-target="#editfaqmodel"
                                  to={" "}
                                  onClick={() =>
                                    (setEditData(faq._id, faq.question, faq.answer), setUserRole("doctor"))
                                  }
                                >
                                  <i className="fi fi-rr-pencil"></i>
                                </Link>

                                <Link
                                  to={" "}
                                  onClick={() => setSelectedFaqId(faq._id)}
                                  className="cstm-cross"
                                  data-bs-toggle="modal"
                                  data-bs-target="#cancelappointment"
                                >
                                  <i className="fi fi-rr-trash"></i>
                                </Link>
                              </div>

                              <button className="accordion-button border-0 " type="button" data-bs-toggle="collapse" data-bs-target={`#collapse${index}`}
                                aria-expanded="true" aria-controls={`collapse${index}`}>
                                {faq.question}
                              </button>
                            </h2>
                            <div id={`collapse${index}`} className="accordion-collapse border-0 collapse show" aria-labelledby={`heading${index}`}
                              data-bs-parent="#buying">
                              <div className="accordion-body text-muted">
                                {faq.answer}
                              </div>
                            </div>
                          </div>
                          ))

                        }

                      </div>
                    </div>
                    <div className="tab-pane fade" id="Pharmacy" role="tabpanel" aria-labelledby="experience-tab">
                      <div className="d-flex justify-content-between align-items-center p-3">
                        <h6 className="mb-0">FAQ</h6>
                        <div className="faq-add-bonus">
                          <a
                            href="javascript:void(0)"
                            data-bs-toggle="modal"
                            data-bs-target="#addfaqmodel"
                            onClick={() => onclickAdd("pharmacy")}
                          >
                            <button className="cstm-btn">Add FAQ</button>
                          </a>
                        </div>
                      </div>

                      <div className="accordion  p-3 cstm-faq-accordion" id="buying">

                        {
                          pharmacyFaqData !== undefined && pharmacyFaqData.map((faq, index) =>
                          (<div className="accordion-item border rounded" key={index}>
                            <h2 className="accordion-header" id={`heading${index}`}>
                              <div className="cstm-doctor-faq-icon">
                                <Link
                                  className="cstm-chekmank mrn-rt2"
                                  data-bs-toggle="modal"
                                  data-bs-target="#editfaqmodel"
                                  to={" "}
                                  onClick={() =>
                                    (setEditData(faq._id, faq.question, faq.answer), setUserRole("pharmacy"))
                                  }
                                >
                                  <i className="fi fi-rr-pencil"></i>
                                </Link>

                                <Link
                                  to={" "}
                                  onClick={() => setSelectedFaqId(faq._id)}
                                  className="cstm-cross"
                                  data-bs-toggle="modal"
                                  data-bs-target="#cancelappointment"
                                >
                                  <i className="fi fi-rr-trash"></i>
                                </Link>
                              </div>

                              <button className="accordion-button border-0 " type="button" data-bs-toggle="collapse" data-bs-target={`#collapse${index}`}
                                aria-expanded="true" aria-controls={`collapse${index}`}>
                                {faq.question}
                              </button>
                            </h2>
                            <div id={`collapse${index}`} className="accordion-collapse border-0 collapse show" aria-labelledby={`heading${index}`}
                              data-bs-parent="#buying">
                              <div className="accordion-body text-muted">
                                {faq.answer}
                              </div>
                            </div>
                          </div>
                          ))

                        }

                      </div>
                    </div>
                    <div className="tab-pane fade" id="Laboratory" role="tabpanel" aria-labelledby="experience-tab">
                      <div className="d-flex justify-content-between align-items-center p-3">
                        <h6 className="mb-0">FAQ</h6>
                        <div className="faq-add-bonus">
                          <a
                            href="javascript:void(0)"
                            data-bs-toggle="modal"
                            data-bs-target="#addfaqmodel"
                            onClick={() => onclickAdd("laboratory")}
                          >
                            <button className="cstm-btn">Add FAQ</button>
                          </a>
                        </div>
                      </div>

                      <div className="accordion  p-3 cstm-faq-accordion" id="buying">

                        {
                          laboratoryFaqData !== undefined && laboratoryFaqData.map((faq, index) =>
                          (<div className="accordion-item border rounded" key={index}>
                            <h2 className="accordion-header" id={`heading${index}`}>
                              <div className="cstm-doctor-faq-icon">
                                <Link
                                  className="cstm-chekmank mrn-rt2"
                                  data-bs-toggle="modal"
                                  data-bs-target="#editfaqmodel"
                                  to={" "}
                                  onClick={() =>
                                    (setEditData(faq._id, faq.question, faq.answer), setUserRole("laboratory"))
                                  }
                                >
                                  <i className="fi fi-rr-pencil"></i>
                                </Link>

                                <Link
                                  to={" "}
                                  onClick={() => setSelectedFaqId(faq._id)}
                                  className="cstm-cross"
                                  data-bs-toggle="modal"
                                  data-bs-target="#cancelappointment"
                                >
                                  <i className="fi fi-rr-trash"></i>
                                </Link>
                              </div>

                              <button className="accordion-button border-0 " type="button" data-bs-toggle="collapse" data-bs-target={`#collapse${index}`}
                                aria-expanded="true" aria-controls={`collapse${index}`}>
                                {faq.question}
                              </button>
                            </h2>
                            <div id={`collapse${index}`} className="accordion-collapse border-0 collapse show" aria-labelledby={`heading${index}`}
                              data-bs-parent="#buying">
                              <div className="accordion-body text-muted">
                                {faq.answer}
                              </div>
                            </div>
                          </div>
                          ))

                        }

                      </div>
                    </div>
                    <div className="tab-pane fade" id="Users" role="tabpanel" aria-labelledby="experience-tab">
                      <div className="d-flex justify-content-between align-items-center p-3">
                        <h6 className="mb-0">FAQ</h6>
                        <div className="faq-add-bonus">
                          <a
                            href="javascript:void(0)"
                            data-bs-toggle="modal"
                            data-bs-target="#addfaqmodel"
                            onClick={() => onclickAdd("patient")}
                          >
                            <button className="cstm-btn">Add FAQ</button>
                          </a>
                        </div>
                      </div>

                      <div className="accordion  p-3 cstm-faq-accordion" id="buying">

                        {
                          patientFaqData !== undefined && patientFaqData.map((faq, index) =>
                          (<div className="accordion-item border rounded" key={index}>
                            <h2 className="accordion-header" id={`heading${index}`}>
                              <div className="cstm-doctor-faq-icon">
                                <Link
                                  className="cstm-chekmank mrn-rt2"
                                  data-bs-toggle="modal"
                                  data-bs-target="#editfaqmodel"
                                  to={" "}
                                  onClick={() => (setEditData(faq._id, faq.question, faq.answer), setUserRole("patient")
                                  )}
                                >
                                  <i className="fi fi-rr-pencil"></i>
                                </Link>

                                <Link
                                  to={" "}
                                  onClick={() => setSelectedFaqId(faq._id)}
                                  className="cstm-cross"
                                  data-bs-toggle="modal"
                                  data-bs-target="#cancelappointment"
                                >
                                  <i className="fi fi-rr-trash"></i>
                                </Link>
                              </div>

                              <button className="accordion-button border-0 " type="button" data-bs-toggle="collapse" data-bs-target={`#collapse${index}`}
                                aria-expanded="true" aria-controls={`collapse${index}`}>
                                {faq.question}
                              </button>
                            </h2>
                            <div id={`collapse${index}`} className="accordion-collapse border-0 collapse show" aria-labelledby={`heading${index}`}
                              data-bs-parent="#buying">
                              <div className="accordion-body text-muted">
                                {faq.answer}
                              </div>
                            </div>
                          </div>
                          ))
                        }
                      </div>
                    </div>

                    {/* {!isLoading ? (doctorFaq.length === 0 || pharmacyFaq.length === 0 || laboratoryFaq.length === 0 || patientFaq.length === 0) &&
                      <div className="NoRecord-cstm">No record found!</div> : <div className="NoRecord-cstm"> <img className="pageloader" src={loader} /></div>} */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>

      {/* add Question modal */}
      <div className="modal fade" id="addfaqmodel" tabindex="-1" aria-labelledby="exampleModalLabel1" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header border-0 p-4">
              <h4 className="modal-title" id="exampleModalLabel1">Add Question</h4>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <form id="addfaqform" onSubmit={onSubmitAdd}>

              <div className="modal-body p-4 pt-0">
                <div className="mb-3">
                  <label className="cstm-label">Question*</label>
                  <input type="text"
                    onChange={handleOnchangeAdd}
                    className="cstm-input"
                    placeholder="Write a question"
                    name="question"
                    value={addVal.question}
                  />
                  {errAddVal.question !== "" && (
                    <span className="error-msg" style={{ color: "red" }}>
                      {errAddVal.question}
                    </span>
                  )}
                </div>
                <div className="mb-4">
                  <label className="cstm-label">Answer*</label>
                  <textarea
                    onChange={handleOnchangeAdd}
                    name="answer"
                    id="answer"
                    rows="3"
                    className="cstm-textarea"
                    placeholder="Write a answer"
                    value={addVal.answer}
                  ></textarea>
                  {errAddVal.answer !== "" && (
                    <span className="error-msg" style={{ color: "red" }}>
                      {errAddVal.answer}
                    </span>
                  )}
                </div>
                <div className="d-flex justify-content-end">
                  <div className="cstm-ldr-mn">
                    <button className="cstm-btn7">Add Question</button>
                    {isLoading &&
                      <div id="btn-loader" className="btn-loader-main">
                        <img className="btn-loaderImg" src={loader} /></div>
                    }
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      {/* end add question modal */}

      {/* edit question modal */}

      <div className="modal fade" id="editfaqmodel" tabindex="-1" aria-labelledby="exampleModalLabel1" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header border-0 p-4">
              <h4 className="modal-title" id="exampleModalLabel1">Edit Question</h4>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <form id="editfaqform" onSubmit={onSubmitEdit}>

              <div className="modal-body p-4 pt-0">
                <div className="mb-3">
                  <label className="cstm-label">Question*</label>
                  <input type="text"
                    onChange={handleOnchangeEdit}
                    className="cstm-input"
                    placeholder="Write a question"
                    name="question1"
                    value={editVal.question1}
                  />
                  {errEditVal.question1 !== "" && (
                    <span className="error-msg" style={{ color: "red" }}>
                      {errEditVal.question1}
                    </span>
                  )}
                </div>
                <div className="mb-4">
                  <label className="cstm-label">Answer*</label>
                  <textarea
                    onChange={handleOnchangeEdit}
                    id="answer"
                    rows="3"
                    className="cstm-textarea"
                    placeholder="Write a answer"
                    name="answer1"
                    value={editVal.answer1}
                  ></textarea>
                  {errEditVal.answer1 !== "" && (
                    <span className="error-msg" style={{ color: "red" }}>
                      {errEditVal.answer1}
                    </span>
                  )}
                </div>
                <div className="d-flex justify-content-end">
                <div className="cstm-ldr-mn">
                  <button className="cstm-btn7">Edit Question</button>
                  {isLoading &&
                      <div id="btn-loader" className="btn-loader-main">
                        <img className="btn-loaderImg" src={loader} /></div>
                    }
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      {/* Delete modal */}
      <div className="modal fade cstm-add-bonus" id="cancelappointment" tabindex="-1" aria-labelledby="exampleModalLabel1" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header border-0 p-4">
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body p-4 pt-0">
              <div className="mb-4">

                <div className="text-center mb-4">
                  <img src={deleteImg} />
                </div>
                <h4 className="text-center mb-4">Are you sure want to delete this Question?</h4>
                <div className="text-center">
                  <button className="cstm-btn7" onClick={onClickDelete}>Delete Question</button>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Index;