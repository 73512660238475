import React, { useEffect, useState } from 'react';
import Header from '../Navbar/Header'
import NavLinkHeader from '../Navbar/NavLinkHeader'
import Services from "../../Services/auth.service";
import swal from "sweetalert";
import loader from '../../assets/images/loder.gif'
import { useTable, usePagination, useGlobalFilter } from "react-table";
import { Link, useNavigate, useLocation } from 'react-router-dom';
import moment from "moment";
import photo from "../../assets/images/photo.png";
import ReactDOM from "react-dom";

const CryptoJS = require("crypto-js");

function Table({ columns, data }) {
  // ids ,currMonth,currYear
  const getid = useLocation().search;
  const getMenuID = new URLSearchParams(getid).get("id");
  var decryptId = CryptoJS.enc.Base64.parse(getMenuID);
  var menuID = CryptoJS.enc.Utf8.stringify(decryptId);
  const [showLoader, setLoader] = useState(true);
  const [isLoading, setIsLoading] = useState(false);


  useEffect(() => {
    setTimeout(() => {
      setLoader(false);
    }, 1500)
  }, [])
  const props = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 20 }
    },
    useGlobalFilter, // useGlobalFilter!
    usePagination
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setGlobalFilter,
    state,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,


    state: { pageIndex, pageSize, globalFilter }
  } = props;

  let firstRecord = pageIndex * pageSize + 1;
  let lastRecord = firstRecord + pageSize - 1;


  return (
    <>

      {/* ============== */}
      <div className="row">
        <div className="col-md-12">
          <div className="card  rounded-md  border">
            <div className="d-flex justify-content-between align-items-center p-3 border-bottom ">
              <div className="col-md-8">
                <div className="row row ">
                  <div className="col-md-5">
                    <i className="fi fi-rr-search cstm-search-ro" />
                    <input
                      name="serch-adminuser"
                      id="serch-adminuser"
                      type="text"
                      class="cstm-input-seacrh"
                      value={globalFilter || ""}
                      onChange={(e) => setGlobalFilter(e.target.value)}
                      placeholder="Search Laboratory"
                    />
                  </div>
                </div>
              </div>
              <div>
               
              </div>
            </div>
            <div className="col-md-12 col-lg-12">
              <div className="table-responsive bg-white rounded cstm-ldr">
                <table
                  {...getTableProps()}
                  className="table mb-0 table-center"
                >
                  <thead>
                    {" "}
                    {headerGroups.map((headerGroup) => (
                      <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column) => (
                          <th className={`border-bottom cstm-userheading ${column.Header === "No" ? "w-3" : ''}${column.Header === "Doctor Name" ? "w-20" : ''}${column.Header === "Age" ? "w-8" : ''}${column.Header === "Gender" ? "w-8" : ''}${column.Header === "Email" ? "w-18" : ''}${column.Header === "Address" ? "w-30" : ''}${column.Header === "Date" ? "w-14" : ''}${column.Header === "Actions" ? "actions3" : ''}`} {...column.getHeaderProps()}>
                            {column.render("Header")}
                            {/* Render the columns filter UI */}
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody {...getTableBodyProps()}>
                    {
                      page.length > 0 && page.map((row, i) => {
                        prepareRow(row);
                        return (
                          <tr {...row.getRowProps()} className="cstm-Tabledesign">
                            {row.cells.map((cell) => {
                              return (
                                <td {...cell.getCellProps()}>
                                  {cell.render("Cell")}
                                </td>
                              );
                            })}
                          </tr>
                        );
                      })}
                  </tbody>

                </table>
                {!showLoader ? page.length === 0 &&
                  <div className="NoRecord-cstm"> Laboratory Payroll Detail Not found!</div> : <div className="NoRecord-cstm"> <img className="pageloader" src={loader} /></div>
                }
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row text-center">
        <div className="col-12 mt-4">
          <div className="d-md-flex align-items-center text-center justify-content-between">
            <span className="text-muted me-3">
              {page.length !== 0 && `Showing ${firstRecord} -${" "}
                  ${lastRecord < rows.length ? lastRecord : rows.length} out of${" "}
                  ${rows.length}`}</span>
            <ul className="pagination justify-content-center mb-0 mt-3 mt-sm-0">
              {pageOptions.length !== 1 && page.length !== 0 && (<li class="page-item">
                <a
                  class="page-link"
                  onClick={() => previousPage()}
                  disabled={!canPreviousPage}
                  href="javascript:void(0)"
                >
                  Prev
                </a>
              </li>)}
              {
                pageOptions.map(pgnumber => {
                  return (
                    pgnumber !== pageCount && pageCount !== 1 && page.length !== 0 &&
                    <li className={`page-item ${pageIndex == pgnumber ? 'active' : ''}`} ><a className="page-link" href="javascript:void(0)" onClick={() => gotoPage(pgnumber)} > {pgnumber + 1} </a></li>)
                })
              }
              {pageOptions.length !== 1 && page.length !== 0 && (<li class="page-item">
                <a
                  class="page-link"
                  onClick={() => nextPage()}
                  disabled={!canNextPage}
                  href="javascript:void(0)"
                >
                  Next
                </a>
              </li>)}
            </ul>
          </div>
        </div>
      </div>



    </>
  );
}

const LaboratoryPayroll = () => {
  const history = useNavigate();
  const getid = useLocation().search;
  const getMenuID = new URLSearchParams(getid).get("id");
  var decryptId = CryptoJS.enc.Base64.parse(getMenuID);
  var menuID = CryptoJS.enc.Utf8.stringify(decryptId);

  const [data, setdata] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [filterData, setFilterData] = useState([]);
  const [paydata, setpayData] = useState("")
  const [SelectYear, setSelectYear] = useState("")
  const [totalPayout, setTotalPayout] = useState("")
  const [doctorId, setsetDoctorId] = useState("")

  var today = new Date(),
    getCurrentMonth = moment(today).format('MM');
  var getCurrentYear = moment().format('yyyy');
  
  const [currMonth, setCurrMonth] = useState(getCurrentMonth)
  const [currYear, setCurrYear] = useState(getCurrentYear)
  var DataArray = [];
  var doc_id = [];

  const columns = [
    {
      Header: 'No',
      accessor: 'serial'
    },

    {
      Header: "Laboratory Name",
      accessor: "name"
    },
    {
      Header: "Report",
      accessor: "total_reports"
    },
    {
      Header: "Total Payout",
      accessor: "total_payout"
    },
    

  ];

  function createData(_id, name, total_reports, total_payout,  createdAt) {
    return {
      _id,
      name,
      total_reports,
      total_payout,
      createdAt,
    };
  }
  // No of Index 
  data.forEach((data_id, index) => { data_id.serial = index + 1; });

  useEffect(() => {
    const loggedInUser = localStorage.getItem("token");
    !loggedInUser && history("/");
    getlabpay(currMonth, currYear)
 
  }, []);

  const getlabpay = (month, year) => {
    var bodyData = `${month}/${year}`;

    Services.getlabpay(menuID, bodyData)
      .then((response) => {
        // return false
        if (response.data.status = true){
          var arr = response.data.data;
          // if (response.data.data.length > 0) {
            var newarr = [];
            for (var i = 0; i < arr.length; i++) {
              // const dates = new Date();
              // const date= dates.getMonth() + 1;
              // var date = moment(arr[i].createdAt).format('MM');
              var get_doctor_id=response.data.data[i].lab_id;
              doc_id.push(get_doctor_id);
              setsetDoctorId(doc_id)
              var doctorDetail = arr[i].lab_info
              // return false
              if (doctorDetail !== null) {
                var doller = `₦`;
                newarr[i] = createData(
                  doctorDetail._id,
                  doctorDetail.name,
                  arr[i].total_reports,
                  `${doller}${arr[i].total_payout}`,
                  // date,
                );
              }
            }
            newarr.map((data1) => {
              DataArray = [...DataArray, data1]
            })
            setdata(DataArray);
            setFilterData(DataArray)
          // }
        } else {
          setdata("");
        }
      })
      .catch(function (err) {
      });
  }

  const handleOnChangeMonth = (e) => {
    var month = e.target.value //handle onchange month
    setCurrMonth(month);


  }
  const handleOnChangeYear = (e) => {
    var year = e.target.value //handle onchange month
    setCurrYear(year);

  }

  var dataFilter = () => {
    getlabpay(currMonth, currYear)
  }



  const totalPayoutAmountForLab = () => {
    const bodyData = {
      "lab_ids": doctorId,
      "filter_month": `${currMonth}/${currYear}`
    }
    Services.totalPayoutAmountForLab(menuID, bodyData)
      .then(response => {
        // return false;
        if (response.data.status === true) {
          setTotalPayout(response.data.data[0].salary)
        }
        else {
          setIsLoading(false)
          swal("Failed", response.data.message, "error");
        }
      }).catch(err => {
        // setIsLoading(false)
        swal("Failed", err.response.data.message, "error");
      })
  }

  const Payout = () => {
    const bodyData = {
      "lab_ids": doctorId,
      "filter_month": `${currMonth}/${currYear}`
    }
    Services.addLabPayout(menuID, bodyData)
      .then(response => {
        setIsLoading(false)
        if (response.data.status === true) {
          document.querySelector("#AddPayout .btn-close").click();
          swal("Success", response.data.message, "success");
          setTimeout(() => {
            window.location.reload()
          }, 1500)
          // getlabpay();
          // totalPayoutAmountForLab();
        } else {
          setIsLoading(false)
          swal("Failed", response.data.message, "error");
        }
      }).catch(err => {
        setIsLoading(false)
        swal("Failed", err.response.data.message, "error");
      })
  }


  return (
    <>
      <main className="page-content bg-light">
        <Header />
        <div className="container-fluid">
          <div className="layout-specing">
            <div className="d-flex justify-content-between align-items-center mb-3 cstm-dfmn">
              <h4 className="mb-0">Laboratory Payroll </h4>

              <div class="col-md-12 mb-3 cstm-dp-mn2">
                <select className='cstm-select cstm-om-slc' id="month" value={currMonth} onChange={handleOnChangeMonth} >
                  {/* <option value="all">select month</option> */}
                  <option value="01">January</option>
                  <option value="02">February</option>
                  <option value="03">March</option>
                  <option value="04">April</option>
                  <option value="05">May</option>
                  <option value="06">June</option>
                  <option value="07">July</option>
                  <option value="08">August</option>
                  <option value="09">September</option>
                  <option value="10">October</option>
                  <option value="11">November</option>
                  <option value="12">December</option>
                </select>

                <select className='cstm-select cstm-om-slc' id="year" value={currYear} onChange={handleOnChangeYear}>
                  {/* <option value="all">select month</option> */}
                  <option value="2020">2020</option>
                  <option value="2021">2021</option>
                  <option value="2022">2022</option>
                  <option value="2023">2023</option>
                  <option value="2024">2024</option>
                  <option value="2025">2025</option>
                  <option value="2026">2026</option>
                  <option value="2028">2028</option>
                  <option value="2029">2029</option>
                  <option value="2030">2030</option>
                  <option value="2031">2031</option>
                  <option value="2032">2032</option>
                  <option value="2033">2033</option>
                  <option value="2034">2034</option>
                  <option value="2035">2035</option>
                  <option value="2036">2036</option>
                  <option value="2037">2037</option>
                  <option value="2038">2038</option>
                  <option value="2039">2039</option>
                  <option value="2040">2040</option>
                </select>
              <button className='cstm-btn' onClick={dataFilter}> Filter Data </button>

                <Link
                  href="javascript:void(0)"
                  data-bs-toggle="modal"
                  data-bs-target="#AddPayout"
                  onClick={totalPayoutAmountForLab}
                >
                { data.length > 0 ? <button className="cstm-btn">Total Payout</button> : ""}
                </Link>

              </div>

              <NavLinkHeader title1="Laboratory List" title1_link={`/laboratory?id=${getMenuID}`} />
            </div>

            <Table columns={columns} data={data} />
          </div>
        </div>



        <div class="modal fade cstm-add-bonus" id="AddPayout" tabindex="-1" aria-labelledby="exampleModalLabel1" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-header border-0 p-4">
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body p-4 pt-0">
                <div class="mb-4">
                  <h4 class="text-center mb-4">Are you sure want to payment</h4>
                  <h4 class="text-center mb-4">₦{totalPayout}</h4>
                  <div class="text-center">
                    <button class="cstm-btn7" data-bs-toggle="modal" aria-label="Close">No</button> &nbsp;
                    <button class="cstm-btn7" data-bs-toggle="modal" onClick={Payout} >Laboratory Payout</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </main>
    </>
  )
}
export default LaboratoryPayroll;
