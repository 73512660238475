import React, { useState, useEffect } from 'react'
import Header from '../Navbar/Header'
import SideBar from '../Navbar/SideBar'
import NavLinkHeader from '../Navbar/NavLinkHeader'
import Services from "../../Services/auth.service"
import swal from "sweetalert"
import $ from 'jquery'
import loader from '../../assets/images/loder.gif'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import moment from "moment"
const CryptoJS = require("crypto-js")


const AddAdminUser = () => {
  const history = useNavigate();
  const getid = useLocation().search;
  const getMenuID = new URLSearchParams(getid).get("id");
  var decryptId = CryptoJS.enc.Base64.parse(getMenuID);
  var menuID = CryptoJS.enc.Utf8.stringify(decryptId);

  const [isLoading, setIsLoading] = useState(false);
  const [checkedResource, setCheckedResource] = useState([])
  const [resourceData, setResourceData] = useState([])
  const [rolData, setRolData] = useState([])
  var finalResourceArr = [];
  var ResourceDataArray = [];
  var RoleDataArray = [];
  //auto complete
  const [formFields, setFormFields] = useState({
    "full_name": "",
    "role": "",
    "bio": "",
    "email": "",
    "phone_no": "",
    "resource_access": "",

  });
  const [err, seterr] = useState({
    "full_name": "",
    "role": "",
    "email": "",
    "phone_no": "",
    "resource_access": "",
  });
  useEffect((data, e) => {
    const loggedInUser = localStorage.getItem("token");
    !loggedInUser && history("/");


    Services.getResources(menuID)
      .then((response) => {
        // return false;
        if (response.data.status === true) {
          if (response.data.data.length !== 0) {
            var data = response.data.data;
            data.map((data1) => {
              ResourceDataArray = [...ResourceDataArray, data1]
            });

          }
          setResourceData(ResourceDataArray);
        }
      })
      .catch(function (err) {
      });
    GetAdminRole();
  }, []);

  const GetAdminRole = () => {
    Services.getAdminRole(menuID)
      .then((response) => {
        // return false;
        if (response.data.status === true) {
          if (response.data.data.length !== 0) {
            var roleData = response.data.data;
            roleData.map((data1) => {
              RoleDataArray = [...RoleDataArray, data1]
            });

          }
          setRolData(RoleDataArray);
        }
      })
      .catch(function (err) {
      });
  }

  //image onchange 
  const onChange = (e) => {

    var checkedvalue = e.target.value
    if (e.target.name !== 'All') {
      setCheckedResource(e.target.checked ? [...checkedResource, checkedvalue] : checkedResource.filter(e => e !== checkedvalue))
    }
    if (e.target.name == 'All' && e.target.checked) {
      $(".case").prop("checked", e.target.checked);
      resourceData.map((resource) => {
        finalResourceArr.push(resource._id)
      })
      setCheckedResource(finalResourceArr);
    } else if (e.target.name == 'All' && e.target.checked === false) {
      $(".case").prop("checked", e.target.checked);
      setCheckedResource([])
    }
    if (resourceData.length == $(".case:checked").length) {
      $("#All").prop("checked", e.target.checked);
    } else {
      $("#All").prop("checked", false);
    }
  }

  const handleOnchange = (e) => {
    const { value, name } = e.target;
    switch (name) {
      case "full_name":
        err.full_name = value.length > 0 ? "" : "Enter full name";
        break;
      case "email":
        err.email = value.length > 0 ? "" : "Enter your email";
        err.email = !new RegExp(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        ).test(value)
          ? "Enter a valid email address"
          : "";
        break;
      case "phone_no":
        if (value.length !== 10) {
          err.phone_no = "phone atleast have 10 number";
        } else if (!/^[0-9\b]+$/.test(value)) {
          err.phone_no = "Enter only number";
        } else if (value.length === "") {
          err.phone_no = "Enter phone number";
        } else {
          err.phone_no = "";
        }
        break;
      case "role":
        err.role = value.length > 0 ? "" : "Enter role";
        break;

      default:
        break;
    }

    setFormFields({ ...formFields, [name]: value });
    seterr({ ...err });
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();


    if (formFields.full_name === "") {
      err.full_name = "Enter full name";

    } else {
      err.full_name = "";
    }
    if (formFields.role === "") {
      err.role = "Enter role"

    } else {
      err.role = "";
    }

    if (formFields.phone_no === "") {
      err.phone_no = "Enter phone number"

    } else {
      err.phone_no = "";
    }

    if (formFields.email === "") {
      err.email = "Enter email"

    } else {
      err.email = "";
    }
    seterr({ ...err });

    if (err.full_name === "" && err.role === "" && err.email === "" && err.phone_no === "") {
      var bodydata = {
        fullname: formFields.full_name,
        email: formFields.email,
        phone_number: formFields.phone_no,
        assign_role: formFields.role,
        role_resources: checkedResource
      };
      setIsLoading(true)
      Services.addAdminUSer(menuID, bodydata)
        .then((response) => {
          setIsLoading(false)
          if (response.data.status == true) {
            swal("Success", response.data.message, "success");
            history(`/role-list?id=${getMenuID}`)

          } else {
            setIsLoading(false)
            swal("Failed", response.data.message, "error");
          }
        })
        .catch((err) => {
          setIsLoading(false)
          swal("Failed", err.response.data.message, "error");

        });
    };
  }
  return (
    <>
      <main class="page-content bg-light">
        <Header title="Add Admin User" main_title="Admin Setting" />
        <div class="container-fluid">
          <div class="layout-specing">
            <div className="d-flex justify-content-between align-items-center mb-3">
              <h4 className="mb-0">Add Admin User</h4>
              <NavLinkHeader title1="User List" title1_link={`/role-list?id=${getMenuID}`} title2="Add Admin User" />
            </div>
            <div class="row">
              {/* <input type="text" ref={ref} placeholder="Find & Select your Organization on Google"/> */}
              <div class="col-md-12">
                <div class="card border-0 shadow rounded mb-4 p-3">

                  <form id="add-organization" onSubmit={handleOnSubmit}>

                    <div class="mb-4 col-md-7 ">
                      <label class="cstm-label">Full name</label>
                      <input
                        name="full_name"
                        id="full_name"
                        type="text"
                        class="cstm-input"
                        placeholder="Enter first name"
                        onChange={handleOnchange}
                      />
                      {err.full_name !== "" && (
                        <span style={{ color: "red" }}>
                          {err.full_name}
                        </span>
                      )
                      }
                    </div>
                    {/* <div iv class="mb-4 col-md-7 ">
                      <label class="cstm-label">Last name</label>
                      <input
                        name="last_name"
                        id="last_name"
                        type="text"
                        class="cstm-input"
                        placeholder="Enter last name"
                        onChange={handleOnchange}
                      />
                      {err.last_name !== "" && (
                        <span style={{ color: "red" }}>
                          {err.last_name}
                        </span>
                      )
                      }
                    </div> */}

                    <div class="mb-4 col-md-7 ">
                      <label class="cstm-label">Phone number</label>
                      <input
                        name="phone_no"
                        id="phone_no"
                        type="text"
                        maxlength="10"
                        class="cstm-input"
                        placeholder="Enter phone number"
                        onChange={handleOnchange}
                      />
                      {err.phone_no !== "" && (
                        <span style={{ color: "red" }}>
                          {err.phone_no}
                        </span>
                      )
                      }
                    </div>
                    <div class="mb-4 col-md-7 ">
                      <label class="cstm-label">Email</label>
                      <input
                        name="email"
                        id="email"
                        type="text"
                        class="cstm-input"
                        placeholder="Enter email"
                        onChange={handleOnchange}
                      />
                      {err.email !== "" && (
                        <span style={{ color: "red" }}>
                          {err.email}
                        </span>
                      )
                      }
                    </div>

                    <div class="mb-4 col-md-7">
                      <label class="cstm-label">Role</label>
                      <select
                        name="role"
                        class="form-select cstm-category"
                        aria-label="Default select example"
                        onChange={handleOnchange}
                      >
                        <option value="" selected>
                          Select Role
                        </option>
                        {rolData.length !== 0 && rolData.map((role, i) => {
                          return (
                            <option value={role._id}>{role.role_name}</option>

                          )
                        })
                        }

                      </select>
                      {err.role !== "" && (
                        <span style={{ color: "red" }}>
                          {err.role}
                        </span>
                      )
                      }
                    </div>
                    <div class="mb-4 col-md-7">
                      <div className='table-responsive bg-white border-top rounded-0 border cstm-rr'>
                        <table class="table mb-0 table-center">
                          <tr>
                            <th className='border-bottom'>Role Resources</th>
                            <th className='border-bottom'><input type="checkbox" id="All" name="All" value="" onChange={(e) => onChange(e)} /> Select All</th>
                          </tr>
                          {
                            resourceData.length !== 0 && resourceData.map((resource, i) => {
                              return (
                                <tr>
                                  <td>{resource.resource_name}</td>
                                  <td><input type="checkbox" id={resource.resource_name} class="case" name="resources_access[]" value={resource._id} onChange={(e) => onChange(e)} /> Active</td>
                                </tr>
                              )
                            })
                          }

                        </table>
                      </div>

                      {err.resource_access !== "" && (
                        <span style={{ color: "red" }}>
                          {err.resource_access}
                        </span>
                      )
                      }
                    </div>
                    <div class="d-flex cstm-loader">
                      <div className="cstm-ldr-mn">
                        <button class="cstm-btn cstm-submitbutton" type="submit">
                          Add Admin User
                        </button>
                        {isLoading &&
                          <div id="btn-loader" className="btn-loader-main">
                            <img className="btn-loaderImg" src={loader} /></div>
                        }
                      </div>
                    </div>
                  </form>

                  {/* </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  )
}

export default AddAdminUser

